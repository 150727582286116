// Customizable Area Start
export const checkedIcon = require('../assets/checkedIcon.png');

export const pinkDownArrowIcon = require('../assets/pinkDownArrowIcon.png');

export const digitIcon = require('../assets/digitIcon.png');
export const closeIcon = require('../assets/closeIcon.png');
export const backIcon= require('../assets/backIcon.png');
export const backgroundImage= require('../assets/backgroundImage.png');
export const tapsureLogo = require('../assets/tappmartLogo.png');
export const aboutHomeIcon= require('../assets/aboutHomeIcon.png');
export const careHealthLogo = require('../assets/CareHealth.jpg');
export const premiumIcon = require('../assets/premiumIcon.png');
export const calendarIcon = require('../assets/calendarIcon.png');
export const zeroHassleIcon = require('../assets/zeroHassleIcon.png');
export const nomedicalIcon = require('../assets/nomedicalIcon.png');
export const familyProtectIcon = require('../assets/familyProtectIcon.png');
export const healthBoosterIcon = require('../assets/healthBoosterIcon.png');

// Customizable Area End