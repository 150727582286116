import React from "react";
// Customizable Area Start
import {
  Box,
  Modal,
  Container,
  Typography
} from "@material-ui/core";
import {
    menu,
    close,
    profile_user_topbar,
    tappmart_logo
} from "./assets";
import DashboardControllerThree from "./DashboardControllerThree";
// Customizable Area End

// Customizable Area Start
class MerchantLoginIconHeader extends DashboardControllerThree {

    handleOpen = () => {
        this.setState({ flag: !this.state.flag });
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ flag: !this.state.flag });
        this.setState({ open: false });
    }

    render() {
        const { navigation } = this.props;
        return (
            <>
                <Container maxWidth='sm'>
                    {/*@ts-ignore */}
                    <Box
                        sx={style.header}
                    >
                        {!this.state.flag ?
                            <img
                                style={{transform: "scale(1)", marginLeft: "-8px", cursor: 'pointer'}}
                                src={menu}
                                alt="menu"
                                onClick={() => this.handleOpen()}
                                />
                                :
                                <img
                                style={{marginLeft: "-8px"}}
                                src={close}
                                data-test-id="imgElementTest"
                                alt="close"
                                height="20"
                                width="20"
                                onClick={() => this.handleOpen()}
                            />
                        }
                        <img
                            style={{marginRight: "145px", cursor: 'pointer'}}
                            src={tappmart_logo}
                            height="40" 
                            alt="logo"
                            data-test-id="logoTest"
                            onClick={() => navigation.navigate("Dashboard")}
                        />
                        <img
                            style={{marginRight: "-12px", cursor: 'pointer'}}
                            src={profile_user_topbar}
                            data-test-id="myProfileTest"
                            alt=""
                            onClick={() => navigation.navigate("MyProfile")}
                        />
                    </Box>
                </Container>
                
                {/* FAQs and Contact Us/Support Modal */}

                <Container maxWidth="sm">
                    {/*@ts-ignore */}
                    <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                        data-test-id="modalTest"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        {/*@ts-ignore */}
                        <Box sx={style.modalStyle}>
                            {/*@ts-ignore */}
                            <Box sx={style.textStyle}>
                            <Typography
                                    style={{cursor: 'pointer'}}
                                    variant="h6"
                                    data-test-id="aboutUsTest"
                                    gutterBottom
                                    onClick={() => navigation.navigate("AboutUs")}
                                >
                                    <b>About Us</b>
                                </Typography>
                                 {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="ContactUsTest"
                                    variant="h6"
                                    onClick={() => navigation.navigate("ContactUs")}
                                >
                                    <b>Contact Us/Support</b>
                                </Typography>
                                 {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="FAQsTest"
                                    variant="h6"
                                    gutterBottom
                                    onClick={() => navigation.navigate("FAQs")}
                                >
                                    <b>FAQs</b>
                                </Typography>
                                {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="TCGroupInsuranceTest"
                                    variant="h6"
                                    gutterBottom
                                    onClick={() => navigation.navigate("TCGroupInsurance")}
                                >
                                    <b>Terms & Conditions - Group Insurance</b>
                                </Typography>
                                 {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="PrivacyPolicyTest"
                                    variant="h6"
                                    onClick={() => navigation.navigate("PrivacyPolicy")}
                                >
                                    <b>Privacy Policy</b>
                                </Typography>
                                 {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="TermsAndConditionsTest"
                                    variant="h6"
                                    onClick={() => navigation.navigate("TermsAndConditions")}
                                >
                                    <b> Terms & Conditions - General</b>
                                </Typography>
                                 {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="RefundAndCancelTest"
                                    variant="h6"
                                    onClick={() => navigation.navigate("RefundAndCancel")}
                                >
                                    <b>Refund And Cancellation</b>
                                </Typography>
                                {/*@ts-ignore */}
                                <Box style={style.hr} />
                                <Typography
                                    style={{cursor: 'pointer'}}
                                    data-test-id="BlogsTest"
                                    variant="h6"
                                    onClick={() => navigation.navigate("Blogs")}
                                >
                                    <b>Blogs</b>
                                </Typography>
                                
                            </Box>
                            {/*@ts-ignore */}
                            <Box sx={style.footer}>
                                <Typography
                                    variant="body2"
                                >
                                    &copy; Copyright 2008-2022 tappmart.All Rights Reserved
                                </Typography>
                            </Box>
                        </Box>
                    </Modal>
                </Container>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    header: {
        p: 1.5,
        mt: 0.75,
        mb: 0.75,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '65%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        width: '100%',
        height: '100%',
        overflowY: "scroll",
      
    },
    textStyle: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    hr: {
        marginTop: 16,
        marginBottom: 25,
        borderTop: '1px solid grey'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '110%',
    }
};

// Customizable Area End
export default MerchantLoginIconHeader;