import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Button,
  Container,
  Typography,
} from "@material-ui/core";
import MerchantLoginIconHeader from "../../dashboard/src/MerchantLoginIconHeader.web";
import {
    background,
    success_tick
} from "./assets";
import AdditionalDetailFormController from "./AdditionalDetailFormController";
// Customizable Area End

// Customizable Area Start
class CongratulationsPage extends AdditionalDetailFormController {

    render() {
        const { navigation } = this.props;
        return (
            <>
                {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>
                    <Container maxWidth='sm'>
                        <Paper elevation={2}>
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <img
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                    src={success_tick}
                                    alt=""
                                    height="50"
                                    width="50"
                                />
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                >
                                    <b>Congratulations</b>
                                </Typography>
                                <Typography
                                    gutterBottom
                                    align="center"
                                    variant="body1"
                                >
                                    Your account has been created successfully.
                                </Typography>
                                <Button
                                    style={style.dashboardBtn}
                                    size="large"
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    onClick={() => navigation.navigate("Dashboard")}
                                    data-test-id="handle-navigation"
                                >
                                    Go to Dashboard
                                </Button>
                            </Box>
                        </Paper>
                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    image: {
        height: '100%',
        paddingBottom: "25px",
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        height: '92%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dashboardBtn: {
        marginTop: "10px",
        padding: "15px",
        borderRadius: 8,
    }
};
// Customizable Area End
export default CongratulationsPage;