import React  from "react";

// Customizable Area Start
 
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
 
import HealthInsuranceController, { Props } from "./HealthInsuranceController";
// Customizable Area End

// Customizable Area Start

export default class CustomerPolicyTabs extends HealthInsuranceController {
  constructor(props: Props) {
    super(props);
  }
  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  render() {
    return (
      <>
         {/* nothing */}
      </>
    );
  }
}
// Customizable Area End


// Customizable Area Start
 
 

// Customizable Area End
