import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Modal,
  Button,
  Container,
  Typography,
  Chip,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
  back,
  background,
  star_logo,
  reliance_logo,
  download,
  close,
  facebook,
  gmail,
  whatsapp,
} from "./assets";

// import QRCode from "react-qr-code";

import DashboardControllerThree from "./DashboardControllerThree";
import QRCode from "qrcode.react";

// Customizable Area End

// Customizable Area Start
class MerchantCatalogue extends DashboardControllerThree {
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  qrModal = (item: any) => {
    let productName = item.attributes.name;
    let product_id=item?.id
    this.handlQrCodePath(productName,product_id)
    this.setState({ openQrCode: true, selectedProduct: item });
  };

  sharePolicyModal = (item: any) => {
    let productName = item?.attributes?.name;
    let product_id=item?.id
    this.handlQrCodePath(productName,product_id )
    this.setState({ openSharePolicy: true});
  };

  handleQrClose = () => {
    this.setState({ openQrCode: false });
  };

  handleSharePolicyClose = () => {
    this.setState({ openSharePolicy: false });
  };

  handlQrCodePath = (productName: any,product_id :any) => {
    let merhchantId=localStorage.getItem('merchantId')
    let qrPath = ""
    if (productName == "Two Wheeler Insurance") {
      qrPath = "CustomerTwoWheelerSteps?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "Four Wheeler Insurance") {
      qrPath = "CustomerFourWheelerSteps?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "ICICI Lombard Home Insurance") {
      qrPath = "CustomerHomeInsPolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "ICICI Lombard Cyber Fraud") {
      qrPath = "CustomerCyberFraudPolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "One Assist") {
      qrPath = "CustomerOneAssistPolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "ICICI Lombard Travel Insurance") {
      qrPath = "CustomerTravelInsPolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "Care Hospicash") {
      qrPath = "CustomerHospiCashPolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "Care Vector Borne") {
      qrPath = "CustomerVBCarePolicyDetails?merchantId="+merhchantId+"&product_id="+product_id
    }
    else if (productName == "DoctorOnCall") {
      qrPath = "CustomerHealthServicePolicyTabs?merchantId="+merhchantId+"&product_id="+product_id
    }
    this.setState({
      qrPath
    })
  }

  render() {
    const { navigation } = this.props;
    const mockData = [
      {
        id: 1,
        type: "Family Health Plan",
        company: "Star Health and Allied Insurance",
        image: star_logo,
      },
      {
        id: 2,
        type: "Health Infinity (More Global)",
        company: "Reliance General Insurance",
        image: reliance_logo,
      },
      {
        id: 3,
        type: "General Health Insurance",
        company: "Reliance General Insurance",
        image: reliance_logo,
      },
    ];
    return (
      <>
      <Backdrop
                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                    open={this.state.isLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

      {/*@ts-ignore */}
        <MerchantLoginIconHeader {...this.props} />
        <div style={style_MerchantCatalogueWeb.image}>
          <Container maxWidth="sm">
            {/*@ts-ignore */}
            <Box
              sx={style_MerchantCatalogueWeb.back}
              onClick={() => navigation.navigate("Dashboard")}
            >
              <img src={back} alt="" height="15" width="10" />
              <Typography
                style={{ marginTop: -2, marginLeft: 8 }}
                variant="body2"
              >
                Back to Dashboard
              </Typography>
            </Box>
            {/*@ts-ignore */}
            <Box sx={style_MerchantCatalogueWeb.customers}>
              <Typography variant="h6">Catalogue</Typography>
            </Box>
            {/*@ts-ignore */}
            <Box sx={style_MerchantCatalogueWeb.tabContainer}>
              {/*@ts-ignore */}
              <Box sx={style_MerchantCatalogueWeb.tabs}>
                {this.state.categories.length ? (
                  this.state.categories.map((category: any, id: number) => (
                    <>
                    {/*@ts-ignore */}
                    <Box sx={style_MerchantCatalogueWeb.tabItem} key={id}>
                      <Chip 
                        label={
                          category[0] +
                          " (" +
                          category[1] +
                          ")"
                        }
                        style={
                          this.state.selectedCategory == category[0]
                            ? {
                              background: "#1876d2",
                              color: "white",
                              fontWeight: "bold",
                            }
                            : { background: "#9157af", color: "white" }
                        }
                        onClick={() => this.handleCategory(category)}
                      />
                    </Box>
                    </>
                  ))
                ) : (
                  ""
                )}
              </Box>
            </Box>
            {
            this.state.products.length > 0 ? this.state.products.map((item: any,id:any) => {
              return (
                <Paper elevation={2} key={id}
                  style={{ cursor: 'hand' }}
                  onClick={() => {
                    // if (parseInt((this.state.selectedCategory).toString()) === 8) {
                    //   localStorage.setItem("selectedProduct", JSON.stringify(item));
                    //   // this.props.navigation.navigate("CustomerHealthPolicy");
                    // }
                    //this.selectPolicyProduct(this.state.selectedCategory, item)
                  }
                  }
                >
                  {/*@ts-ignore */}
                  <Box sx={style_MerchantCatalogueWeb.companies} >
                    {/*@ts-ignore */}
                    <Box sx={style_MerchantCatalogueWeb.familyHealth}>
                      <img
                        src={item.attributes.image}
                        alt=""
                        height="50"
                        width="50"
                      />
                      {/*@ts-ignore */}
                      <Box sx={style_MerchantCatalogueWeb.company} >
                        <Typography gutterBottom variant="body1">
                          <b>{item.attributes.name}</b>
                        </Typography>
                        <Typography variant="body2">
                          {item.attributes.discription}
                        </Typography>
                      </Box>
                    </Box>
                    {/*@ts-ignore */}
                    <Box sx={style_MerchantCatalogueWeb.buttons}>
                      <Button
                        style={{
                          padding: "10px",
                          backgroundColor: "rgb(70, 130, 180, 0.1)",
                          color: "#4682B4",
                          textTransform: "capitalize",
                        }}
                        fullWidth
                        variant="outlined"
                        onClick={() => this.qrModal(item)}
                      >
                        <b>QR Code</b>
                      </Button>
                      <Button
                        style={{
                          padding: "10px",
                          marginLeft: "10px",
                          textTransform: "capitalize",
                        }}
                        fullWidth
                        variant="outlined"
                        onClick={() => this.sharePolicyModal(item)}
                      >
                        <b>Share Policy</b>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              );
            }) : "" 
          }
        

          </Container>

          <Container maxWidth="sm">
            {/*@ts-ignore */}
            <Modal
              open={this.state.openQrCode}
              onClose={this.handleQrClose}
              aria-labelledby="modal-modal-title"
            >
              {/*@ts-ignore */}
              <Box sx={style_MerchantCatalogueWeb.container}>
                {/*@ts-ignore */}
                <Box sx={style_MerchantCatalogueWeb.filter}>
                  <Typography variant="h6">
                    <b>QR Code</b>
                  </Typography>
                  <img
                    src={close}
                    alt=""
                    height="18"
                    width="18"
                    onClick={this.handleQrClose}
                  />
                </Box>
                {/*@ts-ignore */}
                <Box sx={style_MerchantCatalogueWeb.insurance}>
                  <img
                    style={{ marginBottom: "5px" }}
                    src={this.state.selectedProduct?.attributes?.image}
                    alt=""
                    height="70"
                    width="70"
                  />
                  <Typography variant="h6">
                    <b>{this.state.selectedProduct?.attributes?.name}</b>
                  </Typography>
                  <Typography variant="body1">{this.state.selectedProduct?.attributes?.discription}</Typography>
                  {/* <img
                                        style={{marginTop: '15px',}}
                                        src={qr}
                                        alt=""
                                        height="220"
                                        width="220"
                                    /> */}
                  <QRCode
                    size={220}
                    value={this.state.qrUrl + this.state.qrPath}
                    includeMargin={true}
                  />
                  <Button
                    data-test-id="download-qr"
                    onClick={this.downloadQRCode}
                    style={style_MerchantCatalogueWeb.download}
                    fullWidth
                    variant="outlined"
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={download}
                      alt=""
                      height="20"
                      width="20"
                    />
                    <b>Download QR Code</b>
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Container>

          <Container maxWidth="sm">
            {/*@ts-ignore */}
            <Modal
              open={this.state.openSharePolicy}
              onClose={this.handleSharePolicyClose}
              aria-labelledby="modal-modal-title"
            >
              {/*@ts-ignore */}
              <Box sx={style_MerchantCatalogueWeb.containerSharePolicy}>
                <div style={{ display: "none" }}>
                  <QRCode
                    size={220}
                    value={this.state.qrUrl + this.state.qrPath}
                    includeMargin={true}
                  />
                </div>
                {/*@ts-ignore */}
                <Box sx={style_MerchantCatalogueWeb.filter}>
                  <Typography variant="h6">
                    <b>Share Policy</b>
                  </Typography>
                  <img
                    style={{ cursor: "pointer" }}
                    src={close}
                    alt=""
                    height="18"
                    width="18"
                    onClick={this.handleSharePolicyClose}
                  />
                </Box>
                {/*@ts-ignore */}
                <Box sx={style_MerchantCatalogueWeb.socialMedia}>
                  <a
                    // href={
                    //   "mailto:?subject=I wanted you to see this site&amp;body=Check out this site -" +
                    //   this.state.qrUrl +
                    //   this.state.qrPath
                    // }
                    title="Share by Email"
                  >
                    <img src={gmail} alt="" height="65" width="65" data-test-id="share-img" onClick={this.handleShare} />
                  </a>
                  <a
                  // href={
                  //   "https://api.whatsapp.com/send?text= Kindly tap to check our latest policy here -" +
                  //   this.state.qrUrl +
                  //   this.state.qrPath
                  // }
                  // target="_blank"
                  >
                    <img src={whatsapp} alt="" height="65" width="65" onClick={this.handleShare} />
                  </a>
                  <a
                  // href={
                  //   "https://www.facebook.com/sharer/sharer.php?u=" +
                  //   this.state.qrUrl +
                  //   this.state.qrPath
                  // }
                  // target="_blank"
                  >
                    <img src={facebook} alt="" height="65" width="65" onClick={this.handleShare} />
                  </a>
                </Box>
                {/*@ts-ignore */}
                <Box sx={style_MerchantCatalogueWeb.link}>
                  <Typography variant="body1" style={{ overflow: "scroll" }}>
                    {this.state.qrUrl + this.state.qrPath}
                  </Typography>
                  <Button
                    style={{ color: "rgb(200,0,200,1)" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${this.state.qrUrl + this.state.qrPath}`
                      )
                    }
                  >
                    <b>Copy Link</b>
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Container>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style_MerchantCatalogueWeb = {
  image: {
    paddingBottom: "25px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
  },
  back: {
    mt: 3,
    display: "flex",
    color: "white",
    flexDirection: "row",
    cursor: "pointer",
  },
  familyHealth: {
    display: "flex",
    flexDirection: "row",
  },
  tabContainer: {
    paddingBottom: "10px",
    display: "block",
    overflowX: "scroll",
    width: "100%",
  },
  tabs: {
    mt: 1,
    display: "block",
    whiteSpace: "nowrap",
  },
  tabItem: {
    display: "inline-block",
    marginRight: "10px",
  },
  customers: {
    mt: 2,
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  company: {
    display: "flex",
    flexDirection: "column",
    ml: 1.5,
  },
  companies: {
    p: 2,
    mt: 2,
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    mt: 2.5,
    mr: 3,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },

  // QR Code
  container: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "63%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  insurance: {
    mt: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  download: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "rgb(70, 130, 180, 0.1)",
    color: "#4682b4",
  },

  // Share Policy
  containerSharePolicy: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "102%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  socialMedia: {
    mt: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  link: {
    p: 1.2,
    mt: 4,
    border: "0.1px solid #d3d3d3",
    backgroundColor: "rgb(70, 130, 180, 0)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
// Customizable Area End
export default MerchantCatalogue;
