import React, { Component } from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
 
  withStyles,
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Drawer from "@material-ui/core/Drawer";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import {
  
  tapsureLogo,
  digitIcon,
  closeIcon,
  backgroundImage,
  backIcon
} from "./assets";

import CustomerHomeInsController2, { Props } from "./CustomerHomeInsController2";

// Customizable Area End

// Customizable Area Start

export default class CustomerHomeInsDetailsSteps extends CustomerHomeInsController2 {

  constructor(props: Props) {
    super(props);
     
  }
  
 

  render() {
    
    return (
      <>

        {/* Logo section */}

        <Snackbar open={this.state.openToast} autoHideDuration={1000} style={{ margin: "10px" }}>
          <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
            {this.state.toastMessage}
          </Alert>
        </Snackbar>
        <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
           {/*@ts-ignore */}
          <Modal
            open={this.state.openModal}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modalClass">
              <div style={webStyle.modalStyle}>
                <div>
                  <InfoIcon style={{ marginTop: '15px' }} />
                </div>
                <div style={{ padding: 5 }}>
                  <h4>We regret that your policy creation has failed at our end. In case payment is done, it will be  refunded within next 72 hours.</h4>
                </div>
                <div>
                  <Button
                    onClick={() => this.closeModel()} >
                    <img height="15px" src={closeIcon} alt="" />
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Backdrop>


        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={{ margin: "10px" }}
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
        </Grid>{" "}
        {/* Steps-Container */}
         {/*@ts-ignore */}
        <FormContainer container>
        <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ margin: "10px" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={""}>
               {/*@ts-ignore */}
              <FormStepper  alternativeLabel>
                
                  <Step >
                    {/*@ts-ignore */}
                    <CustomStepLabel>{this.state.steps}</CustomStepLabel>
                  </Step>
             
              </FormStepper>
               
                <Grid style={formDivStyle}>
                
                  <Grid container spacing={2}>
                  {/* <Grid item sm={12} md={12} lg={12} xs={12}>
             
            </Grid> */}
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Full Name
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder="Full Name"
                type="text"
                aria-invalid="false"
                value={this.state.insurerName}
                variant="outlined"
                onChange={this.handleInsurerName}
                error={!this.state.insurerNameValid}
                helperText={
                  this.state.insurerNameValid
                    ? ""
                    : "Please enter Full name."
                }
              />
              </Grid>

              <Grid item sm={12} md={12} xs={12}> 
              {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Contact Person Name
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder="Contact Person"
                type="text"
                aria-invalid="false"
                value={this.state.contactPerson}
                variant="outlined"
                onChange={this.handleContactPerson}
                error={!this.state.isContactPersonValid}
                helperText={
                  this.state.isContactPersonValid
                    ? ""
                    : "Please enter name."
                }
              />
              </Grid>


            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                {" "}
                Email
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder="Email"
                variant="outlined"
                type="text"
                value={this.state.email}
                onChange={this.handleEmail}
                error={!this.state.isEmailValid}
                helperText={
                  this.state.isEmailValid
                    ? ""
                    : "Please enter a valid Email."
                }
              />
            </Grid>
      
               <Grid item sm={12} md={12} xs={12}>
                 {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Phone Number
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number"
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumber}
                error={!this.state.isPhoneNumberValid}
                helperText={
                  this.state.isPhoneNumberValid
                    ? ""
                    : "Please enter a valid Number."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Gender
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomFormControl variant="outlined">
                 {/*@ts-ignore */}
                <CustomSelect
                  native
                  value={this.state.gender}
                 onChange={this.handleGender}
                  inputProps={{
                    name: "gender",

                    id: "outlined-age-native-simple",
                  }}
                  error={!this.state.isGenderValid}
                >
                  <option aria-label="None" value="">
                    Gender
                  </option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                  {/* <option value={"Others"}>Others</option> */}
                </CustomSelect>
                {this.state.isGenderValid ? (
                  ""
                ) : (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Select a valid Gender.
                  </FormHelperText>
                )}
              </CustomFormControl>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Date Of Birth
              </CustomInputLabel>
                   {/*@ts-ignore */}
              <CustomInputField
                id="outlined-start-adornment"
                type="date"
                className={""}
                value={this.state.dateOfBirth}
                onChange={this.handleDOB}
                variant="outlined"
                placeholder="Date of Birth"
                InputProps={{inputProps: { min: "", max: this.state.maxYear} }}
                error={!this.state.isDateOfBirthValid}
                helperText={
                  this.state.isDateOfBirthValid
                    ? ""
                    : "Please enter a valid Date."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Nominee Name
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder="Nominee Name"
                type="text"
                aria-invalid="false"
                value={this.state.nomineeName}
                variant="outlined"
                onChange={this.handleNomineeName}
                error={!this.state.isNomineeNameValid}
                helperText={
                  this.state.isNomineeNameValid
                    ? ""
                    : "Please enter Nominee name."
                }
              />
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                 {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Relationship With Nominee
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomFormControl variant="outlined">
                 {/*@ts-ignore */}
                <CustomSelect
                  native
                  value={this.state.relationship}
                  onChange={this.handleRelationship}
                  inputProps={{
                    name: "age",

                    id: "outlined-age-native-simple",
                  }}
                  error={!this.state.isRelationshipValid}
                >
                  <option aria-label="None" value="">
                    Relationship
                  </option>
                  <option value={"self"}>Self</option>
                  <option value={"BR"}>Brother</option>
                  <option value={"DA"}>Daughter</option>
                  <option value={"FA"}>Father</option>
                  <option value={"HU"}>Husband</option>
                  <option value={"MO"}>Mother</option>
                  <option value={"SI"}>Sister</option>
                  <option value={"SO"}>Son</option>
                  <option value={"WI"}>Wife</option>
                </CustomSelect>
                {this.state.isRelationshipValid ? (
                  ""
                ) : (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Select Relationship
                  </FormHelperText>
                )}
              </CustomFormControl>
            </Grid> 
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Street Address
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder=" Street Address "
                variant="outlined"
                value={this.state.addressOne}
                onChange={this.handleAddressOne}
                error={!this.state.isAddress1Valid}
                helperText={
                  this.state.isAddress1Valid
                    ? ""
                    : "Please enter a valid Adderess."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Pincode
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="number"
                placeholder="Pincode"
                value={this.state.pinCode}
                onChange={this.handlePinCode}
                 error={!this.state.isPinCodeValid}
                helperText={
                  this.state.isPinCodeValid
                    ? ""
                    : "Please enter a valid pincode."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
              Locality
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomFormControl variant="outlined">
                 {/*@ts-ignore */}
                <CustomSelect
                  native
                  value={this.state.locality}
                  onChange={this.handleCity}
                  inputProps={{
                     value:this.state.locality
                  }}
                  error={!this.state.isCityValid}
                >
                  
                    {this.state.city.map((e:any) => (
                      <option key={e.id} value={e.locality}>{e.locality}</option>
                    ))}
                  
                 
                </CustomSelect>
                {this.state.isCityValid ? (
                  ""
                ) : (
                  <FormHelperText style={{ color: "#f44336" }}>
                   Please Select One
                  </FormHelperText>
                )}
              </CustomFormControl>
            </Grid>

           
            <Grid item sm={12} md={12} xs={12} style={{ margin: '5px 0px' }}>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-select-title"
                select
                fullWidth
                label="Sum to be Insured"
                name="sumToBeInsured"
                //error={this.state.isErrorPersonal.title ? true : false}
                value={this.state.policyDetailsForm.sumToBeInsured}
                onChange={this.handleChangePolicy}
              >
                {
                  this.homePolicyPlans.map((data, index) => {
                    return (
                      <MenuItem value={data.amount} key={index}>{data.amount}</MenuItem>
                    )
                  })
                }
              </CustomInputField>
             
            </Grid>

          </Grid>
         </Grid> 
            
            </div>
          </Grid>
        </FormContainer>
        {/* Premium section */}
         {/*@ts-ignore */}
        <BottomContainer container spacing={2}>
          
            <>
              <Grid item sm={7} lg={7} xs={7}>
                <span>Total Premium</span>
                <br/>
                <span>
                {this.state.policyDetailsForm.premium}<ExpandMoreIcon />
                </span>
              </Grid>
              <Grid item sm={5} lg={5} xs={5}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => this.handleProceed()}
                  >
                    Proceed
                  </Button>
                </div>
              </Grid>
            </>
          
        
         
 
        </BottomContainer>
        <Loader loading={this.state.loading} />
        {/* Drawer Section */}
         {/*@ts-ignore */}
        <CustomDrawer
          open={this.state.openPolicyDetailDrawer}
          anchor={"bottom"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            <Grid item>
              <h3> Payment Details</h3>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                {
                  this.handleDrawerClose();
                }
                }
              >
                <img height="10px" src={closeIcon} alt="" />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            <Grid item>
              <img style={webStyle.digitImage} src={digitIcon} alt="" />
            </Grid>
            <Grid item>
              <h4> Total Premium</h4>
              <h3>{this.state.policyDetailsForm.premium}</h3>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            <Grid item>
              <img style={webStyle.iciciImage}  alt="" />
            </Grid>
            <Grid item>
              <div>Total Premium (Inc 18% GST)</div>
              <h3 style={{ float: "right" }}>
                <b>&#x20B9; {this.state.policyDetailsForm.premium}</b>
              </h3>
            </Grid>
          </Grid>
       

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.drawerSectionStyle}
          >
            <Grid item>
              <p>Policy Period</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle.marginZero}>1 Year</h3>
            </Grid>
          </Grid>
 

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                 {
                  this.createPayOrder();
                 }
                }
                style={{ width: "100%", marginTop: "100px" }}
              >
                Make Payment
              </Button>

            </Grid>
          </Grid>
        </CustomDrawer>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const FormStepper = withStyles({
  root: {
    // backgroundImage: `url(${backgroundImage})`,
    // backgroundSize: "cover",
    padding: "15px 5px",
    // backgroundColor: "inherit",
    background:'inherit'
  },
})(Stepper);
const FormContainer = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const formDivStyle = {
  backgroundColor: "white",
  padding: "10px 15px",
  borderRadius: "10px",
  margin: "10px",
};
const BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const CustomStepLabel = withStyles({
  root: {
    color: "white",
    "& .MuiStepLabel-labelContainer": {
      color: "white",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "white",
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "White",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "white",
        border:'none'
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "transparent",
         borderRadius: "12px",
      },
      "& .MuiStepIcon-root":{
        border: "1px solid white",
        borderRadius: "12px",
        color: "transparent",
      }
    },
    font:
      "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(StepLabel);

const webStyle: any = {
  greyText: {
    color: "grey",
    marginTop: "5px",
  },
  paddingLeftBottom: {
    padding: "0 15px",
  },
  marginBottom: "15px",
  drawerSectionStyle: {
    borderBottom: "1px solid #4a444430",
    padding: "0 15px",
  },
  marginZero: {
    margin: "0px",
  },
  digitImage: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #8080804a",
    height: "50px",
  },
  checkedRadio: {
    border: "1px solid #4054b5",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#4054b5",
    fontWeight: "bold",
  },
  unCheckedRadio: {
    border: "1px solid #e1e1e1",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#848484",
    fontWeight: "bold",
  },
};

const InputField = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

const CustomInputLabel = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);

const CustomDrawer = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      padding: "0 5px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Drawer);
 
const CustomInputField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField);

const CustomSelect = withStyles({
  root: {
    padding: "15px",
  },
})(Select);
const CustomFormControl = withStyles({
  root: {
    width: "100%",
    marginTop: 5,
  },
})(FormControl);

// Customizable Area End
