import React, { Component } from "react";

// Customizable Area Start
import {
  Grid,
  Button,
  Typography,
  AppBar,
  Tabs,
  Box,
  Tab,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import {
  tapsureLogo,
  starIns,
  calendarIcon,
  premiumIcon,
  iciciIcon,
  nivaHealthInsIcon,
  backgroundImage
} from "./assets";
import PropTypes from "prop-types";
import CatalogueController, { Props } from "./CatalogueController";
// Customizable Area End

// Customizable Area Start
export default class CustomerHealthPolicy extends CatalogueController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={CustomerHealthPolicyStyle}
              onClick={() => this.redirectToComponent("CustomerHomeScreen", '')}
            />
          </Grid>
        </Grid>{" "}
        <CustomerHealthPolicyMainContainer container spacing={2}>
          {/* Arrow Container */}
          <Grid container>
            <Grid>
              <h3 style={{ color: "white", marginLeft: "15px" }}>
                {'Health Policy Plans'
                }</h3>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          {
            this.policyList.map((data, index) => {
              return (
                <Grid container style={CustomerHealthPolicyChildContainer} key={index}>
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    </div>
                  </Grid>

                  {/* Policy Cards */}

                  <Grid item sm={12} xs={12} lg={12}>
                    <Grid container direction="row" justifyContent="space-between"
                      style={{ flexWrap: "nowrap", marginTop: 5 }}>
                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"


                        >
                          <Grid item>
                            <img src={premiumIcon} height="20px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Premium</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.premium}</h3>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <img src={premiumIcon} height="25px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Sum Insured</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.amount}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => this.redirectToComponent('CustomerPolicyTabs', data)}
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              )
            })
          }
        </CustomerHealthPolicyMainContainer>
      </>
    );
  }
}

// Customizable Area Start

const CustomerHealthPolicyMainContainer = withStyles({
  root: {
    backgroundImage:
      `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "5px",
    marginTop: "10px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  },
})(Grid);

const CustomerHealthPolicyChildContainer = {
  padding: "10px",
  font: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",


  borderRadius: "5px",

  margin: "15px",
  backgroundColor: "white",
};

const CustomerHealthPolicyStyle = { margin: "10px 20px" }

// Customizable Area End
