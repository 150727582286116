import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";

import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import { Markup } from 'interweave';
import DashboardController2 from "./DashboardController2";
// Customizable Area End

// Customizable Area Start
class FAQs extends DashboardController2 {

  handleBtnClick = (index:any) => {
    if(index === this.state.open){
      this.setState({open:""})
    }else{
      this.setState({ open: index });
    }
     console.log(index)

  };

  render() {
    return (
      <>
      {/*@ts-ignore */}
        <MerchantLoginIconHeader {...this.props} />
        <div style={style_FAQs.image}>
          <Container maxWidth='sm'>
            <Typography
              style={{marginTop: 20, color: "white"}}
              variant="h6"
            >
              FAQs
            </Typography>
        
        {
              this.state.arrayHolder !== null && (
                this.state.arrayHolder.map((data: { question: string | null | undefined; answer: string | null | undefined; }, index:any) => {
                  return (
                    <Paper elevation={2} key={index}>
                      {/*@ts-ignore */}
                    <Box
                      sx={style_FAQs.container}
                    >
       <ListItem button onClick={() => {this.handleBtnClick(index)}}>
              <Typography
                variant="body1"
              >
              <b> <Markup content={data.question} className="question" /></b>
              
              </Typography>
              <ListItemText
                disableTypography
                primary=""
              />
              {index === this.state.open ? <ExpandMore /> : <ExpandLess /> }
            </ListItem>
            <Collapse in={index === this.state.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText
                    primary={<Markup content={data.answer} className="answer" />}
                  />
                </ListItem>
              </List>
            </Collapse>
 
                    </Box>
                  </Paper>
                  )
                })
              )
            }
       
      </Container>
    </div>
  </>
);

  }
}
// Customizable Area End

// Customizable Area Start
const style_FAQs = {
  image: {
    height: '100%',
    paddingBottom: '25px',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  container: {
    p: 2,
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  continueBtn: {
    marginTop: '20px',
    padding: "15px",
    borderRadius: 8,
  },
};
// Customizable Area End
export default FAQs;