 
import React from "react";
 
// Customizable Area Start
 
import PolicyPaymentController, { Props } from "./PolicyPaymentController";
 

export default class PolicyPaymentOptions extends PolicyPaymentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
      {/* nothing */}
      </>
    );
  }
} 
// Customizable Area End
