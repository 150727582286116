
import React, { Component } from "react";

// Customizable Area Start
import {
 
  iciciIcon,
  
  backgroundImage,
  backIcon,
  tapsureLogo
} from "./assets";
import {
  Grid,
  Button,
  
  Tabs,
 
  Tab,
  
} from "@material-ui/core";
import {
 
  withStyles,
} from "@material-ui/core/styles";
import { Markup } from 'interweave';
 

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

import CustomerHomeInsController2, { Props } from "./CustomerHomeInsController2";
// Customizable Area End

// Customizable Area Start

export default class CustomerHomeInsPolicyTabsTwo extends CustomerHomeInsController2 {
  constructor(props: Props) {
    super(props);
  }
  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={CustomerHomeInsPolicyTabsTwoStyleCss}
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
        </Grid>{" "}
        <CustomerHomeInsPolicyTabsTwoMainContainer container>
          {/* Arrow Container */}
          <Grid>
            <Grid>
              <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ margin: "10px" }}
                  onClick={this.handleBack}
                />
              </Button>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          <Grid container style={CustomerHomeInsPolicyTabsTwoChildContainer}>
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="80px" width="80px" src={iciciIcon} alt="" />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                    Home Plan
                  </h4>
                  <p style={{ margin: "0px 0 5px 10px" }}>ICIC Lomabard </p>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* Policy Details Container */}

          <Grid container style={CustomerHomeInsPolicyTabsTwoChildContainer}>
            <Grid item>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handlePolicyTabsChange}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                 {/*@ts-ignore */}
                <CustomTabs label="Policy Benefits" />
                 {/*@ts-ignore */}
                <CustomTabs label="About" />
                 {/*@ts-ignore */}
                <CustomTabs label="FAQs" />
              </Tabs>

              <TabPanel
                value={this.state.value}
                index={0}
                {...this.a11yProps(0)}
              >
                
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                  <Grid item>
                    <h3 style={{ ...CustomerHomeInsPolicyTabsTwoWebStyle.headColor, marginBottom: '0px' }}>Policy Benefits</h3>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    {
                      this.state.arrayHolder.PolicyDetail !== undefined && (
                        <div>
                          <div style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ margin: "0px 0px 18px 5px" }}>
                              {" "}
                              
                              <Markup content={this.state.arrayHolder.PolicyDetail.policy_benefits} className="policyBenifits" />
                            </div>
                          </div>
                        </div>
                      )
                    }
 
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={1}
                {...this.a11yProps(1)}
              >
                <Grid container>
                  <Grid item>
                    <p>
                      <Markup content={this.state.arrayHolder.PolicyDetail?.about} />
                    </p>
                    
 
                  </Grid>
                </Grid>
                
              </TabPanel>

              

              <TabPanel
                value={this.state.value}
                index={2}
                {...this.a11yProps(2)}
              >
                <Grid container>
                  <Grid item>
                    <h3>FAQs</h3>
                  </Grid>
                </Grid>
                {
                  this.state.arrayHolder?.Faqs?.map((data: { question: string | null | undefined; answer: string | null | undefined; }, index: number) => {
                    return (
                      <Grid container key={index}>
                        <Grid item>
                          <h3 style={CustomerHomeInsPolicyTabsTwoWebStyle.headColor}>
                            <Markup content={data.question} className="question" />
                          </h3>

                          <p>
                            <Markup content={data.answer} className="answer" />
                          </p>
                        </Grid>
                      </Grid>
                    )
                  })
                }
               
              </TabPanel>
            </Grid>
          </Grid>
        </CustomerHomeInsPolicyTabsTwoMainContainer >
        <BottomContainer container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.handleNext}
              style={{ width: "100%" }}
            >
              NEXT
            </Button>
          </Grid>
        </BottomContainer>
      </>
    );
  }
}

// Customizable Area End


// Customizable Area Start

const CustomerHomeInsPolicyTabsTwoMainContainer = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      " system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);
 

const BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
  },
})(Grid);

const CustomerHomeInsPolicyTabsTwoChildContainer = {
  padding: "5px 10px",
  display: "flex",
  borderRadius: "5px",
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  margin: "15px",
  backgroundColor: "white",
  overflow:"auto"
};
const CustomTabs = withStyles({
  root: {
    textTransform: "none",
    padding: "6px 12px",

    letterSpacing: 0,
    whiteSpace: "normal",
  },
})(Tab);
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
const CustomerHomeInsPolicyTabsTwoWebStyle = {
  headColor: { color: "#5254b3" },
};

const CustomerHomeInsPolicyTabsTwoStyleCss = { margin: "10px 20px" }
// Customizable Area End
