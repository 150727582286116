//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  flag: boolean;
  open: string;
  company: any;
  date: any;
  selectedDate: any;
  customerFilter: boolean;
  value: number;
  openQrCode: boolean;
  openSharePolicy: boolean;
  tapPointsModal: boolean;
  formData: {
    name: any;
    phoneNumber:string;
    message:string;
  };
  isError: any;
  categories: any;
  selectedCategory: number;
  qrUrl:string;
  products:any;
  isLoading:boolean;
  qrPath:string;
  selectedProduct:any;
  customerCount:number;
  customers:any;
  isCustomerLoading:boolean
  productsCount:number;
  arrayHolder: any;
  buyingPolicyContact:any;
  complaintsContact:any;
  supportEmail:any;
  openToast:boolean;
  toastType:string,
  openErrorPage:boolean,
  toastMessage:string;
  tapPointsData:any;
  tapPoints:number;
  totalOrders:number;
  
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController2 extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCategoriesId: string = "";
  getProductsApiCallId: string = "";
  getAssureCustomerApiCallId:string = "";
  getMerchantFaqs : string = "";
  getContactDetails: any;
  getContactUsForm : any;
  userProfileApiCallId: string;
  getApiCallIdPoints:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
   
    
    
    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      flag: false,
      open: "",
      company: null,
      date: null,
      selectedDate: new Date("2014-08-18T21:11:54"),
      customerFilter: false,
      value: 0,
      openQrCode: false,
      openSharePolicy: false,
      tapPointsModal: false,
      formData: {
        name:"",
        phoneNumber:"",
        message:"",
      },
      isError: {},
      categories: [],
      selectedCategory: 0,
      qrPath:"CustomerTwoWheelerSteps",
      products:[],
      isLoading:true,
      qrUrl:"",
      selectedProduct:{},
      customerCount:0,
      customers:[],
      isCustomerLoading:true,
      productsCount:0,
      arrayHolder: [],
      buyingPolicyContact:"",
      complaintsContact:"",
      supportEmail:"",
      openToast:false,
      toastMessage:"",
      toastType:"success",
      openErrorPage:false,
      tapPointsData:[],
      tapPoints:0,
      totalOrders:0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  

  

  
  

  async receive(from: string, message: Message) {
    // Customizable Area Start
     

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
      
      });
    }
    let responseJson:any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse:any = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getMerchantFaqs != null &&
      this.getMerchantFaqs ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     
      if(responseJson && !responseJson.error){
        console.log("faq",responseJson.faq)
        
     this.setState({
      arrayHolder:responseJson.faq
    })
      
      }else{
       
        console.log(errorReponse)
        
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userProfileApiCallId != null &&
      this.userProfileApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
       
     console.log(responseJson)
      if(responseJson && !responseJson.error){
        let fullMobile = responseJson?.data?.attributes?.full_phone_number
        let mobile = fullMobile.split("1").pop();
        this.setState({
          formData:{...this.state.formData, name:responseJson?.data?.attributes?.first_name,
            phoneNumber:mobile}
        })
      }else{
        
        console.log(errorReponse)
       this.setState({ loading: false, openToast: true, toastMessage: errorReponse, toastType: "error" });
      }
    }
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getContactDetails != null &&
      this.getContactDetails ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      if(responseJson && !responseJson.error){
        console.log("contact",responseJson.support_setting)
       this.setState({
        buyingPolicyContact:responseJson.support_setting.buying_policy_contact_number,
        complaintsContact:responseJson.support_setting.complaints_contact_number,
        supportEmail:responseJson.support_setting.support_email,
       })
      
      }else{
       console.log(errorReponse)   
      }
    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getContactUsForm != null &&
      this.getContactUsForm ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      console.log(responseJson)
  
       
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.setState({
           
          loading:false
        })
        this.setState({ loading: false,openToast:true,toastMessage:"Sent Successfully.",toastType:"success" });
         
      }else {
         
        this.setState({ loading: false,openToast:true,toastType:"error",toastMessage:"We're experiencing technical difficulties .Please try after some times."  });
      
      }
    }  

 

    // Customizable Area End
 
  }
  // Customizable Area Start
  async componentDidMount() {
  super.componentDidMount();
   
    this.contactGetProfileData();
    this.getToken();
    
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    // Customizable Area Start
    
    this.getFaqs();
    this.getContact();
   // Customizable Area End
  }

  getToken = () => {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
     // Customizable Area End
  };

  
   
   getContact=()=>{
    // Customizable Area Start
    console.log("test")
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("authToken"),
    };
  console.log("check" + localStorage.getItem("authToken"))
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMerchantApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
 
   getFaqs=()=>{
    // Customizable Area Start
    console.log("test")
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("authToken"),
    };
  console.log("check" + localStorage.getItem("authToken"))
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMerchantFaqs = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMerchantFaqs
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMerchantApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  contactGetProfileData =()=>{
    // Customizable Area Start
    
    let token = localStorage.getItem("newToken")
    let loginToken = localStorage.getItem("loginToken")
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("authToken"),
    };
   
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactData+`?token=${token === null ? loginToken : token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMerchantApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleContactSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if(!this.validate(this.state.formData) || Object.keys(this.validate(this.state.formData)).length === 0){
      setTimeout(()=> this.props.navigation.navigate('Dashboard'),3000)
    }
    this.setState({ isError: this.validate(this.state.formData) });
    const { name, phoneNumber,message } = this.state.formData;
    if(name != "" && phoneNumber.length === 10 && message !== "") {
     
      this.setState({ formData: {...this.state.formData, name: "", phoneNumber: "",message:""} });

      const headers = {
        "Content-Type": configJSON.dashboarContentType,
        "token": localStorage.getItem("authToken"),
      };
      const httpBody = {
        contact: {
          
          contact_number:this.state.formData.phoneNumber,
          email:this.state.formData.name,
          message:this.state.formData.message
        }
      };
      console.log(httpBody)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
        console.log(httpBody)
      this.getContactUsForm = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsFormApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.contact_methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
 
  
  getCustomizedDate=(dateStr:string)=>{
     // Customizable Area Start
    const date = new Date(dateStr);
      const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
      const monthArr = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      return `${day} ${monthArr[month]} ${year}`
      // Customizable Area End
    }
 
   

    validate = (values: any) => {
      const errors: any = {};
      let isErros : any = true
      const regex = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
      if(!values.name) {
        errors.name = "Name is required";
        isErros = false;
      }
      if(!values.phoneNumber) {
        errors.phoneNumber = "Phone number is required";
        isErros = false;
      } else if(!regex.test(values.phoneNumber)) {
        errors.phoneNumber = "This is not a valid phone number";
        isErros = false;
      } else if(this.state.formData.phoneNumber.length != 10) {
        errors.phoneNumber = "This is not a valid phone number";
        isErros = false;
      }else if(!this.state.formData.message) {
        errors.message = "Please write something";  
      }
      return errors;
    }
  // Customizable Area End



}
