import React  from "react";

// Customizable Area Start
 
import CustomerTravelInsController2   from "./CustomerTravelIncController2";
// Customizable Area End

// Customizable Area Start
export default class CustomerTravelPolicy extends  CustomerTravelInsController2 {
  constructor(props: any) {
    super(props);
  }

  render() {
     
    return (
      <>
        {" "}
       
      </>
    );
  }
}

// Customizable Area Start
 

// Customizable Area End
