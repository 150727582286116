import React from "react";
// Customizable Area Start
 
import CatalogueForCustomerController from "./CatalogueForCustomerController";
 
// Customizable Area End

// Customizable Area Start
class CatalogueForCustomer extends CatalogueForCustomerController {
  render() {
   
    return (
     <>
     <p>no data</p>
     </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
 
// Customizable Area End
export default CatalogueForCustomer;
