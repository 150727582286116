import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;
  steps: any;
  innerStep: number;
  selectModelManually: boolean;
  OwnerType: string;
  claimMade: string;
  showCustomerDetailSteps: boolean;
  policyType: string;
  openpolicyTypeDrawer: boolean;
  IDVType: string;
  IDVTypeDrawer: boolean;
  policyDetailsDrawer: boolean;
  OpenVechileDetailsDrawer: boolean;
  ownerDetailsStepIndex: number;
  previousPolicyType: string;
  modelNumber: string;
  registrationNumber: string;
  brand: string;
  model: string;
  policyExpiredRecently: string;
  vechileOnLoan: string;
  NoclaimBonus: string;
  // Customizable Area Start
  customerCategories: any;
  loading: boolean;
  selectedCategory: any;
  customerProducts: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueForCustomerController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getCategoriesId: any;
  getProductListApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      steps: ["Basic Details ", "Get A Quote", "Get Policy"],
      innerStep: 0,
      selectModelManually: false,
      OwnerType: "Company Owned",
      claimMade: "No",
      showCustomerDetailSteps: false,
      policyType: "Comprehensive",
      openpolicyTypeDrawer: false,
      IDVType: "Recommended",
      IDVTypeDrawer: false,
      policyDetailsDrawer: false,
      OpenVechileDetailsDrawer: false,
      ownerDetailsStepIndex: 0,
      previousPolicyType: "Comprehensive",
      modelNumber: "",
      registrationNumber: "",
      brand: "",
      model: "",
      policyExpiredRecently: "No",
      vechileOnLoan: "No",
      NoclaimBonus: "50",
      customerCategories: [],
      loading: false,
      selectedCategory: 0,
      customerProducts: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }
    this.getCustomerCategories();
    let id = localStorage.getItem('category-id')
    if (id != null) {
      this.setState({
        selectedCategory: id
      })
      this.getProducts(id)
    }
  }
 
  
 
  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectoHomeScreen = () => {
    this.props.navigation.navigate("CustomerHomeScreen");
  };
 
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesId != null &&
      this.getCategoriesId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson?.data_hash) {
        console.log(responseJson?.data_hash)
        this.setState({
          customerCategories: responseJson?.data_hash,
          loading: false
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({

          loading: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductListApiCallId != null &&
      this.getProductListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.error && responseJson.data) {
        this.setState({
          customerProducts: responseJson?.data,
          loading: false
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({

          loading: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  policyList = [
    {
      id: 1,
      amount: "10000",
      premium: "299",
    },
    {
      id: 2,
      amount: "20000",
      premium: "359",
    },
    {
      id: 3,
      amount: "50000",
      premium: "449",
    },
  ];
  productDetails = localStorage.getItem("selectedProduct");
     
  redirectToComponent = (component: string, data: any) => {
    localStorage.setItem("planDetails", JSON.stringify(data));
    this.props.navigation.navigate(component);
  };
  getCustomerCategories = () => {
    this.setState({
      loading: true
    })
    let merchantPhone = localStorage.getItem('merchantId')
    console.log(merchantPhone)
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMerchantDetails+`${merchantPhone}`+`?data_for=Categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCustomerScreen = (id: any) => {
    console.log(id)
    localStorage.setItem('category-id', id);
    this.props.navigation.navigate('CustomerProductList')
  }
  handleCategory = (id: number) => {
    this.setState({
      selectedCategory: id,
      loading: true
    });
    this.getProducts(id);

  };
  getProducts = (id: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/products?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  redirectToPolicyDetails = (productName: any) => {
    if (productName == "Two Wheeler Insurance") {
      this.props.navigation.navigate("CustomerTwoWheelerSteps")
    }
    else if (productName == "Four Wheeler Insurance") {
      this.props.navigation.navigate("CustomerFourWheelerSteps")
    }
    else if (productName == "ICICI Lombard Home Insurance") {
      this.props.navigation.navigate("CustomerHomeInsPolicyTabs")
    }
    else if (productName == "ICICI Lombard Cyber Fraud") {
      this.props.navigation.navigate("CustomerCyberFraudPolicyTabs")
    }
    else if (productName == "One Assist") {
      this.props.navigation.navigate("CustomerOneAssistPolicyTabs")
    }
    else if (productName == "ICICI Lombard Travel Insurance") {
      this.props.navigation.navigate("CustomerTravelInsPolicyTabs")
    } else if (productName == "Care Hospicash") {
      this.props.navigation.navigate("CustomerHospiCashPolicyTabs")
    }
    else if (productName == "Care Vector Borne") {
      this.props.navigation.navigate("CustomerVBCarePolicyDetails")
    }
    else if (productName == "DoctorOnCall") {
      this.props.navigation.navigate("CustomerHealthServicePolicyTabs")
    }
  }

  // Customizable Area End
}
