// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
// import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cf3rdPartyIntegrationWithInsuranceCo3 from "../../blocks/Cf3rdPartyIntegrationWithInsuranceCo3/src/Cf3rdPartyIntegrationWithInsuranceCo3";
import Payments from "../../blocks/Payments/src/Payments";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import TwilioIntegration2 from "../../blocks/TwilioIntegration2/src/TwilioIntegration2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TermsAndConditions from "../../blocks/dashboard/src/TermsAndConditions.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications"; 

// Merchant----------
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import FAQs from "../../blocks/dashboard/src/FAQs.web";
import AboutUs from "../../blocks/dashboard/src/AboutUs.web";
import RefundAndCancel from "../../blocks/dashboard/src/RefundAndCancellation.web"
import PrivacyPolicy from "../../blocks/dashboard/src/PrivacyPolicy.web"
import Blogs from "../../blocks/dashboard/src/Blogs.web"
import TCGroupInsurance from "../../blocks/dashboard/src/TCGroupInsurance.web"
import ContactUs from "../../blocks/dashboard/src/ContactUs.web";
import Customers from "../../blocks/dashboard/src/Customers.web";
import OrderHistory from "../../blocks/dashboard/src/OrderHistory.web";
import MerchantCatalogue from "../../blocks/dashboard/src/MerchantCatalogue.web";
import Tappoints from "../../blocks/dashboard/src/Tappoints.web";
import MyProfile from "../../blocks/user-profile-basic/src/MyProfile.web";
import CustomerTwoWheelerSteps from '../../blocks/catalogue/src/CustomerTwoWheelerSteps.web'
import CustomerFourWheelerSteps from '../../blocks/catalogue/src/CustomerFourWheelerSteps.web'
import CustomerICICICyberFraud from '../../blocks/catalogue/src/CustomerICICICyberFraud.web'
import ManageTags from "../../blocks/dashboard/src/ManageTags.web"
import EnrollPolicyPage from "../../blocks/catalogue/src/EnrollPolicyPage.web" 
import CustomerHealthPolicy from '../../blocks/catalogue/src/CustomerHealthPolicy.web'
import CustomerPolicyTabs from '../../blocks/catalogue/src/CustomerPolicyTabs.web'
import CustomerDetailsSteps from '../../blocks/catalogue/src/CustomerDetailsSteps.web'
import CustomerHomeInsPolicyTabsTwo from '../../blocks/catalogue/src/CustomerHomeInsPolicyTabsTwo.web'
import CustomerHomeInsDetailsSteps from '../../blocks/catalogue/src/CustomerHomeInsDetailsSteps.web'
import CustomerTravelInsPolicyTabsTwo from '../../blocks/catalogue/src/CustomerTravelInsPolicyTabsTwo.web'
import CustomerTravelDetailsStepsTwo from '../../blocks/catalogue/src/CustomerTravelDetailsStepsTwo.web'
import CustomerTravelPolicy from '../../blocks/catalogue/src/CustomerTravelPolicy.web'
import CustomerOneAssistDetailsSteps from '../../blocks/catalogue/src/CustomerOneAssitDetailsSteps.web'
import PolicyPaymentOptions from "../../blocks/catalogue/src/PolicyPayment.web";
import CustomerOrderSuccess from "../../blocks/catalogue/src/CustomerOrderSuccessPage.web";
import CustomerCyberFraudPolicyTabs from '../../blocks/catalogue/src/CustomerHomeCyberFraudPolicyTabs.web';
import CustomerCyberFraudPolicy from '../../blocks/catalogue/src/CustomerCyberFraudPolicy.web'
import CustomerHomeInsPolicyPlans from '../../blocks/catalogue/src/CustomerHomeInsPolicyPlans.web'
import CustomerTravelPlans from '../../blocks/catalogue/src/CustomerTravelPlans.web'
import CustomerOneAssistPolicyTabs from "../../blocks/catalogue/src/CustomerOneAssistPolicyTabs.web";
import CustomerHomeScreenDynamic from "../../blocks/catalogue/src/CustomerHomeScreenDynamic.web";
import CustomerProductList from "../../blocks/catalogue/src/CustomerProductList.web";
import CustomerVBCare from "../../blocks/categoriessubcategories/src/CustomerVBCare.web";
import CustomerHospiCashCareSteps from "../../blocks/categoriessubcategories/src/CustomerHospiCashCare.web";
import CustomerHospiCashPolicyTabs from "../../blocks/categoriessubcategories/src/CustomerHospiCashPolicyDetails.web";
import CustomerCareHospiCashPolicyList from "../../blocks/categoriessubcategories/src/CustomerHospiCashPlanList.web";
import CustomerVBCarePolicyDetails from "../../blocks/categoriessubcategories/src/CustomerVBCarePolicyDetails.web";
import OneAssistPolicyPlans from "../../blocks/catalogue/src/OneAssistPolicyPlans.web"; 
import MerchantPolicyDetails from "../../blocks/dashboard/src/MerchantPolicyDetails.web"
import CatalogueForCustomer from '../../blocks/catalogue/src/CatalogueForCustomer.web'
import CustomerHospiCashOrderSuccess from '../../blocks/categoriessubcategories/src/CustomerOrderSuccess.web'
import CustomerVBPlanList from "../../blocks/categoriessubcategories/src/CustomerVBPlanList.web";
import CustomerHealthServicePlanList from "../../blocks/categoriessubcategories/src/CustomerHealthServicePlanList.web"
import CustomerHealthServiceDetailsStep from "../../blocks/categoriessubcategories/src/CustomerHealthServiceDetailsStep.web"
import CustomerHealthServicePolicyTabs from "../../blocks/categoriessubcategories/src/CustomerHealthServicePolicyDetails.web"
 
const routeMap = {
  Login: {
    component: MobileAccountLoginBlock,
    path: '/Login',
   },
  Signup: {
    component: AdditionalDetailForm,
    path: '/Signup',
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  AboutUs: {
    component: AboutUs,
    path: '/AboutUs'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  RefundAndCancel: {
    component: RefundAndCancel,
    path: '/RefundAndCancel'
  },
  FAQs: {
    component: FAQs,
    path: '/FAQs'
  },
  TCGroupInsurance: {
    component: TCGroupInsurance,
    path: '/TCGroupInsurance'
  },
  ContactUs: {
    component: ContactUs,
    path: '/ContactUs'
  },
  Blogs: {
    component: Blogs,
    path: '/Blogs'
  },
  Customers: {
    component: Customers,
    path: '/Customers'
  },
  OrderHistory: {
    component: OrderHistory,
    path: '/OrderHistory'
  },
  MerchantCatalogue: {
    component: MerchantCatalogue,
    path: '/MerchantCatalogue'
  },
  Tappoints: {
    component: Tappoints,
    path: '/Tappoints'
  },
  MyProfile: {
    component: MyProfile,
    path: '/MyProfile'
  },
  ManageTags:{
    component:ManageTags,
    path:'/managetags'
  },
  EnrollPolicyPage:{
    component:EnrollPolicyPage,
    path:'/enrollpolicy'
  },
  // Merchant---------

  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SplitPayments: {
    component: SplitPayments,
    path: "/SplitPayments"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Cf3rdPartyIntegrationWithInsuranceCo3: {
    component: Cf3rdPartyIntegrationWithInsuranceCo3,
    path: "/Cf3rdPartyIntegrationWithInsuranceCo3"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  QrCodes: {
    component: QrCodes,
    path: "/QrCodes"
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: "/PaymentAdmin2"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  TwilioIntegration2: {
    component: TwilioIntegration2,
    path: "/TwilioIntegration2"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  OrderSummary: {
    component: OrderSummary,
    path: "/OrderSummary"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },

  CustomerTwoWheelerSteps: {
    component: CustomerTwoWheelerSteps,
    path: "/CustomerTwoWheelerSteps"
  },

  CustomerFourWheelerSteps: {
    component: CustomerFourWheelerSteps,
    path: "/CustomerFourWheelerSteps"
  },

  CustomerICICICyberFraud: {
    component: CustomerICICICyberFraud,
    path: "/CustomerICICICyberFraud"
  },

  CustomerPaymentOptions: {
    component: PolicyPaymentOptions,
    path: "/CustomerPaymentOptions"
  },

  CustomerHealthPolicy: {
    component: CustomerHealthPolicy,
    path: "/CustomerHealthPolicy"
  },

  CustomerPolicyTabs: {
    component: CustomerPolicyTabs,
    path: "/CustomerPolicyTabs"
  },


  CustomerDetailsSteps: {
    component: CustomerDetailsSteps,
    path: "/CustomerDetailsSteps"
  },

  CustomerHomeScreen: {
    component: CustomerHomeScreenDynamic,
    path: "/CustomerHomeScreen"
  },
  CustomerHomeInsPolicyPlans:{
component:CustomerHomeInsPolicyPlans,
path:'/CustomerHomeInsPolicyPlans'
  },
  CustomerTravelPlans:{
    component:CustomerTravelPlans,
    path:'/CustomerTravelPlans'
  },
  CustomerHomeInsPolicyTabs: {
    component: CustomerHomeInsPolicyTabsTwo,
    path: "/CustomerHomeInsPolicyTabs"
  },
  CustomerHomeInsDetailsSteps: {
    component: CustomerHomeInsDetailsSteps,
    path: "/CustomerHomeInsDetailsSteps"
  },
  CustomerTravelPolicy:{
    component:CustomerTravelPolicy,
    path:"/CustomerTravelPolicy"
  },
  CustomerTravelInsPolicyTabs: {
    component: CustomerTravelInsPolicyTabsTwo,
    path: "/CustomerTravelInsPolicyTabs"
  },
  CustomerTravelDetailsSteps: {
    component: CustomerTravelDetailsStepsTwo,
    path: "/CustomerTravelDetailsSteps"
  },
  
  CustomerOneAssistDetailsSteps: {
    component: CustomerOneAssistDetailsSteps,
    path: "/CustomerOneAssistDetailsSteps"
  },
  CustomerOneAssistPolicyTabs: {
    component: CustomerOneAssistPolicyTabs,
    path: "/CustomerOneAssistPolicyTabs"
  },
  CustomerProductList: {
    component: CustomerProductList,
    path: "/CustomerProductList"
  },
  CustomerVBCare: {
    component: CustomerVBCare,
    path: "/CustomerVBCare"
  },
  CustomerVBPlanList: {
    component: CustomerVBPlanList,
    path: '/CustomerVBPlanList'
  },
  CustomerHealthServicePlanList:{
    component:CustomerHealthServicePlanList,
    path:'/CustomerHealthServicePlanList'
  },
  CustomerHealthServiceDetailsStep:{
    component:CustomerHealthServiceDetailsStep,
    path:'/CustomerHealthServiceDetailsStep'
  },
  OneAssistPolicyPlans: {
    component: OneAssistPolicyPlans,
    path: "/OneAssistPolicyPlans"
  },

  Home: {
    component: MobileAccountLoginBlock,
    path: "/",
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  PolicyPaymentOptions: {
    component: PolicyPaymentOptions,
    path: '/PolicyPaymentOptions',
  },
  CustomerOrderSuccess: {
    component: CustomerOrderSuccess,
    path: '/CustomerOrderSuccess'
  },
  MerchantPolicyDetails: {
    component:MerchantPolicyDetails,
    path:'/MerchantPolicyDetails'
  },
  CatalogueForCustomer:{
  component:CatalogueForCustomer,
  path:'/CatalogueForCustomer'
  },
  CustomerCyberFraudPolicyTabs: {
    component: CustomerCyberFraudPolicyTabs,
    path: '/CustomerCyberFraudPolicyTabs',
  },
  CustomerCyberFraudPolicy: {
    component: CustomerCyberFraudPolicy,
    path: '/CustomerCyberFraudPolicy'
  },
  CustomerHospiCashCareSteps: {
    component: CustomerHospiCashCareSteps,
    path: '/CustomerHospiCashCareSteps'
  },
  CustomerHospiCashPolicyTabs: {
    component: CustomerHospiCashPolicyTabs,
    path: '/CustomerHospiCashPolicyTabs'
  },
  CustomerCareHospiCashPolicyList: {
    component: CustomerCareHospiCashPolicyList,
    path: '/CustomerCareHospiCashPolicyList'
  },
  CustomerVBCarePolicyDetails: {
    component: CustomerVBCarePolicyDetails,
    path: '/CustomerVBCarePolicyDetails'
  },
  CustomerHealthServicePolicyTabs:{
    component:CustomerHealthServicePolicyTabs,
    path:'/CustomerHealthServicePolicyTabs'
  },
  CustomerCareOrderSucces:{
    component: CustomerHospiCashOrderSuccess,
    path: '/CustomerCareOrderSucces'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
