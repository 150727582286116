import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Button,
    Modal,
    Container,
    Typography,
    TextField,
    CircularProgress,

} from "@material-ui/core";
import MerchantLoginIconHeader from "../../dashboard/src/MerchantLoginIconHeader.web";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from "@material-ui/core/Backdrop";
import {
    background,
    default_profile,
    edit,
    edit_profile,
    close,
} from "./assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UserProfileBasicController from "./UserProfileBasicController";
// Customizable Area End

// Customizable Area Start
class MyProfile extends UserProfileBasicController {
    fileInput: any = React.createRef();

    render() {
        const { navigation } = this.props;
        const regexName = /^[A-Za-z +]+$/;
        const regexIfsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/g;
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        return (
            <React.Fragment>
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>
                    <Container maxWidth='sm'>
                        <Paper elevation={2}>
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.personal}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                    >
                                        <b>Personal Details</b>
                                    </Typography>
                                    <Button
                                        style={{ color: "rgb(200,0,200,1)" }}
                                        data-testId="btnHandlePersonalModal"
                                        onClick={this.handlePersonalModal}
                                    >
                                        <img
                                            src={edit}
                                            alt=""
                                            height="15"
                                            width="15"
                                        />&nbsp;&nbsp;
                                        <b>Edit</b>
                                    </Button>
                                </Box>
                                {/*@ts-ignore */}
                                <Box>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={this.fileInput}
                                        hidden
                                        data-test-id="btnOnSelectImage"
                                        onClick={this.onSelectImage}
                                    />
                                    <img
                                        src={this.state.src === "" ? default_profile : this.state.src}
                                        alt=""
                                        height="90"
                                        width="90"
                                        style={{ borderRadius: '50%' }}
                                    //onClick={() => {this.fileInput?.current?.click()}}
                                    />
                                    {/* <img
                                        style={{ marginLeft: -25 }}
                                        src={edit_profile}
                                        alt=""
                                        height="25"
                                        width="25"
                                        onClick={() => this.fileInput?.current?.click()}
                                    /> */}
                                </Box>
                                {!this.state.isLoading ?
                                    <>
                                        {/*@ts-ignore */}
                                        <Box
                                            data-test-id="boxLoadIfIsLoadingIsFalse"
                                            sx={style.customerContainer}
                                        >
                                            {/*@ts-ignore */}
                                            <Box
                                                data-test-id="boxLoadWithUserData"
                                                sx={style.details}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Full Name
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.first_name} {this.state.profileData?.attributes?.last_name}</b>
                                                </Typography>
                                                <Typography
                                                    style={{ marginTop: 10 }}
                                                    variant="subtitle1"
                                                >
                                                    Email ID
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.email}</b>
                                                </Typography>
                                            </Box>
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.side}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Phone Number
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.full_phone_number}</b>
                                                </Typography>
                                                <Typography
                                                    style={{ marginTop: 10 }}
                                                    variant="subtitle1"
                                                >
                                                    Sales Code
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.sales_code.code}</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                    :
                                    <Backdrop
                                        style={{ zIndex: 1000 + 1, color: "#fff" }}
                                        open={this.state.isLoading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                }
                            </Box>
                        </Paper>
                        <Paper elevation={2}>
                            {/*@ts-ignore */}
                            <Box
                                sx={style.bankContainer}
                            >
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.bank}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                    >
                                        <b>Bank Details</b>
                                    </Typography>
                                    <Button
                                        data-test-id="buttonHandleBankModal"
                                        style={{ color: "rgb(200,0,200,1)" }}
                                        onClick={this.handleBankModal}
                                    >
                                        <img
                                            src={edit}
                                            alt=""
                                            height="15"
                                            width="15"
                                        />&nbsp;&nbsp;
                                        <b>Edit</b>
                                    </Button>
                                </Box>
                                {!this.state.isLoading ?
                                    <>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.bankDetails}
                                        >
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.details}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Account Number
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.bank_detail?.account_number}</b>
                                                </Typography>
                                                <Typography
                                                    style={{ marginTop: 10 }}
                                                    variant="subtitle1"
                                                >
                                                    IFSC Code
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.bank_detail?.ifsc_code}</b>
                                                </Typography>
                                            </Box>
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.bankName}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Bank Name
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    <b>{this.state.profileData?.attributes?.bank_detail?.bank_name}</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                    :
                                    <Backdrop
                                        style={{ zIndex: 1000 + 1, color: "#fff" }}
                                        open={this.state.isLoading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                }
                            </Box>
                        </Paper>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.logout}
                        >
                            <Typography
                                variant="body1"
                                data-test-id="btnLogin"
                                onClick={() => { navigation.navigate("Login"); localStorage.removeItem("authToken"); }}
                            >
                                Log Out
                            </Typography>
                        </Box>
                    </Container>

                    {/* Edit Personal Details Modal */}

                    <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Modal
                            open={this.state.openPersonalModal}
                            onClose={this.handleClosePersonal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            {!this.state.isLoadingPersonal ?
                                <>
                                    <Snackbar open={this.state.openToast} autoHideDuration={6000} style={{ margin: "10px" }}>
                                        <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
                                            {this.state.toastMessage}
                                        </Alert>
                                    </Snackbar>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.containerEdit}
                                    >
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.editPersonalDetails}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h6"
                                            >
                                                <b>Edit Personal Details</b>
                                            </Typography>
                                            <img
                                                data-test-id="imgHandleClosePersonal"
                                                src={close}
                                                alt=""
                                                height="18"
                                                width="18"
                                                onClick={this.handleClosePersonal}
                                            />
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={this.fileInput}
                                                hidden
                                                onChange={this.onSelectImage}
                                            />
                                            <img
                                                src={this.state.src === "" ? default_profile : this.state.src}
                                                data-test-id="imageSelect"
                                                alt=""
                                                height="90"
                                                width="90"
                                                style={{ borderRadius: '50%' }}
                                                onClick={() => this.fileInput?.current?.click()}
                                            />
                                            <img
                                                style={{ marginLeft: -25 }}
                                                src={edit_profile}
                                                data-test-id="imageSelect2"
                                                alt=""
                                                height="25"
                                                width="25"
                                                onClick={() => this.fileInput?.current?.click()}
                                            />
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                First Name
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                data-test-id="inputFirstName"
                                                name='firstName'
                                                label="First Name"
                                                variant="outlined"
                                                error={!this.state.first_name || !regexName.test(this.state.first_name)}
                                                value={this.state.first_name}
                                                onChange={(e) => this.setState({ first_name: e.target.value })}
                                            />
                                            {!this.state.first_name ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    First name is required!
                                                </div>
                                                : null ||
                                                    !regexName.test(this.state.first_name) ?
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        Please enter in alphabetical order!
                                                    </div>
                                                    : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Last Name
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                data-test-id="inputLastName"
                                                label="Last Name"
                                                name='lastName'
                                                variant="outlined"
                                                error={!this.state.last_name || !regexName.test(this.state.last_name)}
                                                value={this.state.last_name}
                                                onChange={(e) => this.setState({ last_name: e.target.value })}
                                            />
                                            {!this.state.last_name ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Last name is required!
                                                </div>
                                                : null ||
                                                    !regexName.test(this.state.last_name) ?
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        Please enter in alphabetical order!
                                                    </div>
                                                    : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Email ID
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                label="Email ID"
                                                data-test-id="inputEmail"
                                                variant="outlined"
                                                type="email"
                                                error={!this.state.emailId}
                                                value={this.state.emailId}
                                                onChange={(e) => this.setState({ emailId: e.target.value })}
                                            />
                                            {!this.state.emailId ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Email ID is required!
                                                </div>
                                                : null ||
                                                    !regexEmail.test(this.state.emailId) ?
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        This is not a valid email format!
                                                    </div>
                                                    : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Phone Number
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                label="Phone Number"
                                                variant="outlined"
                                                type="number"
                                                disabled
                                                value={this.state.profileData?.attributes?.full_phone_number}
                                            />
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Merchant Type
                                            </Typography>
                                            <TextField
                                                fullWidth

                                                label="Merchant Type"
                                                variant="outlined"
                                                name="merchantType"
                                                data-test-id="merchantType"
                                                select
                                                disabled
                                                value={this.state.merchant_type}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                onChange={(e) => this.setState({ merchant_type: e.target.value })}
                                            > 
                                            
                                                <option value={"Chemist"}>Chemist</option>
                                                <option value={"Jewellery shops"}>Jewellery shops</option>
                                                <option value={"Grocery"}>Grocery</option>
                                                <option value={"Restaurants"}>Restaurants</option>
                                                <option value={"Footwear shops"}>Footwear shops</option>
                                                <option value={"Fashion and accessories"}>Fashion and accessories</option>
                                                <option value={"Garments"}>Garments</option>
                                                <option value={"Electronics"}>Electronics</option>
                                                <option value={"Beauty and wellness"}>Beauty and wellness</option>
                                                <option value={"Entertainment"}>Entertainment</option>
                                                <option value={"Education services"}>Education services</option>
                                                <option value={"Health and medical care"}>Health and medical care</option>
                                                <option value={"Retail and shopping"}>Retail and shopping</option>
                                                <option value={"stationery"}>stationery</option>
                                                <option value={"Travel and transport"}>Travel and transport</option>
                                                <option value={"Utility"}>Utility</option>
                                                <option value={"Mobile shops"}>Mobile shops</option>
                                                <option value={"Home furnishing"}>Home furnishing</option>
                                                <option value={"Pan shops"}>Pan shops</option>
                                                <option value={"Retailer and manufacturer"}>Retailer and manufacturer</option>
                                                <option value={"Pet Stores"}>Pet Stores</option>
                                                <option value={"Mechanical Shops"}>Mechanical Shops</option>
                                                <option value={"Others"}>Others</option>
                                            </TextField>
                                            {/* {!this.state.emailId ?
                                            <div style={{ marginTop: 5, color: "red" }}>
                                                Merchant Type is required!
                                            </div>
                                            : null } */}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typography}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Address
                                            </Typography>
                                            <TextField

                                                fullWidth
                                                label="Address"
                                                variant="outlined"
                                                type="text"
                                                name='Address'
                                                data-test-id="Address"
                                                value={this.state.address}
                                                onChange={(e) => this.setState({ address: e.target.value })}
                                            />
                                            {/* {!this.state.emailId ?
                                            <div style={{ marginTop: 5, color: "red" }}>
                                                Address is required!
                                            </div>
                                            : null  } */}
                                        </Box>
                                        <Button
                                            data-test-id="btnHandleUpdatePersonalData"
                                            style={style.buttonSave}
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                                !this.state?.first_name ||
                                                !regexName.test(this.state.first_name) ||
                                                !regexName.test(this.state.last_name) ||
                                                !regexEmail.test(this.state.emailId) ||
                                                !this.state?.last_name ||
                                                !this.state?.emailId
                                            }
                                            onClick={() => this.handleUpdatePersonalData()}
                                        >
                                            Save
                                        </Button>

                                    </Box>
                                </>
                                :
                                <>
                                    <Backdrop
                                        style={{ zIndex: 1000 + 1, color: "#fff" }}
                                        open={this.state.isLoadingPersonal}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop></>
                            }
                        </Modal>
                    </Container>

                    {/* Edit Bank Details Modal */}

                    <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Modal
                            open={this.state.openBankModal}
                            onClose={this.handleCloseBank}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            {!this.state.isLoadingBank ?
                                <>                           
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.containerBank}
                                    >
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.editBankDetails}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h6"
                                            >
                                                <b>Edit Bank Details</b>
                                            </Typography>
                                            <img
                                                src={close}
                                                data-test-id="imgHandleCloseBank"
                                                alt=""
                                                height="18"
                                                width="18"
                                                onClick={this.handleCloseBank}
                                            />
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typographyBank}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Bank Name
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                data-test-id="BankName"
                                                type="text"
                                                name="BankName"
                                                label="Bank Name"
                                                error={!this.state.bank_name}
                                                value={this.state.bank_name}
                                                onChange={(e) => this.setState({ bank_name: e.target.value })}
                                            />
                                            {!this.state.bank_name ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Bank Name is required!
                                                </div>
                                                : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typographyBank}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Account Number
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="AccountNumber"
                                                data-test-id="AccountNumber"
                                                type="number"
                                                label="Account Number"
                                                error={!this.state.account_number || this.state.confirm_account_number !== this.state.account_number}
                                                value={this.state.account_number}
                                                onChange={(e) => this.setState({ account_number: e.target.value })}
                                            />
                                            {!this.state.account_number ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Account number is required!
                                                </div>
                                                : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typographyBank}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                Confirm Account Number
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                type="number"
                                                data-test-id="ConfirmAccountNumber"
                                                label="Confirm Account Number"
                                                error={!this.state.confirm_account_number || this.state.confirm_account_number !== this.state.account_number}
                                                value={this.state.confirm_account_number}
                                                onChange={(e) => this.setState({ confirm_account_number: e.target.value })}
                                            />
                                            {this.state.confirm_account_number !== this.state.account_number ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Account number does not matches!
                                                </div>
                                                : null ||
                                                    !this.state.confirm_account_number ?
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        Confirm Account number is required!
                                                    </div>
                                                    : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.typographyBank}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="body1"
                                            >
                                                IFSC Code
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                data-test-id="ifscCode"
                                                variant="outlined"
                                                label="IFSC Code"
                                                error={!this.state.ifsc_code || this.state.ifsc_code.length !== 11}
                                                value={this.state.ifsc_code}
                                                onChange={(e) => this.setState({ ifsc_code: e.target.value })}
                                            />
                                            {!this.state.ifsc_code ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    IFSC code is required!
                                                </div>
                                                : null ||
                                                    this.state.ifsc_code.length !== 11 ?
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        This is not a valid IFSC code!
                                                    </div>
                                                    : null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box sx={style.typography}>
                                            {/*@ts-ignore */}
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <Typography gutterBottom variant="body1">
                                                    PAN Card
                                                </Typography>
                                                {!this.state.uploadedPan ?
                                                    <>
                                                        {/*@ts-ignore */}
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography gutterBottom variant="body1">
                                                                Uploaded
                                                            </Typography>&nbsp;&nbsp;
                                                            <CheckCircleIcon style={{ color: 'green' }} color="primary" />
                                                        </Box>
                                                    </> : null}
                                            </Box>
                                            <div style={style.docContainer}>
                                                {!this.state.showFilePan.name ? "Upload PAN Card" :
                                                    <div style={{ marginLeft: -8 }}>

                                                        <span style={style.docStyle}>
                                                            {this.state.showFilePan.name}
                                                            <img
                                                                data-testid='PanCardImageSelect'
                                                                style={{ marginLeft: 13, cursor: 'pointer' }}
                                                                src={close}
                                                                alt="close"
                                                                height="10"
                                                                width="10"
                                                                onClick={() => this.setState({ uploadedPan: false, showFilePan: [] })}
                                                            />
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            {/*@ts-ignore */}
                                            <Box sx={style.uploadBtn}
                                            >
                                                <Button
                                                    style={style.btnColor}
                                                    variant="text"
                                                    component="label"
                                                    >
                                                    <input
                                                    data-test-id="btnHandleFileChange"
                                                        hidden
                                                        type="file"
                                                        accept={"image/*, application/pdf"}
                                                        onChange={this.handleFileChangePan}
                                                    />
                                                    <b>Upload</b>
                                                </Button>
                                            </Box>
                                            {this.state.existingAccountNumber !== this.state.account_number && this.state.uploadedPan !== true ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Pan is required!
                                                </div> :
                                                null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box sx={style.typography}>
                                            {/*@ts-ignore */}
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <Typography gutterBottom variant="body1">
                                                    Cancelled Cheque
                                                </Typography>
                                                {!this.state.uploadedCheque ?
                                                    <>
                                                        {/*@ts-ignore */}
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography gutterBottom variant="body1">
                                                                Uploaded
                                                            </Typography>&nbsp;&nbsp;
                                                            <CheckCircleIcon style={{ color: 'green' }} color="primary" />
                                                        </Box>
                                                    </>
                                                    :
                                                    null}

                                            </Box>
                                            <div style={style.docContainer}>
                                                {!this.state.showFileCheque.name ? "Upload Cancelled Cheque" :
                                                    <div style={{ marginLeft: -8 }}>
                                                        <span style={style.docStyle}>
                                                            {this.state.showFileCheque.name}
                                                            <img
                                                                style={{ marginLeft: 13, cursor: 'pointer' }}
                                                                data-testid='UploadCancelledCheque'
                                                                src={close}
                                                                alt="close"
                                                                height="10"
                                                                width="10"
                                                                onClick={() => this.setState({ uploadedCheque: false, showFileCheque: [] })}
                                                            />
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            {/*@ts-ignore */}
                                            <Box sx={style.uploadBtn}>
                                                <Button
                                                    style={style.btnColor}
                                                    variant="text"
                                                    component="label"
                                                >
                                                    <input
                                                        hidden
                                                        type="file"
                                                        data-test-id="btnhandleFileChangeCheque"
                                                        accept={"image/*, application/pdf"}
                                                        onChange={this.handleFileChangeCheque}
                                                    />
                                                    <b>Upload</b>
                                                </Button>
                                            </Box>
                                            {this.state.existingAccountNumber !== this.state.account_number && this.state.uploadedCheque !== true ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Cancelled Cheque is required!
                                                </div> :
                                                null}
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box sx={style.typography}>
                                            {/*@ts-ignore */}
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <Typography gutterBottom variant="body1">
                                                    Aadhar Card
                                                </Typography>
                                                {!this.state.uploadedAadhar ?
                                                    <>
                                                        {/*@ts-ignore */}
                                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography gutterBottom variant="body1">
                                                                Uploaded
                                                            </Typography>&nbsp;&nbsp;
                                                            <CheckCircleIcon style={{ color: 'green' }} color="primary" />
                                                        </Box>
                                                    </>
                                                    : null}
                                            </Box>
                                            <div style={style.docContainer}>
                                                {this.state.showFileAadhar.name ? "Upload Aadhar Card" :
                                                    <div style={{ marginLeft: -8 }}>
                                                        <span style={style.docStyle}>
                                                            {this.state.showFileAadhar.name}
                                                            <img
                                                                style={{ marginLeft: 13, cursor: 'pointer' }}
                                                                data-testid='UploadAadharImage'
                                                                src={close}
                                                                alt="close"
                                                                height="10"
                                                                width="10"
                                                                onClick={() => this.setState({ uploadedAadhar: false, showFileAadhar: [] })}
                                                            />
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            {/*@ts-ignore */}
                                            <Box sx={style.uploadBtn}>
                                                <Button
                                                    style={style.btnColor}
                                                    variant="text"
                                                    component="label"
                                                >
                                                    <input
                                                        hidden
                                                        data-test-id="btnHandleFileChangeAadhar"
                                                        type="file"
                                                        accept={"image/*, application/pdf"}
                                                        onChange={this.handleFileChangeAadhar}
                                                    />
                                                    <b>Upload</b>
                                                </Button>
                                            </Box>
                                            {this.state.existingAccountNumber !== this.state.account_number && this.state.uploadedAadhar !== true ?
                                                <div style={{ marginTop: 5, color: "red" }}>
                                                    Aadhar is required!
                                                </div> :
                                                null}
                                        </Box>
                                        <Button
                                            style={style.buttonSaveBank}
                                            data-testid='buttonSaveBank'
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            disabled={!this.state.account_number ||
                                                !this.state.confirm_account_number ||
                                                !this.state.ifsc_code ||
                                                this.state.ifsc_code.length !== 11 ||
                                                this.state.account_number !== this.state.confirm_account_number ||
                                                this.state.uploadedPan === false ||
                                                this.state.uploadedCheque === false ||
                                                this.state.uploadedAadhar === false
                                            }
                                            onClick={() => this.handleUpdateBankData()}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </>
                                :
                                <Backdrop
                                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                                    open={this.state.isLoadingBank}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>}
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    container: {
        p: 2,
        mt: 3,

    },
    personal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    customerContainer: {
        mt: 2,
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    side: {
        marginLeft: '-50px'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    documents: {
        display: 'flex',
        flexDirection: 'column',
    },
    documentContainer: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '23px'
    },
    bankDetails: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    bankName: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '23px'
    },
    bankContainer: {
        p: 2,
        mt: 2
    },
    bank: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    logout: {
        marginTop: '18px',
        padding: '15px',
        color: '#fff',
        border: '1px solid #fff',
        textAlign: 'center',
        borderRadius: 5,
        cursor: 'pointer',
    },

    // Edit Personal Details
    containerEdit: {
        top: "57%",
        left: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
        width: "92%",
        border: "0px solid rgb(0, 0, 0)",
        height: "580px",
        padding: "15px 41px 50px",
        display: "flex",
        overflow: "auto",
        position: "absolute",
        borderRadius: "15px",
        flexDirection: "column",
        backgroundColor: "rgb(255, 255, 255)",
    },
    editPersonalDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    typography: {
        mt: 2
    },
    buttonSave: {
        marginTop: 20,
        padding: "15px",
        borderRadius: 8,
    },

    // Edit Bank Details
    editBankDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerBank: {
        top: "57%",
        left: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
        width: "92%",
        border: "0px solid rgb(0, 0, 0)",
        height: "580px",
        padding: "15px 41px 50px",
        display: "flex",
        overflow: "auto",
        position: "absolute",
        borderRadius: "15px",
        flexDirection: "column",
        backgroundColor: "rgb(255, 255, 255)",
    },
    typographyBank: {
        mt: 2
    },
    buttonSaveBank: {
        marginTop: 20,
        padding: "15px",
        borderRadius: 8,
    },
    docContainer: {
        padding: 18,
        border: "1px solid grey",
        borderRadius: 4,
        color: 'darkgray'
    },
    docStyle: {
        padding: 8,
        borderRadius: 5,
        backgroundColor: '#eeeef6',
        color: '#1636cb'
    },
    uploadBtn: {
        marginLeft: "10px",
        marginTop: "-45px",
        float: "right"
    },
    btnColor: {
        color: "rgb(200,0,200,1)",
    },
};
// Customizable Area End
export default MyProfile;