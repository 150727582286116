import React, { Component } from "react";
// Customizable Area Start

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
 
import Drawer from "@material-ui/core/Drawer";
import {
 
  closeIcon,
 
  tappmart_logo,
  backgroundImage,
  backIcon,
} from "./assets";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
 
import OneAssistController from "./OneAssistController";
import FormHelperText from "@material-ui/core/FormHelperText";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
import '../assets/policyDetails.css'
// Customizable Area End

// Customizable Area Start
export default class CustomerOneAssistDetailsSteps extends OneAssistController {
  constructor(props: any) {
    super(props);
  }

  getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Personal Details</h3>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                {" "}
                First Name
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder=" First Name"
                variant="outlined"
                value={this.state.firstName}
                onChange={this.handleFirstName}
                error={!this.state.isFirstNameValid}
                helperText={
                  this.state.isFirstNameValid
                    ? ""
                    : "Please enter a valid Name."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Phone Number
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number"
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumber}
                error={!this.state.isPhoneNumberValid}
                helperText={
                  this.state.isPhoneNumberValid
                    ? ""
                    : "Please enter a valid Number."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Email ID
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="text"
                placeholder="Email ID"
                value={this.state.email}
                onChange={this.handleEmail}
                error={!this.state.isEmailValid}
                helperText={
                  this.state.isEmailValid ? "" : "Please enter a valid Email."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Date Of Birth
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-start-adornment"
                type="date"
                className={""}
                value={this.state.dateOfBirth}
                onChange={this.handleDOB}
                variant="outlined"
                placeholder="Date of Birth"
                 InputProps={{inputProps: { min: "", max: this.state.maxYear} }}
                error={!this.state.isDateOfBirthValid}
                helperText={
                  this.state.isDateOfBirthValid
                    ? ""
                    : "Your age should be more than 18 years."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Gender
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomFormControl variant="outlined">
                 {/*@ts-ignore */}
                <CustomSelect
                  native
                  value={this.state.gender}
                  onChange={this.handleGender}
                  inputProps={{
                    name: "gender",

                    id: "outlined-age-native-simple",
                  }}
                  error={!this.state.isGenderValid}
                >
                  <option aria-label="None" value="">
                    Gender
                  </option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                  
                </CustomSelect>
                {this.state.isGenderValid ? (
                  ""
                ) : (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Select a valid Gender.
                  </FormHelperText>
                )}
              </CustomFormControl>
            </Grid>
 
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Pincode
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="number"
                
                placeholder="Pincode"
                value={this.state.pinCode}
                onChange={this.handlePinCode}
                error={!this.state.isPinCodeValid}
               
                helperText={
                  this.state.isPinCodeValid
                    ? ""
                    : "Please enter a valid pincode."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                City/Taluka
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="text"
                placeholder="City/Taluka"
                value={this.state.city}
                disabled={true}
             
                error={!this.state.isCityValid}
                helperText={
                  this.state.isCityValid ? "" : "Please enter a valid City."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                State
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                disabled={true}
                type="text"
                placeholder="State"
                value={this.state.selectedState}
             
                error={!this.state.isStateValid}
                helperText={
                  this.state.isStateValid ? "" : "Please enter a valid State."
                }
              />
            </Grid>
            
          </>
        );
      case 1:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Contact Details</h3>
            </Grid>

           
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Street Address 1
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder=" Street Address 1"
                variant="outlined"
                value={this.state.addressOne}
                onChange={this.handleAddressOne}
                error={!this.state.isAddress1Valid}
                helperText={
                  this.state.isAddress1Valid
                    ? ""
                    : "Please enter a valid Adderess."
                }
              />
            </Grid>
            
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Pincode
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="number"
                
                placeholder="Pincode"
                value={this.state.pinCode}
                onChange={this.handlePinCode}
                error={!this.state.isPinCodeValid}
               
                helperText={
                  this.state.isPinCodeValid
                    ? ""
                    : "Please enter a valid pincode."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                City/Taluka
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                type="text"
                placeholder="City/Taluka"
                value={this.state.city}
                disabled={true}
                
                error={!this.state.isCityValid}
                helperText={
                  this.state.isCityValid ? "" : "Please enter a valid City."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                State
              </CustomInputLabel>
               {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                disabled={true}
                type="text"
                placeholder="State"
                value={this.state.selectedState}
               
                error={!this.state.isStateValid}
                helperText={
                  this.state.isStateValid ? "" : "Please enter a valid State."
                }
              />
            </Grid>

            
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  
  render() {
    return (
      <>
        {/* Logo section */}
        <Grid container spacing={2}>
          <Grid item>
            <img
              
              src={tappmart_logo}

              className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
          <Snackbar open={this.state.openToast} autoHideDuration={6000} style={{margin:"10px"}}>
              <Alert  severity={this.state.toastType=="success"?"success":"error"}>
                 {this.state.toastMessage}
               </Alert>
           </Snackbar>  
           <Backdrop
                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                    open={this.state.loading}
                  >
                     {/*@ts-ignore */}
                    <Modal
        open={this.state.openModal}
       
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
       <div className="modalClass">
       
        <div style={webStyle.modalStyle}>
        <p> <InfoIcon/></p>
        <div style={{padding:5}}>
       
        <h4>We regret that your policy creation has failed at our end. In case payment is done, it will be  refunded within next 72 hours.</h4>

        </div>
        <div>
        <Button
                onClick={() =>this.closeModel()} >
                <img height="15px" src={closeIcon} alt="" />
              </Button>
        </div>
        </div>
       
       
         </div>
      </Modal>
                  </Backdrop>
           
        </Grid>{" "}
        {/* Steps-Container */}
         {/*@ts-ignore */}
        <FormContainer container>
          <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ marginTop: "15px",marginRight:"auto" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={""}>
              
              <Grid style={formDivStyle}>
                
                
                  <Grid container spacing={2}>
                    {this.getStepContent(this.state.activeStep)}
                  </Grid>
                
              </Grid>
            </div>
          </Grid>
        </FormContainer>
        {/* Premium section */}
         {/*@ts-ignore */}
        <BottomContainer container spacing={2}>
          {this.state.activeStep<=0 ? (
            <>
              <Grid item sm={7} lg={7} xs={7}>
                <Typography>Total Premium</Typography>
                <span>
                  {this.state?.selectedPlans!=0?this.state?.selectedPlans:""} <ExpandMoreIcon />
                </span>
              </Grid>
              <Grid item sm={5} lg={5} xs={5}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => this.handleProceed("")}
                  >
                    Proceed
                  </Button>
                </div>
                <Backdrop
                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                    open={this.state.loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
              </Grid>
            </>
          ) : (
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleActiveStep}
                style={{ width: "100%" }}
              >
                NEXT
              </Button>
            </Grid>
          )}
        </BottomContainer>
        {/* Drawer Section */}
         {/*@ts-ignore */}
        <CustomDrawer
          open={this.state.openPolicyDetailDrawer}
          anchor={"bottom"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            <Grid item>
              <h3> Policy Details</h3>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                  this.setState({
                    openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer,
                  })
                }
              >
                <img height="10px" src={closeIcon} alt="" />
              </Button>
            </Grid>
          </Grid>

          
           {/*@ts-ignore */}
          <DrawerContainers
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            
          >
            <Grid item>
              <p>Total Premium (Inc 18% GST)</p>
            </Grid>
            <Grid item>
              <p>Rs {this.state.selectedPlans}</p>
            </Grid>
          </DrawerContainers>

         

          {/* Inside Container */}


          {/* Inside Containers END */}
          

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  this.createPayOrder()
                }
                style={{ width: "100%", marginTop: "60px" }}
              >
                Make Payment
              </Button>
            </Grid>
          </Grid>
        </CustomDrawer>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const FormStepper = withStyles({
  root: {
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    padding: "15px 5px",
    background: "inherit",
  },
})(Stepper);
const FormContainer = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const formDivStyle = {
  backgroundColor: "white",
  padding: "10px 15px",
  borderRadius: "10px",
  margin: "10px",
};
const BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);
 

const webStyle: any = {
  greyText: {
    color: "grey",
    marginTop: "5px",
  },
  paddingLeftBottom: {
    padding: "0 15px",
  },
  marginBottom: "15px",
  drawerSectionStyle: {
    borderBottom: "1px solid #4a444430",
    padding: "0 15px",
  },
  marginZero: {
    margin: "0px",
  },
  digitImage: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #8080804a",
    height: "50px",
  },
  checkedRadio: {
    border: "1px solid #4054b5",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#4054b5",
    fontWeight: "bold",
  },
  unCheckedRadio: {
    border: "1px solid #e1e1e1",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#848484",
    fontWeight: "bold",
  },
  docContainer: {
    padding: 18,
    border: "1px solid grey",
    borderRadius: 4,
    color: "darkgray",
  },
  docStyle: {
    padding: 8,
    borderRadius: 5,
    backgroundColor: "#eeeef6",
    color: "#1636cb",
  },
  uploadBtn: {
    marginLeft: "10px",
    marginTop: "-45px",
    float: "right",
  },
  modalStyle:{color: "grey",display:"flex",alignItems:"baseline",backgroundColor:"white",padding:"5px",   font:
  "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",borderRadius:"7px"
}
  
};

const InputField = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

const CustomInputLabel = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);

const CustomDrawer = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      padding: "0 5px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Drawer);
const DrawerContainers = withStyles({
  root: {
    padding: "0 15px",
    height: "40px",
  },
})(Grid);

 

 
const CustomInputField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField);

const CustomSelect = withStyles({
  root: {
    padding: "15px",
  },
})(Select);
const CustomFormControl = withStyles({
  root: {
    width: "100%",
    marginTop: 5,
  },
})(FormControl);

// Customizable Area End
