import React from "react";

// Customizable Area Start
import {
  Grid,
  
} from "@material-ui/core";
 
 
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import EnrollPolicyController from "./EnrollPolicyController";
// Customizable Area End

// Customizable Area Start
export default class EnrollPolicyPage extends EnrollPolicyController {
  
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
         <Grid >
      { 
         <Backdrop
         style={{ color: "blue",margin: "0",
         position: "absolute",
         top: "50%",
         left: "50%",
         transform: "translate(-50%, -50%)",
         backgroundColor: "white",
         width: "fit-content",
         height: "fit-content", }}
         open={this.state.loading}
       >
         <CircularProgress color="inherit" />
       </Backdrop> 
   
      }
    </Grid>
      </>
    );
  }
}

// Customizable Area Start

// Customizable Area End
