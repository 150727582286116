import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
 

// Customizable Area Start
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
   
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class CustomerTravelInsController2 extends BlockComponent<Props, S, SS> {
    getLocationApiId: any;
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
             
            arrayHolder: [],
             
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        console.log("h")
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
       
 
       
        // Customizable Area End
    }
    async componentDidMount() {

        super.componentDidMount();
 

    }
 


    // Customizable Area Start
  
    // Customizable Area End
}