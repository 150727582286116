import React, { Component } from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import {
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

import Drawer from "@material-ui/core/Drawer";

import RadioGroup from "@material-ui/core/RadioGroup";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {
   closeIcon,
  tapsureLogo,
  backgroundImage,
  backIcon,
} from "./assets2";
import CustomerHealthServiceController, {
  Props,
} from "./CustomerHealthServiceController";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar'
import '../assets/policyDetails.css'
// Customizable Area End

// Customizable Area Start

export default class CustomerHealthServiceDetailsStep extends CustomerHealthServiceController {
  constructor(props: Props) {
    super(props);
  }

  getStepContent(stepIndex: number) {
    const {
      gender,
      firstName,
      lastName,
      dateOfBirth,
      
    } = this.state.personalDetailsForm;
    const {
      email,
      phoneNumber,
      
    } = this.state.contactDetailsForm;
    const { sumToBeInsured } = this.state.policyDetailsForm;
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Personal Details</h3>
            </Grid>
 
                        <>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                {" "}
                First Name
              </CustomInputLabel>
              <CustomInputField
                id="outlined-basic"
                placeholder="First Name"
                variant="outlined"
                name="firstName"
                value={firstName}
                onChange={this.handleChangePersonal}
                data-test-id="handle-Change-Personal"
                error={this.state.isErrorPersonal.firstName ? true : false}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.firstName}
              </div>
            </Grid>
            </>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                Last Name
              </CustomInputLabel>
              <CustomInputField
                id="outlined-basic"
                placeholder="Last Name"
                name={"lastName"}
                value={lastName}
                variant="outlined"
                onChange={this.handleChangePersonal}
                error={this.state.isErrorPersonal.lastName ? true : false}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.lastName}
              </div>
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                Date Of Birth
              </CustomInputLabel>
              <CustomInputField
                id="outlined-start-adornment"
                type="date"
                className={""}
                error={this.state.isErrorPersonal.dateOfBirth ? true : false}
                name="dateOfBirth"
                value={dateOfBirth}
                onChange={this.handleChangePersonal}
                variant="outlined"
                placeholder="Date of Birth"
                InputProps={{
                  inputProps: {min: "", max: this.state.maxYear },
                }}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.dateOfBirth}
              </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                {" "}
                Gender
              </CustomInputLabel>
              <CustomFormControl variant="outlined">
                <CustomSelect
                  native
                  value={gender}
                  onChange={this.handleChangePersonal}
                  inputProps={{
                    name: "gender",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" disabled>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </CustomSelect>
              </CustomFormControl>
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.gender}
              </div>
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3>Contact Details</h3>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                Email ID
              </CustomInputLabel>
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                placeholder="Email ID"
                type="text"
                value={email}
                name="email"
                onChange={this.handleChangeContact}
                data-test-id="handle-Change-email"
                error={this.state.isErrorPersonal.email ? true : false}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.email}
              </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel htmlFor="component-simple">
                Phone Number
              </CustomInputLabel>
              <CustomInputField
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChangeContact}
                inputProps={{ maxLength: 10 }}
                error={this.state.isErrorPersonal.phoneNumber ? true : false}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.phoneNumber}
              </div>
            </Grid>
            
          </>
        );
      case 2:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Policy Details</h3>
            </Grid>
            <Grid item sm={12} md={12} xs={12} style={{ margin: "5px 0px" }}>
              <CustomInputLabel htmlFor="component-simple">
                Sum To Be Insured
              </CustomInputLabel>
              <CustomFormControl variant="outlined">
                <CustomSelect
                  native
                  value={sumToBeInsured}
                  onChange={this.handleChangePolicy}
                  inputProps={{
                    name: "sumToBeInsured",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" disabled>
                    Sum to be insured
                  </option>
                  {this.state.policyList.map((data, index) => {
                    return (
                      <option value={data.amount} key={index}>
                        {data.amount}
                      </option>
                    );
                  })}
                </CustomSelect>
              </CustomFormControl>
            </Grid>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  render() {
    return (
      <>
        {/* Logo section */}
        <Grid container spacing={2}>
          <Grid item>
            <img 
              src={tapsureLogo} 
              className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
          <Snackbar
            open={this.state.openToast}
            autoHideDuration={3000}
            style={{ margin: "10px" }}
          >
            <Alert
              severity={this.state.toastType == "success" ? "success" : "error"}
            >
              {this.state.toastMessage}
            </Alert>
          </Snackbar>
        </Grid>{" "}
        {/* Steps-Container */}
        <FormContainerHS container>
          <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ marginTop: "15px" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={""}>
              <FormStepperHS activeStep={this.state.activeStep} alternativeLabel>
                {this.state.steps.map((label: string) => (
                  <Step key={label}>
                    <CustomStepLabelHS>{label}</CustomStepLabelHS>
                  </Step>
                ))}
              </FormStepperHS>
              <Grid style={formDivStyle}>
                <Grid container spacing={2}>
                  {this.getStepContent(this.state.activeStep)}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </FormContainerHS>
        {/* Premium section */}
        <BottomContainerHS container spacing={2}>
          {this.state.activeStep === 2 ? (
            <>
              <Grid item sm={7} lg={7} xs={7}>
                <Typography>Total Premium</Typography>
                <span>
                  Rs {this.state.policyDetailsForm.premium}{" "}
                  <img
                    style={{ marginLeft: "5px" }}
                    
                    height="10px"
                  />
                </span>
              </Grid>
              <Grid item sm={5} lg={5} xs={5}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => this.handleProceed("")}
                  >
                    Proceed
                  </Button>
                </div>
              </Grid>
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#fff" }}
                open={this.state.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          ) : (
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleActiveStep}
                style={{ width: "100%" }}
              >
                NEXT
              </Button>
            </Grid>
          )}
        </BottomContainerHS>
        {/* Drawer Section */}
        <CustomDrawerHS
          open={this.state.openPolicyDetailDrawer}
          anchor={"bottom"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            <Grid item>
              <h3> Policy Details</h3>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                  this.setState({
                    openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer,
                  })
                }
              >
                <img height="10px" src={closeIcon} alt="" />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
          >
            {/* <Grid item>
                            <img style={webStyle.digitImage} src={digitIcon} alt="" />
                        </Grid> */}
            <Grid item>
              <h4> Total Premium (Inc 18% GST)</h4>
              <h3>Rs {this.state.policyDetailsForm.premium}</h3>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.drawerSectionStyle}
          >
            <Grid item>
              <p>Base Plan</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle.marginZero}>
                {this.state.policyDetailsForm.sumToBeInsured}
              </h3>
            </Grid>
          </Grid>

          {/* <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={webStyle.drawerSectionStyle}
                    >
                        <Grid item>
                            <p>Cover Amount</p>
                        </Grid>
                        <Grid item>
                            <h3 style={webStyle.marginZero}>7890.06</h3>
                        </Grid>
                    </Grid> */}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle.drawerSectionStyle}
          >
            <Grid item>
              <p>Policy Period</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle.marginZero}>1 Year</h3>
            </Grid>
          </Grid>

          {/* <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={webStyle.drawerSectionStyle}
                    >
                        <Grid item>
                            <p>GST(18%)</p>
                        </Grid>
                        <Grid item>
                            <h3 style={webStyle.marginZero}>2,738.69</h3>
                        </Grid>
                    </Grid> */}

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={webStyle.paddingLeftBottom}
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                data-test-id="create-pay-order"
                color="primary"
                size="large"
                onClick={() => {
                  this.setState({
                    openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer,
                  });
                  this.createPayOrder();
                }}
                style={{ width: "100%", marginTop: "65px" }}
              >
                Make Payment
              </Button>
            </Grid>
          </Grid>
        </CustomDrawerHS>
        <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
          <>
          {/*@ts-ignore */}
          <Modal
            open={this.state.openModal}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modalClass">
              <div style={webStyle.modalStyle}>
                <p>
                  {" "}
                  <InfoIcon />
                </p>
                <div style={{ padding: 5 }}>
                  <h4>
                    We regret that your policy creation has failed at our end.
                    In case payment is done, it will be refunded within next 72
                    hours.
                  </h4>
                </div>
                <div>
                  <Button data-test-id="close-model" onClick={() => this.closeModel()}>
                    <img height="15px" src={closeIcon} alt="" />
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          </>
        </Backdrop>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const FormStepperHS:any = withStyles({
  root: {
    // backgroundImage: `url(${backgroundImage})`,
    // backgroundSize: "cover",
    padding: "15px 5px",
    // backgroundColor: "inherit",
    background: "inherit",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Stepper);
const FormContainerHS:any = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

 

const formDivStyle = {
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "10px",
  margin: "10px",
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
};
const BottomContainerHS:any = withStyles({
  root: {
    padding: "10px 15px",
  },
})(Grid);

const CustomStepLabelHS:any = withStyles({
  root: {
    color: "white",
    "& .MuiStepLabel-labelContainer": {
      color: "white",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "white",
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "White",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "white",
        border: "none",
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "transparent",
        borderRadius: "12px",
      },
      "& .MuiStepIcon-root": {
        border: "1px solid white",
        borderRadius: "12px",
        color: "transparent",
      },
    },
  },
})(StepLabel);

const webStyle: any = {
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  greyText: {
    color: "grey",
    marginTop: "5px",
  },
  paddingLeftBottom: {
    padding: "0 15px",
  },
  marginBottom: "15px",
  digitImage: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #8080804a",
    height: "50px",
  },
  drawerSectionStyle: {
    borderBottom: "1px solid #4a444430",
    padding: "0 15px",
  },
  marginZero: {
    margin: "0px",
  },
  checkedRadio: {
    border: "1px solid #4054b5",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#4054b5",
    fontWeight: "bold",
  },
  unCheckedRadio: {
    border: "1px solid #e1e1e1",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#848484",
    fontWeight: "bold",
  },
  helperText: {
    fontSize: "inherit",
    color: "inherit",
    marginTop: "-10px",
    marginLeft: "30px",
  },
  modalStyle: {
    color: "grey",
    display: "flex",
    alignItems: "baseline",
    backgroundColor: "white",
    padding: "5px",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    borderRadius: "7px",
  },
};

const InputField = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

const CustomInputLabel :any  = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);

const CustomDrawerHS:any = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      padding: "0 5px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      font:
        "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    },
  },
})(Drawer);
 
const CustomInputField:any = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField);
const CustomSelect:any = withStyles({
  root: {
    padding: "12px",
  },
})(Select);
const CustomFormControl:any = withStyles({
  root: {
    width: "100%",
    marginTop: 5,
  },
})(FormControl);
// Customizable Area End
