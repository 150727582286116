import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Button,
    Modal,
    Container,
    Typography,
    TextField,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    Badge,
    InputBase
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";

import {
    back,
    close,
    search,
    profile,
    checked,
    unchecked,
    background,
    image_filter,
    star_logo,
    image_phone_number,
  } from "./assets";

import { withStyles } from '@material-ui/core/styles';
import DashboardControllerThree from "./DashboardControllerThree";
// Customizable Area End

// Customizable Area Start
class OrderHistory extends DashboardControllerThree {

    handleOpen = () => {
        this.setState({ customerFilter: true });
    }

    toggleCompany = (state: string) => {
        console.log(state);
        this.setState({ company: state });
    }

    toggleDate = (state: string) => {
        console.log(state);
        this.setState({ date: state });
    }

    handleDateChange = (date: Date | null) => {
        this.setState({ selectedDate: date });
    };

    handleClose = () => {
        this.setState({ customerFilter: false });
    }
    render() {
        const { navigation } = this.props;
      
        return (
            <>
            {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props}/>
                <div style={style.image} >
                <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Modal
                            open={this.state.customerFilter}
                            onClose={this.handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.containerFilter}
                            >
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.filterFilter}
                                >
                                    <Typography
                                        variant="h5"
                                    >
                                        <b>Filter</b>
                                    </Typography>
                                    <img
                                        style={{cursor: 'pointer'}}
                                        src={close}
                                        alt=""
                                        height="20"
                                        width="20"
                                        onClick={this.handleClose}
                                    />
                                </Box>
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.insurance}
                                >
                                    <Typography
                                        variant="h6"
                                    >
                                        Insurance Company
                                    </Typography>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                    >
                                        <CustomFormControl variant="outlined">
                <CustomSelect
                  native
                  value={this.state.company}
              onChange={this.handleCompanySelect}
                  inputProps={{
                    name: "companyInsurance",

                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="">
                  Insurance Company
                  </option>
                  {this.state.filterProducts.map((item:any)=>{
                    return (<option key={item?.id} value={item!=null?item.name:""}>
                    {item.name}
                  </option>)
                  })}
                  
                 
                </CustomSelect>
                
              </CustomFormControl>
                                        
                                    </Box>
                                    
                                    <Typography
                                        style={{marginTop: 20}}
                                        variant="h6"
                                    >
                                        Purchase Date
                                    </Typography>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                        
                                    >
                                        <img
                                            src={this.state.date === 'week' ? checked : unchecked}
                                            alt=""
                                            height="23"
                                            width="23"
                                            onClick={() => this.state.date !== 'week' && this.toggleDate('week')}
                                        />
                                        <Typography
                                            style={style.typographyFilter}
                                            variant="body1"
                                        >
                                            This Week
                                        </Typography>
                                    </Box>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                    >
                                        <img
                                            src={this.state.date === 'month' ? checked : unchecked}
                                            alt=""
                                            data-test-id="merchantTest"
                                            height="23"
                                            width="23"
                                            onClick={() => this.state.date !== 'month' && this.toggleDate('month')}
                                        />
                                        <Typography
                                            style={style.typographyFilter}
                                            variant="body1"
                                        >
                                            This Month
                                        </Typography>
                                    </Box>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                    >
                                        <img
                                            src={this.state.date === 'year' ? checked : unchecked}
                                            alt=""
                                            height="23"
                                            width="23"
                                            onClick={() => this.state.date !== 'year' && this.toggleDate('year')}
                                        />
                                        <Typography
                                            style={style.typographyFilter}
                                            variant="body1"
                                        >
                                            This Year
                                        </Typography>
                                    </Box>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                    >
                                        <img
                                            src={this.state.date === 'range' ? checked : unchecked}
                                            alt=""
                                            height="23"
                                            width="23"
                                            onClick={() => this.state.date !== 'range' && this.toggleDate('range')}
                                        />
                                        <Typography
                                            style={style.typographyFilter}
                                            variant="body1"
                                        >
                                            Select Date Range
                                        </Typography>
                                    </Box>
                                    {this.state.date === 'range' &&
                                    <>
                                    {/*@ts-ignore */}
                                        <Box
                                            sx={style.datePicker}
                                        >
                                            <TextField
                                                variant="outlined"
                                                id="date"
                                                type="date"
                                                defaultValue="2021-05-26"
                                                data-test-id="textFieldTest"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={this.state.start_date}
                                                onChange={this.handleStartDate}

                                            />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <TextField
                                                variant="outlined"
                                                id="date"
                                                type="date"
                                                defaultValue="2022-05-26"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={this.state.end_date}
                                                onChange={this.handleEndDate}
                                            />
                                        </Box>
                                        </>
                                    }
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.buttonStyle}
                                    >
                                        <Button
                                            style={style.buttonReset}
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="outlined"
                                            onClick={this.handleResetFilter}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                        disabled={this.state.date=="range" && this.state.start_date=="" && this.state.end_date==""}
                                            style={style.buttonApply}
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            onClick={this.handleFilterApply}
                                        >
                                            Apply
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </Container>
                    <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.back}
                            onClick={() => navigation.navigate("Dashboard")}
                        >
                            <img
                                src={back}
                                alt=""
                                height="15"
                                width="10"
                            />
                            <Typography
                                style={{marginTop: -2, marginLeft: 8}}
                                variant="body2"
                            >
                                Back to Dashboard
                            </Typography>
                        </Box>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.orders}
                        >
                            <Typography
                                variant="h6"
                            >
                                <StyledBadge badgeContent={this.state?.customerCount}  color="primary">
                                    Order History
                                </StyledBadge>
                            </Typography>
                            {/*@ts-ignore */}
                            <Box
                                sx={style.filter}
                                onClick={this.handleOpen}
                            >
                                <img
                                    src={image_filter}
                                    alt=""
                                    height="20"
                                    width="20"
                                />&nbsp;&nbsp;
                                <Typography
                                    variant="body1"
                                >
                                    Filter
                                </Typography>
                            </Box>
                        </Box>
                        <Paper elevation={2}>
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <InputBase
                                data-test-id="input-field"
                                    placeholder="Search Customers" onChange={this.handleCustomerSearch}
                                />
                                <img
                                    style={{float: 'right', marginTop: '2px'}}
                                    src={search}
                                    alt=""
                                    height="30"
                                    width="30"
                                />
                            </Box>
                        </Paper>
                        {
                          this.state.isCustomerLoading ? <Backdrop
                          style={{ zIndex: 1000 + 1, color: "#fff" }}
                          open={this.state.isLoading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>  : 
                        <>
                          {this.state?.customers.map((item: any,id:any) => {
                            return (
                                <Paper elevation={2} key={id}>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.customerDetails}
                                    >
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.familyHealth}
                                        >
                                            <img
                                                src={item.image}
                                                alt=""
                                                height="50"
                                                width="50"
                                            />
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.company}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="body1"
                                                >
                                                    <b>{item.type}</b>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {item.company}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.customerContainer}
                                        >
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.details}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    gutterBottom
                                                >
                                                    Customer Name
                                                </Typography>
                                                {/*@ts-ignore */}
                                                <Box sx={style.icon}>
                                                    <img
                                                        src={item.profileIcon}
                                                        alt=""
                                                        height="20"
                                                        width="20"
                                                    />
                                                    <Typography
                                                        style={style.typography}
                                                        variant="subtitle2"
                                                    >
                                                         <b>{item.first_name } {item.last_name}</b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.phoneDetails}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    gutterBottom
                                                >
                                                    Phone Number
                                                </Typography>
                                                {/*@ts-ignore */}
                                                <Box sx={style.icon}>
                                                    <img
                                                        src={item.phoneIcon}
                                                        alt=""
                                                        height="18"
                                                        width="18"
                                                    />
                                                    <Typography
                                                        style={style.typography}
                                                        variant="subtitle2"
                                                    >
                                                        <b>{item.get_phone_number?item.get_phone_number!=null?item.get_phone_number:"N/A":"N/A"}</b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {/*@ts-ignore */}
                                        <Box
                                            sx={style.tappoints}
                                        >
                                            <Typography
                                                variant="caption"
                                            >
                                                Tappoints Earned:
                                            </Typography>
                                            <Typography
                                                style={{color: "rgb(0, 200, 127, 1)"}}
                                                variant="caption"
                                            >
                                                <b>+{item.tappoint_count.toFixed(2)}</b>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            );
                        })}
                        </> 
                        }
                      
                    </Container>

                   
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const StyledBadge :any= withStyles({
    badge: {
        right: -38,
        top: 5,
        transform: "scale(1.2)"
    },
})(Badge);
const style = {
    orders: {
                mt: 2,
                color: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            tappoints: {
                        p: 0.7,
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        border: "0px solid black",
                        backgroundColor: "rgb(242,242,242)"
                    },
                    phoneDetails: {
                                marginRight: 12,
                            },
    image: {
      height: 'auto',
      paddingBottom: '25px',
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    back: {
      mt: 3,
      color: "white",
      display: "flex",
      flexDirection: "row",
      cursor: 'pointer'
    },
    container: {
      p: 1.5,
      mt: 2,
    },
    familyHealth: {
      display: "flex",
      flexDirection: "row",
    },
    company: {
      display: "flex",
      flexDirection: "column",
      ml: 1.5,
    },
    customers: {
      mt: 2,
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    typography: {
      marginLeft: "7px",
    },
    filter: {
      display: "flex",
      flexDirection: "row",
      cursor: 'pointer'
    },
    customerDetails: {
      p: 2,
      mt: 2,
    },
    icon: {
      display: "flex",
      flexDirection: "row",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    phoneRenewal: {
      marginRight: 12,
    },
    customerContainer: {
      mt: 2,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  
    // Customer Filter
  containerFilter: {
      position: 'absolute' as 'absolute',
      top: '66%',
      left: '50%',
      width: '90%',
      height: '100%',
      padding: '25px',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '0px solid #000',
      borderRadius: '16px',
  },
  filterFilter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
  },
  insurance: {
      mt: 2
  },
  insuranceCompany: {
      p: 0.5,
      mt: 1,
      display: 'flex',
      flexDirection: 'row'
  },
  typographyFilter: {
      marginLeft: 10
  },
  datePicker: {
      mt: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
  },
  buttonStyle: {
      mt: 5,
      display: 'flex',
      flexDirection: 'row',
  },
  buttonReset: {
      marginRight: "8px",
      padding: "15px",
      borderRadius: 8,
  },
  buttonApply: {
      marginLeft: "8px",
      padding: "15px",
      borderRadius: 8,
  },
  };
const CustomFormControl :any = withStyles({
    root: {
      width: "100%",
      marginTop: 5,
    },
  })(FormControl);

const CustomSelect :any = withStyles({
    root: {
      padding: "15px",
    },
  })(Select); 
// Customizable Area End
export default OrderHistory;