import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";
 
import DashboardController from "./DashboardController";
import { Markup } from "interweave";
// Customizable Area End

// Customizable Area Start
class TermsAndConditions extends DashboardController {

    

    render() {
        return (
            <>
            {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>

                    <Container maxWidth='sm'>
                        <Paper elevation={2} >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <Typography
                                    style={{ margin: "15px 0px", color: "black",textAlign:"center",fontWeight: "bold",fontSize:"29px" }}
                                    variant="h6"
                                >
                                     Terms & Conditions - General
                                </Typography>
                                <Typography component="p" style={{fontWeight: "bold" , marginTop: "15px" }}>
                                <Markup content= {this.state.termsCondition.discription} />
                               
                                </Typography>

                              
                            </Box>
                        </Paper>

                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        overflowY: "scroll",
        height:"100%"
    },
    continueBtn: {
        marginTop: '20px',
        padding: "15px",
        borderRadius: 8,
    },
};
// Customizable Area End
export default TermsAndConditions;