import React, { Component } from "react";

// Customizable Area Start
import {
  Grid,
  Button,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";

import {
  tapsureLogo,
  premiumIcon,
  backgroundImage
} from "./assets";
import CatalogueController from "./CatalogueController";
// Customizable Area End

// Customizable Area Start
export default class CustomerHomeInsPolicyPlans extends CatalogueController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={{ margin: "10px 20px" }}
              onClick={() => this.redirectToComponent("CustomerHomeScreen", '')}
            />
          </Grid>
        </Grid>{" "}
         {/*@ts-ignore */}
        <MainContainer container spacing={2}>
          {/* Arrow Container */}
          <Grid container>
            <Grid>
              <h3 style={{ color: "white", marginLeft: "15px" }}>
                {'ICICI Home Plans'
                  // this.productDetails !== null ?
                  //   (JSON.parse(this.productDetails)).attributes.name : 'Policy'
                }</h3>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          {
            this.homePolicyPlans.map((data, index) => {
              return (
                <Grid container style={childContainer} key={index}>
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <img height="40px" width="80px" src={starIns} alt="" />
                    <div>
                      <h4 style={{ margin: "10px 0 10px 10px" }}>
                        Family Health Plan
                      </h4>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        Star Health and Allied Insurance
                      </p>
                    </div> */}
                    </div>
                  </Grid>

                  {/* Policy Cards */}

                  <Grid item sm={12} xs={12} lg={12}>
                    <Grid container direction="row" justifyContent="space-between"
                      style={{ flexWrap: "nowrap", marginTop: 5 }}>
                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"


                        >
                          <Grid item>
                            <img src={premiumIcon} height="20px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Premium</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.premium}</h3>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <img src={premiumIcon} height="25px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Content Sum Insured</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.amount}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => this.redirectToComponent('CustomerHomeInsDetailsSteps', data)}
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              )
            })
          }


          {/* <Grid container style={childContainer}  > */}
          {/* <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="80px" width="80px" src={iciciIcon} alt="" />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                    Family Health Plan
                  </h4>
                  <p style={{ margin: "0px 0 10px 10px" }}>ICIC Lomabard </p>
                </div>
              </div>
            </Grid> */}

          {/* Policy Cards */}

          {/* <Grid item sm={12} xs={12} lg={12}>
              <Grid container direction="row" justifyContent="space-between"
                style={{ flexWrap: "nowrap", marginTop: 5 }}>
                <Grid
                  item
                  style={{
                    border: "1px solid #3451af",
                    padding: "10px 15px",
                    margin: "15px 0px 10px 5px",
                    borderRadius: "6px",
                    width: "50%"
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={premiumIcon} height="20px" />
                    </Grid>
                    <Grid item style={{ margin: "5px" }}>
                      <p style={{ margin: "2px" }}>Premium</p>
                      <h3 style={{ margin: "2px", color: "5254b3" }}>359</h3>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    border: "1px solid #3451af",
                    padding: "10px 15px",
                    margin: "15px 0px 10px 5px",
                    borderRadius: "6px",
                    width: "50%"
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={premiumIcon} height="25px" />
                    </Grid>
                    <Grid item style={{ margin: "5px" }}>
                      <p style={{ margin: "2px" }}>Price points (Incl GST)</p>
                      <h3 style={{ margin: "2px", color: "5254b3" }}>20000</h3>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}

          {/* <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => this.redirectToComponent('CustomerICICICyberFraud', '2')}
                style={{ width: "100%" }}
              >
                PROCEED
              </Button>
            </Grid>
          </Grid> */}

          {/* <Grid container style={childContainer}>
            <Grid item> */}
          {/* <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  height="40px"
                  width="80px"
                  src={nivaHealthInsIcon}
                  alt=""
                />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                    Family Health Plan
                  </h4>
                  <p style={{ margin: "0px 0 10px 10px" }}>
                    Niva Health Insurance
                  </p>
                </div>
              </div> */}
          {/* </Grid> */}

          {/* Policy Cards */}

          {/* <Grid item sm={12} xs={12} lg={12}>
              <Grid container direction="row" justifyContent="space-between"
                style={{ flexWrap: "nowrap", marginTop: 5 }}>
                <Grid
                  item
                  style={{
                    border: "1px solid #3451af",
                    padding: "10px 15px",
                    margin: "15px 0px 10px 5px",
                    borderRadius: "6px",
                    width: "50%"
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={premiumIcon} height="20px" />
                    </Grid>
                    <Grid item style={{ margin: "5px" }}>
                      <p style={{ margin: "2px" }}>Premium</p>
                      <h3 style={{ margin: "2px", color: "5254b3" }}>449</h3>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    border: "1px solid #3451af",
                    padding: "10px 15px",
                    margin: "15px 0px 10px 5px",
                    borderRadius: "6px",
                    width: "50%"
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={premiumIcon} height="25px" />
                    </Grid>
                    <Grid item style={{ margin: "5px" }}>
                      <p style={{ margin: "2px" }}>Price points (Incl GST)</p>
                      <h3 style={{ margin: "2px", color: "5254b3" }}>30000</h3>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}

          {/* <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => this.redirectToComponent('CustomerICICICyberFraud', '3')}
                style={{ width: "100%" }}
              >
                PROCEED
              </Button>
            </Grid>
          </Grid> */}
        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start

const MainContainer = withStyles({
  root: {
    backgroundImage:
      `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "5px",
    marginTop: "10px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  },
})(Grid);

const childContainer = {
  padding: "10px",
  font: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",


  borderRadius: "5px",

  margin: "15px",
  backgroundColor: "white",
};

// Customizable Area End
