import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Container,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";

import DashboardController from "./DashboardController";
// Customizable Area End

// Customizable Area Start
class Blogs extends DashboardController {



    render() {
        return (
            <>
                {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>

                    <Container maxWidth='sm'>
                        <Paper elevation={2} >
                                {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <Typography
                                    style={{ margin: "15px 0px", color: "black", textAlign: "center", fontWeight: "bold", fontSize: "29px" }}
                                    variant="h6"
                                >
                                    Blogs
                                </Typography>
                                <p style={style.subHeader}> <u>The Art Of Living</u> </p>
                                <TableContainer className="tableStyle" component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell>5 Tips to maintain active life style</TableCell>
                                                <TableCell>Self Care</TableCell>
                                                <TableCell>7 Foods to reduce Bloating</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow

                                            >
                                                <TableCell  >
                                                    <ol type="1" style={{padding:"0"}} className="bolgTableOl">
                                                        <li>Regular Exercise & Keep Moving</li>
                                                        <li>Healthy Eating Habits</li>
                                                        <li>Go for Regular Health Checkups</li>
                                                        <li>Drink Safe Water</li>
                                                        <li> Take care of your mental health</li>
                                                    </ol>
                                                </TableCell>
                                                <TableCell  >
                                                    <ol type="1" style={{padding:"0"}}  className="bolgTableOl">
                                                        <li>Consider Your hair</li>
                                                        <li>Consider your Skin</li>
                                                        <li>Target Your insecurities</li>
                                                        <li>Food allergies are true , accept it</li>
                                                        <li>Seek help</li>

                                                    </ol>
                                                </TableCell>
                                                <TableCell  >
                                                    <ol type="1" style={{padding:"0"}}  className="bolgTableOl">
                                                       <li> Cucumbers</li>
                                                       <li>Bananas</li> 
                                                       <li>Papaya</li> 
                                                       <li> Asparagus</li>
                                                      <li> Curd with Probioties</li> 
                                                      <li> Ginger and Fennel Seeds</li> 


                                                    </ol>
                                                </TableCell>

                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Box>
                        </Paper>

                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style: any = {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    continueBtn: {
        marginTop: '20px',
        padding: "15px",
        borderRadius: 8,
    },
    header: {
        position: "relative",
        left: "45%",
        width: "100px",
    },
    subHeader: {
        fontSize: '20px',
        position: "relative",
       textAlign:"center",
       width:"100%"
    },
    flexBox: {
        display: "flex",
    }
};
// Customizable Area End
export default Blogs;