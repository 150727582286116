Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.assureUrlContentType = "application/json";
exports.assure_url = "bx_block_categories/assure_url";
exports.assure_methodType = "POST";
exports.assure_urlTwo = "bx_block_categories/assure_four_wheeler_url"
exports.productNameAPIEndPoint = "bx_block_3rdpartyintegrationwithinsuranceco3/product_master";
exports.mobileBrandsApiEndpoint = "bx_block_3rdpartyintegrationwithinsuranceco3/mobile_brand";
exports.customer_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/customer";
exports.pinCodeMaster_url = "bx_block_3rdpartyintegrationwithinsuranceco3/search_pin_code";
exports.create_order_url = "bx_block_payments/create_order";
exports.issue_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/issue_policy";
exports.update_methodType = "PUT";
exports.verify_signature_url = "bx_block_payments/billdesk_transaction_details";
exports.assureUrlContentType = "application/json";
exports.assure_url = "bx_block_categories/assure_url";
exports.assure_methodType = "POST";
exports.assure_urlTwo = "bx_block_categories/assure_four_wheeler_url"
exports.productNameAPIEndPoint = "bx_block_3rdpartyintegrationwithinsuranceco3/product_master";
exports.mobileBrandsApiEndpoint = "bx_block_3rdpartyintegrationwithinsuranceco3/mobile_brand";
exports.customer_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/customer";
exports.pinCodeMaster_url = "bx_block_3rdpartyintegrationwithinsuranceco3/search_pin_code"
exports.serviceListUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/service_list"
exports.planMasterUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/plan_master";
exports.update_payment = "bx_block_payments/razorpays";
exports.assureUrlContentType = "application/json";
exports.assure_url = "bx_block_categories/assure_url";
exports.assure_methodType = "POST";
exports.assure_urlTwo = "bx_block_categories/assure_four_wheeler_url"
exports.productNameAPIEndPoint = "bx_block_3rdpartyintegrationwithinsuranceco3/product_master";
exports.mobileBrandsApiEndpoint = "bx_block_3rdpartyintegrationwithinsuranceco3/mobile_brand";
exports.customer_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/customer";
exports.pinCodeMaster_url = "bx_block_3rdpartyintegrationwithinsuranceco3/search_pin_code"
exports.serviceListUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/service_list"
exports.planMasterUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/plan_master"
exports.oneAssistUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/on_board"
exports.oneAssistIssuePolicyUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/on_board_payment";
exports.planMasterFilterUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/plan_master_filter";
exports.HomePolicyDetailsAPIEndPoint = "bx_block_catalogue/get_prdouctwise_policy_details";
exports.assureUrlContentType="application/json";
exports.assure_url="bx_block_categories/assure_url";
exports.assure_methodType="POST";
exports.assure_urlTwo="bx_block_categories/assure_four_wheeler_url"
exports.productNameAPIEndPoint="bx_block_3rdpartyintegrationwithinsuranceco3/product_master";
exports.mobileBrandsApiEndpoint="bx_block_3rdpartyintegrationwithinsuranceco3/mobile_brand";
exports.customer_policy_url="bx_block_3rdpartyintegrationwithinsuranceco3/customer";
exports.pinCodeMaster_url="bx_block_3rdpartyintegrationwithinsuranceco3/search_pin_code"
exports.serviceListUrl="bx_block_3rdpartyintegrationwithinsuranceco3/service_list"
exports.planMasterUrl="bx_block_3rdpartyintegrationwithinsuranceco3/plan_master"
exports.oneAssistUrl="bx_block_3rdpartyintegrationwithinsuranceco3/on_board"
exports.oneAssistIssuePolicyUrl="bx_block_3rdpartyintegrationwithinsuranceco3/on_board_payment";
exports.planMasterFilterUrl="bx_block_3rdpartyintegrationwithinsuranceco3/plan_master_filter"
exports.oneAssistPolicyTabsEndPoint="bx_block_catalogue/get_prdouctwise_policy_details"
exports.travelCustomerUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/customer"
exports.travelIssuePolicy = "bx_block_3rdpartyintegrationwithinsuranceco3/travel_issue_policy"
exports.travelPolicyDetails = "bx_block_catalogue/get_prdouctwise_policy_details/";
exports.getCategoriesUrl="bx_block_categories/categories";
exports.getCategoriesMethodType = "GET";
exports.homePolicyDetails = "bx_block_catalogue/get_prdouctwise_policy_details/";
exports.createCustomerHomeApi = "bx_block_3rdpartyintegrationwithinsuranceco3/home_insurance";
exports.homePinCodeMaster = "/bx_block_3rdpartyintegrationwithinsuranceco3/pincode_listing"
exports.homeIssuePolicy = "bx_block_3rdpartyintegrationwithinsuranceco3/home_proposal";
exports.generateCommissionUrl="account_block/merchants_commission";
exports.sendMessagesUrl="bx_block_3rdpartyintegrationwithinsuranceco3/send_payment_sms"
exports.getMerchantDetails ="filter_merchant_products/"
exports.getPolicySmsUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/complete_the_policy_sms"
exports.getPdfDataUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/"
exports.getDataByTagUrl = "bx_block_qr_codes/get_tag_by_id/"
exports.getBaseUrlPath = "bx_block_qr_codes/get_base_url/"
// Customizable Area End