
import React, { Component } from "react";

// Customizable Area Start
import {
   
  backgroundImage,
  backIcon,
  tappmart_logo
} from "./assets";
import {
  Grid,
  Button,
  Box,
  Tabs,
   
  Tab,
  
  CircularProgress, Backdrop
} from "@material-ui/core";
import {
   
  withStyles,
} from "@material-ui/core/styles";
import { Markup } from 'interweave';

 
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

import OneAssistController, { Props } from "./OneAssistController";
import '../assets/policyDetails.css'

// Customizable Area End

// Customizable Area Start

export default class CustomerOneAssistPolicyTabs extends OneAssistController {
  constructor(props: Props) {
    super(props);
  }
  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  

  render() {
    return (
      <>
     
      <Backdrop
      style={{ zIndex: 1000 + 1, color: "#fff" }}
      open={this.state.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      
        
        <Grid container spacing={2}>
          <Grid item>
            <img 
              src={tappmart_logo} 

              className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
        </Grid>{" "}
         {/*@ts-ignore */}
        <MainContainer container>
          {/* Arrow Container */}
          <Grid>
            <Grid>
              <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ marginTop: "15px" }}
                  onClick={this.handleBack}
                  data-test-id="button"
                />
              </Button>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          <Grid container style={childContainer}>
            <Grid xs={12} item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="80px" width="80px" src={this.state.policyTabsData?.attributes?.image} alt="" />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                   {this.state.policyTabsData?.attributes?.name}
                  </h4>
                  <p style={{ margin: "0px 0 5px 10px" }}>{this.state.policyTabsData?.attributes?.discription}</p>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* Policy Details Container */}

          <Grid container style={childContainer}>
            <Grid xs={12} item>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handlePolicyTabsChange}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                
                 {/*@ts-ignore */}
                <CustomTabs label="Policy Benefits" />
                 {/*@ts-ignore */}
                <CustomTabs label="About" />
                 {/*@ts-ignore */}
                <CustomTabs label="FAQs" />
              </Tabs>

              <TabPanel
                value={this.state.value}
                index={0}
                {...this.a11yProps(0)}
              >
                <Grid container spacing={2} >
                  <Grid item xs={12} >
                   <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                   <h3 style={webStyle.headColor}>Policy Benefits</h3>
                    <div id="policyBenefits" >
                     {this.state.policyTabsData?.attributes?.PolicyDetail?.policy_benefits?
                     <Markup content={this.state.policyTabsData?.attributes?.PolicyDetail?.policy_benefits} className="policyBenifits" />
                     :""}
                     
                       
                    </div> 
                   </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={1}
                {...this.a11yProps(1)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                    <h3 style={webStyle.headColor}>About</h3>
                  <div id="aboutPolicy" >
                     {this.state.policyTabsData?.attributes?.PolicyDetail?.about?
                     <Markup content={this.state.policyTabsData?.attributes?.PolicyDetail?.about}  />
                     :""}
                     
                    </div> 
                    </Box>
                  
                  </Grid>
                </Grid>
              
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={2}
                {...this.a11yProps(2)}
              >
                <Grid container>
                  <Grid item>
                  <h3 style={webStyle.headColor}>FAQs</h3>
                  </Grid>
                </Grid>
                <div id="faqTable" >
                {this.state.policyTabsData?.attributes?.Faqs?.map((faq:any)=>
                 ( <Grid container>
                  <Grid xs={12} item>
                    <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                    <h3 style={webStyle.headColor}><Markup content={faq?.question} className="" /></h3>
                  <Markup content={faq.answer}  />
                    </Box>
                
                    
                  </Grid>
                </Grid>)
                )}
                </div>
                
                
                
              </TabPanel>
            </Grid>
          </Grid>
        </MainContainer>
         {/*@ts-ignore */}
        <BottomContainer container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.handleNext}
              style={{ width: "100%" }}
            >
              NEXT
            </Button>
          </Grid>
        </BottomContainer>
      </>
    );
  }
}

// Customizable Area End


// Customizable Area Start

const MainContainer = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      " system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);
const CenterCardContainer = withStyles({
  root: {
    padding: "10px",
    display: "flex",
    borderRadius: "5px",
    color: "white",
    margin: "15px",
    flexWrap: "nowrap",
  },
})(Grid);

const BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
  },
})(Grid);

const childContainer = {
  padding: "5px 10px",
  display: "flex",
  borderRadius: "5px",
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  margin: "15px",
  backgroundColor: "white",
  
};
const CustomTabs = withStyles({
  root: {
    textTransform: "none",
    padding: "6px 12px",

    letterSpacing: 0,
    whiteSpace: "normal",
  },
})(Tab);
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
const webStyle = {
  headColor: { color: "#5254b3" },
};

// Customizable Area End
