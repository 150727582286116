
import React, { Component } from "react";

// Customizable Area Start
import '../assets/policyDetails.css'
import {

  iciciIcon,

  backgroundImage,
  backIcon,
  tapsureLogo,

} from "./assets";
import {
  Grid,
  Button,
  Tabs,
  Tab,

} from "@material-ui/core";
import {

  withStyles,
} from "@material-ui/core/styles";
import { Markup } from 'interweave';


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

import CustomerHomeInsController, { Props } from "./CustomerCyberFraudController";
// Customizable Area End

// Customizable Area Start

export default class CustomerCyberFraudPolicyTabs extends CustomerHomeInsController {
  constructor(props: Props) {
    super(props);
  }
  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  componentDidMount(): any {
    if (window.location?.href.includes("CustomerCyberFraudPolicyTabs")) {
      let url = new URL(window.location.href)
      let merchantId: any = url.searchParams.get("merchantId");
      let product_id: any = url.searchParams.get("product_id");
      if (merchantId && product_id) {
        localStorage.setItem('merchantId', merchantId)
        localStorage.setItem('product_id', product_id)
      }
    }
    this.getListRequest("");
  }
  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={{ margin: "10px 20px" }}
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
        </Grid>{" "}
        <MainContainer_CustomerCyberFraudPolicyTabsWeb container>
          {/* Arrow Container */}
          <Grid>
            <Grid>
              <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ margin: "10px" }}
                  onClick={this.handleBack}
                />
              </Button>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          <Grid container style={childContainer}>
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="80px" width="80px" src={iciciIcon} alt="" />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                    ICICI Lombard Cyber Fraud Plan
                  </h4>
                  <p style={{ margin: "0px 0 5px 10px" }}>ICICI Lomabard </p>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* Policy Details Container */}

          <Grid container style={childContainer}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handlePolicyTabsChange}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                 {/*@ts-ignore */}
                <CustomTabs label="Policy Benefits" />
                 {/*@ts-ignore */}
                <CustomTabs label="About" />
                 {/*@ts-ignore */}
                <CustomTabs label="FAQs" />
              </Tabs>
              <TabPanel
                value={this.state.value}
                index={0}
                {...this.a11yProps(0)}
              >
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                  <Grid item>
                    <h3 style={{ ...webStyle_CustomerCyberFraudPolicyTabsWeb.headColor, margin: '0px' }}>Policy Benefits</h3>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div>
                      {this.state.arrayHolder.PolicyDetail !== null &&
                        <div style={{ display: "flex", alignItems: 'center' }}>
                          <div style={{ margin: "0px 0px 18px 5px" }}>
                            {" "}

                            {
                              this.state.arrayHolder.PolicyDetail !== undefined && (
                                <>
                                  {" "}
                                  <div className="policyDetailsTable">
                                    <Markup content={this.state.arrayHolder.PolicyDetail.policy_benefits} className="policyBenifits" />
                                  </div>
                                  </>
                              )
                            }
                          </div>
                        </div>
                      }
                    </div>


                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={1}
                {...this.a11yProps(1)}
              >
                <Grid container>
                  <Grid item style={{ overflowX: "auto" }}>

                    {
                      this.state.arrayHolder.PolicyDetail !== null && (
                        <Markup content={this.state.arrayHolder.PolicyDetail?.about} />
                      )
                    }

                  </Grid>
                </Grid>

              </TabPanel>
                 <TabPanel
                value={this.state.value}
                index={2}
                {...this.a11yProps(2)}
              >
                <Grid container>
                  <Grid item>
                    <h3>FAQs</h3>
                  </Grid>
                </Grid>
                {
                  this.state.arrayHolder?.Faqs !== null && (
                    this.state.arrayHolder?.Faqs?.map((data: { question: string | null | undefined; answer: string | null | undefined; }, index: number) => {
                      return (
                        <Grid container key={index}>
                          <Grid item>
                            <h3 style={webStyle_CustomerCyberFraudPolicyTabsWeb.headColor}>
                              <Markup content={data.question} className="question" />
                            </h3>

                            <div>
                              <Markup content={data.answer} className="answer" />
                            </div>
                          </Grid>
                        </Grid>
                      )
                    })
                  )
                }

              </TabPanel>
            </Grid>
          </Grid>

        </MainContainer_CustomerCyberFraudPolicyTabsWeb>
        <BottomContainer_CustomerCyberFraudPolicyTabsWeb container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.handleNext}
              style={{ width: "100%" }}
            >
              NEXT
            </Button>
          </Grid>
        </BottomContainer_CustomerCyberFraudPolicyTabsWeb>
      </>
    );
  }
}

// Customizable Area End


// Customizable Area Start

const MainContainer_CustomerCyberFraudPolicyTabsWeb = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      " system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);
const BottomContainer_CustomerCyberFraudPolicyTabsWeb = withStyles({
  root: {
    padding: "10px 15px",
  },
})(Grid);
const CustomTabs = withStyles({
  root: {
    textTransform: "none",
    padding: "6px 12px",

    letterSpacing: 0,
    whiteSpace: "normal",
  },
})(Tab);
const childContainer = {
  padding: "5px 10px",
  display: "flex",
  borderRadius: "5px",
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  margin: "15px",
  backgroundColor: "white",
  overflow: "auto"
};

const webStyle_CustomerCyberFraudPolicyTabsWeb = {
  headColor: { color: "#5254b3" },
};
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

// Customizable Area End
