import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;

  innerStep: number;
  showCustomerDetailSteps: boolean;
  OpenVechileDetailsDrawer: boolean;
  ownerDetailsStepIndex: number;
  value: number;
  openPolicyDetailDrawer: boolean;
  steps: string[];
  personalDetailsForm: {
    title: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
  },
  contactDetailsForm: {
    email: string;
    phoneNumber: string;
    address: string;
    city: string;
    pinCode: string;
  },
  // Customizable Area Start
  policyDetailsForm: {
    sumToBeInsured: string;
    policyStartDate: string;
    gstin: string;
    premium: string;
  },
  isErrorPersonal: any;
  isErrorContact: any;
  isErrorPolicy: any;
  policyDetails: any;
  loading: boolean;
  Account_ID: number;
  EndDate: Date;
  city: string;
  orderDetails: any;
  cyberDetails: any;
  transaction_id: any;
  pay_description: string;
  toastMessage: string;
  openToast: boolean;
  toastType: string;
  inValidpinCode: boolean;
  openModal: boolean;
  policy_Number:any;
  stopPaymentApi:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerHomeInsController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      innerStep: 0,
      showCustomerDetailSteps: false,
      OpenVechileDetailsDrawer: false,
      ownerDetailsStepIndex: 0,
      value: 0,
      openPolicyDetailDrawer: false,
      steps: ["Personal Details", "Contact Details"],
      personalDetailsForm: {
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        dateOfBirth: "",
      },
      contactDetailsForm: {
        email: "",
        phoneNumber: "",
        address: "A-110. Sector 5 Noida Uttar Pradesh 201301",
        city: "",
        pinCode: "",
      },
      policyDetailsForm: {
        sumToBeInsured: "",
        policyStartDate: "",
        gstin: "",
        premium: "",
      },
      isErrorPersonal: {},
      isErrorContact: {},
      isErrorPolicy: {},
      policyDetails: {},
      loading: false,
      Account_ID: 0,
      EndDate: new Date('12-02-2022'),
      city: '',
      orderDetails: {},
      cyberDetails: {},
      transaction_id: 0,
      pay_description: '',
      toastMessage: "",
      toastType: "success",
      openToast: false,
      inValidpinCode: false,
      openModal: false,
      policy_Number:0,
      stopPaymentApi:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.PinCodeCyberVerifyAPICalled:
            this.setCyberLocationApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.policyCyberCustomerAPICalledID:
            this.setCyberFraudCustomer(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.createCyberOrderAPICalledID:
            this.setCyberCreateOrderApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.verifyCyberOrderAPICalledID:
            this.verifyCyberOrderApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.IssueCyberPolicyAPICalledID:
            this.issueCyberFraudApi(responseJson, errorReponse)
            break;
          default:
        }

      }

    }

     
    // Customizable Area End
  }

  // Customizable Area Start
  policyCyberCustomerAPICalledID: any;
  PinCodeCyberVerifyAPICalled: any;
  createCyberOrderAPICalledID: any;
  verifyCyberOrderAPICalledID: any;
  IssueCyberPolicyAPICalledID: any;
  generateCyberCommissionId: any;
  getCyberPolicySmsId: any
  sendCyberMessagesApi: any;
  policyList = [
    {
      id: 1,
      amount: '10000',
      premium: '299'
    },
    {
      id: 2,
      amount: '20000',
      premium: '359'
    },
    {
      id: 3,
      amount: '50000',
      premium: '449'
    }
  ];
  handleBack = () => {
    if (this.state.activeStep > 0) {
      this.setState({ activeStep: this.state.activeStep - 1 })
    }
    else {
      this.props.navigation.goBack();
    }
  };
  handleActiveStep = () => {
    let step = this.state.activeStep + 1
    if (step == 1) {
      this.setState({ isErrorPersonal: this.validateCyberPersonal(this.state.personalDetailsForm) },
        () => {
          const { firstName, lastName, gender, dateOfBirth } = this.state.personalDetailsForm;
          if (!this.state.isErrorPersonal.firstName && !this.state.isErrorPersonal.lastName && !this.state.isErrorPersonal.dateOfBirth) {
            if (firstName && lastName && gender && dateOfBirth) {
              this.setState({ activeStep: step });
            }
          }
        }
      );
    }
  
  };

  redirectToComponent = (component: string, policyType: string) => {
    this.props.navigation.navigate(component, { policyID: policyType });
  };

  handleProceed = () => {
    let merchant_id = localStorage.getItem('merchantId')
    this.setState({ isErrorContact: this.validateCyberContact(this.state.contactDetailsForm) });
    this.setState({ isErrorPolicy: this.validateCyberPolicy(this.state.policyDetailsForm) },
      () => {
        const { sumToBeInsured } = this.state.policyDetailsForm;
        const { email, phoneNumber, pinCode } = this.state.contactDetailsForm;
        if (sumToBeInsured && email && phoneNumber.length === 10 && this.state.city && pinCode && pinCode.length === 6 && !this.state.inValidpinCode) {
          this.setState({ openPolicyDetailDrawer: true });
          this.setState({ loading: true });
          const headers = {
            "Content-Type": configJSON.assureUrlContentType,
          };
          const httpBody = {
            data: {
              title: "mr",
              first_name: this.state.personalDetailsForm.firstName,
              last_name: this.state.personalDetailsForm.lastName,
              gender: this.state.personalDetailsForm.gender,
              date_of_birth: this.state.personalDetailsForm.dateOfBirth,
              email: this.state.contactDetailsForm.email,
              full_phone_number: "91" + this.state.contactDetailsForm.phoneNumber,
              address: this.state.contactDetailsForm.address,
              city: this.state.city,
              pincode: this.state.contactDetailsForm.pinCode,
              sum_to_be_insured: this.state.policyDetailsForm.sumToBeInsured,
              new_policy_start_date: new Date(),
              gst_in: this.state.policyDetailsForm.gstin,
              premiun: this.state.policyDetailsForm.premium,
              insurance_type: "Cyber Fraud",
              merchant_id,
              id: parseInt(this.state.policyDetails.id)
            }
          };
          localStorage.setItem("policyDetails", JSON.stringify(httpBody.data));
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          this.policyCyberCustomerAPICalledID = requestMessage.messageId;

          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.customer_policy_url
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.assure_methodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        }
      }
    );
  }

  handleDrawerClose = () => {
    this.setState({ openPolicyDetailDrawer: false });
  }

  handleChangePersonal = (e: any) => {
    this.setState({
      personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
    });
    if (e.target.name === 'gender') {
      if (e.target.value === 'Male') {
        this.setState({
          personalDetailsForm: {
            ...this.state.personalDetailsForm, ['title']: 'mr',
            [e.target.name]: e.target.value
          }
        });
      } else if (e.target.value === 'Female') {
        this.setState({
          personalDetailsForm: {
            ...this.state.personalDetailsForm, ['title']: 'mrs',
            [e.target.name]: e.target.value
          }
        });
      }
    }
  };

  validateCyberPersonal = (values: any) => {
    const errors: any = {};
    const regexName = /^[A-Za-z +]+$/;
    
    if (!values.firstName) {
      errors.firstName = "Firstname is required!";
    } else if (!regexName.test(values.firstName)) {
      errors.firstName = "Please enter alphabetical characters!";
    }
    if (!values.lastName) {
      errors.lastName = "Lastname is required!";
    } else if (!regexName.test(values.lastName)) {
      errors.lastName = "Please enter alphabetical characters!";
    }
    if (new Date(values.dateOfBirth) > new Date()) {
      errors.dateOfBirth = "Please enter past date";
    }
    if (!values.gender) {
      errors.gender = "Gender is required!";
    }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Date Of Birth is required!";
    }
    return errors;
  };

  handleChangeContact = (e: any) => {
    this.setState({
      contactDetailsForm: { ...this.state.contactDetailsForm, [e.target.name]: e.target.value }
    });
    if (e.target.name === 'email') {
      this.setState({
        contactDetailsForm: { ...this.state.contactDetailsForm, ['email']: e.target.value.trim() }
      });
    }
    if (e.target.name === 'pinCode' && e.target.value.length == 6) {
      this.handleChangePincode(e.target.value);
    } else if (e.target.value.length === 0) {
      this.setState({
        city: ''
      });
    }
  }

  validateCyberContact = (values: any) => {
    const { phoneNumber } = this.state.contactDetailsForm;
    const errors: any = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexPhone = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    const regexPinCode = /^\d{3}\s?\d{3}$/
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regexEmail.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone number is required!";
    } else if (!regexPhone.test(values.phoneNumber)) {
      errors.phoneNumber = "This is not a valid phone number!";
    } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
      errors.phoneNumber = "This is not a valid phone number!";
    }
    if (!values.address) {
      errors.address = "Address is required!";
    }
    if (!this.state.city) {
      errors.city = "City is required!";
    }
    if (!values.pinCode) {
      errors.pinCode = "Pincode is required!";
    } else if (!regexPinCode.test(values.pinCode)) {
      errors.pinCode = "This is not a valid pin code!";
    }
    return errors;
  };

  handleChangePolicy = (e: any) => {
    this.setState({
      policyDetailsForm: { ...this.state.policyDetailsForm, [e.target.name]: e.target.value }
    });
    if (e.target.name === 'policyStartDate') {
      var oneYearFromNow = new Date(e.target.value);
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      this.setState({ EndDate: oneYearFromNow })
    }
    if (e.target.name === 'sumToBeInsured') {
      this.setState({
        policyDetailsForm: {
          ...this.state.policyDetailsForm, ['premium']: e.target.value === '10000' ? "299"
            : e.target.value === '20000' ? "359" : "449",
          ['sumToBeInsured']: e.target.value
        }
      });
    }
  }

  validateCyberPolicy = (values: any) => {
    const errors: any = {};
    if (!values.sumToBeInsured) {
      errors.sumToBeInsured = "Sum Insured is required!";
    }
    if (!values.policyStartDate) {
      errors.policyStartDate = "Policy start date is required!";
    }
    let newDate = new Date(Date.now() - (3600 * 1000 * 24))
    if (new Date(values.policyStartDate) < newDate) {
      errors.policyStartDate = "Please enter future date";
    }
    
    return errors;
  };
 
  RedirectoHomeScreen = () => {
    this.props.navigation.navigate("CustomerHomeScreen");
  };

  async componentDidMount() {
    super.componentDidMount();
    const planDetails = localStorage.getItem("planDetails");
    let PlanDetails
    if (planDetails !== null) {
      PlanDetails = JSON.parse(planDetails);
      this.setState({
        policyDetailsForm: {
          ...this.state.policyDetailsForm, ['sumToBeInsured']: PlanDetails.amount,
          ['premium']: PlanDetails.premium
        }
      });
    }
  };


  setCyberLocationApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      this.setState({ loading: false });
      this.setState({
        city: responseJson.city_name,
        inValidpinCode: false
      })
    } else if (responseJson === undefined) {
      this.setState({ loading: false });
      this.setState({
        city: '',
        inValidpinCode: true
      })
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  setCyberFraudCustomer = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ policyDetails: responseJson.data });
      localStorage.setItem("contact-id", JSON.stringify(responseJson.data.id));
      this.setState({ loading: false });
      this.getCyberPolicySms()
      console.log(this.state.policyDetails.id)
      localStorage.setItem("customerAccountID", this.state.policyDetails.id)
      runEngine.debugLog("arrayHolder", this.state.policyDetails);
    } else {

      this.handleDrawerClose();
      this.setState({ loading: false, openToast: true, toastMessage: responseJson.error, toastType: "error" });
      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  setCyberCreateOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson.data) {
      console.log(responseJson.data[0].orderid);
      console.log(responseJson.data[0].bdorderid);
      if (responseJson.transaction_id) {
        this.setState({
          transaction_id: responseJson.transaction_id
        })
        setInterval(() => {
          if (this.state.stopPaymentApi === true) {
            this.verifyCyberPayment(responseJson.data[0].orderid, responseJson.data[0].bdorderid, responseJson.transaction_id)
          }
        }, 3000)

      }

      const flow_config = {
        merchantId: responseJson.data[0].mercid,
        bdOrderId: responseJson.data[0].bdorderid,
        authToken: responseJson.data[0].links[1].headers.authorization,
        childWindow: true,
        retryCount: 3,
        prefs: { "payment_categories": ["card", "emi", "nb", "upi", "wallets", "qr", "gpay"] }
      }

      const responseHandler = function (txn: any) {
        console.log("callback received status:: ", txn.status)
        console.log("callback received response:: ", txn.response)
      }
      const config = {
        responseHandler: responseHandler,
        merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
        flowConfig: flow_config,
        flowType: "payments"
      }
      console.log("kumar", config.responseHandler)

      setTimeout(()=>{
        window.loadBillDeskSdk(config)
      })


      this.setState({ loading: false, openPolicyDetailDrawer: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  verifyCyberOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) {

      console.log(responseJson.payment_status);
      if (responseJson.payment_status === "success") {
        console.log("srccc")
        this.setState({
          stopPaymentApi: false
        })
        this.issueCyberPolicy()
      }

    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  issueCyberFraudApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.errors && !responseJson.error && responseJson.data.attributes.policyNo !== null) {
      console.log(responseJson.meta.account.first_name + " " + responseJson.meta.account.last_name)
      localStorage.setItem("ownername", responseJson.meta.account.first_name + " " + responseJson.meta.account.last_name);
      localStorage.setItem("base_url", responseJson.data.attributes.base_url)
      localStorage.setItem("policyType", 'Cyber Fraud');
      localStorage.setItem("policyAmount", this.state.policyDetailsForm.premium);
      localStorage.setItem("transactionDate", moment(responseJson.data.attributes.policy_start_date).format('YYYY-MM-DD'));
      localStorage.setItem("membershipNumber", responseJson.data.attributes.policyNo);
      localStorage.setItem("policyEndDate", moment(responseJson.data.attributes.policy_end_date).format('YYYY-MM-DD'));

      this.setState({
        policy_Number: responseJson.data.attributes.policyNo
      })
      this.setState({ loading: false, openToast: true, toastMessage: "Policy Issued Successfully.", toastType: "success" });

      let merchant_id = localStorage.getItem('merchantId')

      if (merchant_id) {
        this.generateCyberCommission()
      }
      this.redirectToSuccessPage();

    } else {

      this.setState({ loading: false, openModal: true });
      this.setState({ loading: false });
      let merchant_id = localStorage.getItem('merchantId')
      if (merchant_id === "null" || merchant_id === null) {
        setTimeout(() =>
          this.props.navigation.navigate("CustomerHomeScreen")
          , 3000)
      } else {
        setTimeout(() =>
          this.props.navigation.navigate("MerchantPolicyDetails")
          , 3000)
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  redirectToPayment = () => {
    this.props.navigation.navigate('CustomerPaymentOptions', { policyID: 1 });
  };
  getCyberAddressDetails = (pinCode: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PinCodeCyberVerifyAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pinCodeMaster_url + "?pin_code=" + pinCode
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChangePincode = (pinCode: any) => {
    this.getCyberAddressDetails(pinCode);
  }
  createPayOrder = async () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const httpBody = {
      amount: this.state.policyDetailsForm.premium,
      account_id: parseInt(this.state.policyDetails.id),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCyberOrderAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_order_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 
  verifyCyberPayment = (orderid: any, bdOrderId: any, transaction_id: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
      "account_id": parseInt(this.state.policyDetails.id),
      "transaction_id": transaction_id,
      "order_id": orderid,
      "bdorder_id": bdOrderId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCyberOrderAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verify_signature_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  issueCyberPolicy = () => {
    let merchant_id = localStorage.getItem('merchantId')
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    var oneYearFromNow = new Date();
    oneYearFromNow.setDate(oneYearFromNow.getDate() - 1);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    console.log(oneYearFromNow, 'oneYearFromNow');
    const httpBody = {
      data: {
        account_id: parseInt(this.state.policyDetails.id),
        transaction_id: parseInt(this.state.transaction_id),
        payment_status: true,
        policy_end_date: moment(oneYearFromNow).format("DD/MM/YYYY"),
        policy_start_date: moment().format("DD/MM/YYYY"),
        merchant_id
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.IssueCyberPolicyAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.issue_policy_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 

  redirectToSuccessPage = () => {
    this.props.navigation.navigate("CustomerOrderSuccess");
  }

  redirectToHomeScreen = () => {
    this.props.navigation.navigate('CustomerHomeScreen');
  }

  closeModel = () => {
    this.setState({ openModal: !this.state.openModal });
  }

  generateCyberCommission = () => {
    let merchant_id = localStorage.getItem('merchantId')
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let transactionDate = localStorage.getItem('transactionDate');
    let policyAmount: any = localStorage.getItem('policyAmount');
    let product_id: any = localStorage.getItem('product_id');
    const httpBody = {
      "data": {
        "account_id": parseInt(this.state.policyDetails.id),
        "insurance_type": "Cyber Fraud",
        "merchant_id": merchant_id,
        "date_of_purchase": transactionDate,
        "premium_paid": parseInt(policyAmount),
        "product_id": parseInt(product_id),


      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateCyberCommissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateCommissionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getCyberPolicySms = () => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = parseInt(this.state.policyDetails.id)
    const httpBody = {

      "data": {
        account_id,
        "policy_name": "Cyber fraud",
        "platform": "Tappmart"
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCyberPolicySmsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPolicySmsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
 
  // Customizable Area End
}
