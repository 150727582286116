Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first";
exports.pinCodeMaster_url="bx_block_3rdpartyintegrationwithinsuranceco3/search_pin_code";
exports.oneAssistPolicyTabsEndPoint="bx_block_catalogue/get_prdouctwise_policy_details";
exports.create_order_url = "bx_block_payments/create_order";
exports.customer_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/customer";
exports.update_payment = "bx_block_payments/razorpays";
exports.issue_policy_url = "bx_block_3rdpartyintegrationwithinsuranceco3/issue_policy";
exports.verify_signature_url = "bx_block_payments/billdesk_transaction_details";
exports.hospicashUrl="bx_block_3rdpartyintegrationwithinsuranceco3/care_policy"
exports.update_methodType = "PUT";
exports.assure_methodType = "POST";
exports.generateCommissionUrl="account_block/merchants_commission";
exports.vectorBorneURL="bx_block_3rdpartyintegrationwithinsuranceco3/vector_care_policy";
exports.sendMessagesUrl="bx_block_3rdpartyintegrationwithinsuranceco3/send_payment_sms"
exports.getPolicySmsUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/complete_the_policy_sms"
exports.healthServiceUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/group_care_360";
exports.getPdfDataUrl = "bx_block_3rdpartyintegrationwithinsuranceco3/policy_pdf";
exports.issueAfterProposalDOC = "bx_block_3rdpartyintegrationwithinsuranceco3/group_care_payment"
// Customizable Area End