import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";
 
import DashboardController from "./DashboardController";
// Customizable Area End

// Customizable Area Start
class TCGroupInsurance extends DashboardController {

    

    render() {
        return (
            <>
            {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>

                    <Container maxWidth='sm'>
                        <Paper elevation={2} >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <Typography
                                    style={{ margin: "15px 0px", color: "black",textAlign:"center",fontWeight: "bold",fontSize:"29px" }}
                                    variant="h6"
                                >
                                Terms & Conditions - Group Insurance
                                </Typography>
                                <p style={style.para}>{"NetAmbit Value first solutions private Limited ( Tappmart) is master policy holder of Care health Insurance IRDA Registration No. 148. Copyrights 2013, All right reserved by Care Health Insurance Ltd  with products ( Group Care 360 – {Doctor on Call + Discount Connect} , Daily Cash Allowance and Vector Born Cover ) Reg Office - Care Health Insurance Limited , 5th Floor, 19, Chawla House, Nehru Place, New Delhi-110019 | CIN - U66000DL2007PLC161503"}</p>
        <p style={style.para}>{"Correspondence Address - Care Health Insurance Limited , Unit no 604-607, 6th Floor, Tower C, Unitech Cyber Park, Sector 39, Gurgaon – 122001, For more details on the risk factors, terms & Conditions , please read the policy wordings, terms and conditions etc mentioned in our website before concluding the sale."}</p>
        <p style={style.para}>{"NetAmbit Value first solutions Private Limited ( Tappmart ) is not an Insurance Intermediary Registered with Insurance Regulatory and Development Authority of India and does not undertake any activity Pertaining to Marketing, Solicitation and Distribution of Insurance Products. Any Insurance product mentioned Information displayed on the Tappmart Website is solely based on information received from the concerned Insurer."}</p>
        <p style={style.para}>{"Please note that the insurance products are obligations only of the Insurance company. They are not obligations of or guaranteed by tappmart or any of its affiliates or subsidiaries. Enrolment under group Insurance policy is subject to eligibility and other requirements prescribed by the insurance company. All claims under the insurance policy will be solely decided upon the insurance company."}</p>
        <p style={style.para}>{"Tappmart or any of its Group Entities hold no warranty and do not make any representation or are responsible for the insurance cover offered by the insurance company and shall not be responsible for any grievances, complaints, claims, or for processing of or settlement of claims in any manner what so ever."}</p>
        <p style={style.para}>{"The information available on Tappmart Website does not constitute distribution of any information or making of any offer or solicitation by anyone of any jurisdiction in which such distribution or offer is not authorized or to any person to whom it is unlawful to distribute such a document or make such a offer or solicitation"}</p>
        <p style={style.para}>{"Participation Tappmart Merchants or customers in an Insurance product is purely on a voluntary basis and is not liked to availing of any other facility or service from Tappmart. Tax Benefits are subject to changes in the Tax laws and the customers should seek advice based on the tax payers particular Circumstances from any independent tax advisor"}</p>
        <p style={style.para}>{"For Any Queries with respect to your Insurance cover Availed through Tappmart, Kindly Contact – Contact@Tappmart.com"}</p>

                              
                            </Box>
                        </Paper>

                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style:any= {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        overflowY: "scroll",
        height:"100%"
    },
    continueBtn: {
        marginTop: '20px',
        padding: "15px",
        borderRadius: 8,
    },
    header:{
        position:'relative',
        left:'20%',
        width:'1000px'
      },
      para:{
        paddingLeft:"20px",
        paddingRight:"20px"
      }
};
// Customizable Area End
export default TCGroupInsurance;