import PropTypes from "prop-types";
import React, { Component } from "react";
import { CheckBox as CheckBoxIos } from "react-native-elements";
import { CheckBox, Platform, Switch } from "react-native";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { checkedIcon, tapsureLogo } from "./assets";
import { close, gmail, facebook, whatsapp } from "./Webassets";

// Customizable Area Start
import moment from "moment";
import OrderSuccessController2, { Props } from "./OrderSuccessController2";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  Modal,
} from "@material-ui/core";
import {
  withStyles,
}from "@material-ui/core/styles";


class CustomerOrderSuccess extends OrderSuccessController2 {
  
  sharePolicyModal = () => {
    this.setState({ openSharePolicy: true });
 
  };

  handleSharePolicyClose = () => {
    this.setState({ openSharePolicy: false });
  };

  constructor(props: Props) {
    super(props);
  }
  render() {
    console.log(this.state.policyType === "Home")
    console.log(this.state.policyType)
    let newDatePolicy = this.state.transactionDate;
    let endDatePolicy = this.state.policyEndDate;
    let displayDate1 =   moment.utc(newDatePolicy).format('DD/MM/YYYY')
    let displayDate2 =   moment.utc(endDatePolicy).format('DD/MM/YYYY')
    let newHomeStartDate = this.state.homePolicyStartDate;
    let newHomeEndDate = this.state.homePolicyEndDate;
    let displayHomeStartDate = moment.utc(newHomeStartDate).format('DD/MM/YYYY')
    let displayHomeEndDate = moment.utc(newHomeEndDate).format('DD/MM/YYYY')
    
    return (
      
      <>
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={CustomerOrderSuccessStyleCss}
              onClick={() => this.RedirectoHomeScreen()}
            />
          </Grid>
        </Grid>
        <MainContainer_CustomerOrderSuccessPage container>
          <ChildContainer_CustomerOrderSuccessPage>
            <Grid item>
              <img
                src={checkedIcon}
                height="55px"
                style={{ marginBottom: "10px" }}
              />
            </Grid>

            <Grid>
              <Grid item style={{ fontWeight: "bold" }}>
                Policy enrolled successfully
              </Grid>
            </Grid>
            <Grid item>
              <p style={{ color: "grey" }}>
                We will send customer notification email and SMS with download
                link
              </p>
            </Grid>
            {this.state.policyType == "One Assist" && this.state.membershipNumber ? (
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              // style={webStyle.paddingLeftBottom}
              >
                <Grid item style={{ color: "grey", textAlign: "left" }}>
                  <p>Customer Name:</p>
                  <p>Policy number:</p>
                  <p>Start Date:</p>
                  <p>End Date:</p>
                  <p>Premium Paid:</p>
                  <p>Insurer Name:</p>
                  <p>Policy status:</p>

                </Grid>
                <Grid item style={{ fontWeight: "bold", textAlign: "start" }}>
                <p>{this.state.OwnerType}</p>
                  <p> {this.state.membershipNumber}</p>
                  <p> {this.state.transactionDate}</p>
                  <p>{this.state.policyEndDate} </p>
                  <p> Rs {this.state.policyAmount}</p>
                  <p>One Assist</p>

                  <p>Success</p>
                </Grid>
              </Grid>
            ) : this.state.policyType === 'Cyber Fraud' ?
              (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                // style={webStyle.paddingLeftBottom}
                >
                  <Grid item style={{ color: "grey", textAlign: "left" }}>
                    <p>Customer Name:</p>
                    <p>Policy number:</p>
                    <p>Start Date:</p>
                    <p>End Date:</p>
                    <p>Premium Paid:</p>
                    <p>Policy Type:</p>
                    <p>Policy status:</p>

                  </Grid>
                  <Grid item style={{ fontWeight: "bold", textAlign: "start" }}>
                  <p>{this.state.OwnerType}</p>
                    <p>{this.state.membershipNumber}</p>
                    <p> {this.state.orderId}</p>
                    <p> {this.state.transactionDate}</p>
                    <p>{this.state.policyEndDate} </p>
                    <p> Rs {this.state.policyAmount}</p>
                    <p>Cyber Fraud</p>

                    <p>Success</p>
                  </Grid>
                </Grid>
              ) : 
              this.state.policyType === "Home" ? 
              (
                <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              // style={webStyle.paddingLeftBottom}
              >
                <Grid item style={{ color: "grey", textAlign: "left" }}>
                  <p>Customer Name:</p>
                  <p>Policy number:</p>
                  <p>Start Date:</p>
                  <p>End Date:</p>
                  <p>Premium Paid:</p>
                  <p>Insurance Type:</p>
                  <p>Policy status:</p>

                </Grid>
                <Grid item style={{ fontWeight: "bold", textAlign: "start" }}>
                  <p>{this.state.homeInsurerName}</p>
                  <p> {this.state.PolicyNoHome}</p>
                
                  <p> {displayHomeStartDate}</p>
                  <p>{displayHomeEndDate} </p>
                  <p> Rs {this.state.homePolicyAmount}</p>
                  <p>Home</p>

                  <p>Success</p>
                </Grid>
              </Grid> 
              ) :
              this.state.policyType === 'Travel' ?
              (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                // style={webStyle.paddingLeftBottom}
                >
                  <Grid item style={{ color: "grey", textAlign: "left" }}>
                    <p>Customer Name:</p>
                    <p>Policy number:</p>
                    <p>Start Date:</p>
                    <p>End Date:</p>
                    <p>Premium Paid:</p>
                    <p>Insurance Type:</p>
                    <p>Policy status:</p>

                  </Grid>
                  <Grid item style={{ fontWeight: "bold", textAlign: "start" }}>
                    <p>{this.state.brand?.toUpperCase()}</p>
                    <p> {this.state.model}</p>
                  
                    <p> {displayDate1}</p>
                    <p>{displayDate2} </p>
                    <p> Rs {this.state.policyAmount}</p>
                    <p>Travel</p>

                    <p>Success</p>
                  </Grid>
                </Grid>
              ): ""}

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item style={{ marginTop: "5px" }}>
                <Button
                  variant="outlined"
                  color="default"
                  size="large"
                  style={{ width: "100%", margin: "5x", fontWeight: "bold" }}
                  onClick={this.sharePolicyModal}
                >
                  SHARE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ width: "100%", margin: "5x", fontWeight: "bold" }}
                  onClick={ this.handleAfterSuccessContinue}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </ChildContainer_CustomerOrderSuccessPage>
          <Container maxWidth="sm">
             {/*@ts-ignore */}
            <Modal
              open={this.state.openSharePolicy}
              onClose={this.handleSharePolicyClose}
              aria-labelledby="modal-modal-title"
            >
              <Box sx={style_CustomerOrderSuccessPage.containerSharePolicy}>
                <Box sx={style_CustomerOrderSuccessPage.filter}>
                  <Typography variant="h6">
                    <b>Share Policy</b>
                  </Typography>
                  <img
                    style={{ cursor: "pointer" }}
                    src={close}
                    alt=""
                    height="18"
                    width="18"
                    onClick={this.handleSharePolicyClose}
                  />
                </Box>
                <Box sx={style_CustomerOrderSuccessPage.socialMedia}>
                  <a
                    // href={
                    //   "mailto:?subject=I wanted you to see this site&amp;body=Check out this site -" +
                    //   this.state.qrUrl +
                    //   this.state.qrPath
                    // }
                    title="Share by Email"
                  >
                    <img
                      src={gmail}
                      alt=""
                      height="65"
                      width="65"
                      onClick={this.handleShare}
                    />
                  </a>
                  <a
                  // href={
                  //   "https://api.whatsapp.com/send?text= Kindly tap to check our latest policy here -" +
                  //   this.state.qrUrl +
                  //   this.state.qrPath
                  // }
                  // target="_blank"
                  >
                    <img
                      src={whatsapp}
                      alt=""
                      height="65"
                      width="65"
                      onClick={this.handleShare}
                    />
                  </a>
                  <a
                  // href={
                  //   "https://www.facebook.com/sharer/sharer.php?u=" +
                  //   this.state.qrUrl +
                  //   this.state.qrPath
                  // }
                  // target="_blank"
                  >
                    <img
                      src={facebook}
                      alt=""
                      height="65"
                      width="65"
                      onClick={this.handleShare}
                    />
                  </a>
                </Box>
                {/* <Box sx={style.link}>
                  <Typography variant="body1" style={{ overflow: "scroll" }}>
                    {this.state.qrUrl + "CustomerHomeScreen"}
                  </Typography>
                  <Button
                    style={{ color: "rgb(200,0,200,1)" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${this.state.qrUrl + "CustomerHomeScreen"}`
                      )
                    }
                  >
                    <b>Copy Link</b>
                  </Button>
                </Box> */}
              </Box>
            </Modal>
          </Container>
        </MainContainer_CustomerOrderSuccessPage>
      </>
    );
  }
}
const MainContainer_CustomerOrderSuccessPage = withStyles({
  root: {
    backgroundImage:
      "linear-gradient( 75deg,rgb(202,101,193) 50%,rgb(173,95,189) 100%)",
    color: "",
    height: "100%",
    textAlign: "center",
    font:
      "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const ChildContainer_CustomerOrderSuccessPage = withStyles({
  root: {
    padding: "120px 10px",

    borderRadius: "5px",

    margin: "15px",
    textAlign: "center",
    backgroundColor: "white",
    font:
      "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);
const CustomIcon = withStyles({
  root: {
    color: "rgb(202,101,193) ",

    fontSize: "3.5rem",
  },
})(CheckCircleOutlineIcon);
const style_CustomerOrderSuccessPage = {
  containerSharePolicy: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "102%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  socialMedia: {
    mt: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  link: {
    p: 1.2,
    mt: 4,
    border: "0.1px solid #d3d3d3",
    backgroundColor: "rgb(70, 130, 180, 0)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const CustomerOrderSuccessStyleCss = { margin: "10px 20px" }
// Customizable Area End
export default CustomerOrderSuccess;
