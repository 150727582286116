import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,

} from "@material-ui/core";
import { menu } from "./assets";
import { tappmart_logo } from "./assets";
// Customizable Area End

// Customizable Area Start
class MerchantSignUpHeader extends React.Component<any, any, any> {

  render() {
    const { navigation } = this.props;
    return (
      <>
        <Container maxWidth='sm'>
        {  /*@ts-ignore*/}
          <Box
            sx={style.header}
          >
            <img
              style={{transform: "scale(1)", marginLeft: "-8px"}}
              src={menu}
              alt="menu"
            />
            <img
              style={{marginRight: "80px"}}
              src={tappmart_logo}
              height="40"
              alt="logo"
            />
            <Button
              style={{marginRight: "-10px", textTransform: "capitalize", border: "1px solid black"}}
              variant="outlined"
              color="primary"
              onClick={() => navigation.navigate("Signup")}
            >
              Sign Up
            </Button>
          </Box>
        </Container>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style = {
  header: {
      p: 1.5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
  },
};
// Customizable Area End
export default MerchantSignUpHeader;