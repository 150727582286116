import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MerchantSignUpHeader from "../../mobile-account-registration/src/MerchantSignUpHeader.web";
import {
  background
} from "./assets";
//@ts-ignore
import OtpInput from 'react-otp-input';
import MobileAccountLoginController from "./MobileAccountLoginController";
// Customizable Area End


export default class MobileAccountLoginBlock extends MobileAccountLoginController {

  render() {
    // Customizable Area Start
    const { mobile } = this.state;
    return (
      <>
        <MerchantSignUpHeader {...this.props} />
        <div style={style.image}>
        {!this.state.otp ?
          <Container maxWidth='sm'>
            {!this.state.isLoading ?
              <Paper elevation={2}>
                {  /*@ts-ignore*/}
                <Box
                  sx={style.container}
                  >
                  <form onSubmit={this.handleSubmit}>
                    <Typography
                      style={{ marginBottom: "15px" }}
                      variant="h6"
                      >
                        <b>Login</b>
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                    >
                        Phone Number
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Phone Number"
                      data-test-id="inputPhoneNumber"
                      name="mobile"
                      type="number"
                      value={mobile}
                      InputProps={{
                        startAdornment: 
                          <InputAdornment position="start">
                            +91
                          </InputAdornment>,
                      }}
                      error={this.state.isError.mobile ? true : false}
                      onChange={this.handleChange}
                    />
                    <div style={{marginTop: 5, color: 'red'}}>
                      {this.state.isError.mobile}
                    </div>
                    <Button
                      style={style.continueBtn}
                      type="submit"
                      size="large"
                      fullWidth
                      color="primary"
                      variant="contained"
                    >
                      Continue
                    </Button>
                  </form>
                </Box>
              </Paper>
            :
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#fff" }}
                open={this.state.isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            }
          </Container>
          :
          <Container maxWidth='sm'>
            {!this.state.isLoadingOtp ?
              <Paper elevation={2}>
                {  /*@ts-ignore*/}
                <Box
                sx={style.containerOtp}
                >
                  <form onSubmit={this.handleOtpSubmit}>
                    <Typography
                      style={{ marginBottom: "20px" }}
                      variant="h6"
                    >
                      <b>Enter OTP</b>
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                    >
                      A Verification code has been sent to 
                      your given phone <b>+91 {mobile}</b>
                    </Typography>
                    {  /*@ts-ignore*/}
                    <Box
                      sx={style.textfield}
                    >
                      {  /*@ts-ignore*/}
                      <OtpInput
                        inputStyle={{
                          height: '50px',
                          width: '50px',
                          fontSize: '18px',
                          border: '1px solid black',
                          marginRight: 15,
                          marginLeft: 15,
                        }}
                        data-test-id = "otpHandleChange"
                        value={this.state.otpInput}
                        onChange={this.otpHandleChange}
                        numInputs={4}
                        isInputNum={true}
                        shouldAutoFocus={true}
                      />
                    </Box>
                    <div style={{marginTop: 5, color: 'red', textAlign: 'center'}}>
                        {this.state.isErrorOtp.otpInput}
                    </div>
                    <Button
                      style={style.verifyBtn}
                      data-test-id="btnOtpFormSubmit"
                      size="large"
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Verify OTP
                    </Button>
                    {this.state.seconds > 0 ?
                      <Typography
                        gutterBottom
                        data-test-id="btnResendCode2"
                        align="center"
                        variant="body1"
                      >
                        Resend Code {`${0}${this.fmtMSS(this.state.seconds)}`}
                      </Typography>
                      :
                      <Typography
                        style={{textDecoration: "underline"}}
                        gutterBottom
                        data-test-id="btnResendCode"
                        align="center"
                        variant="body1"
                        onClick={() => {this.login(); this.handleClickResendOtp();}}
                      >
                        Resend Code
                      </Typography>
                    }
                  </form>
                  <Dialog
                    open={this.state.openResendOtp}
                    onClose={this.handleCloseResendOtp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    {/* <DialogTitle id="alert-dialog-title">
                      {"Login"}
                    </DialogTitle> */}
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        OTP sent successfully.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleCloseResendOtp} autoFocus>
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Paper>
            :
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#fff" }}
                open={this.state.isLoadingOtp}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            }
          </Container>}
        </div>
      </>
    );
    // Customizable Area End
  }
}

const style = {
  // Customizable Area Start
  image: {
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  container: {
    p: 2,
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  continueBtn: {
    marginTop: '20px',
    padding: "15px",
    borderRadius: 8,
  },

  // OTP Style
  textfield: {
    p: 0.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  containerOtp: {
    p: 2,
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  verifyBtn: { 
    marginTop: '15px',
    marginBottom: '20px',
    padding: "15px",
    borderRadius: 8,
  },
  // Customizable Area End
};
