import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";
 
import DashboardController2 from "./DashboardController2";
// Customizable Area End

// Customizable Area Start
class AblutUs extends DashboardController2 {
 
    render() {
        return (
            <>
            {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>

                    <Container maxWidth='sm'>
                        <Paper elevation={2} >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <Typography
                                    style={{ margin: "15px 0px", color: "black",textAlign:"center",fontWeight: "bold" }}
                                    variant="h6"
                                >
                                    About Us
                                </Typography>
                                <Typography component="p" style={{ textAlign: "center" }}>
                                    Tappmart is NetAmbits Merchant webpage with a <span style={{ fontWeight: "bold" }} >Vision</span>  to make Low Ticket Fintech & consumer tech products  accessible in high touch offline areas where every common customer walks in to merchant location on a daily basis several no of times.

                                </Typography>

                                <Typography component="p" style={{ textAlign: "center", marginTop: "25px" }}>
                                    We Observed that these products are mostly available in digital space and not available for common man who still prefers to purchase offline with a bit of physical assistance. In order to fill the gap we envisioned this phygital approach which is  <span style={{ fontWeight: "bold" }} >Tappmart</span>
                                </Typography>
                                <Typography component="p" style={{ textAlign: "center", marginTop: "25px" }}>
                                We ensure a One Tapp Process with real time product Issuance 

                                </Typography>
                                <Typography component="p" style={{ textAlign: "center", marginTop: "25px" }}>
                                Mission & Vision : With strong bag round of 20+ years and Network presence across 900+ cities in India our  <span style={{ fontWeight: "bold" }} >Mission & Vision</span> is to built Tappmart substantially in more than 1 million high touch merchant locations across India

                              </Typography>
                                <Typography component="p" style={{ textAlign: "center", marginTop: "25px" }}>
                                We have our presence from a small shop to departmental stores , Hotels to Restaurants and Fancy stores to Jewelry shops etc. in all possible daily customer walk-in locations.
                              </Typography>
                              <Typography component="p" style={{ textAlign: "center", marginTop: "25px" }}>
                              <span style={{ fontWeight: "bold" }} >  NETAMBITS Philosophy:</span> THINK BIG & DO GOOD : Inspired by this Spirit our goal is to create a stronger, More Effective , Compassionate and Inclusive System where Honesty is Central. 
                              </Typography>
                            </Box>
                        </Paper>

                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    continueBtn: {
        marginTop: '20px',
        padding: "15px",
        borderRadius: 8,
    },
};
// Customizable Area End
export default AblutUs;