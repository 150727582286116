import React from "react";
// Customizable Area Start
import {
 
  Paper,
  
  Button,
  Container,
   
  CircularProgress,
  Backdrop,
  Grid,
} from "@material-ui/core";
import CustomerController from "./CustomerController";
import {
 
  rightArrowIcon,
  tappmart_logo,
  backgroundImage,
} from "./assets";
// Customizable Area End

// Customizable Area Start
class CustomerHomeScreenDynamic extends CustomerController {
  render() {
    
   
    return (
      <>
        <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item>
            <img
              onClick={this.RedirectoHomeScreen}
              height="40px"
            style={{marginTop: "6px",
              marginBottom: "6px"}}
              src={tappmart_logo} 
            />
          </Grid>
        </Grid>{" "}
        <div style={styleHome.imageHome}>
          <Grid container>
            <Grid>
              <h4 style={styleHome.instructionsHome}>Choose a Service</h4>
            </Grid>
          </Grid>
          <Container maxWidth="sm">
            {this.state.customerCategories.length > 0
              ? this.state.customerCategories.map((item: any,id:any) => {
                  return (
                    <Paper
                      elevation={2}
                      style={{ cursor: "hand" }}
                      onClick={() => {}}
                      key={id}
                    >
                      <Grid
                        container
                        style={styleHome.childContainerHome}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <div style={{ display: "flex", marginLeft: "10px" }}>
                            <img
                              height="40px"
                              width="40px"
                              src={item?.attributes?.image}
                              alt=""
                            />
                            <h4 style={styleHome.categoryTextHome}>
                              {item?.attributes?.name

                              }                            </h4>
                          </div>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() =>this.handleCustomerScreen(item.id)
                              
                            }
                          >
                            <img height="20px" src={rightArrowIcon} alt="" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })
              : ""}
          </Container>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styleHome: any = {
  imageHome: {
    paddingBottom: "25px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
  },
  back: {
    mt: 3,
    display: "flex",
    color: "white",
    flexDirection: "row",
    cursor: "pointer",
  },
 
  

  // QR Code
  container: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "63%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  

  // Share Policy
 
  instructionsHome: {
    color: "white",
    marginBottom: 5,
    marginTopn: 5,
    fontWeight: "bold",
    fontSize: 16,
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

    padding: 10,
  },
  categoryTextHome: {
    color: "#5254b3",

    margin: "11px 0px 0px 20px",
    textTransform: "uppercase",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
  childContainerHome: {
    padding: "10px",

    borderRadius: "5px",

    margin: "25px auto",
    backgroundColor: "white",
  },
};
// Customizable Area End
export default CustomerHomeScreenDynamic;
