import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getEnabledCategories } from "trace_events";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  flag: boolean;
  open: boolean;
  company: any;
  date: any;
  selectedDate: any;
  customerFilter: boolean;
  value: number;
  openQrCode: boolean;
  openSharePolicy: boolean;
  tapPointsModal: boolean;
  formData: {
    name: string;
    phoneNumber: string;
  };
  isError: any;
  categories: any;
  selectedCategory: number;
  qrUrl:string;
  products:any;
  isLoading:boolean;
  qrPath:string;
  selectedProduct:any;
  customerCount:number;
  customers:any;
  isCustomerLoading:boolean
  productsCount:number;
  termsCondition:any,
  privacyPolicy:any
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCategoriesId: string = "";
  getProductsApiCallId: string = "";
  getAssureCustomerApiCallId:string = "";
  privacyPolicyApiCallId:any=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      flag: false,
      open: false,
      company: null,
      date: null,
      selectedDate: new Date("2014-08-18T21:11:54"),
      customerFilter: false,
      value: 0,
      openQrCode: false,
      openSharePolicy: false,
      tapPointsModal: false,
      formData: {
        name: "",
        phoneNumber: "",
      },
      isError: {},
      categories: [],
      selectedCategory: 0,
      qrPath:"CustomerTwoWheelerSteps",
      products:[],
      isLoading:true,
      qrUrl:"",
      selectedProduct:{},
      customerCount:0,
      customers:[],
      isCustomerLoading:true,
      productsCount:0,
      termsCondition: {},
      privacyPolicy: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  

  

  
  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }
    let responseJson: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.privacyPolicyApiCallId) {
        if (!responseJson.errors) {
         
          
          responseJson.data.forEach((element: any) => {
       
            console.log(element);
            if (element.attributes.title === "terms_and_condition") {
              this.setState({
                termsCondition: element.attributes
              })
               
              console.log(element);
              console.log(this.state.termsCondition)
            } else if (element.attributes.title === "privacy_policy") {
              this.setState({
                privacyPolicy: element.attributes
              })
            }
          });
        
        } else {
         
        
          this.parseApiErrorResponse(responseJson);
        }
    
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    // Customizable Area Start
    this.getCategories();
    // this.getProducts(2);
    this.getAssureCustomers();
    // Customizable Area End
  }

  getToken = () => {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
     // Customizable Area End
  };

  getDashboardData(){
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
   getCategories=()=>{
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.privacyPolicyApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.privacyPolicyEndPoint
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.dashboarApiMethodType
);

   

runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getProducts=(id:any)=>{
    // Customizable Area Start
 
     const headers = {
       token:localStorage.getItem("authToken")
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getProductsApiCallId = requestMessage.messageId;
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_categories/products?category_id=${id}`
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getProductsMethodType
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
     // Customizable Area End
     return true;
   }
 
   downloadQRCode=()=> {
     // Customizable Area Start
     try {
       const canvas: any = document.querySelector('canvas');
       const pngUrl = canvas
         .toDataURL("image/png")
         .replace("image/png", "image/octet-stream");
       let downloadLink = document.createElement("a");
       downloadLink.href = pngUrl;
       downloadLink.download = `${this.state.selectedProduct?.attributes?.name}.png`;
       document.body.appendChild(downloadLink);
       downloadLink.click();
       document.body.removeChild(downloadLink);
     } catch (e) {
       console.log(e);
     }
     // Customizable Area Start
   }

   handleCategory = (id: number) => {
    // Customizable Area Start
    // if(id==1){
    //   this.setState({
    //     qrPath:"CustomerPolicyTabs"
    //   })
    // }
    // else if(id==2){
    //   this.setState({
    //     qrPath:"CustomerTwoWheelerSteps"
    //   })
    // }
    // else if (id==3){
    //   this.setState({
    //     qrPath:"CustomerFourWheelerSteps"
    //   })
    // }
   
    
    this.setState({
      selectedCategory: id,
      isLoading:true
    });
    this.getProducts(id);
    // Customizable Area Start
  };

  getAssureCustomers=()=>{
      // Customizable Area Start
      this.setState({
        isCustomerLoading:true
      })
      const headers = {
        token:localStorage.getItem("authToken")
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssureCustomerApiCallId = requestMessage.messageId;
    let merchant_id=localStorage.getItem('merchantId')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssureCustomersUrl+"?merchant_id="+merchant_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssureCustomerMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  getCustomizedDate=(dateStr:string)=>{
     // Customizable Area Start
    const date = new Date(dateStr);
      const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
      const monthArr = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      return `${day} ${monthArr[month]} ${year}`
      // Customizable Area End
    }
 
    handleShare= async ()=>{
      console.log("clciked")
      try{
        const canvas :any =document.querySelector("canvas")
      const dataUrl = canvas.toDataURL();
      const blob = await (await fetch(dataUrl)).blob();
      const filesArray = [
        new File([blob], "animation.png", {
          type: blob.type,
          lastModified: new Date().getTime()
        })
      ];
      const shareData = {
        files: filesArray,
        text: "Kindly tap to check our latest policy here- "+this.state.qrUrl + this.state.qrPath
      };
      navigator.share(shareData).then(() => {
        console.log("Shared successfully");
      });
      }
      catch(e){
        console.log(e)
      }
      
    }
  // Customizable Area End



}
