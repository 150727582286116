import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const keyID = "rzp_test_K9EZwa6ry1nFUd";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;
  steps: any;
  innerStep: number;
  selectModelManually: boolean;
  OwnerType: string;
  claimMade: string;
  showCustomerDetailSteps: boolean;
  policyType: string;
  openpolicyTypeDrawer: boolean;
  IDVType: string;
  IDVTypeDrawer: boolean;
  policyDetailsDrawer: boolean;
  OpenVechileDetailsDrawer: boolean;
  ownerDetailsStepIndex: number;
  previousPolicyType: string;
  modelNumber: string;
  registrationNumber: string;
  brand: string;
  model: string;
  policyExpiredRecently: string;
  vechileOnLoan: string;
  NoclaimBonus: string;
  // Customizable Area Start
  customerCategories: any;
  loading: boolean;
  selectedCategory: any;
  customerProducts: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class Custom extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getCategoriesId: any;
  getProductListApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      steps: ["Basic Details ", "Get A Quote", "Get Policy"],
      innerStep: 0,
      selectModelManually: false,
      OwnerType: "Company Owned",
      claimMade: "No",
      showCustomerDetailSteps: false,
      policyType: "Comprehensive",
      openpolicyTypeDrawer: false,
      IDVType: "Recommended",
      IDVTypeDrawer: false,
      policyDetailsDrawer: false,
      OpenVechileDetailsDrawer: false,
      ownerDetailsStepIndex: 0,
      previousPolicyType: "Comprehensive",
      modelNumber: "",
      registrationNumber: "",
      brand: "",
      model: "",
      policyExpiredRecently: "No",
      vechileOnLoan: "No",
      NoclaimBonus: "50",
      customerCategories: [],
      loading: false,
      selectedCategory: 0,
      customerProducts: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }
    this.getCustomerCategories();
    let id = localStorage.getItem('category-id')
    if (id != null) {
      this.setState({
        selectedCategory: id
      })
      this.getProducts(id)
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  handleTwoWheerler = (component: string) => {
    this.props.navigation.navigate(component);
  };
  handleModelManually = () => {
    let value = this.state.selectModelManually;
    this.setState({
      selectModelManually: !value,
    });
  };
  handleInnerStep = (stepType: string) => {
    let _innerStep = this.state.innerStep;
    if (stepType === "next") {
      this.setState({
        innerStep: _innerStep + 1,
      });
    } else {
      this.setState({
        innerStep: _innerStep - 1,
      });
    }
  };
  handleOwnerTypeChange = (e: any) => {
    this.setState({
      OwnerType: e.target.value,
    });
  };
  handleActiveStep = (stepType: string) => {
    let _activeStep = this.state.activeStep;
    if (stepType === "next") {
      if (_activeStep == 2) {
        this.props.navigation.navigate("CustomerPaymentOptions");
      } else {
        this.setState({
          activeStep: _activeStep + 1,
        });
      }
    } else if (stepType == "back") {
      this.setState({
        activeStep: _activeStep - 1,
      });
    }
  };
  handleOwnerDetailsSteps = (stepType: string) => {
    let _step = this.state.ownerDetailsStepIndex;
    if (stepType == "next") {
      if (this.state.ownerDetailsStepIndex == 3) {
        this.handleActiveStep("next");
      } else {
        this.setState({
          ownerDetailsStepIndex: _step + 1,
        });
      }
    } else if (stepType == "back") {
      if (this.state.ownerDetailsStepIndex == 0) {
        this.setState({
          OpenVechileDetailsDrawer: true,
          showCustomerDetailSteps: false,
        });
      } else {
        this.setState({
          ownerDetailsStepIndex: _step - 1,
        });
      }
    }
  };
  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectoHomeScreen = () => {
    this.props.navigation.navigate("CustomerHomeScreen");
  };
  handleModelNumber = (e: any) => {
    this.setState({
      modelNumber: e.target.value,
    });
  };
  handleRegistrationNumber = (e: any) => {
    this.setState({
      registrationNumber: e.target.value,
    });
  };
  handleBrand = (e: any) => {
    this.setState({
      brand: e.target.value,
    });
  };
  handleModel = (e: any) => {
    this.setState({
      model: e.target.value,
    });
  };
  handlePolicyExpiration = (e: any) => {
    this.setState({
      policyExpiredRecently: e.target.value,
    });
  };
  handleNoclaimBonus = (e: any) => {
    this.setState({
      NoclaimBonus: e.target.value,
    });
  };
  handleClaimMade = (e: any) => {
    this.setState({
      claimMade: e.target.value,
    });
  };

  handlePreviousPolicyType = (e: any) => {
    this.setState({
      previousPolicyType: e.target.value,
    });
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesId != null &&
      this.getCategoriesId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          customerCategories: responseJson?.data,
          loading: false
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({

          loading: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductListApiCallId != null &&
      this.getProductListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.error && responseJson.data) {
        this.setState({
          customerProducts: responseJson?.data,
          loading: false
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({

          loading: false
        })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  policyList = [
    {
      id: 1,
      amount: "10000",
      premium: "299",
    },
    {
      id: 2,
      amount: "20000",
      premium: "359",
    },
    {
      id: 3,
      amount: "50000",
      premium: "449",
    },
  ];
  productDetails = localStorage.getItem("selectedProduct");
  
  redirectToComponent = (component: string, data: any) => {
    localStorage.setItem("planDetails", JSON.stringify(data));
    this.props.navigation.navigate(component);
  };
  getCustomerCategories = () => {
    this.setState({
      loading: true
    })
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesUrl + "?type=customer"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCustomerScreen = (id: any) => {
    console.log(id)
    localStorage.setItem('category-id', id);
    this.props.navigation.navigate('CustomerProductList')
  }
  handleCategory = (id: number) => {
    this.setState({
      selectedCategory: id,
      loading: true
    });
    this.getProducts(id);

  };
  getProducts = (id: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/products?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  redirectToPolicyDetails = (productName: any) => {
    if (productName == "Two Wheeler Insurance") {
      this.props.navigation.navigate("CustomerTwoWheelerSteps")
    }
    else if (productName == "Four Wheeler Insurance") {
      this.props.navigation.navigate("CustomerFourWheelerSteps")
    }
    else if (productName == "ICICI Lombard Home Insurance") {
      this.props.navigation.navigate("CustomerHomeInsPolicyTabs")
    }
    else if (productName == "ICICI Lombard Cyber Fraud") {
      this.props.navigation.navigate("CustomerCyberFraudPolicyTabs")
    }
    else if (productName == "One Assist") {
      this.props.navigation.navigate("CustomerOneAssistPolicyTabs")
    }
    else if (productName == "ICICI Lombard Travel Insurance") {
      this.props.navigation.navigate("CustomerTravelInsPolicyTabs")
    } else if (productName == "Care Hospicash") {
      this.props.navigation.navigate("CustomerHospiCashPolicyTabs")
    }
    else if (productName == "Care Vector Borne") {
      this.props.navigation.navigate("CustomerVBCarePolicyDetails")
    }
    else if (productName == "DoctorOnCall") {
      this.props.navigation.navigate("CustomerHealthServicePolicyTabs")
    }
  }

  // Customizable Area End
}
