import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine"; 
// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;  
  model: string;
  value: number; 
  openPolicyDetailDrawer: boolean;
  phoneNumber: string;
  address: string;
  sumInsured: string;
  homeType: string;
  cutomerType: string;
  steps: any;
  passengersAge: string;
  numOfPassengers: string;
  travelPlans: string;
  tripDuration: string;
  basicPremium: number;
  // Customizable Area Start
  orderDetails: any;
  policyDetailsForm: {
    sumToBeInsured: string;
    currDate: string;
    lastDate: string;
    gst: string;
    premium: string;
  },
  policyDetails: any;
  pinCode: string;
  proposerName: string;
  insurerName: string;
  nomineeName: string;
  contactPerson: string;
  email: string;
  gender: string;
  relationship: string;
  city: any;
  isEmailValid: boolean;
  isGenderValid: boolean;
  isRelationshipValid: boolean;
  insurerNameValid: boolean;
  isNomineeNameValid: boolean;
  isContactPersonValid: boolean;
  isPinCodeValid: boolean;
  isCityValid: boolean;
  isSelectedStateValid: boolean;
  proposerNameValid: boolean;
  loading: boolean; 
  cyberDetails: any;
  transaction_id: any;
  pay_description: string;
  Account_ID: any;
  policyBenifits: string;
  aboutDetails: string;
  aboutImage: string;
  travelFaq: any;
  openToast: boolean;
  toastMessage: string;
  toastType: string;
  openErrorPage: boolean;
  openModal: boolean;
  isPhoneNumberValid: boolean;
  dateOfBirth: string;
  isDateOfBirthValid: boolean;
  maxYear: string;
  addressOne: string;
  isAddress1Valid: boolean;
  locality: any;
  cityCode: string;
  stateCode: string;
  stopPaymentApi: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

declare global {
  interface Window {
    loadBillDeskSdk?: any;
  }
}
export default class CustomerHomeInsController2 extends BlockComponent<Props, S, SS> {
  policyCustomerAPICalledID: any;
  getHomeLocationApiId: any;
  getHomePolicyDetails: any;
  createHomeOrderAPICalledID: any;
  verifyHomeOrderAPICalledID: any;
  IssueHomePolicyAPICalledID: any;
  generateHomeCommissionId: any;
  getHomePolicySmsId: any;
  homePolicyPlans = [
    {
      id: 1,
      amount: '100000',
      premium: '169'
    },
    {
      id: 2,
      amount: '200000',
      premium: '349'
    },
    {
      id: 3,
      amount: '300000',
      premium: '499'
    },
    {
      id: 4,
      amount: '400000',
      premium: '669'
    },
    {
      id: 5,
      amount: '500000',
      premium: '839'
    }
  ]
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = { 
      arrayHolder: [],
      token: "",
      activeStep: 0,  
      model: "",
      value: 0,
      proposerName: "", 
      openPolicyDetailDrawer: false,
      insurerName: "",
      nomineeName: "",
      contactPerson: "",
      email: "",
      gender: "",
      relationship: "",
      phoneNumber: "",
      pinCode: "",
      city: [],
      address: "",
      sumInsured: "Building + Household Items",
      homeType: "Flat",
      cutomerType: "Owner",
      steps: ["Personal Details"],
      passengersAge: "",
      numOfPassengers: "",
      travelPlans: "",
      tripDuration: "",
      basicPremium: 422.89,
      // Customizable Area Start
      policyDetailsForm: {
        sumToBeInsured: "",
        currDate: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
        lastDate: ``,
        gst: "",
        premium: "",
      },
      policyDetails: {},
      orderDetails: {},
      isEmailValid: true,
      isGenderValid: true,
      insurerNameValid: true,
      isNomineeNameValid: true,
      isContactPersonValid: true,
      isPinCodeValid: true,
      isSelectedStateValid: true,
      isCityValid: true,
      proposerNameValid: true,
      loading: false, 
      cyberDetails: {},
      transaction_id: 0,
      pay_description: '',
      Account_ID: 0,
      policyBenifits: '',
      aboutDetails: "",
      aboutImage: "",
      travelFaq: [],
      openToast: false,
      toastMessage: "",
      toastType: "success",
      openErrorPage: false,
      openModal: false,
      isPhoneNumberValid: true,
      dateOfBirth: "",
      isDateOfBirthValid: true,
      isRelationshipValid: true,
      maxYear: "",
      addressOne: "",
      isAddress1Valid: true,
      locality: "",
      cityCode: "",
      stateCode: "",
      stopPaymentApi: true
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id
    ) {
      const apiHomeRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiHomeRequestCallId != null) {
        switch (true) {
          
          case apiHomeRequestCallId === this.policyCustomerAPICalledID:
            this.setHomePolicyCustomerApi(responseJson, errorReponse)
            break;
          case apiHomeRequestCallId === this.getHomeLocationApiId:
            this.setHomeLocationApi(responseJson, errorReponse)
            break;
          case apiHomeRequestCallId === this.getHomePolicyDetails:
            this.setHomePolicyDetailsApi(responseJson, errorReponse)
            break;
            case apiHomeRequestCallId === this.createHomeOrderAPICalledID:
              this.setCreateHomeOrderApi(responseJson, errorReponse)
              break;
          case apiHomeRequestCallId === this.verifyHomeOrderAPICalledID:
            this.verifyHomeOrderApi(responseJson, errorReponse)
            break;

          case apiHomeRequestCallId === this.IssueHomePolicyAPICalledID:
            this.issueHomeApi(responseJson, errorReponse)
            break;
          case apiHomeRequestCallId === this.generateHomeCommissionId:
            this.generateHomeCommisionApi(responseJson, errorReponse)
            break;
          default:
        }
      }
      this.setState({ loading: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    }

    // Customizable Area End
  }
  async componentDidMount() {

    super.componentDidMount();
    let todaysDate = this.formatDate(new Date());
  
    const planDetails = localStorage.getItem("planDetails");
    let PlanDetails
    if (planDetails !== null) {
      PlanDetails = JSON.parse(planDetails);
      this.setState({
        policyDetailsForm: {
          ...this.state.policyDetailsForm, ['sumToBeInsured']: PlanDetails.amount,
          ['premium']: PlanDetails.premium
        }
      });
    }
    this.getPolicyPageDetails();
    this.validateDateOfBirth(todaysDate)

    if (window.location.href.includes("CustomerHomeInsPolicyTabs")) {
      let url = new URL(window.location.href)

      let merchantId: any = url.searchParams.get("merchantId");
      let product_id: any = url.searchParams.get("product_id");

      if (merchantId && product_id) {
        localStorage.setItem('merchantId', merchantId)
        localStorage.setItem('product_id', product_id)
      }
    }
  }
  setHomePolicyCustomerApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.errors) {
      let id = responseJson.account_id;
      console.log(id)
      this.getHomePolicySms(id)
      this.setState({
        Account_ID: parseInt(id),
        loading: false
      })
      this.setState({ policyDetails: responseJson });
      this.setState({ loading: false });
      runEngine.debugLog("arrayHolder", this.state.policyDetails);
    } else {
      
      this.setState({ loading: false, openToast: true, toastType: "error", toastMessage: "We're experiencing technical difficulties .Please try after some times." });
      setTimeout(() => this.redirectToHomeScreen(), 4000)
      this.parseApiCatchErrorResponse(errorReponse);

    }
  }
  setHomeLocationApi = (responseJson: any, errorReponse: any) => {

    if (responseJson && !responseJson.errors && !responseJson.error && responseJson.pincode.length !== 0) {
      this.setState({
        locality: responseJson.pincode[0].locality,
        city: responseJson.pincode,
        cityCode: responseJson.pincode[0].citydistrict,
        stateCode: responseJson.pincode[0].state,
      })
      this.setState({ loading: false, openToast: true, toastMessage: "Valid Pincode", toastType: "success" });
 
      if (this.state.toastMessage === "Valid Pincode") {
        setTimeout(() => this.setState({
          openToast: false
        }), 3000)
      }
    } else { 
      this.setState({ loading: false, openToast: true, toastMessage: "Invalid Pincode", toastType: "error" });
      if (this.state.toastMessage === "Invalid Pincode") {
        setTimeout(() => this.setState({
          openToast: false
        }), 3000)
      }
    }
  }
  setHomePolicyDetailsApi = (responseJson: any, errorReponse: any) => {
      if(responseJson?.data){
        this.setState({
          arrayHolder: responseJson.data.attributes
        })
   
        this.setState({
          aboutDetails: responseJson.data.attributes.PolicyDetail.about
        })
        this.setState({
          aboutImage: responseJson.data.attributes.PolicyDetail.image
        })
        this.setState({
          travelFaq: responseJson.data.attributes.Faqs
        })
  
      }
  }
  setCreateHomeOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data) { 
      if (responseJson.transaction_id) {
        this.setState({
          transaction_id: responseJson.transaction_id
        })
        setInterval(() => {
          if (this.state.stopPaymentApi === true) {
            this.verifyHomePayment(responseJson.data[0].orderid, responseJson.data[0].bdorderid, responseJson.transaction_id)
          }
        }, 3000)

      }

      const flow_configHome = {
        merchantId: responseJson.data[0].mercid,
        bdOrderId: responseJson.data[0].bdorderid,
        authToken: responseJson.data[0].links[1].headers.authorization,
        childWindow: true,
      
        retryCount: 3,
        prefs: { "payment_categories": ["card", "emi", "nb", "upi", "wallets", "qr", "gpay"] }
      }

      const responseHomeHandler = function (txn: any) {
        console.log("callback received status:: ", txn.status)
        console.log("callback received response:: ", txn.response)
      }
      const config = {
        responseHomeHandler: responseHomeHandler,
        merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
        flowConfig: flow_configHome,
        flowType: "payments"
      } 
      setTimeout(() => {
        window.loadBillDeskSdk(config)
      })

      this.setState({ loading: false, openPolicyDetailDrawer: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  verifyHomePayment = (orderid: any, bdOrderId: any, transaction_id: any) => {
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody_h = {
      "account_id": this.state.Account_ID,
      "transaction_id": transaction_id,
      "order_id": orderid,
      "bdorder_id": bdOrderId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyHomeOrderAPICalledID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verify_signature_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody_h)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyHomeOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) { 
      if (responseJson.payment_status === "success") {
        this.setState({
          stopPaymentApi: false
        })
        this.issueHomePolicy()
      }

    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  generateHomeCommisionApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.error && responseJson?.data) {
      console.log("RESSS", responseJson)
    }
    else {
      this.setState({ loading: false })
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  issueHomeApi = (responseJson: any, errorReponse: any) => {
    this.setState({ loading: true });

    if (responseJson && !responseJson.errors && !responseJson.error) { 
      let insurer_Name = this.state.insurerName
      let policy_Number = responseJson.cdbgResponse.cdbgResponseList[0].PolicyNo
      let policy_Type = "Home";
      let policy_Amount = "" + this.state.policyDetailsForm.premium;
      let start_Date = new Date();
      start_Date.setDate(start_Date.getDate());
      start_Date.setFullYear(start_Date.getFullYear()) 
      let oneYearFrom_Now = new Date();
      oneYearFrom_Now.setDate(oneYearFrom_Now.getDate() - 1);
      oneYearFrom_Now.setFullYear(oneYearFrom_Now.getFullYear() + 1); 
      let transaction_Date = start_Date.toString();
      let policyEnd_Date = oneYearFrom_Now.toString();
      localStorage.setItem('insurerName', insurer_Name)
      localStorage.setItem('policyType', policy_Type)
      localStorage.setItem('policyAmount', policy_Amount)
      localStorage.setItem('transactionDate', transaction_Date)
      localStorage.setItem('membershipNumber', policy_Number)
      localStorage.setItem('policyEndDate', policyEnd_Date)
      this.generateHomeCommission()
      this.setState({ loading: false, openToast: true, toastMessage: "Your Policy Issued Successfully.", toastType: "success" });
      setTimeout(() => this.RedirectoSuccessScreen(), 4001)
    }
    else {  
      this.setState({openModal: true , loading: false, openToast: true, toastMessage: responseJson.errors, toastType: "error" });
      setTimeout(() => this.redirectToHomeScreen(), 4001)

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

 

  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectToComponent = (component: string) => {
   
    this.props.navigation.navigate(component);
  };
  redirectToHomeScreen = () => {
    this.props.navigation.navigate('CustomerHomeScreen');
  }
  RedirectoSuccessScreen = () => {
    this.setState({ loading: false });
    this.props.navigation.navigate('CustomerOrderSuccess');
  }


  handleNext = () => {
    console.log("ccbj");
    let val = this.state.value + 1;
    if (val) {
      this.props.navigation.navigate("CustomerHomeInsPolicyPlans");
    } else {
      this.setState({ value: val });
    }
    this.props.navigation.navigate("CustomerHomeInsPolicyPlans");
    console.log(val)
  }

  handleProceed = () => {
    const { sumToBeInsured } = this.state.policyDetailsForm;
    
    if (sumToBeInsured && this.validateFirstStep() === true) {
 
      this.setState({ openPolicyDetailDrawer: true });
      this.setState({ loading: true });
      
      this.setState({ loading: true });
      const headers = {
        "Content-Type": configJSON.assureUrlContentType,
      };
      let merchant_id = localStorage.getItem('merchantId')
      let splitName = []
      splitName = this.state.insurerName.split(' ')
      console.log(this.state.insurerName)
      const httpBody = {
        data: {
          "first_name": splitName[0],
          "last_name": splitName[1] ?? "",
          "email": this.state.email,
          "mobile": this.state.phoneNumber,
          "date_of_birth": this.state.dateOfBirth,
          "pincode": this.state.pinCode,
          "address": this.state.addressOne,
          "relationship": this.state.relationship,
          "sum_to_be_insured": this.state.policyDetailsForm.sumToBeInsured,
          "gender": this.state.gender,
          "nominee_name": this.state.nomineeName,
          "state_code": this.state.stateCode,
          "locality": this.state.locality,
          "city_code": this.state.cityCode,
          "contact_person": this.state.contactPerson,
          merchant_id
        }
      };
      console.log(httpBody)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.policyCustomerAPICalledID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCustomerHomeApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.assure_methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

  }

  handleChangePolicy = (e: any) => {
    this.setState({
      policyDetailsForm: { ...this.state.policyDetailsForm, [e.target.name]: e.target.value }
    });
    if (e.target.name === 'policyStartDate') {
      let oneYearFromNow = new Date(e.target.value);
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      
    }
    if (e.target.name === 'sumToBeInsured') {
      this.setState({
        policyDetailsForm: {
          ...this.state.policyDetailsForm, ['premium']: e.target.value === '100000' ? "169"
            : e.target.value === '200000' ? "349" : e.target.value === '300000' ? "499"
              : e.target.value === '400000' ? "669" : "839",
          ['sumToBeInsured']: e.target.value
        }
      });
    }
  }
  handlePolicyTabsChange = ( value: any) => {
    this.setState({
      value: value
    })
  }

  handleInsurerName = (e: any) => {
    this.setState({
      insurerName: e.target.value,
      insurerNameValid: true,
    })
  }
  handleNomineeName = (e: any) => {
    this.setState({
      nomineeName: e.target.value,
      isNomineeNameValid: true,
    })
  }

  handleContactPerson = (e: any) => {
    this.setState({
      contactPerson: e.target.value,
      isContactPersonValid: true,
    })
  }
 
  validateDateOfBirth = (tday: any) => {
    let todaysDate = tday;
    let tYear = tday.split("/")[2];
    let maxYear = parseInt(tYear) - 18;
    this.setState({ maxYear: maxYear + "-06-10" })
  }
  padTo2Digits = (num: any) => {
    return num.toString().padStart(2, "0");
  };
  formatDate = (date: any) => {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };
  handleEmail = (e: any) => {

    this.setState({
      email: e.target.value,
      isEmailValid: true,
    })
  }
  handleGender = (e: any) => {
    this.setState({
      gender: e.target.value,
      isGenderValid: true,
    })
  }
  handlePhoneNumber = (e: any) => {
    this.setState({
      phoneNumber: e.target.value,
      isPhoneNumberValid: true
    });
  };

  handleDOB = (e: any) => {
    this.setState({
      dateOfBirth: e.target.value,
      isDateOfBirthValid: true
    });
  };
  handleRelationship = (e: any) => {
    this.setState({
      relationship: e.target.value,
      isRelationshipValid: true
    });
  };
  handleAddressOne = (e: any) => {
    this.setState({
      addressOne: e.target.value,
      isAddress1Valid: true
    });
  };
  handlePinCode = (e: any) => {
    this.setState({
      pinCode: e.target.value,
      isPinCodeValid: true,
    })
    if (e.target.value.length == 6) {
      this.getAddressDetails(e.target.value)
    }


  }



  handleCity = (e: any) => {
    this.setState({
      isCityValid: true
    })
    let idx = e.target.selectedIndex;
    let dataset = e.target.options[idx];
    this.setState({
      locality: dataset.value,
    })
  }

  handleDrawerClose = () => {
    this.setState({
      openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer,
    })
    this.setState({ openPolicyDetailDrawer: false });
  }
  RedirectoHomeScreen = () => {
    this.props.navigation.navigate("CustomerHomeScreen");
  };
  closeModel = () => {
    this.setState({ openModal: !this.state.openModal });
  }
  validateFirstStep = () => {
    const NameRegex = /^[a-zA-Z ]{4,30}$/;
    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isFirstStepValid = true;
    if (this.state.insurerName == "" || !NameRegex.test(this.state.insurerName)) {
      this.setState({
        insurerNameValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.nomineeName == "" || !NameRegex.test(this.state.nomineeName)) {
      this.setState({
        isNomineeNameValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.contactPerson == "" || !NameRegex.test(this.state.contactPerson)) {
      this.setState({
        isContactPersonValid: false,
      });
      isFirstStepValid = false;
    }
    if (
      this.state.phoneNumber == "" ||
      !mobileRegex.test(this.state.phoneNumber)
    ) {
      this.setState({
        isPhoneNumberValid: false,
      });
      isFirstStepValid = false;
    }
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        isEmailValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.gender == "") {
      this.setState({
        isGenderValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.relationship == "") {
      this.setState({
        isRelationshipValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.dateOfBirth == "") {
      this.setState({
        isDateOfBirthValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.addressOne.length > 500 || this.state.addressOne == "") {
      this.setState({
        isAddress1Valid: false
      })
      isFirstStepValid = false;
    }
    const regexPinCode = /^\d{3}\s?\d{3}$/;
    if (this.state.pinCode === "" || !regexPinCode.test(this.state.pinCode)) {
      this.setState({
        isPinCodeValid: false,
      })
      isFirstStepValid = false
    }
    return isFirstStepValid;
  }


  // Customizable Area Start
  getAddressDetails = (pin: any) => {
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeLocationApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homePinCodeMaster + "?pincode=" + pin
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  getPolicyPageDetails = () => {
    const headers_h = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomePolicyDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homePolicyDetails + `?name=ICICI Lombard Home Insurance`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers_h)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
   }
 
   createPayOrder = async () => {
    this.handleDrawerClose();
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
      amount: this.state.policyDetailsForm.premium,
      account_id: parseInt(this.state.policyDetails.id),
    }; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createHomeOrderAPICalledID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_order_url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End


  issueHomePolicy = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let merchant_id = localStorage.getItem('merchantId')
    let BaseUrl = localStorage.getItem('base_url')
    let startDate = new Date();
    startDate.setDate(startDate.getDate());
    startDate.setFullYear(startDate.getFullYear())
    console.log(startDate)
    let oneYearFromNow = new Date();
    oneYearFromNow.setDate(oneYearFromNow.getDate() - 1);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    console.log(oneYearFromNow, 'oneYearFromNow');
    const httpBody = {

      account_id: parseInt(this.state.policyDetails.account_id),
      PolicyStartDate: startDate,
      PolicyEndDate: oneYearFromNow,
      base_url: BaseUrl,
      merchant_id

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.IssueHomePolicyAPICalledID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.homeIssuePolicy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getHomePolicySms = (id: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const httpBody = {

      "data": {
        "account_id": parseInt(id),
        "policy_name": "Home",
        "platform": "Tappmart"
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHomePolicySmsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPolicySmsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  generateHomeCommission = () => {
    let merchant_id = localStorage.getItem('merchantId')
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    }; 
    let startDate = new Date();
    startDate.setDate(startDate.getDate());
    startDate.setFullYear(startDate.getFullYear())
    console.log(startDate)
    let policyAmount: any = localStorage.getItem('policyAmount');
    let product_id: any = localStorage.getItem('product_id');
    const httpBody = {
      "data": {
        "account_id": this.state.Account_ID,
        "insurance_type": "Home Insurance",
        "merchant_id": merchant_id,
        "date_of_purchase": startDate,
        "premium_paid": parseInt(policyAmount),
        "product_id": parseInt(product_id),


      }


    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateHomeCommissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateCommissionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

}
