import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
   
  Button,
  Container,
  Typography,
  
  Chip,
  CircularProgress,
  Backdrop,
  Grid
} from "@material-ui/core";
import CustomerController from "./CustomerController";
 
import {
    
    tappmart_logo,
    backgroundImage,
    backIcon
  } from "./assets";
// Customizable Area End

// Customizable Area Start
class CustomerProductList extends CustomerController {
  
  render() {
    
    return (
      <>
       <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item>
            <img
              onClick={this.RedirectoHomeScreen}
              height="40px"
             
              src={tappmart_logo}
              style={CustomerProductList_StyleCss}
            />
          </Grid>
        </Grid>{" "}
        <div style={style_CustomerProductList.image}>
        <Grid>
            <Grid>
              <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ marginTop: "15px" }}
                  onClick={this.handleBack}
                  data-test-id="button"
                />
              </Button>
            </Grid>
          </Grid>
          <Container maxWidth="sm">
            <Box sx={style_CustomerProductList.tabContainer}>
              <Box sx={style_CustomerProductList.tabs}>
                {this.state.customerCategories.length ? (
                  this.state.customerCategories.map((category: any, id: number) => (
                    <Box sx={style_CustomerProductList.tabItem}>
                      <Chip
                        label={
                          category?.attributes?.name +
                          " (" +
                          category?.attributes?.product_count +
                          ")"
                        }
                        style={
                          this.state.selectedCategory == category.id
                            ? {
                              background: "#1876d2",
                              color: "white",
                              fontWeight: "bold",
                            }
                            : { background: "#9157af", color: "white" }
                        }
                        onClick={() => this.handleCategory(category.id)}
                        data-test-id="button"
                      />
                    </Box>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {this.state.customerProducts.length > 0 ? this.state.customerProducts.map((item: any) => {
              return (
                <Paper elevation={2}
                  style={{ cursor: 'hand' }}
                  onClick={() => {
                  
                  }
                  }
                >
                  <Box sx={style_CustomerProductList.companies}>
                    <Box sx={style_CustomerProductList.familyHealth}>
                      <img
                        src={item?.attributes?.image}
                        alt=""
                        height="50"
                        width="50"
                      />
                      <Box sx={style_CustomerProductList.company}>
                        <Typography gutterBottom variant="body1">
                          <b>{item?.attributes?.name}</b>
                        </Typography>
                        <Typography variant="body2">
                          {item?.attributes?.discription}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={style_CustomerProductList.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ width: "100%" }}
                      onClick={()=>this.redirectToPolicyDetails(item?.attributes?.name)}
                      data-test-id="button"

                    >
                      PROCEED
                    </Button>
                     
                    </Box>
                  </Box>
                </Paper>
              );
            }) : ""}

          </Container>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style_CustomerProductList = {
  image: {
    paddingBottom: "25px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
  },
  back: {
    mt: 3,
    display: "flex",
    color: "white",
    flexDirection: "row",
    cursor: "pointer",
  },
  familyHealth: {
    display: "flex",
    flexDirection: "row",
  },
  tabContainer: {
    paddingBottom: "10px",
    display: "block",
    overflowX: "scroll",
    width: "100%",
    marginTop:5,
    marginBottom:5
  },
  container: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "63%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  tabs: {
    mt: 1,
    display: "block",
    whiteSpace: "nowrap",
  },
  tabItem: {
    display: "inline-block",
    marginRight: "10px",
  },
  customers: {
    mt: 2,
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  company: {
    display: "flex",
    flexDirection: "column",
    ml: 1.5,
  },
  companies: {
    p: 2,
    mt: 2,
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    mt: 2.5,
    mr: 3,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  link: {
    p: 1.2,
    mt: 4,
    border: "0.1px solid #d3d3d3",
    backgroundColor: "rgb(70, 130, 180, 0)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  // QR Code
  
  filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  insurance: {
    mt: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  download: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "rgb(70, 130, 180, 0.1)",
    color: "#4682b4",
  },

  // Share Policy
  containerSharePolicy: {
    padding: "16px",
    mt: "22px",
    position: "absolute" as "absolute",
    top: "102%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "95%",
    height: "80%",
    paddingBottom: "35px",
    borderRadius: "15px",
  },
  socialMedia: {
    mt: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  
};

const CustomerProductList_StyleCss = { marginTop: "6px",
marginBottom: "6px"}
// Customizable Area End
export default CustomerProductList;
