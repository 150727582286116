import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {
  arrayHolder: any;
   
  OwnerType: string; 
  policyType: string;
  
  modelNumber: string;
  registrationNumber: string;
  brand: string;
  model: string;
  
  // Customizable Area Start
  openSharePolicy: boolean;
  qrUrl: string;
  policyPrice:string;
  orderId:string|null;
  policyAmount:string|null;
  transactionDate:string|null;
  membershipNumber:string|null;
  policyEndDate:string|null;
  homeInsurerName:string|null;
  PolicyNoHome:string|null;
  homePolicyStartDate:string|null;
  homePolicyEndDate:string|null;
  homePolicyAmount:string|null;
  merchantId: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderSuccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDataPdfApi:any;
  sendMessagesApi: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
       
      OwnerType: "Company Owned",
       
      policyType: "",
    
      modelNumber: "",
      registrationNumber: "",
      brand: "",
      model: "",
     
      openSharePolicy: false,
      qrUrl:
        "https://netambitmvp-135107-react-native.b135107.dev.eastus.az.svc.builder.cafe",
      policyPrice: "cscsc",
      orderId: "",
      policyAmount: "",
      transactionDate: "",
      membershipNumber: "",
      policyEndDate:"",
      homeInsurerName:"",
      PolicyNoHome:"",
      homePolicyStartDate: "",
  homePolicyEndDate: "",
  homePolicyAmount: "",
  merchantId:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
 
 
  handleAfterSuccessContinue = () => {
    
    if( this.state.merchantId  === null ||  this.state.merchantId  === "null"){
      this.props.navigation.navigate("CustomerHomeScreen");
      } else{
     this.redirectToComponent('MerchantPolicyDetails','')
     }
  }
  
 
  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectoHomeScreen = () => {
    console.log("chjcds");
    this.props.navigation.navigate("CustomerHomeScreen");
  };
 
  
 
  getPdf = (policyNo:any,issuePolicyType:any) => {
    
    const headers = {
        "Content-Type": configJSON.productApiContentType,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataPdfApi = requestMessage.messageId;
    console.log(this.getDataPdfApi)
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPdfDataUrl + `generate_your_pdf?policyNo=${policyNo}&type=${issuePolicyType}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDataPdfApi != null &&
      this.getDataPdfApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.pdf_url !== "") {
          console.log(responseJson.pdf_url)
          setTimeout(()=>{
            let new_tab :any =window.open()
            new_tab.location.href=responseJson.pdf_url
            //window.open(responseJson.pdf_url, '_blank', 'noopener,noreferrer');
          },)
      }
      else {
  
          let errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorReponse);
      }
  }
 
    // Customizable Area End
  }

  // Customizable Area Start
 
  redirectToComponent = (component: string, data: any) => {
    localStorage.setItem("planDetails", JSON.stringify(data));
    this.props.navigation.navigate(component);
  };
  handleShare = async () => {
    console.log("clciked");
    try {
      const shareData = {
      
        text:
          "Hello there! I have purchased an insurance from Tappsure! You can also check affordable policies here -" +
          this.state.qrUrl +
          "CustomerHomeScreen",
      };
      navigator.share(shareData).then(() => {
        console.log("Shared successfully");
      });
    } catch (e) {
      console.log(e);
    }
  };
  async componentDidMount() {
    super.componentDidMount();
    const base_url = localStorage.getItem("base_url");
    if (base_url !== null) {
      this.setState({ qrUrl: base_url });
    } 
    let model = localStorage.getItem("policyNo");
    let policyTypeName = localStorage.getItem("policyType");
    let policyAmount = localStorage.getItem("policyAmount");
    let transactionDate = localStorage.getItem("transactionDate");
    let policyEndDate = localStorage.getItem("policyEndDate");
    let ownername=localStorage.getItem("ownername");
    let brand = localStorage.getItem("insurerName")
    let homeInsurerName = localStorage.getItem("insurerName")
    let policyIdHome = localStorage.getItem("policyNo"); 
    let membershipNumber = localStorage.getItem('membershipNumber')
    

     
    this.getPdf(membershipNumber  ,policyTypeName)
    
    this.setState({
    merchantId:localStorage.getItem('merchantId')
  })
  console.log(this.state.merchantId)
    
   
    if (
      policyTypeName !== null &&
      policyAmount !== null &&
      transactionDate !== null &&
      ownername != null && 
      membershipNumber !== null  
   ){
   
      this.setState({
    
        policyType:policyTypeName,
        policyAmount,
        transactionDate,
        policyEndDate,
        OwnerType:ownername,
        membershipNumber

      });
    }

    if(policyTypeName !== null &&
      policyAmount !== null &&
      transactionDate !== null &&
      homeInsurerName!= null )
      {
        
       this.setState({
        policyType:policyTypeName,
        homeInsurerName:homeInsurerName,
        PolicyNoHome:policyIdHome,
        homePolicyStartDate:transactionDate,
        homePolicyEndDate:policyEndDate,
        homePolicyAmount:policyAmount

       })
       
       
    }
     
      if (model !== null && brand !== null) {
        this.setState({
          brand:brand,
          model:model,
          transactionDate,
          policyEndDate,
          policyAmount,
        });
      }
  }

  // Customizable Area End
 
  // Customizable Area End
}
