import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Paper,
    Modal,
    Container,
    Typography,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,

} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background,
    close,
    back,
    banner,
    image_filter,
    tappoints_table,
    tappoint_banner_icon,
} from "./assets";
import DashboardControllerThree from "./DashboardControllerThree";
// Customizable Area End

// Customizable Area Start
class TapPoints extends DashboardControllerThree {
    handleOpen = () => {
        this.setState({ customerFilter: true });
        console.log("click")
    }

    toggleCompany = (state: string) => {
        console.log(state);
        this.setState({ company: state });
    }

    toggleDate = (state: string) => {
        console.log(state);
        this.setState({ date: state });
    }

    handleDateChange = (date: Date | null) => {
        this.setState({ selectedDate: date });
    };

    handleClose = () => {
        this.setState({ customerFilter: false, tapCustomerModal: false, isCustomerLoading: false, tappointsTableModal: false });

    }
    tapPointsFilter = () => {
        this.setState({ tapPointsModal: true });
    }




    render() {
        const { navigation } = this.props;
        let uniqueTappointsTable = [...new Set(this.state.tapPointsData)];
        console.log(uniqueTappointsTable)

        return (
            <>
                {/*@ts-ignore */}:
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>
                    <Backdrop
                        style={{ zIndex: 1000 + 1, color: "#fff" }}
                        open={this.state.isCustomerLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.back}
                            onClick={() => navigation.navigate("Dashboard")}
                        >
                            <img
                                src={back}
                                alt=""
                                height="15"
                                width="10"
                            />
                            <Typography
                                style={{ marginTop: -2, marginLeft: 8 }}
                                variant="body2"
                            >
                                Back to Dashboard
                            </Typography>
                        </Box>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.table}
                        >
                            <Typography
                                variant="h6"
                            >
                                Tappoints
                            </Typography>
                            {/*@ts-ignore */}
                            <Box sx={style.tappointsTable}>
                                <img
                                    src={tappoints_table}
                                    alt=""
                                    height="18"
                                    width="18"
                                />&nbsp;
                                <Typography
                                    data-test-id="tapPoints-table"
                                    variant="body1"
                                    onClick={() => this.handleOpenTabTable()}
                                    style={{ display: "inline-block", whiteSpace: "nowrap" }}
                                >

                                    Tappoints Table

                                </Typography>
                                {this.state.isCustomerLoading ? <Backdrop
                                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                                    open={this.state.isLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop> : <Container  >
                                    {/*@ts-ignore */}
                                    <Modal
                                        open={this.state.tappointsTableModal}
                                        onClose={this.handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        style={{ top: '25%', }}
                                    >
                                        {/*@ts-ignore */}
                                        <Box sx={style.containerFilter}  >
                                            <img
                                                style={{ cursor: 'pointer', position: "absolute", right: '0', marginRight: '30px' }}
                                                src={close}
                                                alt=""
                                                height="20"
                                                width="20"
                                                onClick={this.handleClose}
                                            />
                                            <Typography id="modal-modal-title" variant="h6" component="h1" style={{ paddingBottom: '20px', fontWeight: 'bold' }}>
                                                Tappoints Table
                                            </Typography>
                                            {this.state.tapPointsData.map((item: any, index: any) => {
                                                return <Paper key={index} elevation={2}>
                                                    {/*@ts-ignore */}
                                                    <Box style={{ backgroundColor: "rgba(70, 130, 180, 0.1)" }}
                                                        sx={style.customerDetails}
                                                    >
                                                        {/*@ts-ignore */}
                                                        <Box
                                                            sx={style.familyHealth}
                                                        >
                                                            {/*@ts-ignore */}
                                                            <Box sx={style.rowPoints} >
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    <b>{item.product_name}</b>
                                                                </Typography>
                                                                <Typography
                                                                    style={style.points}
                                                                    variant="body1"
                                                                >
                                                                    <b style={{ cursor: 'pointer' }} onClick={() => this.handleOpenCustomerTappoint(item.product_name)}>+</b>
                                                                    <b>{item.total_tappoints.toFixed(2)}</b>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            })}
                                        </Box>
                                    </Modal>
                                </Container>
                                }
                            </Box>
                        </Box>
                        {/*@ts-ignore */}
                        <Box
                            style={style.banner}
                        >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.tapPointsBanner}
                            >
                                <img
                                    src={tappoint_banner_icon}
                                    alt=""
                                    height="40"
                                    width="40"
                                />
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.tappoints}
                                >
                                    <Typography
                                        variant="body1"
                                    >
                                        Tappoints
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        <b>{this.state.tapPoints.toFixed(2)}</b>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/*@ts-ignore */}
                        <Box
                            sx={style.orders}
                        >
                            <Typography
                                variant="body1"
                            >
                                Total Orders {this.state.totalOrders}
                            </Typography>
                            {/*@ts-ignore */}
                            <Box
                                data-test-id="filter-handleOpen"
                                sx={style.filter}
                                onClick={this.handleOpen}
                            >
                                <img
                                    src={image_filter}
                                    alt=""
                                    height="20"
                                    width="20"
                                />&nbsp;
                                <Typography
                                    variant="body1"
                                >
                                    Filter
                                </Typography>
                            </Box>
                        </Box>
                        {this.state.isCustomerLoading ? <Backdrop
                            style={{ zIndex: 1000 + 1, color: "#fff" }}
                            open={this.state.isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop> :
                            <>


                                {this.state.tapPointsData.map((item: any, index: any) => {
                                    return (
                                        <Paper key={index} elevation={2}>
                                            {/*@ts-ignore */}
                                            <Box
                                                sx={style.customerDetails}
                                            >
                                                {/*@ts-ignore */}
                                                <Box
                                                    sx={style.familyHealth}
                                                >
                                                    {/*@ts-ignore */}
                                                    <Box
                                                        sx={style.rowPoints}
                                                    >

                                                        <Typography
                                                            variant="body1"
                                                        >
                                                            <b>{item.product_name}</b>
                                                        </Typography>

                                                        <Typography
                                                            style={style.points}
                                                            variant="body1"
                                                        >
                                                            {/* @ts-ignore */}
                                                            <b style={{ cursor: 'pointer' }} onClick={() => this.handleOpenCustomerTappoint(item.product_name)}>+</b>
                                                            <b>{item.total_tappoints.toFixed(2)}</b>
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        gutterBottom
                                                        variant="subtitle1"
                                                    >
                                                        {item.discription}
                                                    </Typography>

                                                </Box>
                                            </Box>
                                        </Paper>
                                    );
                                })}
                            </>
                        }
                        {this.state.tapPointsData.length == 0 ? <h3 style={{ color: "white", font: "inherit" }}>No Tappoints have registered with your Merchant id.</h3> : ""}
                    </Container>
                    <Container maxWidth='sm'>
                        {/*@ts-ignore */}
                        <Modal
                            open={this.state.customerFilter}
                            onClose={this.handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.containerFilter}
                            >
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.filterFilter}
                                >
                                    <Typography
                                        variant="h5"
                                    >
                                        <b>Filter</b>
                                    </Typography>
                                    <img
                                        style={{ cursor: 'pointer', }}
                                        src={close}
                                        alt=""
                                        height="20"
                                        width="20"
                                        onClick={this.handleClose}
                                    />
                                </Box>
                                {/*@ts-ignore */}
                                <Box
                                    sx={style.insurance}
                                >
                                    <Typography
                                        variant="h6"
                                    >
                                        Insurance Company
                                    </Typography>
                                    {/*@ts-ignore */}
                                    <Box
                                        sx={style.insuranceCompany}
                                    >
                                        <CustomFormControl variant="outlined">
                                            <CustomSelect
                                                native
                                                value={this.state.company}
                                                data-test-id="handleCompanySelectTest"
                                                onChange={this.handleCompanySelect}
                                                inputProps={{
                                                    name: "companyInsurance",

                                                    id: "outlined-age-native-simple",
                                                }}
                                            >
                                                <option aria-label="None" value="">
                                                    Insurance Company
                                                </option>
                                                {this.state.filterProducts.map((item: any) => {
                                                    return (<option key={item?.id} value={item != null ? item.name : ""}>
                                                        {item.name}
                                                    </option>)
                                                })}


                                            </CustomSelect>

                                        </CustomFormControl>

                                    </Box>
                                    {/*@ts-ignore */}
                                    <Box sx={style.buttonStyle}>
                                        <Button
                                            data-test-id="reset-filter"
                                            style={style.buttonReset}
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="outlined"
                                            onClick={this.handleResetFilter}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            data-test-id="apply-filter-tapPoints"
                                            //disabled={this.state.date == "range" && this.state.start_date == "" && this.state.end_date == ""}
                                            style={style.buttonApply}
                                            size="large"
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            onClick={this.handleFilterApply}
                                        >
                                            Apply
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </Container>
                    <Container  >
                        {/*@ts-ignore */}
                        <Modal
                            data-test-id="model-model-close"
                            open={this.state.tapCustomerModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{ top: '25%', }}
                        >
                            {/*@ts-ignore */}
                            <Box sx={style.containerFilter}  >
                                <img
                                    style={{ cursor: 'pointer', position: "absolute", right: '0', marginRight: '30px' }}
                                    src={close}
                                    alt=""
                                    height="20"
                                    width="20"
                                    onClick={this.handleClose}
                                />
                                <Typography id="modal-modal-title" variant="h6" component="h1" style={{ paddingBottom: '20px', fontWeight: 'bold' }}>
                                    Tappoints Details
                                </Typography>

                                <TableContainer component={Paper} style={{ background: 'white', height: 400 }}   >
                                    <Table aria-label="simple table" stickyHeader style={{ height: "max-content" }}>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell style={{ fontWeight: 'bold' }} >Customer Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Tappoints Earned</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Premium Paid</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Date Of Purchase</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ minWidth: '170' }}>
                                            {this.state.tapCustomerData.map((item: any, i: any) => (
                                                <TableRow key={item.id} style={{ background: 'rgba(70, 130, 180, 0.1)' }}>
                                                    {/* <TableCell component="th" scope="row">
                                            {item.name}
                                          </TableCell> */}
                                                    <TableCell  >{item.customer_name}</TableCell>
                                                    <TableCell >{item.commission_earned.toFixed(2)}</TableCell>
                                                    <TableCell  >{item.premium_paid}</TableCell>
                                                    <TableCell >{item.date_of_purchase}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Box>
                        </Modal>
                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    back: {
        mt: 3,
        color: "white",
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
    },
    image: {
        paddingBottom: "25px",
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    banner: {
        marginTop: 5,
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    },
    tappoints: {
        p: 2.5,
        display: 'flex',
        color: 'white',
        flexDirection: 'column',
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
    },
    tapPointsBanner: {
        ml: 2.5,
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: "center",
    },

    tappointsTable: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    familyHealth: {
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        mt: 2,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    customerDetails: {
        p: 2,
        mt: 2,
    },
    rowPoints: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    points: {
        color: "rgb(0, 200, 127, 1)",
        pointer: "cursor"
    },
    orders: {
        mt: 1,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    // Tappoints table
    filterTapPoints: {
        mb: "15px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    coin: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        position: 'absolute' as 'absolute',
        top: '92%',
        left: '50%',
        height: '100%',
        width: '90%',
        padding: '16px 41px 70px',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: '35px'
    },
    insurance: {
        mt: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: "15px",
        backgroundColor: "rgb(70, 130, 180, 0.1)",
        borderRadius: 5,
    },
    customers: {
        mt: 2,
        color: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    typography: {
        marginLeft: "7px",

    },
    company: {
        display: "flex",
        flexDirection: "column",
        ml: 1.5,
    },
    customerContainer: {
        mt: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    icon: {
        display: "flex",
        flexDirection: "row",
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    phoneRenewal: {
        marginRight: 12,
    },

    modalContainer: {
        backgroundImage: `url(${background})`,
    },
    // Customer Filter
    filterFilter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    containerFilter: {
        position: 'absolute' as 'absolute',
        top: '66%',
        left: '50%',
        width: '90%',
        height: '100%',
        padding: '25px',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: '16px',
    },
    typographyFilter: {
        marginLeft: 10
    },
    datePicker: {
        mt: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    insuranceCompany: {
        p: 0.5,
        mt: 1,
        display: 'flex',
        flexDirection: 'row'
    },

    buttonReset: {
        marginRight: "8px",
        padding: "15px",
        borderRadius: 8,
    },
    buttonApply: {
        marginLeft: "8px",
        padding: "15px",
        borderRadius: 8,
    },
    buttonStyle: {
        mt: 5,
        display: 'flex',
        flexDirection: 'row',
    },
};
const CustomFormControl: any = withStyles({
    root: {
        width: "100%",
        marginTop: 5,
    },
})(FormControl);

const CustomSelect: any = withStyles({
    root: {
        padding: "15px",
    },
})(Select);

// Customizable Area End
export default TapPoints;
