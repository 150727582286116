import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;

  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  otpInput: string;
  token: string;
  isErrorOtp: any;
  mobile: string;
  seconds: number;
  otp: boolean;
  isError: any;
  isLoading: boolean;
  isLoadingOtp: boolean;
  openResendOtp: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  labelTitle: string;
  apiLoginCallId: any;
  otpVerifyApiCallId: any;
  private readonly errorTitle = "Error";
  myInterval: any;
  // Customizable Area End    

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryCodeSelected: "",
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,
      otpInput: "",
      token: "",
      isErrorOtp: {},
      mobile: '',
      seconds: 50,
      otp: false,
      isError: {},
      isLoading: false,
      isLoadingOtp: false,
      openResendOtp: false,

      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiLoginCallId) {
          if (!responseJson.errors) {
            this.setState({otp: true });
            this.setState({ isLoading: false });
            this.saveLoggedInUserData(responseJson);
            if(this.state.seconds !== 0) {
              this.myInterval = setInterval(() => {
                this.setState({ seconds: this.state.seconds - 1 });
              }, 1000);
            }
            console.log("apiLoginCallId", responseJson, errorReponse);
          } else {
            //Check Error Response
            this.setState({ isLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.otpVerifyApiCallId) {
          if (!responseJson.errors) {
            this.setState({ isLoadingOtp: false });
            clearInterval(this.myInterval);
            this.saveLoggedInUserDataAccessToken(responseJson);
            this.props.navigation.navigate("Dashboard");
            console.log("otpVerifyApiCallId", responseJson, errorReponse);
            let loginToken = responseJson.access_token
            localStorage.setItem('loginToken',loginToken)
            let accId = responseJson.account_id
            localStorage.setItem('accountID',accId)
          } else {
            //Check Error Response
            this.setState({ isLoadingOtp: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

   // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.mobileNo);

    msg.addData(
      getName(MessageEnum.LoginCountryCode),
      this.state.countryCodeSelected
    );

    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  saveLoggedInUserDataAccessToken(responseJson: any) {
    if (responseJson && responseJson.access_token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.access_token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn(): boolean {
    if (
      this.state.countryCodeSelected === null ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorCountryCodeNotSelected
      );
      return false;
    }
    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorMobileNoNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      full_phone_number: this.state.countryCodeSelected + this.state.mobileNo,
      password: this.state.password
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Login
  // Timer----
  async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if(prevState.seconds !== this.state.seconds && this.state.seconds === 0) {
      clearInterval(this.myInterval);
    }
  }

  async componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  fmtMSS(s: any) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

  // OTP----
  otpHandleChange = (otpInput: any) => {
    this.setState({ otpInput });
  }

  handleOtpSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({ isErrorOtp: this.validateOtp(this.state.otpInput) });
    if(this.state.otpInput.length >= 4) {
      this.setState({ isLoadingOtp: true });
      this.verifyOtp();
    }
  }

  validateOtp = (values: any) => {
    const errors: any = {};
    if(!values || values.length < 4) {
      errors.otpInput = "Otp fields are required!";
    }
    return errors;
  }

  handleClickResendOtp = () => {
    this.setState({ openResendOtp: true });
  }

  handleCloseResendOtp = () => {
    this.setState({ openResendOtp: false });
  }
  
  // Mobile Number----
  handleChange = (e: any) => {
    this.setState({ mobile: e.target.value });
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({ isError: this.validate({ mobile: this.state.mobile }) });
    const { mobile } = this.state;
    if(mobile.length === 10) {
      this.setState({ isLoading: true });
      this.login();
    }
  }
  
  validate = (values: any) => {
    const errors: any = {};
    const regex = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    if(!values.mobile) {
      errors.mobile = "Phone number is required";
    } else if(!regex.test(values.mobile)) {
      errors.mobile = "This is not a valid phone number";
    } else if(this.state.mobile.length != 10) {
      errors.mobile = "This is not a valid phone number";
    }
    return errors;
  }

  login = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const httpBody = {
      "data": {
        "type":"sms_account",
        "attributes": {
          "full_phone_number": `91${this.state.mobile}`
        }
      }
    }
    localStorage.setItem('merchantId',`91${this.state.mobile}`)

    const loginMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLoginCallId = loginMsg.messageId;

    loginMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/send_otp"
    );

    loginMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    loginMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    loginMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(loginMsg.id, loginMsg);
  }

  verifyOtp = () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken"),
    };

    const data = {
      "otp_code": this.state.otpInput,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpVerifyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // return true;
  }
  // Customizable Area End
}
