import React from "react";
// Customizable Area Start

import { Grid, CircularProgress, Backdrop } from "@material-ui/core";

import {
  tapsureLogo,
} from "./assets";

import CustomerTwoWheelerController, {
  Props,
} from "./CustomerTwoWheelerController";
// Customizable Area End

// Customizable Area Start
export default class CustomerTwoWheelerSteps extends CustomerTwoWheelerController {
  constructor(props: Props) {
    super(props);
  }
  openRequestedPopup = (url: any) => {
    console.log("cnscnksnkns", this.state.assureURL);
    let windowObjectReference;
    windowObjectReference = window.open(
      url,"_self");
  };

  render() {
    if (this.state.assureURL != "") {
      this.openRequestedPopup(this.state.assureURL);
    }
    return (
      <>
        {/* Logo section */}
        <Grid container spacing={2}>
          <Grid item>
            <img
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={CustomerTwoWheelerSteps_StyleCss}
            />
          </Grid>
        </Grid>{" "}
        <Grid
          container
          spacing={2}
          style={CustomerTwoWheelerSteps_gridStyleCss}
        >
          <Grid item xs={12}>
            {this.state.isLoading ? (
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#fff" }}
                open={this.state.isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              // <iframe
              //   src={this.state.assureURL}
              //   style={{ height: "100vh", width: "100vw", border: "none" }}
              // />
              ""
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const CustomerTwoWheelerSteps_StyleCss = { margin: "10px 20px" }
const CustomerTwoWheelerSteps_gridStyleCss =     { justifyContent: "center", alignItems: "center" }
// Customizable Area End
