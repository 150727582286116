import React from "react";
// Customizable Area Start
 
import HealthInsuranceController, { Props } from "./HealthInsuranceController";
// Customizable Area End

// Customizable Area Start

export default class CustomerDetailsSteps extends HealthInsuranceController {
  constructor(props: Props) {
    super(props);
  }

  

  render() {
    return (
     <> 
     {/* nothing  */}
     </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
 

// Customizable Area End
