import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Modal,
    Button,
    Container,
    Typography,

    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    back,
    background,

    close,

} from "./assets";


import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DashboardControllerThree from "./DashboardControllerThree";
import TextField from "@material-ui/core/TextField";


// Customizable Area End

// Customizable Area Start
class ManageTags extends DashboardControllerThree {
    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };
    handleClose = () => {
        this.setState({
            customerFilter: false,
            tapCustomerModal: false,
            isCustomerLoading: false,
            tagIdModal: false,
        });
        this.setState({
            tagIdNumber: [],
            confirmTagIdNumber: [],
            isTagIdValid: true
        })

    }


    render() {
        const { navigation } = this.props;
        console.log(this.state.tagIdNumber.length)
        return (
            <>
                <Backdrop
                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                    open={this.state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.tagToast} autoHideDuration={3000} style={{ margin: "10px" }}>
                    <Alert severity={this.state.tagToastType == "success" ? "success" : "error"}>
                        {
                            this.state.TagToastMessage
                        }
                    </Alert>
                </Snackbar>
                {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>
                    <Container maxWidth="sm">
                        {/*@ts-ignore */}
                        <Box
                            sx={style.back}
                            onClick={() => navigation.navigate("Dashboard")}
                        >
                            <img src={back} alt="" height="15" width="10" />
                            <Typography
                                style={{ marginTop: -2, marginLeft: 8 }}
                                variant="body2"
                            >
                                Back to Dashboard
                            </Typography>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.customers}>
                            <Typography variant="h6">Manage Tag Id's</Typography>
                        </Box>
                        {
                            this.state.isCustomerLoading ? <Backdrop
                                style={{ zIndex: 1000 + 1, color: "#fff" }}
                                open={this.state.isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop> :
                                <>
                                    {
                                        this.state.tagProducts.map((i: any, id: any) => {
                                            return <Paper elevation={2} key={id}  >
                                                {/*@ts-ignore */}
                                                <Box
                                                    sx={style.customerDetails}
                                                >
                                                    {/*@ts-ignore */}
                                                    <Box
                                                        sx={style.familyHealth}
                                                    >
                                                        {/*@ts-ignore */}
                                                        <Box
                                                            sx={style.company}
                                                        >

                                                        </Box>
                                                    </Box>
                                                    {/*@ts-ignore */}
                                                    <Box
                                                        sx={style.customerContainer}
                                                    >
                                                        {/*@ts-ignore */}
                                                        <Box
                                                            sx={style.details}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom
                                                            >
                                                                Product Name
                                                            </Typography>
                                                            {/*@ts-ignore */}
                                                            <Box sx={style.icon}>

                                                                <Typography
                                                                    style={style.typography}
                                                                    variant="subtitle2"
                                                                >
                                                                    <b>{i.ProductName}</b>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {/*@ts-ignore */}
                                                        <Box

                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom

                                                            >
                                                                {i.Tagid === null || "" ? "" : "Tag Id"}
                                                            </Typography>
                                                            {/*@ts-ignore */}
                                                            <Box sx={style.icon}>
                                                                {/* <img
                                                           src={item.phoneIcon}
                                                           alt=""
                                                           height="18"
                                                           width="18"
                                                       /> */}

                                                                {
                                                                    i.Tagid !== null || "" ? <Typography
                                                                        style={style.typography}
                                                                        variant="subtitle2"
                                                                    >
                                                                        <b>{i.Tagid.toString()}</b>

                                                                    </Typography> :
                                                                        <>
                                                                            {/*@ts-ignore */}
                                                                            <Box sx={style.buttons}>
                                                                                <Button
                                                                                    style={{
                                                                                        marginBottom: "16px",
                                                                                        padding: "10px",
                                                                                        backgroundColor: "rgb(70, 130, 180, 0.1)",
                                                                                        color: "#4682B4",
                                                                                        textTransform: "capitalize",
                                                                                    }}
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    onClick={() => this.handleOpenTagModal(i.ProductId)}
                                                                                >
                                                                                    <b style={{
                                                                                        marginLeft: "auto"
                                                                                    }}>Add Tag ID</b>
                                                                                </Button>

                                                                            </Box>
                                                                        </>

                                                                }

                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </Paper>
                                        })
                                    }


                                </>
                        }


                    </Container>

                </div>

                <Container  >
                    {/*@ts-ignore */}
                    <Modal
                        open={this.state.tagIdModal}

                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ top: '35%', }}
                    >
                        {/*@ts-ignore */}
                        <Box sx={style.containerFilter}>
                            <Backdrop
                                style={{ zIndex: 1000 + 1, color: "#404040" }}
                                open={this.state.isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <Snackbar open={this.state.ModaltagToast} autoHideDuration={6000} style={{ margin: "10px" }}>
                                <Alert severity={this.state.tagToastType == "success" ? "success" : "error"}>
                                    {

                                        this.state.TagToastMessage
                                    }
                                </Alert>
                            </Snackbar>
                            <img
                                style={{ cursor: 'pointer', position: "absolute", right: '0', marginRight: '35px' }}
                                src={close}
                                alt=""
                                height="20"
                                width="20"
                                onClick={this.handleClose}
                                data-test-id="handle-close"
                            />
                            <Typography id="modal-modal-title" variant="h6" component="h1" style={{ paddingBottom: '20px', fontWeight: 'bold' }}>
                                Add Tag ID
                            </Typography>

                            {/*@ts-ignore */}
                            <Box style={{ marginBottom: "30px", marginTop: "30px" }}

                            >
                                <TextField style={{ backgroundColor: "rgba(70, 130, 180, 0.1)" }} label="Enter Tag ID" variant="outlined" fullWidth
                                    type="text"

                                    value={this.state.tagIdNumber}
                                    onChange={this.handleTagId}
                                    data-test-id="handle-tag"
                                />

                            </Box>
                            {/*@ts-ignore */}
                            <Box

                            >

                                <TextField style={{ backgroundColor: "rgba(70, 130, 180, 0.1)" }} label="Confirm Tag ID" variant="outlined" fullWidth
                                    type="text"
                                    error={!this.state.isTagIdValid}
                                    value={this.state.confirmTagIdNumber}
                                    onChange={this.handleConfirmTagId}
                                    data-test-id="confirm-tag-id"
                                    helperText={
                                        this.state.isTagIdValid
                                            ? ""
                                            : "Tag Id Should be Same"
                                    } />

                            </Box>
                            <p style={{ fontSize: "10px" }}>Note: Use only comma separated numeric values if you want to add multiple Tags</p>
                            {/*@ts-ignore */}
                            <Box style={{ marginTop: "30px", position: "relative" }}

                            >
                                <Button
                                    variant="contained" style={{
                                        width: "150px",
                                        height: "50px",
                                        left: "25%",
                                    }}

                                    disabled={(this.state.tagIdNumber.length === 0 && this.state.confirmTagIdNumber.length === 0) ?
                                        true : false}
                                    onClick={this.handleLoadBtn}
                                    data-test-id="handle-load-btn"
                                >
                                    Submit
                                </Button>

                            </Box>

                        </Box>
                    </Modal>
                </Container>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    points: {
        color: "rgb(0, 200, 127, 1)",
        pointer: "cursor"
    },
    orders: {
        mt: 2,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    containerFilter: {
        position: 'absolute' as 'absolute',
        top: '20%',
        left: '50%',
        width: 300,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: '16px',
    },
    rowPoints: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tappoints: {
        p: 0.7,
        mt: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        border: "0px solid black",
        backgroundColor: "rgb(242,242,242)"
    },
    phoneDetails: {
        marginRight: 68,
    },
    back: {
        mt: 3,
        color: "white",
        display: "flex",
        flexDirection: "row",
        cursor: 'pointer'
    },
    container: {
        p: 1.5,
        mt: 2,
    },
    image: {
        height: 'auto',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    familyHealth: {
        display: "flex",
        flexDirection: "row",
    },
    company: {
        display: "flex",
        flexDirection: "column",
        ml: 1.5,
    },
    customers: {
        mt: 2,
        color: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    typography: {
        //marginLeft: "7px",
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        cursor: 'pointer'
    },
    customerDetails: {
        p: 2,
        mt: 2,
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    icon: {
        display: "flex",
        flexDirection: "row",
    },
    phoneRenewal: {
        marginRight: 12,
    },
    tabContainer: {
        paddingBottom: "10px",
        display: "block",
        overflowX: "scroll",
        width: "100%",
    },
    customerContainer: {
        mt: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    tabs: {
        mt: 1,
        display: "block",
        whiteSpace: "nowrap",
    },
    companies: {
        p: 2,
        mt: 2,
        display: "flex",
        flexDirection: "column",
    },
    tabItem: {
        display: "inline-block",
        marginRight: "10px",
    },
    buttons: {
        width: "100%",
    },
    // QR Code
    insurance: {
        mt: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    download: {
        marginTop: "20px",
        padding: "15px",
        backgroundColor: "rgb(70, 130, 180, 0.1)",
        color: "#4682b4",
    },

    // Share Policy
    containerSharePolicy: {
        padding: "16px",
        mt: "22px",
        position: "absolute" as "absolute",
        top: "102%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "0px solid #000",
        width: "95%",
        height: "80%",
        paddingBottom: "35px",
        borderRadius: "15px",
    },
    socialMedia: {
        mt: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    link: {
        p: 1.2,
        mt: 4,
        border: "0.1px solid #d3d3d3",
        backgroundColor: "rgb(70, 130, 180, 0)",
        borderRadius: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
};
// Customizable Area End
export default ManageTags;
