import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import {

  profile,

  image_phone_number,
  purchase_date,
  renewal_date,
} from "./assets";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  flag: boolean;
  open: boolean;
  company: string;
  date: any;
  selectedDate: any;
  customerFilter: boolean;
  value: number;
  openQrCode: boolean;
  openSharePolicy: boolean;
  tapPointsModal: boolean;
  formData: {
    name: string;
    phoneNumber: string;
  };
  isError: any;
  categories: any;
  selectedCategory: any;
  qrUrl: string;
  products: any;
  isLoading: boolean;
  qrPath: string;
  selectedProduct: any;
  customerCount: number;
  customers: any;
  productsName: any
  isCustomerLoading: boolean
  productsCount: number;
  filterProducts: any;
  tagProducts: any;
  start_date: string;
  end_date: string;
  first_name: string;
  openToast: boolean;
  toastType: string,
  tagToast: boolean;
  ModaltagToast: boolean;
  TagToastMessage: string;
  tagToastType: string;
  openErrorPage: boolean,
  toastMessage: string;
  tapPointsData: any;
  tapPoints: number;
  totalOrders: number;
  companyName: string;
  insuranceTypeName: string;
  tapCustomerModal: boolean;
  tapCustomerInfo: string;
  tapCustomerData: any;
  tappointsTableModal: boolean;
  tagIdModal: boolean;
  merchantPhoneNumber: string;
  circularLoading: boolean;
  tagIdNumber: any;
  confirmTagIdNumber: any;
  isTagIdValid: any;
  tagBtnDisabled: any;
  initialTagState: any;
  merchantAccountId: any;


  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardControllerThree extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCategoriesId: string = "";
  getProductsApiCallId: string = "";
  getAssureCustomerApiCallId: string = "";
  getFilterProductsApiId: string = "";
  // @ts-ignore
  getApiCallIdPoints: string;
  getTapCustomerDetails: string = "";
  getMerhcantCategories: string = ""
    // @ts-ignore
  userProfileApiCallId: string;
  getTagIdDetailsApi: string = "";
  addTagIdApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      flag: false,
      open: false,
      company: "",
      date: "",
      selectedDate: new Date("2014-08-18T21:11:54"),
      customerFilter: false,
      value: 0,
      openQrCode: false,
      openSharePolicy: false,
      tapPointsModal: false,
      formData: {
        name: "",
        phoneNumber: "",
      },
      isError: {},
      categories: [],
      selectedCategory: 0,
      qrPath: "CustomerTwoWheelerSteps",
      products: [],
      isLoading: true,
      qrUrl: "",
      selectedProduct: {},
      customerCount: 0,
      customers: [],
      productsName: [],
      isCustomerLoading: true,
      productsCount: 0,
      filterProducts: [],
      tagProducts: [],
      start_date: "",
      end_date: "",
      first_name: "",
      openToast: false,
      toastMessage: "",
      toastType: "success",
      ModaltagToast: false,
      tagToast: false,
      TagToastMessage: "",
      tagToastType: "success",
      openErrorPage: false,
      tapPointsData: [],
      tapPoints: 0,
      totalOrders: 0,
      companyName: "",
      insuranceTypeName: "",
      tapCustomerModal: false,
      tappointsTableModal: false,
      tagIdModal: false,
      tapCustomerInfo: "",
      tapCustomerData: [],
      merchantPhoneNumber: "",
      circularLoading: false,
      tagIdNumber: [],
      confirmTagIdNumber: [],
      isTagIdValid: true,
      tagBtnDisabled: true,
      initialTagState: {},
      merchantAccountId: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }
    let apiResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let responseJson: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );



    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false,
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",

          });
        }
      } else {
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false,
          });
        } else {
          this.setState({
            errorMsg: errorReponse,

          });
        }
      }
      if (apiRequestCallId == this.getCategoriesId) {
        console.log("response", responseJson)
        console.log(responseJson != undefined)
        if (responseJson != undefined) {
          console.log(responseJson.data_hash)

          let count = 0;
          responseJson.data_hash.map((item: any) => {
            count += item?.attributes?.product_count
          })

          this.setState({
            categories: responseJson.data_hash,
            isLoading: false,
            productsCount: responseJson.count

          });

          console.log('item' + this.state.categories[0][0])
          let productName = this.state.categories[0][0]

          if (productName != "") {
            this.setState({
              selectedCategory: productName
            })
            this.getProducts(productName)
          }
          if (responseJson.data_hash.length) {
            let firstCategory = responseJson.data_hash
            let id = firstCategory.id
            this.setState({ selectedCategory: id })

            console.log("test")
          }
        } else {
          console.log("err");
        }
      } else
        if (apiRequestCallId == this.getProductsApiCallId) {

          if (responseJson && responseJson.data_hash && responseJson?.merchant_config) {
            console.log("ESSSS", responseJson.data_hash[0])
            this.setState({
              qrUrl: responseJson.data_hash[0]?.data[0]?.attributes.base_url
            })
            let prd: any = []

            responseJson.data_hash.map((item: any) => {
              let obj = { ...item?.data[0] }
              prd.push(obj)
              console.log("data" + prd)
            })


            this.setState({
              products: prd,
              isLoading: false

            })
            if (responseJson && responseJson.data_hash[0]) {
              this.setState({
                qrUrl: responseJson.data_hash[0]?.data[0]?.attributes.base_url
              })
            }
          } else
            if (responseJson && !responseJson?.merchant_config) {
              this.setState({
                products: responseJson?.data_hash[0]?.data,
                isLoading: false

              })
            }
          if (responseJson && responseJson.data_hash[0]) {
            this.setState({
              qrUrl: responseJson.data_hash[0]?.data[0]?.attributes.base_url
            })
          }

        } else
          if (apiRequestCallId == this.getAssureCustomerApiCallId) {
            if (responseJson != undefined) {
              if (responseJson.customer_count && responseJson.customers) {
                let _customers: any = [];
                responseJson?.customers.map((customer: any) => {
                  let obj = { ...customer }
                  obj.image = responseJson?.base_url + customer?.get_description_and_image?.image,
                    obj.profileIcon = profile,
                    obj.phoneIcon = image_phone_number,
                    obj.purchaseDateIcon = purchase_date,
                    obj.renewalDateIcon = renewal_date,
                    obj.type = customer?.company_name,
                    obj.company = customer?.description,
                    obj.new_policy_start_date = this.getCustomizedDate(customer.purches_date)
                  obj.renewal_date = this.getCustomizedDate(customer.renewal_date)
                  _customers.push(obj)

                })
                this.setState({
                  customerCount: responseJson?.customers?.length,
                  customers: _customers,
                  isCustomerLoading: false
                })
              } else {
                this.setState({
                  customerCount: 0,
                  customers: [],
                  isCustomerLoading: false
                })
              }
            }

          } else
            if (apiRequestCallId == this.getFilterProductsApiId) {
              if (responseJson && responseJson.products) {

                this.setState({
                  filterProducts: responseJson?.products
                })
              }
              else {
                console.log("err")
              }
            } else
              if (apiRequestCallId == this.getTagIdDetailsApi) {

                if (responseJson && responseJson.data) {
                  this.setState({
                    tagProducts: responseJson.data,
                  })
                }
                if (responseJson.message) {
                  setTimeout(() => {
                    this.setState({
                      tagToast: false
                    });
                  }, 3000);
                  this.setState({ tagToast: true, TagToastMessage: responseJson.message, tagToastType: "error" });
                }


              } else
                if (apiRequestCallId == this.addTagIdApiCallId) {
                  if (!responseJson.errors) {
                    let merchantAccId: any = localStorage.getItem("merchantAccId")
                    this.getTagPruduct(merchantAccId)

                    setTimeout(() => {
                      this.setState({
                        ModaltagToast: false,
                        tagIdModal: false,
                        tagIdNumber: "",
                        confirmTagIdNumber: "",
                        isTagIdValid: true

                      });
                    }, 2000);
                    this.setState({ isLoading: false, ModaltagToast: true, TagToastMessage: responseJson?.meta?.message, tagToastType: "success" });
                  } else {
                    setTimeout(() => {
                      this.setState({
                        ModaltagToast: false
                      });
                    }, 3000);
                    this.setState({ isLoading: false, ModaltagToast: true, TagToastMessage: responseJson?.errors, tagToastType: "error" });
                  }
                } else
                  if (apiRequestCallId === this.getTapCustomerDetails) {

                    console.log(responseJson)
                    if (responseJson && !responseJson.errors && !responseJson.error) {

                      console.log(responseJson.data)
                      this.setState({
                        tapCustomerData: responseJson.data
                      })

                    } else {

                      this.setState({ openToast: true, toastMessage: "Please Try Again", toastType: "error" });

                      this.parseApiCatchErrorResponse(errorReponse);
                    }
                  } else
                    if (apiRequestCallId === this.getApiCallIdPoints) {

                      if (responseJson && !responseJson.errors && !responseJson.error) {

                        console.log(responseJson)
                        let Tapcustomers: any = [];
                        responseJson.data.map((customer: any) => {
                          let obj = { ...customer };
                          obj.type = customer?.product_name;
                           Tapcustomers.push(obj);
                        })
                        this.setState({
                          tapPointsData: responseJson.data,
                          tapPoints: responseJson.total_earning,
                          totalOrders: responseJson.total_orders,
                          productsName: Tapcustomers,
                          insuranceTypeName: responseJson.data.product_name,

                        })


                      } else {

                        this.setState({ openToast: true, toastMessage: "Please Try Again", toastType: "error" });
                        this.parseApiCatchErrorResponse(errorReponse);
                      }
                    } else

                      if (apiRequestCallId === this.userProfileApiCallId) {

                        localStorage.setItem("userDataName", JSON.stringify(apiResponse?.data?.attributes?.first_name))
                        localStorage.setItem("userDataPhone", JSON.stringify(apiResponse?.data?.attributes?.full_phone_number))
                        if (apiResponse) {
                          console.log('successapiResponse', apiResponse.data.id);
                          this.setState({
                            merchantPhoneNumber: apiResponse?.data?.attributes?.full_phone_number,
                            merchantAccountId: apiResponse.data.id
                          })
                          this.setState({
                            formData: {
                              ...this.state.formData, name: apiResponse?.data?.attributes?.first_name,
                              phoneNumber: apiResponse?.data?.attributes?.full_phone_number
                            }
                          })
                          localStorage.setItem("merchantAccId", apiResponse.data.id)
                          this.getFilterProducts();
                          this.getTagPruduct(apiResponse.data.id)
                          this.getCategories(apiResponse?.data?.attributes?.full_phone_number);
                          console.log(apiResponse?.data?.attributes?.full_phone_number)
                        }
                        else {

                          console.log('error', apiResponse?.error)
                        }
                      }

    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();


    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    // Customizable Area Start


    setTimeout(() => {
      this.handleGetProfileData()
    }, 0)


    //get filter prodcuts
    setTimeout(() => {
      this.getAssureCustomers("");
    }, 0)

    this.getTappointsData();


    // Customizable Area End
  }

  getToken = () => {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
    // Customizable Area End
  };

  getDashboardData() {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }


  // for profile data
  handleGetProfileData = () => {

    const headers = {
      "Content-Type": configJSON.dashboarContentType,

    };
    
    let token = localStorage.getItem("newToken")
    let merchantID = localStorage.getItem("merchantId")
    const getProfileDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileApiCallId = getProfileDetailsMsg.messageId;

    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactData + `?merchant_id=${merchantID}`
    );

    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getProfileDetailsMsg.id, getProfileDetailsMsg);
  }

  // function for merchant categories 


  getCategories = (merchantPhone: any) => {
    // Customizable Area Start

    const headers = {
      "Content-Type": configJSON.validationApiContentType,

      token: localStorage.getItem("newToken") || localStorage.getItem("loginToken")

    };
    console.log(merchantPhone)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMerchantDetails + `${merchantPhone}` + `?data_for=Categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getProducts = (productName: any) => {
    // Customizable Area Start

    this.setState({ isLoading: true })
    let merchant_id = localStorage.getItem('merchantId')

    const headers = {
      token: localStorage.getItem("newToken") || localStorage.getItem("loginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMerchantDetails + `${merchant_id}` + `?data_for=Products&cat_name=${productName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProductsMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  downloadQRCode = () => {
    // Customizable Area Start
    try {
      const canvas: any = document.querySelector('canvas');
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${this.state.selectedProduct?.attributes?.name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.log(e);
    }
    // Customizable Area Start
  }

  handleCategory = (productName: any) => {
    // Customizable Area Start


    console.log(this.state.merchantPhoneNumber)
    localStorage.setItem('productId', productName[0])
    this.setState({
      selectedCategory: productName[0],
      isLoading: true
    });
    console.log(productName[0])
    this.getProducts(productName[0]);
    // Customizable Area Start
  };

  getAssureCustomers = (name: any) => {
    // Customizable Area Start
    this.setState({
      isCustomerLoading: true
    })
    const headers = {
      token: localStorage.getItem("newToken") || localStorage.getItem("loginToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let str = "";
    if (this.state.date) {
      if (this.state.date != "range") {
        let currentDate = new Date().toJSON().slice(0, 10).split('-').reverse().join('-')
        str += "&" + this.state.date + "=" + currentDate
      }
      else {
        str += "&start_date=" + this.state.start_date + "&end_date=" + this.state.end_date
      }
    }

    this.getAssureCustomerApiCallId = requestMessage.messageId;
    let merchant_id = localStorage.getItem('merchantId')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssureCustomersUrl + "?merchant_id=" + merchant_id + "&first_name=" + name + "&company_name=" + this.state.company + str);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssureCustomerMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  getCustomizedDate = (dateStr: string) => {
    // Customizable Area Start
    const date = new Date(dateStr);
    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
    const monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return `${day} ${monthArr[month]} ${year}`
    // Customizable Area End
  }

  handleShare = async () => {
    console.log("clciked")
    try {
      const canvas: any = document.querySelector("canvas")
      const dataUrl = canvas.toDataURL();
      const blob = await (await fetch(dataUrl)).blob();
      const filesArray = [
        new File([blob], "animation.png", {
          type: blob.type,
          lastModified: new Date().getTime()
        })
      ];
      const shareData = {
        files: filesArray,
        text: "Kindly tap to check our latest policy here- " + this.state.qrUrl + this.state.qrPath
      };
      navigator.share(shareData).then(() => {
        console.log("Shared successfully");
      });
    }
    catch (e) {
      console.log(e)
    }

  }

  getTappointsData = () => {
    // Customizable Area Start
    this.setState({
      isCustomerLoading: true
    })


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiCallIdPoints = requestMessage.messageId;
    console.log(requestMessage.messageId)
    let merchant_id = localStorage.getItem('merchantId')
    let str = "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTappointsDataUrl + merchant_id + "?filter_by_policy=" + this.state.company
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMerchantApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  getTapCustomer = (value: any) => {
    // Customizable Area Start
    this.setState({
      isCustomerLoading: true
    })


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTapCustomerDetails = requestMessage.messageId;
    console.log(requestMessage.messageId)
    let merchant_id = localStorage.getItem('merchantId')
    let str = "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTapCustomerDetailsApi + merchant_id + "?policy_name=" + value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMerchantApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getFilterProducts = () => {
    // Customizable Area Start
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterProductsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterProductsUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssureCustomerMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getTagPruduct = (id: any) => {
    // Customizable Area Start

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTagIdDetailsApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagUrl + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssureCustomerMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  addTagId = (productId: any, enterTagId: any) => {
    // Customizable Area Start
    this.setState({ isLoading: true })
    console.log(enterTagId)
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const httpBody = {

      "merchant_id": parseInt(this.state.merchantAccountId),
      "product_id": parseInt(productId),
      "tag_id": enterTagId
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addTagIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTagUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contact_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  handleCompanySelect = (e: any) => {
    console.log(e.target)
    this.setState({
      company: e.target.value
    })
  }
  handleCustomerSearch = (e: any) => {
    this.setState({
      first_name: e.target.value
    })
    let name = e.target.value
    this.getAssureCustomers(name)

  }

  handleOpenTagModal = (ProductId: any) => {

    localStorage.setItem("productId", ProductId)
    this.setState({
      tagIdModal: true
    })
  }
  handleLoadBtn = (e: any) => {
    let newConfirmTagNumber:any = this.state.confirmTagIdNumber
    console.log("cll")
    this.setState({
      circularLoading: true
    })
    console.log(this.state.tagIdNumber)
    console.log(this.state.confirmTagIdNumber)
  
    if (JSON.stringify(this.state.tagIdNumber) === JSON.stringify(this.state.confirmTagIdNumber)) {
      this.setState({
        isTagIdValid: true
      })
     
      let productId: any = localStorage.getItem("productId")
      let enterTagId: any = this.state.confirmTagIdNumber.sort().toString()
      console.log(enterTagId)
      
      this.addTagId(productId, enterTagId)
      

    } else {
      this.setState({
        isTagIdValid: false,
        confirmTagIdNumber:newConfirmTagNumber
        
      })
      console.log(newConfirmTagNumber)
    }


  }

  handleConfirmTagId = (e: any) => {
    this.setState({
      confirmTagIdNumber: e.target.value.split(',')
    })
   
  }
  handleTagId = (e: any) => {
   let inputValue = "";
   let regEx = /^[0-9\b,'']+$/;
   if(regEx.test(e.target.value) === true || e.target.value === ""){
    inputValue = e.target.value;
  
  }else{
    console.log("false")
    return false
  }
 
  this.setState({
    tagIdNumber: inputValue.split(',')
    
  })
  }
  handleFilterApply = () => {
    this.getAssureCustomers("");
    this.getTappointsData();
    this.setState({
      customerFilter: false
    })

  }
  handleStartDate = (e: any) => {
    this.setState({
      start_date: e.target.value
    })
  }
  handleEndDate = (e: any) => {
    this.setState({
      end_date: e.target.value
    })
  }
  handleResetFilter = () => {
    this.setState({
      company: "",
      date: "",
      start_date: "",
      end_date: "",
      first_name: "",
      customerFilter: false

    }, () => {
      this.getAssureCustomers("");
      this.getTappointsData();
    })

  }

  handleTappoints = () => {
    this.props.navigation.navigate("Tappoints")


  }

  handleManageTags = () => {
    //this.getTagPruduct()
    this.props.navigation.navigate("ManageTags")
  }
  handleCustomers = () => {
    this.props.navigation.navigate("Customers")

  }


  handleOpenTabTable = () => {
    this.setState({
      tappointsTableModal: true
    })
  }
  handleOpenCustomerTappoint = (value: any) => {
    this.getTapCustomer(value)
    this.setState({
      tapCustomerModal: true,
      tapCustomerInfo: value
    })

  }

  redirectToHomeScreen = () => {
    this.props.navigation.navigate('CustomerHomeScreen');
  }



  // Customizable Area End



}
