import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {
  arrayHolder: any;
  token: string;
 
  // Customizable Area Start
  
  qrUrl: string;
   
  merchantId: any;
  productId:any;
  productName:any;
  loading:any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EnrollPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  getDataByTagIdApi:any;
  getBaseUrlApi:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      qrUrl:"",
       merchantId:"",
  productId:"",
  productName:"",
  loading:true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
     
      this.setState({ token: token });
      
    }
    let responseJson:any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    let errorReponse:any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getDataByTagIdApi != null &&
        this.getDataByTagIdApi ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
       
        if (responseJson) {
            
            console.log("tagIdRes"+responseJson?.data?.attributes)
            console.log(responseJson?.data?.attributes)
            this.setState({
                merchantId:responseJson?.data?.attributes?.merchant,
                productId:responseJson?.data?.attributes?.product_id,
                productName:responseJson?.data?.attributes?.product_name,
                loading:false
            })
        } else {
              
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getBaseUrlApi != null &&
        this.getBaseUrlApi ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
       
        if (responseJson) {
            this.setState({
                qrUrl:responseJson?.base_url
            })
         } else {
         
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

   
    // Customizable Area End
  }

  // Customizable Area Start
  

  async componentDidMount() {
    super.componentDidMount();
     
    let search = window.location.search
     const  r =   search.split("=")[1]
   
       this.getBaseUrl(r)

        setTimeout(()=>{
           
          this.getDataByTag(r)
        },100)
       
  
       setTimeout(()=>{
       
        if(this.state.loading === false){
          
            if(this.state.productName === "ICICI Lombard Travel Insurance" ){
                return   window.location.replace(`${this.state.qrUrl}${"CustomerTravelInsPolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`);
             }else
             if(this.state.productName === "One Assist"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerOneAssistPolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else 
             if(this.state.productName === "ICICI Lombard Cyber Fraud"){
                 
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerCyberFraudPolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "Two Wheeler Insurance"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerTwoWheelerSteps?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "Four Wheeler Insurance"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerFourWheelerSteps?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "ICICI Lombard Home Insurance"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerHomeInsPolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "Care Hospicash"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerHospiCashPolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "Care Vector Borne"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerVBCarePolicyDetails?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }else
             if(this.state.productName === "DoctorOnCall"){
                 return  window.location.replace(`${this.state.qrUrl}${"CustomerHealthServicePolicyTabs?merchantId="+this.state.merchantId+"&product_id="+this.state.productId}`)
             }
        }
        
       },2000)
      
}

  // Customizable Area End
  getBaseUrl=(tagid:any)=>{
    this.setState({
        loading:true
    })
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBaseUrlApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBaseUrlPath+tagid
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  getDataByTag=(tagid:any)=>{
    this.setState({
        loading:true
    })
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDataByTagIdApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDataByTagUrl+tagid
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  // Customizable Area End
}
