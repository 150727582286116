import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start 
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;
  selectedState: string;
  innerStep: number; 
  policyType: string; 
  model: string;
   value: number;
  companyName: string;
  openPolicyDetailDrawer: boolean;
  phoneNumber: string;
  address: string;
  sumInsured: string;
  homeType: string;
  cutomerType: string;
  steps: any;
  passengersAge: string;
  numOfPassengers: string;
  travelPlans: string;
  tripDuration: string;
  basicPremium: number;
  // Customizable Area Start
  orderDetails: any;
  policyDetailsForm: {
    currDate: string;
    lastDate: string;
    gst: string;
    premium: number,
  },
  policyDetails: any;
  pinCode: string;
  proposerName: string;
  insurerName: string;
  email: string;
  city: string;
  isEmailValid: boolean;
  insurerNameValid: boolean;
  isPinCodeValid: boolean;
  isCityValid: boolean;
  isSelectedStateValid: boolean;
  proposerNameValid: boolean;
  loading: boolean; 
  cyberDetails: any;
  transaction_id: any;
  pay_description: string;
  Account_ID: any;
  policyBenifits: string;
  aboutDetails: string;
  aboutImage: string;
  travelFaq: any;
  openToast: boolean;
  toastMessage: string;
  toastType: string,
  openErrorPage: boolean,
  openModal: boolean,
  timer: any,
  baseUrl: any;
  isPhoneNumberValid: boolean;
  policy_Number: any;
  stopPaymentApi: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
declare global {
  interface Window {
    loadBillDeskSdk?: any;
  }
}
export default class CustomerTravelInsController extends BlockComponent<Props, S, SS> {
  policyTravelCustomerAPICalledID: any;
  getTravelLocationApiId: any;
  getTravelPolicyDetails: any; 
  createTravelOrderAPICalledID: any;
  verifyTravelOrderAPICalledID: any;
  IssueTravelPolicyAPICalledID: any; 
  generateTravelCommissionId: any;
  sendMessagesApi: any;
  getPolicySmsId: any;
  travelPolicyPlans = [
    {
      id: 1,
      amount: '499',
      duration: '1 Year'
    },

  ]

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      selectedState: "",
      arrayHolder: [],
      token: "",
      activeStep: 0,
      innerStep: 0, 
      policyType: "Comprehensive",
      model: "",  
      value: 0,
      proposerName: "",
      companyName: "",
      openPolicyDetailDrawer: false,
      insurerName: "",
      email: "",
      phoneNumber: "",
      pinCode: "",
      city: "",
      address: "",
      sumInsured: "Building + Household Items",
      homeType: "Flat",
      cutomerType: "Owner",
      steps: ["Personal Details"],
      passengersAge: "",
      numOfPassengers: "",
      travelPlans: "",
      tripDuration: "",
      basicPremium: 422.89,
      // Customizable Area Start
      policyDetailsForm: {
        currDate: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
        lastDate: ``,
        gst: "",
        premium: 0,
      },
      policyDetails: {},
      orderDetails: {},
      isEmailValid: true,
      insurerNameValid: true,
      isPinCodeValid: true,
      isSelectedStateValid: true,
      isCityValid: true,
      proposerNameValid: true,
      loading: false, 
      cyberDetails: {},
      transaction_id: 0,
      pay_description: '',
      Account_ID: 0,
      policyBenifits: '',
      aboutDetails: "",
      aboutImage: "",
      travelFaq: [],
      openToast: false,
      toastMessage: "",
      toastType: "success",
      openErrorPage: false,
      openModal: false,
      timer: null,
      baseUrl: "",
      isPhoneNumberValid: true,
      policy_Number: 0,
      stopPaymentApi: true
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start


    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
 
    if(
      getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiTravelRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiTravelRequestCallId != null) {
        switch (true) {
          case apiTravelRequestCallId === this.policyTravelCustomerAPICalledID:
            this.setTravelCustomerApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.getTravelPolicyDetails:
            this.setTravelPolicyDetailsApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.createTravelOrderAPICalledID:
            this.setCreateTravelOrderApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.verifyTravelOrderAPICalledID:
            this.verifyTravelOrderApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.IssueTravelPolicyAPICalledID:
            this.issueTravelApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.generateTravelCommissionId:
            this.generateHomeCommisionApi(responseJson, errorReponse)
            break;
          case apiTravelRequestCallId === this.getTravelLocationApiId:
            this.getHomeLocationApi(responseJson, errorReponse)
            break;
          default:
        }
      }
      this.setState({ loading: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End
  }
  async componentDidMount() {

    super.componentDidMount();

    this.setState({
      policyDetailsForm: { ...this.state.policyDetailsForm, lastDate: `${new Date().getDate() - 1}/${new Date().getMonth() + 1}/${new Date().getFullYear() + 1}` }
    })

    this.getPolicyPageDetails();

    if (window.location.href.includes("CustomerTravelInsPolicyTabs")) {
      let url = new URL(window.location.href)

      let merchantId: any = url.searchParams.get("merchantId");
      let product_id: any = url.searchParams.get("product_id");
      if (merchantId && product_id) {
        localStorage.setItem('merchantId', merchantId)
        localStorage.setItem('product_id', product_id)
      }
    }

  }
 
  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectToComponent = (component: string, data: any) => {
    
    this.props.navigation.navigate(component);
  };
  redirectToHomeScreen = () => {
    this.props.navigation.navigate('MerchantPolicyDetails');
  }
  RedirectoSuccessScreen = () => {
    this.setState({ loading: false });
    this.props.navigation.navigate('CustomerOrderSuccess');
  }

  setTravelCustomerApi = (responseJson: any, errorReponse: any) => {

    if (responseJson && !responseJson.errors && responseJson.data) {
      let id = responseJson?.data.id;
      console.log(id)
      this.getTravelPolicySms(id)
      this.setState({
        Account_ID: parseInt(id),
        loading: false
      })
      localStorage.setItem("customerAccountID", responseJson?.data.id)
      this.setState({ policyDetails: responseJson.data });
     
      this.setState({ loading: false });
      runEngine.debugLog("arrayHolder", this.state.policyDetails);
    } else {
       
      this.setState({ loading: false, openToast: true, toastType: "error", toastMessage: "We're experiencing technical difficulties .Please try after some times." });
     
      this.parseApiCatchErrorResponse(errorReponse);

    }
  }

  setTravelPolicyDetailsApi = (responseJson: any, errorReponse: any) => {
     if(responseJson?.data){
      console.log(responseJson)
      
      this.setState({
        arrayHolder: responseJson.data.attributes
      })
 
      this.setState({
        aboutDetails: responseJson.data.attributes.PolicyDetail.about
      })
      this.setState({
        aboutImage: responseJson.data.attributes.PolicyDetail.image
      })
      this.setState({
        travelFaq: responseJson.data.attributes.Faqs
      })

     }
  }

  setCreateTravelOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson.data) {
      console.log(responseJson.data[0].orderid);
      console.log(responseJson.data[0].bdorderid);
      if (responseJson.transaction_id) {
        this.setState({
          transaction_id: responseJson.transaction_id
        })
        setInterval(() => {
          if (this.state.stopPaymentApi === true) {
            this.verifyTravelPayment(responseJson.data[0].orderid, responseJson.data[0].bdorderid, responseJson.transaction_id)
          }
        }, 3000)

      }

      const flow_configTravel = {
        merchantId: responseJson.data[0].mercid,
        bdOrderId: responseJson.data[0].bdorderid,
        authToken: responseJson.data[0].links[1].headers.authorization,
        childWindow: true,
        retryCount: 3,
        prefs: { "payment_categories": ["card", "emi", "nb", "upi", "wallets", "qr", "gpay"] }
      }

      const responseHandler = function (txn: any) {
        console.log("callback received status:: ", txn.status)
        console.log("callback received response:: ", txn.response)
      }
      const config = {
        responseHandler: responseHandler,
        merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
        flowConfig: flow_configTravel,
        flowType: "payments"
      } 
      setTimeout(() => {
        window.loadBillDeskSdk(config)
      })

      this.setState({ loading: false, openPolicyDetailDrawer: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  verifyTravelPayment = (orderid: any, bdOrderId: any, transaction_id: any) => {
    const headers_t = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpTravelBody = {
      "account_id": this.state.Account_ID,
      "transaction_id": transaction_id,
      "order_id": orderid,
      "bdorder_id": bdOrderId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyTravelOrderAPICalledID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verify_signature_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers_t)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpTravelBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyTravelOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      console.log(responseJson.payment_status);
      if (responseJson.payment_status === "success") {
        this.setState({
          stopPaymentApi: false
        })
        this.issueTravelPolicy()
      }

    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  getHomeLocationApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.error) {
      console.log("DATA", responseJson)
      console.log(responseJson.city_name)
      this.setState({
        selectedState: responseJson.state_name.toUpperCase(),
        city: responseJson.city_name,
      })
      this.setState({ loading: false, openToast: true, toastMessage: "Valid Pincode", toastType: "success" });
      console.log(this.state.toastMessage)
      if (this.state.toastMessage === "Valid Pincode") {
        setTimeout(() => this.setState({
          openToast: false
        }), 3000)
      }
    } else {
      console.log(errorReponse)
      this.setState({ loading: false, openToast: true, toastMessage: "Invalid Pincode", toastType: "error" });
      if (this.state.toastMessage === "Invalid Pincode") {
        setTimeout(() => this.setState({
          openToast: false
        }), 3000)
      }
    }
  }

  generateHomeCommisionApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.error && responseJson?.data) {
      console.log("RESSS", responseJson)
    }
    else {
      this.setState({ loading: false })
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }


  issueTravelApi = (responseJson: any, errorReponse: any) => {
    this.setState({ loading: true });

    if (responseJson && !responseJson.errors && !responseJson.error) {
      console.log(responseJson)

      let insurerName = this.state.insurerName
      let policyNumber = responseJson.cdbgResponse.cdbgResponseList[0].PolicyNo
      let policyType = "Travel";
      let policyAmount = "" + this.state.policyDetailsForm.premium;
      let startDate = new Date();
      startDate.setDate(startDate.getDate());
      startDate.setFullYear(startDate.getFullYear())
      console.log(startDate)
      let oneYearFromNow = new Date();
      oneYearFromNow.setDate(oneYearFromNow.getDate() - 1);
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      console.log(oneYearFromNow, 'oneYearFromNow');
      let transactionDate = startDate.toString();
      let policyEndDate = oneYearFromNow.toString();
      localStorage.setItem('insurerName', insurerName)
      localStorage.setItem('policyType', policyType)
      localStorage.setItem('policyAmount', policyAmount)
      localStorage.setItem('transactionDate', transactionDate)
      localStorage.setItem('membershipNumber', policyNumber)
      localStorage.setItem('policyEndDate', policyEndDate)


      this.generateTravelCommission()
      this.setState({ loading: false, openToast: true, toastMessage: "Policy Issued Successfully.", toastType: "success" });
      setTimeout(() => this.RedirectoSuccessScreen(), 4000)
    }
    else {
      console.log("ERRRRRR", responseJson.errors)
      this.setState({ loading: false, openModal: true });
      this.setState({ loading: false, openToast: true, toastMessage: responseJson.errors, toastType: "error" });
      setTimeout(() => this.redirectToHomeScreen(), 4000)

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }



  handleNext = () => { 
    let val = this.state.value + 1;
    if (val) {
      this.props.navigation.navigate("CustomerTravelPlans");
    } else {
      this.setState({ value: val });
    }
    
    console.log(val)
  }


  handleProceed = () => {
    const { currDate } = this.state.policyDetailsForm;
     
    if (currDate && this.validateFirstStep() === true) { 
      const clacGst: any = (this.state.basicPremium * 18 / 100).toFixed(2);
      const calculateGST = this.state.basicPremium * 18 / 100;
      let premium = Math.round(this.state.basicPremium + calculateGST)
      this.setState({
        openPolicyDetailDrawer: true,
        policyDetailsForm: { ...this.state.policyDetailsForm, gst: clacGst, premium: premium }

      })
      this.setState({ loading: true });
      const headers = {
        "Content-Type": configJSON.assureUrlContentType,
      };
      let merchant_id = localStorage.getItem('merchantId')
      let splitName = []
      splitName = this.state.insurerName.split(' ')
      const httpBody = {
        data: {
          first_name: splitName[0],
          last_name: splitName[1] ?? "",
          email: this.state.email,
          proposer_name: this.state.insurerName,
          insurer_name: this.state.insurerName,
          full_phone_number: this.state.phoneNumber, 
          state: this.state.selectedState,
          insurance_type: "Domestic Travel",
          merchant_id,
          id: parseInt(this.state.policyDetails.id)
 
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      console.log(httpBody)
      this.policyTravelCustomerAPICalledID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.travelCustomerUrl
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.assure_methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

  }

  handlePolicyTabsChange = (event: any, value: any) => {
    this.setState({
      value: value
    })
  }


  handleInsurerName = (e: any) => {
    this.setState({
      insurerName: e.target.value,
      insurerNameValid: true,
    })
  }
 
  handleEmail = (e: any) => {

    this.setState({
      email: e.target.value,
      isEmailValid: true,
    })


  }
  handlePhoneNumber = (e: any) => {
    this.setState({
      phoneNumber: e.target.value,
      isPhoneNumberValid: true
    })
  }



  handlePinCode = (e: any) => {
    e.preventDefault();
    this.setState({
      pinCode: e.target.value,
      isPinCodeValid: true,
    })
    if (e.target.value.length == 6) {
      this.getAddressDetails(e.target.value)

    }


  }

  handleState = () => {
    this.setState({
      isSelectedStateValid: true
    })
  }

  handleCity = () => {
    this.setState({
      isCityValid: true
    })
  }

  handleDrawerClose = () => {
    this.setState({
      openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer,
    })
    this.setState({ openPolicyDetailDrawer: false });
  }

  RedirectoHomeScreen = () => {
    this.props.navigation.navigate("CustomerHomeScreen");
  };

  closeModel = () => {
    this.setState({ openModal: !this.state.openModal });
  }

  validateFirstStep = () => {
   
    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isFirstStepValid = true;
    if (this.state.insurerName == "") {
      this.setState({
        insurerNameValid: false,
      });
      isFirstStepValid = false;
    }


    if (!emailRegex.test(this.state.email)) {
      this.setState({
        isEmailValid: false,
      });
      isFirstStepValid = false;
    }

    const regexPinCode = /^\d{3}\s?\d{3}$/;
    if (this.state.pinCode === "" || !regexPinCode.test(this.state.pinCode)) {
      this.setState({
        isPinCodeValid: false,
      })
      isFirstStepValid = false
    }
    if (
      this.state.phoneNumber == "" ||
      !mobileRegex.test(this.state.phoneNumber)
    ) {
      this.setState({
        isPhoneNumberValid: false,
      });
      isFirstStepValid = false;
    }
    return isFirstStepValid;
  }

  // Customizable Area Start
  getAddressDetails = (pin: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTravelLocationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pinCodeMaster_url + "?pin_code=" + pin
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getPolicyPageDetails = () => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
       
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTravelPolicyDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.travelPolicyDetails + `?name=ICICI Lombard Travel Insurance`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }


  createPayOrder = async () => {
    this.handleDrawerClose();
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
      amount: this.state.policyDetailsForm.premium,
      currency: 'INR',
      plan_id: 1,
      account_id: parseInt(this.state.policyDetails.id),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTravelOrderAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_order_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

  issueTravelPolicy = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let oneYearFromNow = new Date();
    oneYearFromNow.setDate(oneYearFromNow.getDate() - 1);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    console.log(moment(oneYearFromNow).format("DD/MM/YYYY"))
    let merchant_id = localStorage.getItem('merchantId')

    let BaseUrl = localStorage.getItem('base_url')
    console.log(BaseUrl)
    const httpBody = {
      data: {
        account_id: parseInt(this.state.policyDetails.id),
        transaction_id: parseInt(this.state.transaction_id),
        payment_status: true,
        policy_end_date: moment(oneYearFromNow).format("DD/MM/YYYY"),
        policy_start_date: moment(this.state.policyDetails.currDate).format("DD/MM/YYYY"),
        base_url: BaseUrl,
        merchant_id
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.IssueTravelPolicyAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.travelIssuePolicy

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateTravelCommission = () => {
    let merchant_id = localStorage.getItem('merchantId')
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = this.state.Account_ID
    let transactionDate = localStorage.getItem('transactionDate');
    let policyAmount: any = localStorage.getItem('policyAmount');
    let product_id: any = localStorage.getItem('product_id');
    const httpBody = {
      "data": {
        "account_id": parseInt(account_id),
        "insurance_type": "Domestic Travel",
        "merchant_id": merchant_id,
        "date_of_purchase": transactionDate,
        "premium_paid": parseInt(policyAmount),
        "product_id": parseInt(product_id),


      }


    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateTravelCommissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateCommissionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getTravelPolicySms = (id: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    }; 
    const httpBody = {

      "data": {
        "account_id": parseInt(id),
        "policy_name": "Travel",
        "platform": "Tappmart"
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPolicySmsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPolicySmsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  sendMessages = (status: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = this.state.Account_ID
 
    const httpBody = {
      "data": {
        "account_id": parseInt(account_id),
        "platform": "Tappmart",
        "payment_status": status,
        "policyNumber": this.state.policy_Number
      }


    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessagesApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendMessagesUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
}