import React, { Component } from "react";

// Customizable Area Start
import {
  Grid,
  Button,

} from "@material-ui/core";
import {

  withStyles,
} from "@material-ui/core/styles";

import {
  tapsureLogo,
  premiumIcon,
  backgroundImage,
  backIcon,
} from "./assets2";
import '../assets/policyDetails.css'
import CustomerHealthServiceController from "./CustomerHealthServiceController";
// Customizable Area End

// Customizable Area Start
export default class CustomerHealthServicePlanList extends CustomerHealthServiceController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item>
            <img 
              src={tapsureLogo} 
              className="tappMartLogoStylePlan"
              onClick={() => this.redirectToComponent("CustomerHomeScreen", '')}
              data-test-id="home-screen"
            />
          </Grid>
        </Grid>{" "}
        <MainContainerHS container spacing={2}>
          {/* Arrow Container */}
          <Grid container>
            <Grid>
            <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ marginTop: "15px" }}
                  onClick={this.handleBack}
                />
              </Button>
              <h3 style={{ color: "white", marginLeft: "15px" }}>
                {'Care Health Service'
                  
                }</h3>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          {
            this.state.policyList.map((data, index) => {
              return (
                <Grid container style={childContainerHS} key={index}>
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      
                    </div>
                  </Grid>

                  {/* Policy Cards */}

                  <Grid item sm={12} xs={12} lg={12}>
                    <Grid container direction="row" justifyContent="space-between"
                      style={{ flexWrap: "nowrap", marginTop: 5 }}>
                        
                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Service</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.amount}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"


                        >
                          <Grid item>
                            <img src={premiumIcon} height="20px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Premium</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>Rs{data.premium}</h3>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => this.redirectToComponent('CustomerHealthServiceDetailsStep', data)}
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              )
            })
          }


           
        </MainContainerHS>
      </>
    );
  }
}

// Customizable Area Start

const MainContainerHS:any = withStyles({
  root: {
    backgroundImage:
      `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "5px",
    marginTop: "10px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  },
})(Grid);

const childContainerHS = {
  padding: "10px",
  font: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",


  borderRadius: "5px",

  margin: "15px",
  backgroundColor: "white",
};

// Customizable Area End
