import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import {
    tapsureLogo
  } from "./assets2";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    activeStep: number;
    steps: any;
    policyType: string;
    value: number;
    openPolicyDetailDrawer: boolean;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    phoneNumber: string;
    pinCode: string;
    address: string;
    // Customizable Area Start
   
    contactDetailsForm: {
        email: string;
        phoneNumber: string;
        addressline2: string;
        addressline1: string;
        city: string;
        pinCode: string;
    };
    personalDetailsForm: {
        title: string;
        firstName: string;
        lastName: string;
        gender: string;
        dateOfBirth: string;
    };
    city: string;
    inValidpinCode: boolean;
    loading: boolean;
    policyList: any[];
    isErrorPersonal: any;
    isErrorContact: any;
    isErrorPolicy: any;
    state: string;
    pay_description: any;
    orderDetails: any;
    openToast: boolean;
    toastMessage: string;
    toastType: string;
    policyTabsData: any;
    customerDetails: any;
    transaction_id: any;
    openModal: boolean;
    maxYear: string;
    id: any;
    upperYear: string;
    policyNum: any;
    stopPaymentApi: any;
    policyDetailsForm: {
        sumToBeInsured: string;
        policyStartDate: string;
        gstin: string;
        premium: string;
    };
    // Customizable Area End
}

interface SS {
    id: any;
}
declare global {
    interface Window {
      loadBillDeskSdk?: any;
    }
  }
export default class CustomerHealthServiceController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    generatePartnerToken: any;
    issueCarePolicyApiCallId: any;
    // Customizable Area Start
    PinCodeVerifyAPICalled: any;
    getHospiCashPolicyTabsID: any;
    createOrderAPICalledID: any;
    policyCustomerAPICalledID: any;
    updateOrderAPICalledID: any;
    proposalNumberAPICalledID: any;
    IssuePolicyAfterProposalNumber:any;
    verifyOrderAPICalledID: any;
    generateCommissionId: any;
    sendMessagesApi: any;
    getPolicySmsId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            activeStep: 0,
            steps: [
                "Personal Details",
                "Contact Details",
                "Policy Details",
            ],
            // Customizable Area Start
            policyType: "Comprehensive",
            value: 0,
            openPolicyDetailDrawer: false,
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            email: "",
            phoneNumber: "",
            pinCode: "201301",
            address: "A-110, Sector 5, Noida, Uttar Pradesh. 201301",
            personalDetailsForm: {
                title: "",
                firstName: "",
                lastName: "",
                gender: "",
                dateOfBirth: "",
            },
            contactDetailsForm: {
                email: "",
                phoneNumber: "",
                addressline1: "A-110, Sector 5, Noida, Uttar Pradesh. 201301",
                addressline2: "",
                city: "Noida",
                pinCode: "201301",
            },
            city: 'Noida',
            inValidpinCode: false,
            loading: false,
            policyDetailsForm: {
                sumToBeInsured: "",
                policyStartDate: "",
                gstin: "",
                premium: "",
            },
            isErrorPersonal: {},
            isErrorContact: {},
            isErrorPolicy: {},
            state: 'Uttar Pradesh',
            policyList: [

                {
                    id: 2,
                    amount: 'Doctor On Call',
                    premium: '198'
                },

            ],
            pay_description: "",
            orderDetails: {},
            openToast: false,
            toastMessage: "",
            toastType: "",
            policyTabsData: {},
            customerDetails: {},
            transaction_id: 0,
            openModal: false,
            maxYear:"",
            id:null,
            upperYear:"",
            policyNum:0,
            stopPaymentApi: true
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }




    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson: any = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse: any = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId != null) {
              switch (true) {
                case apiRequestCallId === this.PinCodeVerifyAPICalled:
                  this.setLocationApi(responseJson,errorReponse)
                  break;
                case apiRequestCallId === this.policyCustomerAPICalledID:
                  this.setDoctorOnCallCustomer(responseJson, errorReponse)
                  break;
                case apiRequestCallId === this.createOrderAPICalledID:
                  this.setCreateOrderApi(responseJson, errorReponse)
                  break;
                case apiRequestCallId === this.verifyOrderAPICalledID:
                  this.verifyOrderApi(responseJson, errorReponse)
                  break;
                case apiRequestCallId === this.proposalNumberAPICalledID:
                  this.issueDocOnCallApi(responseJson, errorReponse)
                  break;
                  case apiRequestCallId === this.IssuePolicyAfterProposalNumber:
                    this.issuePolicyAfterPro(responseJson, errorReponse)
                    break;
                case apiRequestCallId === this.getHospiCashPolicyTabsID:
                  this.getDocOnCallTabsApi(responseJson, errorReponse)
                  break;
                case apiRequestCallId === this.generateCommissionId:
                  this.setCommisionApi(responseJson, errorReponse)
                  break;
                default:
              }
            
            }
          }
       
       
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getHospiCashPolicyDetails()

        const planDetails = localStorage.getItem("planDetails");
        let PlanDetails
        if (planDetails !== null) {
            PlanDetails = JSON.parse(planDetails);
            this.setState({
                policyDetailsForm: {
                    ...this.state.policyDetailsForm, ['sumToBeInsured']: PlanDetails.amount,
                    ['premium']: PlanDetails.premium
                }
            });
        }
        if (window.location.href.includes("CustomerHealthServicePolicyTabs")) {

            let url = new URL(window.location.href)

            let merchantId: any = url.searchParams.get("merchantId");
            let product_id: any = url.searchParams.get("product_id");
            if (merchantId && product_id) {
                localStorage.setItem('merchantId', merchantId)
                localStorage.setItem('product_id', product_id)
            }

        }
        let todaysDate = this.formatDate(new Date());
        this.validateDateOfBirth(todaysDate)
    }

    setLocationApi = (responseJson:any,errorReponse:any) => {
        if (responseJson) {
            this.setState({ loading: false });
            this.setState({
                city: responseJson.city_name,
                state: responseJson.state_name,
                inValidpinCode: false
            })
        } else if (responseJson === undefined) {
            
            this.setState({ loading: false });
            this.setState({
                city: '',
                inValidpinCode: true
            })
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }

    setDoctorOnCallCustomer = (responseJson:any,errorReponse:any) => {
        if (responseJson && !responseJson.errors && responseJson.data) {
            this.setState({ customerDetails: responseJson.data,id:responseJson?.data?.id, pay_description:responseJson.data.attributes.product.id });
            localStorage.setItem("customerAccountID",responseJson?.data?.id)
            this.getPolicySms()
            localStorage.setItem("contact-id", JSON.stringify(responseJson.data.id));
            this.setState({ loading: false });
            runEngine.debugLog("arrayHolder", this.state.customerDetails);
        } else {
            
            this.setState({
                openPolicyDetailDrawer: !this.state.openPolicyDetailDrawer
            });
            this.setState({ loading: false, openToast: true, toastMessage: responseJson.error, toastType: "error" });
            this.setState({ loading: false });
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }

    setCreateOrderApi = (responseJson:any,errorReponse:any) => {
       
        if (responseJson.data && responseJson.data[0].message !== "Internal server error") {
            console.log(responseJson.data[0].orderid);
            console.log(responseJson.data[0].message)
            console.log(responseJson.data[0].bdorderid);
            
            if (responseJson.transaction_id) {
              this.setState({
                transaction_id: responseJson.transaction_id
              })
              setInterval(() => {
                if (this.state.stopPaymentApi === true) {
                  this.verifyPayment(responseJson.data[0].orderid, responseJson.data[0].bdorderid, responseJson.transaction_id)
                }
              }, 3000)
      
            }
      
            const flow_configHS = {
              merchantId: responseJson.data[0].mercid,
              bdOrderId: responseJson.data[0].bdorderid,
              authToken: responseJson.data[0].links[1].headers.authorization,
              childWindow: true, 
              retryCount: 3,
              prefs: { "payment_categories": ["card", "emi", "nb", "upi", "wallets", "qr", "gpay"] }
            }
      
            const responseHandlerHS = function (txn: any) {
              console.log("callback received status:: ", txn.status)
              
            }
            const config = {
              responseHandler: responseHandlerHS,
              merchantLogo: tapsureLogo,
              flowConfig: flow_configHS,
              flowType: "payments"
            }
            console.log("HS", config.responseHandler)
            setTimeout(()=>{
                window.loadBillDeskSdk(config)
              })
    
            this.setState({ loading: false, openPolicyDetailDrawer: false });
            runEngine.debugLog("arrayHolder", this.state.orderDetails);
          } else {
            this.setState({ loading: false, openToast: true, toastMessage: "Try After Some Time", toastType: "error" });
            this.setState({ loading: false });
            setTimeout(()=>{
                this.setState({
                    openToast: false
                })
            },3000)
            this.parseApiCatchErrorResponse(errorReponse);
          }
    }

    verifyOrderApi = (responseJson:any,errorReponse:any) => {
        if (responseJson) {

            console.log(responseJson.payment_status);
            if (responseJson.payment_status === "success") {
              console.log("srccc")
              this.setState({
                stopPaymentApi: false
              })
              this.getPropsalNumber()
            }
      
          } else {
      
            this.setState({ loading: false });
            this.parseApiCatchErrorResponse(errorReponse);
          }
    }

    issueDocOnCallApi = (responseJson:any,errorReponse:any) => {
        console.log(responseJson.proposal_num)
        if(responseJson.proposal_num){
            this.issuePolicyDOC(responseJson.proposal_num)  
            localStorage.setItem("transactionDate",responseJson?.policy_start_date); 
            localStorage.setItem("policyEndDate", responseJson?.policy_end_date);
        } else {
              this.setState({ loading: false, openToast: true, toastMessage: "", toastType: "error",openModal: true  });
              this.parseApiCatchErrorResponse(errorReponse);
          }
          
    }
    issuePolicyAfterPro = (responseJson:any,errorReponse:any) => {
        if (responseJson && responseJson?.response?.responseData?.message != "Failed" && responseJson?.response != null  ) {
        
                  console.log("doc"+ responseJson)
                  console.log(responseJson?.response?.chequeDDReqResIO?.policyNum)
                  localStorage.setItem("ownername", this.state.personalDetailsForm.firstName + " " + this.state.personalDetailsForm.lastName);
                  localStorage.setItem("base_url", responseJson?.base_url)
                  localStorage.setItem("orderId", responseJson?.response?.chequeDDReqResIO?.policyNum);
                  localStorage.setItem("policyType", 'DoctorOnCall');
                  localStorage.setItem("policyAmount", this.state.policyDetailsForm.premium);
                  localStorage.setItem("membershipNumber", responseJson?.response?.chequeDDReqResIO?.policyNum);  
                  localStorage.setItem("proposalNum", responseJson?.response?.chequeDDReqResIO?.policyNum);
                  this.setState({
                      policyNum:responseJson?.response?.chequeDDReqResIO?.policyNum,
                  })
                  if(localStorage.getItem('merchantId')){
                      this.generateCommission()
                  }
                   
            
              
              this.setState({ loading: false, openToast: true, toastMessage: "Policy Issued Successfully.", toastType: "success" });
              setTimeout(() => {
              this.redirectToSuccessPage();
         
              }, 2000)
              
          } else {
              this.setState({ loading: false, openToast: true, toastMessage: "", toastType: "error",openModal: true  });
              let merchant_id=localStorage.getItem('merchantId')
               if(merchant_id === "null"  || merchant_id === null){
              setTimeout(() => 
              this.props.navigation.navigate("CustomerHomeScreen")
                ,3000)
             } else{
              setTimeout(() => 
              this.props.navigation.navigate("MerchantPolicyDetails")
                ,3000)
              }
              this.parseApiCatchErrorResponse(errorReponse);
          }
    }
    getDocOnCallTabsApi = (responseJson:any,errorReponse:any) => {
        if (responseJson && !responseJson.error && responseJson?.data) {
            this.setState({
                policyTabsData: responseJson?.data.attributes,
                loading: false
            })
        }
        else {
            this.setState({ loading: false })
           
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }

    setCommisionApi = (responseJson:any,errorReponse:any) => {
        if (responseJson && !responseJson.error && responseJson?.data) {
            console.log("RESSS",responseJson)
           }
            else {
             this.setState({loading:false})
            
             this.parseApiCatchErrorResponse(errorReponse);
           }
    }
    handleActiveStep = () => {
        let step = this.state.activeStep + 1
        if (step == 1) {
            this.setState({ isErrorPersonal: this.validatePersonal(this.state.personalDetailsForm) },
                () => {
                    const { title, firstName, lastName, gender, dateOfBirth } = this.state.personalDetailsForm;
                    if (!this.state.isErrorPersonal.firstName && !this.state.isErrorPersonal.lastName && !this.state.isErrorPersonal.dateOfBirth) {
                        if (title && firstName && lastName && gender && dateOfBirth) {
                            this.setState({ activeStep: step });
                        }
                    }
                }
            );
        } else if (step == 2) {
            const { email, phoneNumber, addressline1, pinCode } = this.state.contactDetailsForm;
            this.setState({ isErrorContact: this.validateContact(this.state.contactDetailsForm) }, () => {
                if (!this.state.isErrorContact.email && !this.state.isErrorContact.phoneNumber) {
                    if (email && phoneNumber) {
                        this.setState({ activeStep: step });
                    }
                }
            });

        }
        else {
            this.setState({
                activeStep: step
            })
        }
    };

    handleBack = () => {
        if (this.state.activeStep > 0) {
            this.setState({ activeStep: this.state.activeStep - 1 })
        }
        else {
            this.props.navigation.goBack();
        }

    };
    RedirectToComponent = (component: string) => {
        this.props.navigation.navigate(component);
    };
   
    handleNext = () => {
        console.log("ccbj");
        let val = this.state.value + 1;
        if (val == 3) {
            this.props.navigation.navigate("CustomerDetailsSteps");
        } else {
            this.setState({ value: val });
        }

    }
    handleProceed = (component: string) => {
        if (component) {
            this.props.navigation.navigate("CustomerPolicyTabs");
        }
        else {
            this.setState({ isErrorPolicy: this.validatePolicy(this.state.policyDetailsForm) },
                () => {
                    const { sumToBeInsured } = this.state.policyDetailsForm;
                    if (sumToBeInsured) {
                        this.setState({ openPolicyDetailDrawer: true });
                        this.setState({ loading: true });
                        const headers = {
                            "Content-Type": configJSON.categoryApiContentType,
                        };
                        let merchant_id=localStorage.getItem('merchantId');
                        
  
                        const httpBody = {
                            data: {
                                "id": this.state.id,
                                "title": this.state.personalDetailsForm.title,
                                "first_name": this.state.personalDetailsForm.firstName,
                                "last_name": this.state.personalDetailsForm.lastName,
                                "gender": this.state.personalDetailsForm.gender,
                                "date_of_birth": this.state.personalDetailsForm.dateOfBirth,
                                "email": this.state.contactDetailsForm.email,
                                "full_phone_number": "91" + this.state.contactDetailsForm.phoneNumber,
                                "address": "A-110, Sector 5, Noida, Uttar Pradesh. 201301",
                                "city": "Noida",
                                "pincode": 201301,
                                "sum_to_be_insured": "001",
                                "new_policy_start_date": new Date(),
                                "gst_in": "yhjhdkhkd",
                                "phone_number": this.state.contactDetailsForm.phoneNumber,
                                "country_code": "+91",
                                "contact_detail": {
                                    "addressLine1Lang1": "A-110, Sector 5,",
                                    "stateCd": "UTTAR PRADESH",
                                    "cityCd": "Noida",
                                    "pinCode": "201301"

                                },
                                merchant_id,
                                insurance_type: "Care Health Service",
                            }
                        };
                        localStorage.setItem("policyDetails", JSON.stringify(httpBody.data));
                        const requestMessage = new Message(
                            getName(MessageEnum.RestAPIRequestMessage)
                        );

                        this.policyCustomerAPICalledID = requestMessage.messageId;

                        requestMessage.addData(
                            getName(MessageEnum.RestAPIResponceEndPointMessage),
                            configJSON.customer_policy_url
                        );

                        requestMessage.addData(
                            getName(MessageEnum.RestAPIRequestHeaderMessage),
                            JSON.stringify(headers)
                        );
                        requestMessage.addData(
                            getName(MessageEnum.RestAPIRequestBodyMessage),
                            JSON.stringify(httpBody)
                        );
                        requestMessage.addData(
                            getName(MessageEnum.RestAPIRequestMethodMessage),
                            configJSON.httpPostType
                        );
                        runEngine.sendMessage(requestMessage.id, requestMessage);
                        return true;
                    }
                });
        }

    }
    handlePolicyTabsChange = (event: any, value: any) => {
        this.setState({
            value: value
        })
    }
    handleFirstName = (e: any) => {
        this.setState({
            firstName: e.target.value
        })
    }

    handleLastName = (e: any) => {
        this.setState({
            lastName: e.target.value
        })
    }

    handleDOB = (e: any) => {
        this.setState({
            dateOfBirth: e.target.value
        })
    }
     
    handleEmail = (e: any) => {
        this.setState({
            email: e.target.value
        })
    }
    handlePhoneNumber = (e: any) => {
        this.setState({
            phoneNumber: e.target.value
        })
    }
    handlePinCode = (e: any) => {
        this.setState({
            pinCode: e.target.value
        })
    }
    handleAddress = (e: any) => {
        this.setState({
            address: e.target.value
        })
    }
 handleChangePersonal = (e: any) => {
        this.setState({
            personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
        });
        if (e.target.name === 'gender') {
            if (e.target.value === 'Male') {
                this.setState({
                    personalDetailsForm: {
                        ...this.state.personalDetailsForm, ['title']: 'mr',
                        [e.target.name]: e.target.value
                    }
                });
            } else if (e.target.value === 'Female') {
                this.setState({
                    personalDetailsForm: {
                        ...this.state.personalDetailsForm, ['title']: 'ms',
                        [e.target.name]: e.target.value
                    }
                });
            }
        }
    };

    handleChangeContact = (e: any) => {
        this.setState({
            contactDetailsForm: { ...this.state.contactDetailsForm, [e.target.name]: e.target.value }
        });
        if (e.target.name === 'email') {
            this.setState({
                contactDetailsForm: { ...this.state.contactDetailsForm, ['email']: e.target.value.trim() }
            });
        }
        if (e.target.name === 'pinCode' && e.target.value.length == 6) {
            this.handleChangePincode(e.target.value);
        } else if (e.target.value.length === 0) {
            this.setState({
                city: ''
            });
        }
    };

    handleChangePincode = (pinCode: any) => {
        this.getAddressDetails(pinCode);
    };

    getAddressDetails = (pinCode: any) => {

        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.PinCodeVerifyAPICalled = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.pinCodeMaster_url + "?pin_code=" + pinCode
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    handleChangePolicy = (e: any) => {
        this.setState({
            policyDetailsForm: { ...this.state.policyDetailsForm, [e.target.name]: e.target.value }
        });
        if (e.target.name === 'policyStartDate') {
            var oneYearFromNow = new Date(e.target.value);
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            //   this.setState({ EndDate: oneYearFromNow })
        }
        if (e.target.name === 'sumToBeInsured') {
            this.setState({
                policyDetailsForm: {
                    ...this.state.policyDetailsForm, ['premium']: e.target.value === '500' ? "299"
                        : e.target.value === '1000' ? "599" : "999",
                    ['sumToBeInsured']: e.target.value
                }
            });
        }
    };

    validatePersonal = (values: any) => {
        const date1 :any= (new Date()).toISOString().split('T')[0];
        const date2 :any= values.dateOfBirth;
        const startDate = Date.parse(date1);
        const endDate = Date.parse(date2);
        const diffTime = Math.abs(startDate - endDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log(diffTime + " milliseconds");
        console.log(diffDays + " days",date1,date2);
        const errors: any = {};
        const regexName = /^[A-Za-z +]+$/;
        if (!values.title) {
            errors.title = "Title is required!";
        }
        if (!values.firstName) {
            errors.firstName = "First name is required!";
        } else if (!regexName.test(values.firstName)) {
            errors.firstName = "Please enter alphabetical characters!";
        }
        if (!values.lastName) {
            errors.lastName = "Last name is required!";
        } else if (!regexName.test(values.lastName)) {
            errors.lastName = "Please enter alphabetical characters!";
        }
        if (new Date(values.dateOfBirth) > new Date()) {
            errors.dateOfBirth = "Please enter past date";
        }
        if (!values.gender) {
            errors.gender = "Gender is required!";
        }
        if (!values.title) {
            errors.title = "Title is required!";
        }
        if (!values.dateOfBirth) {
            errors.dateOfBirth = "Date Of Birth is required!";
        }
        if (diffDays < 6574) {
            errors.dateOfBirth = "Your age should be more than 18 years.";
        }
        if ( diffDays > 24104) {
            errors.dateOfBirth = "Your age should not be more than 66 years.";
        }
        return errors;
    };

    validateContact = (values: any) => {
        const { phoneNumber } = this.state.contactDetailsForm;
        const errors: any = {};
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regexPhone = /^(\+\d{1,3}\s?)?(\()?[^0-5]\d{2}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
        
        
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regexEmail.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required!";
        } else if (!regexPhone.test(values.phoneNumber)) {
            errors.phoneNumber = "This is not a valid phone number!";
        } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
            errors.phoneNumber = "This is not a valid phone number!";
        }
        
        return errors;
    };
    handleNextView = () => {
        this.props.navigation.navigate("CustomerHealthServicePlanList");
    };
    redirectToComponent = (component: string, data: any) => {
        localStorage.setItem("planDetails", JSON.stringify(data));
        this.props.navigation.navigate(component);
    };
 
    getHospiCashPolicyDetails = () => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHospiCashPolicyTabsID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.oneAssistPolicyTabsEndPoint + "?name=DoctorOnCall"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    createPayOrder = async () => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        const httpBody = {
            
            amount: this.state.policyDetailsForm.premium,
            account_id: parseInt(this.state.customerDetails.id), product_id:this.state.pay_description
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createOrderAPICalledID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.create_order_url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    verifyPayment = (orderid: any, bdOrderId: any, transaction_id: any) => {
      
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        const httpBody = {
            "account_id": parseInt(this.state.customerDetails.id),
      "transaction_id": transaction_id,
      "order_id": orderid,
      "bdorder_id": bdOrderId
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.verifyOrderAPICalledID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.verify_signature_url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
 
    
     getPropsalNumber = () => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        var oneYearFromNow = new Date();
        oneYearFromNow.setDate(oneYearFromNow.getDate() - 1);
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        console.log(oneYearFromNow, 'oneYearFromNow');
        const httpBody = {
            data: {
                account_id: parseInt(this.state.customerDetails.id),
                transaction_id: parseInt(this.state.transaction_id),
                payment_status: true,
                policy_end_date: moment(oneYearFromNow).format("DD/MM/YYYY"),
                policy_start_date: moment().format("DD/MM/YYYY"),total_premium:this.state.policyDetailsForm.premium,
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.proposalNumberAPICalledID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.healthServiceUrl + "?id=" + parseInt(this.state.customerDetails.id)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            (headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    issuePolicyDOC = (propsalNumber:any) => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
       
        const httpBody = {
            
                "proposal_num": propsalNumber,
                "account_id": parseInt(this.state.customerDetails.id)
            
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.IssuePolicyAfterProposalNumber = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.issueAfterProposalDOC
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            (headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    validatePolicy = (values: any) => {
        const errors: any = {};
        if (!values.sumToBeInsured) {
            errors.sumToBeInsured = "Sum Insured is required!";
        }
        if (!values.policyStartDate) {
            errors.policyStartDate = "Policy start date is required!";
        }
        let newDate = new Date(Date.now() - (3600 * 1000 * 24))
        if (new Date(values.policyStartDate) < newDate) {
            errors.policyStartDate = "Please enter future date";
        }
       
        return errors;
    };

    redirectToSuccessPage = () => {
        this.props.navigation.navigate("CustomerCareOrderSucces");
    }

    redirectToHomeScreen = () => {
        this.props.navigation.navigate('CustomerHomeScreen');
    }

    closeModel = () => {
        this.setState({
            openModal: false
        })
        this.redirectToHomeScreen()
    }

    generateCommission = () => {
        let merchant_id = localStorage.getItem('merchantId')
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        let account_id: any = parseInt(this.state.customerDetails.id)
        let transactionDate = localStorage.getItem('transactionDate');
        let policyAmount: any = localStorage.getItem('policyAmount');
        let product_id: any = localStorage.getItem('product_id');
        const httpBody = {
            "data": {
                "account_id": parseInt(account_id),
                "insurance_type": "Care Hospicash",
                "merchant_id": merchant_id,
                "date_of_purchase": transactionDate,
                "premium_paid": parseInt(policyAmount),
                "product_id": parseInt(product_id),
            }
      };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.generateCommissionId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.generateCommissionUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            (headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.assure_methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    padTo2Digits = (num: any) => {
        return num.toString().padStart(2, "0");
    };
    formatDate = (date: any) => {
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join("/");
    };
    validateDateOfBirth = (tday: any) => {
        let todaysDate = tday;
        let tYear = tday.split("/")[2];
        let maxYear = parseInt(tYear) - 18;
        let dateString = new Date()
        let currentMonth = dateString.getMonth() + 1
        let currentDate = dateString.getDate()
        let upperYear = parseInt(tYear) - 65;
        this.setState({ maxYear: maxYear + `-${currentMonth}-${currentDate}`, upperYear: upperYear + `-${currentMonth}-${currentDate}` })
    }
    getPolicySms = () => {

        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        let account_id: any = parseInt(this.state.customerDetails.id)
        const httpBody = {

            "data": {
                "account_id": parseInt(account_id),
                "policy_name": "DoctorOnCall",
                "platform": "Tappmart"
            }

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPolicySmsId = requestMessage.messageId;
        console.log(this.getPolicySmsId)

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPolicySmsUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            (headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.assure_methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    sendMessages = (status: any) => {

        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
        };
        let account_id: any = parseInt(this.state.customerDetails.id)

        const httpBody = {
            "data": {
                "account_id": parseInt(account_id),
                "platform": "Tappmart",
                "payment_status": status,
                "policyNumber": this.state.policyNum
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendMessagesApi = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendMessagesUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            (headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.assure_methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    // Customizable Area End
}
