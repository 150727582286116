import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from "@material-ui/core/Backdrop";
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Paper,
  Button,
  Container,
  Typography,
  TextField,
   
  CircularProgress
} from "@material-ui/core";
import {
  close,
  background,
} from "../../mobile-account-registration/src/assets";
import { Step, Stepper, StepLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AdditionalDetailFormController from "./AdditionalDetailFormController";
// Customizable Area End

// Customizable Area Start
class MerchantBasicDetails extends AdditionalDetailFormController {

  render() {
    const { firstName, lastName, email, addressOne} = this.state.personalFormData;
    const { bankName, accountNumber, confirmAccountNumber, ifscCode } = this.state.bankFormData;
    const steps = ["Basic Details", "Bank Details", "Upload Documents"];
    return (
      <>
      <Snackbar open={this.state.openToast} autoHideDuration={1000} style={{ margin: "10px" }}>
          <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
            {this.state.toastMessage}
          </Alert>
        </Snackbar>
        <div style={style.image}>
          {!this.state.isLoadingDetails ?
            <>
            {/*@ts-ignore */}
              <FormStepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label: any) => (
                  <Step key={label}>
                    {/*@ts-ignore */}
                    <CustomStepLabel>
                      <StepLabel>{label}</StepLabel>
                    </CustomStepLabel>
                  </Step>
                ))}
              </FormStepper>

              {/* Personal Details */}

              {this.state.activeStep === 0 && (
                <Container maxWidth="sm">
                  <Paper elevation={2}>
                    {/*@ts-ignore */}
                    <Box sx={style.container}>
                      <form onSubmit={this.handleSubmitPersonal}>
                        <Typography variant="h6">
                          <b>Personal Details</b>
                        </Typography>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            First Name
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            error={this.state.isErrorPersonal.firstName ? true : false}
                            onChange={this.handleChangePersonal}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorPersonal.firstName}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Last Name
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            error={this.state.isErrorPersonal.lastName ? true : false}
                            onChange={this.handleChangePersonal}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorPersonal.lastName}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Email ID
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Email ID"
                            name="email"
                            value={email}
                            error={this.state.isErrorPersonal.email ? true : false}
                            onChange={this.handleChangePersonal}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorPersonal.email}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Phone Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={this.props.mobile}
                            onChange={this.handleChangePersonal}
                          />
                        </Box>
                        <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Merchant Address
              </CustomInputLabel>
              {/*@ts-ignore */}
              <CustomInputField
                id="outlined-basic"
                placeholder=" Merchant Address "
                variant="outlined"
                name="addressOne"
                value={addressOne}
                error={this.state.isErrorPersonal.email ? true : false}
                onChange={this.handleChangePersonal}
                
              />
                 <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorPersonal.addressOne}
                          </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              {/*@ts-ignore */}
              <CustomInputLabel htmlFor="component-simple">
                Merchant Type
              </CustomInputLabel>
              {/*@ts-ignore */}
              <CustomFormControl variant="outlined">
                {/*@ts-ignore */}
                <CustomSelect
                  native
                  value={this.state.merchantType}
                  name="merchantType"
                  onChange={this.handleMerchant}
                  inputProps={{
                    name: "age",

                    id: "outlined-age-native-simple",
                  }}
                  //error={!this.state.isRelationshipValid}
                >
                  <option aria-label="None" value="">
                    Select One
                  </option>
                  <option value={"Sales Partner"}>Sales Partner</option>
                  <option value={"Chemist"}>Chemist</option>
                  <option value={"Jewellery shops"}>Jewellery shops</option>
                  <option value={"Grocery"}>Grocery</option>
                  <option value={"Restaurants"}>Restaurants</option>
                  <option value={"Footwear shops"}>Footwear shops</option>
                  <option value={"Fashion and accessories"}>Fashion and accessories</option>
                  <option value={"Garments"}>Garments</option>
                  <option value={"Electronics"}>Electronics</option>
                  <option value={"Beauty and wellness"}>Beauty and wellness</option>
                  <option value={"Entertainment"}>Entertainment</option>
                  <option value={"Education services"}>Education services</option>
                  <option value={"Health and medical care"}>Health and medical care</option>
                  <option value={"Retail and shopping"}>Retail and shopping</option>
                  <option value={"stationery"}>stationery</option>
                  <option value={"Travel and transport"}>Travel and transport</option>
                  <option value={"Utility"}>Utility</option> 
                  <option value={"Mobile shops"}>Mobile shops</option>
                  <option value={"Home furnishing"}>Home furnishing</option>
                  <option value={"Pan shops"}>Pan shops</option>
                  <option value={"Retailer and manufacturer"}>Retailer and manufacturer</option>
                  <option value={"Pet Stores"}>Pet Stores</option>
                  <option value={"Mechanical Shops"}>Mechanical Shops</option>
                  <option value={"Others"}>Others</option>
                  
                </CustomSelect>
                <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorPersonal.merchantType}
                          </div>
              </CustomFormControl>
            </Grid> 
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Sales Code
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // type="number"
                            value={this.props.salesCodeProp}
                            onChange={this.handleChangePersonal}
                          />
                        </Box>
                        <Button
                          style={style.nextBtn}
                          size="large"
                          fullWidth
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          <b>Next</b>
                        </Button>
                      </form>
                    </Box>
                  </Paper>
                </Container>
              )}

              {/* Bank Details */}

              {this.state.activeStep === 1 && (
                <Container maxWidth="sm">
                  <Paper elevation={2}>
                    {/*@ts-ignore */}
                    <Box sx={style.container}>
                      <form>
                        <Typography variant="h6">
                          <b>Bank Details</b>
                        </Typography>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Bank Name
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Bank Name"
                            name="bankName"
                            value={bankName}
                            onChange={this.handleChangeBank}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorBank.bankName}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Account Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Account Number"
                            type="number"
                            name="accountNumber"
                            value={accountNumber}
                            onChange={this.handleChangeBank}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorBank.accountNumber}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Confirm Account Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Confirm Account Number"
                            type="number"
                            name="confirmAccountNumber"
                            value={confirmAccountNumber}
                            onChange={this.handleChangeBank}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorBank.confirmAccountNumber}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            IFSC Code
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="IFSC Code"
                            name="ifscCode"
                            value={ifscCode}
                            onChange={this.handleChangeBank}
                          />
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorBank.ifscCode}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.buttonStyle}>
                          <Button
                            style={style.buttonPrev}
                            size="large"
                            fullWidth
                            variant="outlined"
                            onClick={this.handlePrev}
                          >
                            <b>Prev</b>
                          </Button>
                          <Button
                            style={style.buttonNext}
                            size="large"
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={this.handleSubmitBank}
                          >
                            <b>Next</b>
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Paper>
                </Container>
              )}

              {/* Upload Documents */}

              {this.state.activeStep === 2 && (
                <Container maxWidth="sm">
                    <Paper elevation={2}>
                      {/*@ts-ignore */}
                      <Box sx={style.container}>
                        <Typography variant="h6">
                          <b>Upload Documents</b>
                        </Typography>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            PAN Card
                          </Typography>
                          <div style={style.docContainer}>
                            {!this.state.showFilePan.name ? "Upload PAN Card" :
                            <div style={{marginLeft: -8,overflow:"hidden"}}>
                              <span style={style.docStyle}>
                                {this.state.showFilePan.name}
                                <img
                                  style={{marginLeft: 13, cursor: 'pointer'}}
                                  src={close}
                                  alt="close"
                                  height="10"
                                  width="10"
                                  onClick={() => this.setState({ showFilePan: [], isPanFileSelected: false })}
                                />
                              </span>
                            </div>}
                          </div>
                          {/*@ts-ignore */}
                          <Box sx={style.uploadBtn}>
                            <Button
                              style={style.btnColor}
                              variant="text"
                              component="label"
                            >
                              <input
                                hidden
                                type="file"
                                accept={"image/*, application/pdf"}
                                onChange={this.handleFileChangePan}
                              />
                              <b>Upload</b>
                            </Button>
                          </Box>
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorDoc.pan}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Cancelled Cheque
                          </Typography>
                          <div style={style.docContainer}>
                            {!this.state.showFileCheque.name ? "Upload Cancelled Cheque" :
                            <div style={{marginLeft: -8,overflow:"hidden"}}>
                              <span style={style.docStyle}>
                                {this.state.showFileCheque.name}
                                <img
                                  style={{marginLeft: 13, cursor: 'pointer'}}
                                  src={close}
                                  alt="close"
                                  height="10"
                                  width="10"
                                  onClick={() => this.setState({ showFileCheque: [], isChequeFileSelected: false })}
                                />
                              </span>
                            </div>}
                          </div>
                          {/*@ts-ignore */}
                          <Box sx={style.uploadBtn}>
                            <Button
                              style={style.btnColor}
                              variant="text"
                              component="label"
                            >
                              <input
                                hidden
                                type="file"
                                accept={"image/*, application/pdf"}
                                onChange={this.handleFileChangeCheque}
                              />
                              <b>Upload</b>
                            </Button>
                          </Box>
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorDoc.cheque}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.typography}>
                          <Typography gutterBottom variant="body1">
                            Aadhar Card
                          </Typography>
                          <div style={style.docContainer}>
                            {!this.state.showFileAadhar.name ? "Upload Aadhar Card" :
                            <div style={{marginLeft: -8,overflow:"hidden"}}>
                              <span style={style.docStyle}>
                                {this.state.showFileAadhar.name}
                                <img
                                  style={{marginLeft: 13, cursor: 'pointer'}}
                                  src={close}
                                  alt="close"
                                  height="10"
                                  width="10"
                                  onClick={() => this.setState({ showFileAadhar: [], isAadharFileSelected: false })}
                                />
                              </span>
                            </div>}
                          </div>
                          {/*@ts-ignore */}
                          <Box sx={style.uploadBtn}>
                            <Button
                              style={style.btnColor}
                              variant="text"
                              component="label"
                            >
                              <input
                                hidden
                                type="file"
                                accept={"image/*, application/pdf"}
                                onChange={this.handleFileChangeAadhar}
                              />
                              <b>Upload</b>
                            </Button>
                          </Box>
                          <div style={{marginTop: 5, color: 'red'}}>
                            {this.state.isErrorDoc.aadhar}
                          </div>
                        </Box>
                        {/*@ts-ignore */}
                        <Box sx={style.buttonStyle}>
                          <Button
                            style={style.buttonPrev}
                            size="large"
                            fullWidth
                            variant="outlined"
                            onClick={this.handlePrev}
                          >
                            <b>Prev</b>
                          </Button>
                          <Button
                            style={style.buttonSubmit}
                            size="large"
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={() => this.handleSubmitDoc()}
                          >
                            <b>Submit</b>
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                </Container>
              )}
            </>
          :
            <Backdrop
              style={{ zIndex: 1000 + 1, color: "#fff" }}
              open={this.state.isLoadingDetails}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const InputField = withStyles({
  root: {
    width: "100%",
  },
})(TextField)
const FormStepper = withStyles({
  root: {
    // marginTop: "15px",
    backgroundColor: "rgba(145, 90, 180, 0)",
  },
})(Stepper);
const CustomInputLabel = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);
const CustomInputField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField);
;

const CustomSelect = withStyles({
  root: {
    padding: "15px",
  },
})(Select);
const CustomFormControl = withStyles({
  root: {
    width: "100%",
    marginTop: 5,
  },
})(FormControl);


const CustomStepLabel = withStyles({
  root: {
    color: "white",
    "& .MuiStepLabel-labelContainer": {
      color: "white",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "white",
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "White",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "white",
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "transparent",
        border: "1px solid white",
        borderRadius: "12px",
      },
      "& .MuiStepIcon-root": {
        border: "1px solid #ada7a7",
        borderRadius: "12px",
        color: "transparent",
      },
    },
    "& .MuiStepLabel-label": {
      color: "white"
    }
  },
})(StepLabel);

// Personal Details 
const style = {
  image: {
    // height: '100%',
    paddingBottom: "30px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  container: {
    p: 2,
    display: "flex",
    flexDirection: "column",
  },
  docContainer: {
    padding: 18,
    border: "1px solid grey",
    borderRadius: 4,
    color: 'darkgray'
  },
  docStyle: {
    padding: 8,
    borderRadius: 5,
    backgroundColor: '#eeeef6',
    color: '#1636cb'
  },
  typography: {
    mt: 2,
  },
  nextBtn: {
    marginTop: "20px",
    padding: "15px",
    borderRadius: 8,
  },

  // Bank Details
  buttonStyle: {
    mt: 3,
    display: "flex",
    flexDirection: "row",
  },
  buttonPrev: {
    marginRight: "8px",
    padding: "15px",
    borderRadius: 8,
  },
  buttonNext: {
    marginLeft: "8px",
    padding: "15px",
    borderRadius: 8,
  },

  // Upload Documents
  buttonSubmit: {
    marginLeft: "8px",
    padding: "15px",
    borderRadius: 8,
  },
  uploadBtn: {
    marginLeft: "10px",
    marginTop: "-45px",
    float: "right"
  },
  btnColor: {
    color: "rgb(200,0,200,1)",
  },
};
// Customizable Area End
export default MerchantBasicDetails;
