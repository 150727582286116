import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  salesCodeProp?: any;
  oldProps?: any;
  mobile?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  flag: boolean;
  otpInput: string;
  token: string;
  isErrorOtp: any;
  formData: {
    mobile: string;
    salesCode: string;
  };
  getSalesCodes: any;
  seconds: number;
  otp: boolean;
  isError: any;
  activeStep: number;
  finalFlag: boolean;
  openTermsModal: boolean;
  openPrivacyModal: boolean;
  personalFormData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    addressOne:string;
    
  },
  merchantType:string;
  isAddressValid:boolean;
  isMerchantValid:boolean;
  
  isErrorPersonal: any;
  isErrorBank: any;
  isErrorDoc: any;
  bankFormData: {
    bankName: string;
    accountNumber: string;
    confirmAccountNumber: string;
    ifscCode: string;
  },
  documentFormData: {
    pan: string;
    cheque: string;
    aadhar: string;
  },
  showFilePan: any;
  showFileCheque: any;
  showFileAadhar: any;
  convertedPan: any;
  convertedCheque: any;
  convertedAadhar: any;
  isPanFileSelected: boolean;
  isChequeFileSelected: boolean;
  isAadharFileSelected: boolean;
  otpMatch: boolean;
  congrats: boolean;
  isLoading: boolean;
  isLoadingOtp: boolean;
  isLoadingDetails: boolean;
  openResendOtp: boolean;
  termsCondition: any;
  privacyPolicy: any;
  openToast:boolean;
  toastType:string,
  openErrorPage:boolean,
  toastMessage:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdditionalDetailFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  addAdditionalDetailApiCallId: any;
  confirmationCodeApiCallId:any
  signUpApiCallId: any;
  resendOtpApiCallId: any;
  otpVerifyApiCallId: any;
  userDetailsApiCallId: any;
  privacyPolicyApiCallId: any;
  validationApiCallId: string = "";

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  myInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.state = {
      openToast:false,
      toastMessage:"",
      toastType:"success",
      openErrorPage:false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      flag: false,
      otpInput: "",
      token: "",
      isErrorOtp: {},
      formData: {
        mobile: '',
        salesCode: ''
      },
      getSalesCodes: [],
      seconds: 50,
      otp: false,
      isError: {},
      activeStep: 0,
      finalFlag: false,
      openTermsModal: false,
      openPrivacyModal: false,
      personalFormData: {
        firstName: '',
        lastName: '',
        email: '',
        addressOne:"",
        
        phone: this.props.mobile,
      },
      merchantType:"",
      isAddressValid:true,
      isMerchantValid:true,
      
      isErrorPersonal: {},
      isErrorBank: {},
      isErrorDoc: {},
      bankFormData: {
        bankName: '',
        accountNumber: '',
        confirmAccountNumber: '',
        ifscCode: '',
      },
      documentFormData: {
        pan: "",
        cheque: "",
        aadhar: "",
      },
      showFilePan: [],
      showFileCheque: [],
      showFileAadhar: [],
      convertedPan: null,
      convertedCheque: null,
      convertedAadhar: null,
      isPanFileSelected: false,
      isChequeFileSelected: false,
      isAadharFileSelected: false,
      otpMatch: false,
      congrats: false,
      isLoading: false,
      isLoadingOtp: false,
      isLoadingDetails: false,
      openResendOtp: false,
      termsCondition: {},
      privacyPolicy: {}
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.addAdditionalDetailApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        
        if (apiRequestCallId === this.confirmationCodeApiCallId) {
          if (!responseJson.errors && !responseJson.error) {
            //this.setState({ otp: true });
            this.setState({ isLoading: false });
            this.saveLoggedInUserData(responseJson);
            if (this.state.seconds !== 0) {
              this.myInterval = setInterval(() => {
                this.setState({ seconds: this.state.seconds - 1 });
              }, 1000);
            }
            this.signUp();
            console.log("confirmationCodeApiCallId", responseJson, errorReponse);
          } else {
            //Check Error Response
            this.setState({ isLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.signUpApiCallId) {
          if (!responseJson.errors) {
            this.setState({ otp: true });
            this.setState({ isLoading: false });
            this.saveLoggedInUserData(responseJson);
            if (this.state.seconds !== 0) {
              this.myInterval = setInterval(() => {
                this.setState({ seconds: this.state.seconds - 1 });
              }, 1000);
            }
            console.log("signUpApiCallId", responseJson, errorReponse);
          } else {
            //Check Error Response
            this.setState({ isLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.otpVerifyApiCallId) {
          if (!responseJson.errors) {
            this.setState({ otpMatch: true });
            this.setState({ isLoadingOtp: false });
            clearInterval(this.myInterval);
            console.log("otpVerifyApiCallId", responseJson, errorReponse);
          } else {
            //Check Error Response
            this.setState({ isLoadingOtp: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.userDetailsApiCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log(responseJson)
            this.props.oldProps.setState({ congrats: true });
            let merhchantId=responseJson?.account?.full_phone_number
            let aID = responseJson?.account?.id
            localStorage.setItem('merchantId',merhchantId)
            localStorage.setItem('aID',aID)
            let newToken = responseJson.token;
            console.log("token" +newToken)
            localStorage.setItem('newToken',newToken)
            this.setState({ isLoadingDetails: false });
            // clearInterval(this.myInterval);
            this.saveLoggedInUserData(responseJson);
            console.log("userDetailsApiCallId", responseJson, errorReponse);
          } else {
            //Check Error Response
            this.setState({ isLoadingDetails: false });
            this.setState({ openToast: true, toastMessage: "Please Try After Some Time", toastType: "error" });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.privacyPolicyApiCallId) {
          if (!responseJson.errors) {
            // this.props.oldProps.setState({ congrats: true });
            this.setState({ isLoadingDetails: true });
            // clearInterval(this.myInterval);
            this.saveLoggedInUserData(responseJson);
            responseJson.data.forEach((element: any) => {
       
              console.log(element);
              if (element.attributes.title === "terms_and_condition") {
                this.setState({
                  termsCondition: element.attributes
                })
                 
                console.log(element);
                console.log(this.state.termsCondition)
              } else if (element.attributes.title === "privacy_policy") {
                this.setState({
                  privacyPolicy: element.attributes
                })
              }
            });
            // console.log("privacyPolicyApiCallId", this.state.termsCondition);
            this.setState({ isLoadingDetails: false });
          } else {
            //Check Error Response
            this.setState({ isLoadingDetails: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  // MerchantBasicDetails start
  handleFileChangePan = (e: any) => {
    var selectedFile = e.target.files;
    if (selectedFile.length > 0) {
      var fileToLoad = selectedFile[0];
      var fileReader: any = new FileReader();
      var base64;
      fileReader.onload = (fileLoadedEvent: any) => {
        base64 = fileLoadedEvent.target.result;
        let base64Image: any = base64.split(",").pop();
        // console.log("base64Image", base64Image);
        this.setState({ convertedPan: base64Image, showFilePan: selectedFile[0], isPanFileSelected: true });
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  handleFileChangeCheque = (e: any) => {
    var selectedFile = e.target.files;
    if (selectedFile.length > 0) {
      var fileToLoad = selectedFile[0];
      var fileReader: any = new FileReader();
      var base64;
      fileReader.onload = (fileLoadedEvent: any) => {
        base64 = fileLoadedEvent.target.result;
        let base64Image: any = base64.split(",").pop();
        // console.log("base64Image", base64Image);
        this.setState({ convertedCheque: base64Image, showFileCheque: selectedFile[0], isChequeFileSelected: true });
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  handleFileChangeAadhar = (e: any) => {
    var selectedFile = e.target.files;
    if (selectedFile.length > 0) {
      var fileToLoad = selectedFile[0];
      var fileReader: any = new FileReader();
      var base64;
      fileReader.onload = (fileLoadedEvent: any) => {
        base64 = fileLoadedEvent.target.result;
        let base64Image: any = base64.split(",").pop();
        // console.log("base64Image", base64Image);
        this.setState({ convertedAadhar: base64Image, showFileAadhar: selectedFile[0], isAadharFileSelected: true });
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handlePrev = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleChangePersonal = (e: any) => {
    this.setState({
      personalFormData: { ...this.state.personalFormData, [e.target.name]: e.target.value }
    });
  }

  // handleAddressOne = (e: any) => {
  //       this.setState({
  //         addressOne: e.target.value,
  //        isAddressValid:true
  //       });
  //     };

    handleMerchant = (e: any) => {
          this.setState({
              merchantType: e.target.value,
              isMerchantValid:true
          });
        };

  handleChangeBank = (e: any) => {
    this.setState({
      bankFormData: { ...this.state.bankFormData, [e.target.name]: e.target.value }
    });
  }

  handleSubmitPersonal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(this.state.merchantType)
   
    this.setState({ isErrorPersonal: this.validatePersonal(this.state?.personalFormData) },

      () => {
        const { firstName, lastName, email,addressOne } = this.state.personalFormData;
        if (!this.state?.isErrorPersonal?.firstName && !this.state?.isErrorPersonal?.lastName &&
          !this.state?.isErrorPersonal?.addressOne && !this.state?.isErrorPersonal?.merchantType) {
          if (firstName && lastName && email && addressOne && this.state.merchantType   !== "" ) {
            this.handleNext();
          }
        }
      },
      
    );

 
    // this.setState({
    //   isErrorPersonal:this.validateNewDetails()
    // },
    // ()=>{
    //   if(!this.state?.isErrorPersonal?.addressOne){
    //     if(this.state.addressOne !== ""){
    //       this.handleNext();
    //     }

    //   }
    // }
    // )
  }

  handleSubmitBank = () => {
    this.setState({ isErrorBank: this.validateBank(this.state.bankFormData) },
      () => {
        const { bankName, accountNumber, confirmAccountNumber, ifscCode } = this.state.bankFormData;
        if (!this.state?.isErrorBank?.bankName) {
          if (bankName && accountNumber && confirmAccountNumber && ifscCode && ifscCode.length === 11 && accountNumber === confirmAccountNumber) {
            this.handleNext();
          }
        }
      }
    );
  }

  handleSubmitDoc = () => {
    // console.log("this", this);
    this.setState({ isErrorDoc: this.validateDocument(this.state.documentFormData) });
    const { isPanFileSelected, isChequeFileSelected, isAadharFileSelected } = this.state;
    if (isPanFileSelected && isChequeFileSelected && isAadharFileSelected) {
      this.setState({ isLoadingDetails: true });
      this.userDetails();
    }
  }
// validateNewDetails = () => {
//     let isFirstStepValid = true;
//     if(this.state.addressOne.length>500 || this.state.addressOne=="" ){
//         this.setState({
//           isAddressValid:false
//         })
//         isFirstStepValid=false;
//       }
//     if(this.state.merchantType === ""){
//       this.setState({
//         isMerchantValid:false
//       })
//       isFirstStepValid=false;
//     }
//     return isFirstStepValid;
// }
  validatePersonal = (values: any) => {
    const errors: any = {};
    const regexName = /^[A-Za-z +]+$/;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
   
    if (!values.firstName) {
      errors.firstName = "Firstname is required!";
    } else if (!regexName.test(values.firstName)) {
      errors.firstName = "Please enter alphabetical characters!";
    }
    if (!values.lastName) {
      errors.lastName = "Lastname is required!";
    } else if (!regexName.test(values.lastName)) {
      errors.lastName = "Please enter alphabetical characters!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regexEmail.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.addressOne) {
      errors.addressOne = "Please Enter Address";
    }  
    if (!this.state.merchantType) {
      errors.merchantType = "Please select any one";
    }else{
      errors.merchantType = ""
    }
    console.log("errer"+ errors.merchantType)
    return errors;
  };
  
  validateBank = (values: any) => {
    const { accountNumber, confirmAccountNumber } = this.state.bankFormData;
    const regexName = /^[A-Za-z +]+$/;
    const regexIfsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/g;
    const errors: any = {};
    if (!values.bankName) {
      errors.bankName = "Bankname is required!";
    } else if (!regexName.test(values.bankName)) {
      errors.bankName = "Please enter alphabetical characters!";
    }
    if (!values.accountNumber) {
      errors.accountNumber = "Account number is required!";
    }
    if (!values.confirmAccountNumber) {
      errors.confirmAccountNumber = "Confirm account number is required!";
    } else if (accountNumber !== confirmAccountNumber) {
      errors.confirmAccountNumber = "Account number do not matches!";
    }
    if (!values.ifscCode) {
      errors.ifscCode = "IFSC code is required!";
    } else if (!regexIfsc.test(values.ifscCode)) {
      errors.ifscCode = "This is not a valid IFSC code!";
    }
    return errors;
  }

  validateDocument = (values: any) => {
    const errors: any = {};
    if (this.state.showFilePan.length === 0) {
      errors.pan = "Pan is required!";
    }
    if (this.state.showFileCheque.length === 0) {
      errors.cheque = "Cancelled cheque is required!";
    }
    if (this.state.showFileAadhar.length === 0) {
      errors.aadhar = "Aadhar is required!";
    }
    return errors;
  }
  // MerchantBasicDetails end

  // Signup start
  // Timer start

  async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevState.seconds !== this.state.seconds && this.state.seconds === 0) {
      clearInterval(this.myInterval);
    }
  }

  async componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  fmtMSS(s: any) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }
  // Timer end

  handleTermsModal = () => {
    this.setState({ openTermsModal: true });
    this.privacyPolicyData();
  }

  handlePrivacyModal = () => {
    this.setState({ openPrivacyModal: true });
    this.privacyPolicyData();
  }

  handleCloseTerms = () => {
    this.setState({ openTermsModal: false });
  }

  handleClosePrivacy = () => {
    this.setState({ openPrivacyModal: false });
  }

  handleToggle = () => {
    this.setState({ flag: !this.state.flag });
  }

  // OTP----
  otpHandleChange = (otpInput: any) => {
    this.setState({ otpInput });
  }

  handleOtpSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({ isErrorOtp: this.validateOtp(this.state.otpInput) });
    if (this.state.otpInput.length >= 4) {
      this.setState({ isLoadingOtp: true });
      this.verifyOtp();
    }
  }

  validateOtp = (values: any) => {
    const errors: any = {};
    if (!values || values.length < 4) {
      errors.otpInput = "Otp fields are required!";
    }
    return errors;
  }

  handleClickResendOtp = () => {
    this.setState({ openResendOtp: true });
  }

  handleCloseResendOtp = () => {
    this.setState({ openResendOtp: false });
  }

  // Mobile Number----
  handleChange = (e: any) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    });
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { mobile, salesCode } = this.state.formData;
    this.setState({
      isError: this.validate(this.state.formData),
    });
    if (mobile?.length === 10 && salesCode != "" && this.state.flag) {
      this.setState({ isLoading: true });
      this.confirmationCode()
     
    }
  }

  validate = (values: any) => {
    const errors: any = {};
    const regex = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    if (!values.mobile) {
      errors.mobile = "Phone number is required";
    } else if (!regex.test(values.mobile)) {
      errors.mobile = "This is not a valid phone number";
    } else if (this.state.formData.mobile?.length != 10) {
      errors.mobile = "This is not a valid phone number";
    }
    if (!values.salesCode) {
      errors.salesCode = "Sales code is required";
    }
    if (!this.state.flag) {
      errors.flag = "Please accept above to proceed";
    }
    return errors;
  }

  //for confirmation sales code
  
  confirmationCode = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      // "data": {
      //   "type":"sms_account",
      //   "attributes": {
      //     "full_phone_number": `91${this.state.formData.mobile}`,
      //     "sales_code_id": this.state.formData.salesCode,
      //   }
      // }
 
      "full_phone_number": `91${this.state.formData.mobile}`,
      "sales_code_id": this.state.formData.salesCode,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmationCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Signup end

  signUp = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      // "data": {
      //   "type":"sms_account",
      //   "attributes": {
      //     "full_phone_number": `91${this.state.formData.mobile}`,
      //     "sales_code_id": this.state.formData.salesCode,
      //   }
      // }

      "data":
      {"type":"sms_account",
      "attributes":
      {"full_phone_number": `91${this.state.formData.mobile}`}},
      "sales_code": this.state.formData.salesCode
    
      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/send_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  verifyOtp = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem("authToken"),
    };

    const data = {
      "otp_code": this.state.otpInput,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpVerifyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointOtpVerify
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  userDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      "data": {
        "type": "sms_account",
        "first_name": this.state.personalFormData.firstName,
        "last_name": this.state.personalFormData.lastName,
        "email": this.state.personalFormData.email,
        "full_phone_number": `91${this.state.personalFormData.phone}`,
        "address":this.state.personalFormData.addressOne,
        "merchant_type":this.state.merchantType,
        "sales_code_id": this.props.salesCodeProp,
        "bank_detail_attributes": {
          "bank_name": this.state.bankFormData.bankName,
          "ifsc_code": this.state.bankFormData.ifscCode,
          "account_number": this.state.bankFormData.accountNumber,
          "confirm_account_number": this.state.bankFormData.confirmAccountNumber
        },
      },
      "document": {
        "pan_card": this.state.convertedPan,
        "aadhar_card": this.state.convertedAadhar,
        "cancelled_cheque": this.state.convertedCheque
      }
    };
    console.log("httpBody", httpBody);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointUserDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  privacyPolicyData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  addAdditionalDetail(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "sms_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdditionalDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  processOnClickMessage(messageID: string, values: any = null) {
    switch (messageID) {
      case "btnLegalTermsAndCondition":
        this.goToTermsAndCondition();
        break;
      case "btnLegalPrivacyPolicy":
        this.goToPrivacyPolicy();
        break;
      case "btnSignUp":
        this.addAdditionalDetail();
        break;
      default:
        console.log(
          "processOnClickMessage::Not Confifured for message " + messageID
        );
        break;
    }
  }

  public changeState(objectID: string, value: any = null) {
    switch (objectID) {
      case "txtInputFirstName":
        this.setState({ firstName: value });
        break;
      case "txtInputLastName":
        this.setState({ lastName: value });
        break;
      case "txtInputEmail":
        this.setState({ email: value });
        break;
      case "txtInputPassword":
        this.setState({ password: value });
        break;
      case "btnPasswordShowHide":
        this.setState({ enablePasswordField: value });
        break;
      case "txtInputConfirmPassword":
        this.setState({ reTypePassword: value });
        break;
      case "btnConfirmPasswordShowHide":
        this.setState({ enableReTypePasswordField: value });
        break;
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  }

  getState(objectID: string) {
    switch (objectID) {
      case "txtInputFirstName":
        return this.state.firstName;
      case "enablePasswordField":
      case "txtInputPassword":
      case "imgEnablePasswordField":
      case "txtInputConfirmPassword":
      case "btnPasswordShowHide":
        return this.state.enablePasswordField;
      case "enableConfirmPasswordField":
      case "btnConfirmPasswordShowHide":
      case "imgEnableRePasswordField":
        return this.state.enableReTypePasswordField;
      default:
        console.log("getState::Not Confifured for " + objectID);
        return null;
    }
  }
  // Customizable Area End
}
