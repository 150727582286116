
import React, { Component } from "react";

// Customizable Area Start
import {
 
  careHealthLogo,
  
  backgroundImage,
  backIcon,
  tapsureLogo,
  
} from "./assets2";
import {
  Grid,
  Button,
   
  Tabs,
  
  Tab,
  Box
} from "@material-ui/core";
import {
 
  withStyles,
} from "@material-ui/core/styles";
import { Markup } from 'interweave';
 
import '../assets/policyDetails.css'


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

import CustomerVBController, { Props } from "./CustomerVBController";
// Customizable Area End

// Customizable Area Start

export default class CustomerVBCarePolicyDetails extends CustomerVBController {
  constructor(props: Props) {
    super(props);
  }
  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
 
  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <img 
              src={tapsureLogo} 
              className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen")}
            />
          </Grid>
        </Grid>{" "}
        <MainContainerVB container>
          <Grid>
            <Grid>
              <Button>
                <img
                  height="18px"
                  src={backIcon}
                  style={{ marginTop: "15px" }}
                  onClick={this.handleBack}
                />
              </Button>
            </Grid>
          </Grid>
 
          <Grid container style={childContainerVB}>
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img height="55px" width="80px" src={careHealthLogo} alt="" />
                <div>
                  <h4 style={{ margin: "10px 0 10px 10px" }}>
                    Care Vector Borne
                  </h4>
                  <p style={{ margin: "0px 0 5px 10px" }}>Care Health Insurance </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={childContainerVB}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handlePolicyTabsChange}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                {/*@ts-ignore */}
                <CustomTabsVB label="Policy Benefits" />
                {/*@ts-ignore */}
                <CustomTabsVB label="About" />
                {/*@ts-ignore */}
                <CustomTabsVB label="FAQs" />
              </Tabs>

              <TabPanelVB
                value={this.state.value}
                index={0}
                {...this.a11yProps(0)}
              >
                 <Grid container spacing={2} style={{ marginTop: 10 }}>
                  <Grid xs={12} item>
                    <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                    <h3 style={{ ...webStyleVB.headColor, margin: '0px' }}>Policy Benefits</h3>
                    <div>
                      {this.state.policyTabsData.PolicyDetail !== null &&
                        <div >
                          <div >
                            {" "}
                          
                            {
                              this.state.policyTabsData.PolicyDetail !== undefined && (
                                <div className="policyDetailsVB" >
                                  
                                  {" "}
                                  <Markup content={this.state.policyTabsData.PolicyDetail.policy_benefits}  />
                                </div>
                              )
                            }
                          </div>
                        </div>
                      }
                    </div>
                    </Box>
                    
                  </Grid>
                  
                </Grid>
              </TabPanelVB>
              <TabPanelVB
                value={this.state.value}
                index={1}
                {...this.a11yProps(1)}
              >
                <Grid container>
                  <Grid xs={12} item  >
                    <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                      <div className="policyDetailsVB" >
                      {
                      this.state.policyTabsData.PolicyDetail !== null && (
                        <Markup content={this.state.policyTabsData.PolicyDetail?.about} />
                      )
                    }
                      </div>
                    </Box>
              
                 
                  
                  </Grid>
                </Grid>
            
              </TabPanelVB>
             <TabPanelVB
                value={this.state.value}
                index={2}
                {...this.a11yProps(2)}
              >
                <Grid container>
                  <Grid item>
                    <h3>FAQs</h3>
                  </Grid>
                </Grid>
                {
                  this.state.policyTabsData?.Faqs !== null && (
                    this.state.policyTabsData?.Faqs?.map((data: { question: string | null | undefined; answer: string | null | undefined; }, index: number) => (
                       
                        <Grid container key={index}>
                          <Grid item xs={12}>
                            <Box style={{display: "flex",
                                flexWrap: "wrap",
                                boxSizing: "border-box"}}>
                              <div className="policyDetailsVB">
                              <h3 style={webStyleVB.headColor}>
                              <Markup content={data.question} className="question" />
                            </h3>

                            <div>
                              <Markup content={data.answer} className="answer" />
                            </div>
                              </div>
                            </Box>
                          
                          </Grid>
                        </Grid>
                      )
                    ))
                  
                }
                
              </TabPanelVB>
            </Grid>
          </Grid>
          {this.state.value == 0 ? (
            <>
               {/* useless */}
            </>
          ) : (
            ""
          )}
        </MainContainerVB>
        <BottomContainerVB container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.handleNextView}
              style={{ width: "100%" }}
            >
              NEXT
            </Button>
          </Grid>
        </BottomContainerVB>
      </>
    );
  }
}

// Customizable Area End


// Customizable Area Start

const MainContainerVB:any = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      " system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const childContainerVB = {
  padding: "5px 10px",
  display: "flex",
  borderRadius: "5px",
  font:
    "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  margin: "15px",
  backgroundColor: "white",
  
};

const BottomContainerVB :any= withStyles({
  root: {
    padding: "10px 15px",
  },
})(Grid);
const CustomTabsVB = withStyles({
  root: {
    textTransform: "none",
    padding: "6px 12px",

    letterSpacing: 0,
    whiteSpace: "normal",
  },
})(Tab);

const webStyleVB = {
  headColor: { color: "#5254b3" },
};
const TabPanelVB = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

// Customizable Area End
