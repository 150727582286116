import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

// Customizable Area Start
interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;
  steps: any;
  innerStep: number;
  selectModelManually: boolean;
  OwnerType: string;
  claimMade: string;
  showCustomerDetailSteps: boolean;
  policyType: string;
  openpolicyTypeDrawer: boolean;
  IDVType: string;
  IDVTypeDrawer: boolean;
  policyDetailsDrawer: boolean;
  OpenVechileDetailsDrawer: boolean;
  ownerDetailsStepIndex: number;
  previousPolicyType: string;
  modelNumber: string;
  registrationNumber: string;
  brand: string;
  model: string;
  policyExpiredRecently: string;
  vechileOnLoan: string;
  NoclaimBonus: string;
  // Customizable Area Start
  openSharePolicy: boolean;
  qrUrl: string;
  policyPrice:string;
  orderId:string|null;
  policyAmount:any|null;
  transactionDate:string|null;
  membershipNumber:string|null;
  policyEndDate:string|null;
  proposalNum:string|null;
  pdfUrl:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderSuccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDataPdfApi:any;
  sendMessagesApi: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      steps: ["Basic Details ", "Get A Quote", "Get Policy"],
      innerStep: 0,
      selectModelManually: false,
      OwnerType: "Company Owned",
      claimMade: "No",
      showCustomerDetailSteps: false,
      policyType: "Comprehensive",
      openpolicyTypeDrawer: false,
      IDVType: "Recommended",
      IDVTypeDrawer: false,
      policyDetailsDrawer: false,
      OpenVechileDetailsDrawer: false,
      ownerDetailsStepIndex: 0,
      previousPolicyType: "Comprehensive",
      modelNumber: "",
      registrationNumber: "",
      brand: "",
      model: "",
      policyExpiredRecently: "No",
      vechileOnLoan: "No",
      NoclaimBonus: "50",
      openSharePolicy: false,
      qrUrl:
        "https://netambitmvp-135107-react-native.b135107.dev.eastus.az.svc.builder.cafe",
      policyPrice: "cscsc",
      orderId: "",
      policyAmount: "",
      transactionDate: "",
      membershipNumber: "",
      policyEndDate:"",
      proposalNum:"",
      pdfUrl:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // async componentDidMount() {
  // super.componentDidMount();
  // this.getToken();
  // if (this.isPlatformWeb() === false) {
  //   this.props.navigation.addListener("willFocus", () => {
  //     this.getToken();
  //   });
  // }
  // }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  handleTwoWheerler = (component: string) => {
    this.props.navigation.navigate(component);
  };
  handleModelManually = () => {
    let value = this.state.selectModelManually;
    this.setState({
      selectModelManually: !value,
    });
  };
  handleInnerStep = (stepType: string) => {
    let _innerStep = this.state.innerStep;
    if (stepType === "next") {
      this.setState({
        innerStep: _innerStep + 1,
      });
    } else {
      this.setState({
        innerStep: _innerStep - 1,
      });
    }
  };
  handleOwnerTypeChange = (e: any) => {
    this.setState({
      OwnerType: e.target.value,
    });
  };
  handleActiveStep = (stepType: string) => {
    let _activeStep = this.state.activeStep;
    if (stepType === "next") {
      if (_activeStep == 2) {
        this.props.navigation.navigate("CustomerPaymentOptions");
      } else {
        this.setState({
          activeStep: _activeStep + 1,
        });
      }
    } else if (stepType == "back") {
      this.setState({
        activeStep: _activeStep - 1,
      });
    }
  };
  handleOwnerDetailsSteps = (stepType: string) => {
    let _step = this.state.ownerDetailsStepIndex;
    if (stepType == "next") {
      if (this.state.ownerDetailsStepIndex == 3) {
        this.handleActiveStep("next");
      } else {
        this.setState({
          ownerDetailsStepIndex: _step + 1,
        });
      }
    } else if (stepType == "back") {
      if (this.state.ownerDetailsStepIndex == 0) {
        this.setState({
          OpenVechileDetailsDrawer: true,
          showCustomerDetailSteps: false,
        });
      } else {
        this.setState({
          ownerDetailsStepIndex: _step - 1,
        });
      }
    }
  };
  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectoHomeScreen = () => {
    console.log("chjcds");
    this.props.navigation.navigate("CustomerHomeScreen");
  };
  handleModelNumber = (e: any) => {
    this.setState({
      modelNumber: e.target.value,
    });
  };
  handleRegistrationNumber = (e: any) => {
    this.setState({
      registrationNumber: e.target.value,
    });
  };
  handleBrand = (e: any) => {
    this.setState({
      brand: e.target.value,
    });
  };
  handleModel = (e: any) => {
    this.setState({
      model: e.target.value,
    });
  };
  handlePolicyExpiration = (e: any) => {
    this.setState({
      policyExpiredRecently: e.target.value,
    });
  };
  handleNoclaimBonus = (e: any) => {
    this.setState({
      NoclaimBonus: e.target.value,
    });
  };
  handleClaimMade = (e: any) => {
    this.setState({
      claimMade: e.target.value,
    });
  };

  handlePreviousPolicyType = (e: any) => {
    this.setState({
      previousPolicyType: e.target.value,
    });
  };


  handleAfterSuccessContinue = () => {

    console.log(localStorage.getItem('merchantId'))

    if(localStorage.getItem('merchantId')  === "null" || localStorage.getItem('merchantId')  === null){
      
      this.props.navigation.navigate("CustomerHomeScreen");
      
     } else{
    
      this.redirectToComponent('MerchantPolicyDetails','')
     }
  }
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  getPdf = (policy:any,policyType:any) => {
    
    const headers = {
        "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataPdfApi = requestMessage.messageId;
    console.log(this.getDataPdfApi)
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPdfDataUrl + "?policyNo="+policy+"&type="+policyType
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};



sendMessages = (policyNo:any,accountId:any) => {

  const headers = {
      "Content-Type": configJSON.categoryApiContentType,
  };
  
  const httpBody = {
      "data": {
          "account_id": parseInt(accountId),
          "platform": "Tappmart",
          "payment_status": true,
          "policyNumber" : policyNo
      }


  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.sendMessagesApi = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendMessagesUrl
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDataPdfApi != null &&
      this.getDataPdfApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
      var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let custAccId = localStorage.getItem("customerAccountID")
      let orderId = localStorage.getItem("orderId");
     if(responseJson){
      if (responseJson.pdf_url !== "") {
        console.log(responseJson.pdf_url)
       
        setTimeout(()=>{
          window.open(responseJson.pdf_url, '_blank', 'noopener,noreferrer');
        },)
    }
     }

      else {
  
          var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorReponse);
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  policyList = [
    {
      id: 1,
      amount: "10000",
      premium: "299",
    },
    {
      id: 2,
      amount: "20000",
      premium: "359",
    },
    {
      id: 3,
      amount: "50000",
      premium: "449",
    },
  ];
  productDetails = localStorage.getItem("selectedProduct");
 

  redirectToComponent = (component: string, data: any) => {
    localStorage.setItem("planDetails", JSON.stringify(data));
    this.props.navigation.navigate(component);
  };
  handleShare = async () => {
    console.log("clciked");
    try {
      const canvas: any = document.createElement("canvas");
      const dataUrl = canvas.toDataURL();
      const blob = await (await fetch(dataUrl)).blob();
      // const filesArray = [
      //   new File([blob], "animation.png", {
      //     type: blob.type,
      //     lastModified: new Date().getTime(),
      //   }),
      // ];
      const shareData = {
        // files: filesArray,
        text:
          "Hello there! I have purchased an insurance from Tappsure! You can also check affordable policies here -" +
          this.state.qrUrl +
          "CustomerHomeScreen",
      };
      navigator.share(shareData).then(() => {
        console.log("Shared successfully");
      });
    } catch (e) {
      console.log(e);
    }
  };
  async componentDidMount() {
    super.componentDidMount();
    const base_url = localStorage.getItem("base_url");
    if (base_url !== null) {
      this.setState({ qrUrl: base_url });
    }
    let custAccId = localStorage.getItem("customerAccountID")
    let orderId = localStorage.getItem("orderId");
    let model = localStorage.getItem("policyNo");
    let policyType = localStorage.getItem("policyType");
    let policyAmount = localStorage.getItem("policyAmount");
    let transactionDate = localStorage.getItem("transactionDate");
    let membershipNumber = localStorage.getItem("ownername");
    let policyEndDate = localStorage.getItem("policyEndDate");
    let ownername=localStorage.getItem("ownername");
    let brand = localStorage.getItem("insurerName")
    let proposalNum= localStorage.getItem("proposalNum")
    
    this.getPdf(orderId,policyType)
   

    
    if (
      orderId !== null &&
      policyType !== null &&
      policyAmount !== null &&
      transactionDate !== null &&
      membershipNumber != null && ownername
    )
      this.setState({
        orderId,
        policyType,
        policyAmount,
        transactionDate,
        membershipNumber,
        policyEndDate,
        OwnerType:ownername,
        proposalNum
      });
      if (model !== null && brand !== null) {
        this.setState({
          brand,
          model,
        });
      }
     

  }

  // Customizable Area End
  // Customizable Area End
}
