export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const background = require("../assets/background.jpg");
export const checked = require("../assets/checked.png");
export const unchecked = require("../assets/unchecked.png");
export const menu = require("../assets/menu.png");
export const tappmart_logo = require("../assets/tappmartLogo.png");
export const success_tick = require("../assets/success_tick.png");

export const profile_user_topbar = require("../assets/profile_user_topbar.png");

export const close = require("../assets/close.png");
