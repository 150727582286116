import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
} from "@material-ui/core";
import { tappmart_logo ,menu} from "./assets"
import MobileAccountLoginController from "./MobileAccountLoginController";
// Customizable Area End

// Customizable Area Start
class MerchantSignUpHeader extends MobileAccountLoginController {

  render() {
    const { navigation } = this.props;
    return (
      <>
        <Container maxWidth='sm'>
          <Box
            sx={style.header}
          >
            <img
              style={{transform: "scale(1)", marginLeft: "-8px"}}
              src={menu}
              alt="menu"
            />
            <img
              style={{marginRight: "100px"}}
              src={tappmart_logo}
              height="40"
              alt="logo"
            />
            <Button
              style={{marginRight: "-10px", textTransform: "capitalize", border: '1px solid black'}}
              variant="outlined"
              color="primary"
              data-test-id = "btnTest"
              onClick={() =>  navigation.navigate("Login")}
            >
              Login
            </Button>
          </Box>
        </Container>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style = {
  header: {
    p: 1.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
// Customizable Area End
export default MerchantSignUpHeader;