import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Container,
    Typography,
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
    background
} from "./assets";
 
import DashboardController2 from "./DashboardController2";
// Customizable Area End

// Customizable Area Start
class RefundAndCancel extends DashboardController2 {

    render() {
        return (
            <>
            {/*@ts-ignore */}
                <MerchantLoginIconHeader {...this.props} />
                <div style={style.image}>

                    <Container maxWidth='sm'>
                        <Paper elevation={2} >
                            {/*@ts-ignore */}
                            <Box
                                sx={style.container}
                            >
                                <Typography
                                    style={{ margin: "15px 0px", color: "black",textAlign:"center",fontWeight: "bold",fontSize:"29px" }}
                                    variant="h6"
                                >
                                    Refund And Cancellation 
                                </Typography>
                                <Typography component="p" style={{fontWeight: "bold" , marginTop: "15px" }}>
                                    
                                Refund and cancellation depends on our partner company and the product. Request our Merchant or Customer to refer their detailed terms and conditions of the product mentioned during the purchase of the product and shared to them to their registered email ID post purchase of the product through Tappmart Website
                                </Typography>

                              
                            </Box>
                        </Paper>

                    </Container>
                </div>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const style = {
    image: {
        height: '100%',
        paddingBottom: '25px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        p: 2,
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    continueBtn: {
        marginTop: '20px',
        padding: "15px",
        borderRadius: 8,
    },
};
// Customizable Area End
export default RefundAndCancel;