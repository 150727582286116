import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Button,
  Container,
  Typography,
  TextField
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
  background,
  contact_email,

} from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DashboardController2 from "./DashboardController2";
// Customizable Area End

// Customizable Area Start
class ContactUs extends DashboardController2 {

  handleChange = (e: any) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    });
  }





  render() {
    const { name, phoneNumber, message } = this.state.formData;
    return (
      <>
        <Snackbar open={this.state.openToast} autoHideDuration={1000} style={{ margin: "10px" }}>
          <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
            {this.state.toastMessage}
          </Alert>
        </Snackbar>
        {/*@ts-ignore */}
        <MerchantLoginIconHeader {...this.props} />
        <div style={style.image}>
          <Container maxWidth='sm'>
            <Typography
              style={{ marginTop: 20, color: "white" }}
              variant="h6"
            >
              Contact Us
            </Typography>
            <Paper elevation={2}>
              {/*@ts-ignore */}
              <Box
                sx={style.container}
              >
                <form onSubmit={this.handleContactSubmit}>
                  {/* @ts-ignore */}
                  <Box
                  >
                    <Typography
                      gutterBottom
                      variant="body1"
                    >
                      Your Name
                    </Typography>
                    <TextField
                      fullWidth
                      data-test-id="txtInput"
                      variant="outlined"
                      label="Your Name"
                      error={this.state.isError.name ? true : false}
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <div style={{ marginTop: 5, color: 'red' }}>
                      {this.state.isError.name}
                    </div>
                  </Box>
                  {/* @ts-ignore */}
                  <Box
                  >
                    <Typography
                      style={{ marginTop: 18 }}
                      gutterBottom
                      variant="body1"
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="phoneInput"
                      label="Phone Number"
                      type="number"
                      error={this.state.isError.phoneNumber ? true : false}
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={this.handleChange}
                    />
                    <div style={{ marginTop: 5, color: 'red' }}>
                      {this.state.isError.phoneNumber}
                    </div>

                    <Typography
                      gutterBottom
                      variant="body1"
                    >
                      Please mention your query in few words
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      id="filled-multiline-flexible"
                      label="Max 500 Characters"
                      multiline
                      rows={6}
                      inputProps={{
                        maxLength: 500,
                      }}
                      data-test-id="descriptionInput"
                      error={this.state.isError.message ? true : false}
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                    />
                    <div style={{ marginTop: 5, color: 'red' }}>
                      {this.state.isError.message}
                    </div>
                  </Box>
                  <Button
                    style={style.sendBtn}
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-test-id="contact-submit"
                  >
                    Send
                  </Button>
                </form>
              </Box>
            </Paper>

            <Paper elevation={2}>
              {/* @ts-ignore */}
              <Box
                sx={style.support}
              >
                {/*@ts-ignore */}
                <Box sx={style.text}>
                  <Typography
                    gutterBottom
                    variant="body1"
                  >
                    Registered Address:
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    <b>ABL Workspaces Building,
                      A-110, Sector – 5, Noida-201 301</b>
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Paper elevation={2}>
              {/* @ts-ignore */}
              <Box
                sx={style.support}
              >
                <img
                  src={contact_email}
                  alt=""
                  height="50"
                  width="50"
                />
                {/* @ts-ignore */}
                <Box sx={style.text}>
                  <Typography
                    gutterBottom
                    variant="body1"
                  >
                    Email Support
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    <b>{this.state.supportEmail}</b>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Container>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style: any = {
  image: {
    paddingBottom: "25px",
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  container: {
    p: 2,
    mt: 2,
  },
  sendBtn: {
    marginTop: '20px',
    marginBottom: '10px',
    padding: "15px",
    borderRadius: 8,
  },
  support: {
    p: 2,
    mt: 2,
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    ml: 2,
    display: 'flex',
    flexDirection: "column",
  }
};
// Customizable Area End
export default ContactUs;
