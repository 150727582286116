export const menu = require("../assets/menu.png");
export const tappmart_logo = require("../assets/tappmartLogo.png");
export const profile_user_topbar = require("../assets/profile_user_topbar.png");

export const close = require("../assets/close.png");

export const background = require("../assets/background.jpg");
export const customers = require("../assets/customers.png");
export const catalogue = require("../assets/catalogue.png");
export const order_history = require("../assets/order_history.png");
export const tagPng = require("../assets/tag.png")
export const tappoints = require("../assets/tappoints.png");
export const sales = require("../assets/sales.png");
export const next_page = require("../assets/next_page.png");

export const contact_email = require("../assets/contact_email.png");
export const contact_phone = require("../assets/contact_phone.png");


export const back = require("../assets/back.png");
export const image_filter = require("../assets/image_filter.png");
export const search = require("../assets/search.png");
export const star_logo = require("../assets/star_logo.png");
export const reliance_logo = require("../assets/reliance_logo.png");
export const profile = require("../assets/profile.png");
export const image_phone_number = require("../assets/image_phone_number.png");
export const purchase_date = require("../assets/purchase_date.png");
export const renewal_date = require("../assets/renewal_date.png");

export const checked = require("../assets/checked.png");
export const unchecked = require("../assets/unchecked.png");

export const banner = require("../assets/banner.png");
export const tappoints_table = require("../assets/tappoints_table.png");
export const tappoint_banner_icon = require("../assets/tappoint_banner_icon.png");

export const coin = require("../assets/coin.png");


export const download = require("../assets/download.png");
export const icici_logo = require("../assets/icici_logo.png");
export const qr = require("../assets/qr.png");

export const gmail = require("../assets/gmail.png");
export const facebook = require("../assets/facebook.png");
export const whatsapp = require("../assets/whatsapp.png");


