import React, { Component } from "react";

// Customizable Area Start
import {
  Grid,
  Button,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";

import {
  tapsureLogo,
  premiumIcon,
  backgroundImage
} from "./assets";
import CatalogueController from "./CatalogueController";
// Customizable Area End

// Customizable Area Start
export default class CustomerCyberFraudPolicy extends CatalogueController {
  constructor(props: any) {
    super(props);
  }
  // componentDidMount(): any {
  //   if(window.location.href.includes("CustomerCyberFraudPolicy")){
  //     let merchantId=window.location.href.split("=")[1]
  //     localStorage.setItem('merchantId',merchantId) 
  //     }
  // }

  render() {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={styleRedirectComponent}
              onClick={() => this.redirectToComponent("CustomerHomeScreen", '')}
            />
          </Grid>
        </Grid>{" "}
        <MainContainerCustomerCyberFraud container spacing={2}>
          {/* Arrow Container */}
          <Grid container>
            <Grid>
              <h3 style={{ color: "white", marginLeft: "15px" }}>
                {'ICICI Cyber Fraud'
                  // this.productDetails !== null ?
                  //   (JSON.parse(this.productDetails)).attributes.name : 'Policy'
                }</h3>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          {
            this.policyList.map((data, index) => {
              return (
                <Grid container style={childContainerCyberFraud} key={index}>
                  <Grid item>
                    <div style={stylePolicyList}>
                      {/* <img height="40px" width="80px" src={starIns} alt="" />
                    <div>
                      <h4 style={{ margin: "10px 0 10px 10px" }}>
                        Family Health Plan
                      </h4>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        Star Health and Allied Insurance
                      </p>
                    </div> */}
                    </div>
                  </Grid>

                  {/* Policy Cards */}

                  <Grid item sm={12} xs={12} lg={12}>
                    <Grid container direction="row" justifyContent="space-between"
                      style={{ flexWrap: "nowrap", marginTop: 5 }}>
                      <Grid
                        item
                        style={styleCyberFraudPolicy_One}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"


                        >
                          <Grid item>
                            <img src={premiumIcon} height="20px" style={styleCyberFraudPolicy_Three} />
                          </Grid>
                          <Grid item style={styleCyberFraudPolicy_Four}>
                            <p style={styleCyberFraudPolicy_Five}>Premium</p>
                            <h3 style={styleCyberFraudPolicy_Six}>{data.premium}</h3>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={styleCyberFraudPolicy_Two}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <img src={premiumIcon} height="25px" style={styleCyberFraudPolicy_Seven} />
                          </Grid>
                          <Grid item style={styleCyberFraudPolicy_Eight}>
                            <p style={styleSumInsuredCyberFraud}>Sum Insured</p>
                            <h3 style={styleCyberFraudpolicy_Nine}>{data.amount}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => this.redirectToComponent('CustomerICICICyberFraud', data)}
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              )
            })
          }
















        </MainContainerCustomerCyberFraud>
      </>
    );
  }
}

// Customizable Area Start

const MainContainerCustomerCyberFraud = withStyles({
  root: {
    backgroundImage:
      `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "5px",
    marginTop: "10px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  },
})(Grid);

const childContainerCyberFraud = {
  padding: "10px",
  font: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",


  borderRadius: "5px",

  margin: "15px",
  backgroundColor: "white",
};

const styleRedirectComponent = {
  margin: "10px 20px"
};

const stylePolicyList = {
  display: "flex",
  alignItems: "center"
}

const styleCyberFraudPolicy_One = {
  border: "1px solid #3451af",
  padding: "10px 15px",
  margin: "15px 0px 10px 5px",
  borderRadius: "6px",
  width: "50%"
}

const styleCyberFraudPolicy_Two = {
  border: "1px solid #3451af",
  padding: "10px 15px",
  margin: "15px 0px 10px 5px",
  borderRadius: "6px",
  width: "50%"
}

const styleCyberFraudPolicy_Three = {
  marginLeft: '-25px'
}

const styleCyberFraudPolicy_Four = {
  margin: "5px"
}

const styleCyberFraudPolicy_Five = {
  margin: "2px"
}

const styleCyberFraudPolicy_Six = {
  margin: "2px", color: "5254b3"
}

const styleCyberFraudPolicy_Seven = {
  marginLeft: '-25px'
}

const styleCyberFraudPolicy_Eight = {
  margin: "5px"
}

const styleSumInsuredCyberFraud = {
  margin: "2px"
}

const styleCyberFraudpolicy_Nine = {
  margin: "2px", color: "5254b3"
}
// Customizable Area End
