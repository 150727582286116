Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getMerchantDetails ="filter_merchant_products/"
exports.getCategoriesUrl="bx_block_categories/categories";
exports.getMerchantCategoriesUrl = "filter_merchant_products"
exports.getCategoriesMethodType = "GET";
exports.getProductsMethodType = "GET";
exports.getProductsUrl="bx_block_categories/products";
exports.getAssureCustomersUrl="bx_block_3rdpartyintegrationwithinsuranceco3/customer"
exports.getAssureCustomerMethodType="GET"
exports.getMerchantFaqs = "account_block/get_merchant_faq"
exports.getMerchantApiMethod = "GET";
exports.getContactApi = "account_block/get_contact_details"
exports.contactUsFormApi = "account_block/contact_us"
exports.contact_methodType = "POST"
exports.getContactData = "bx_block_profile/show_profile"
exports.getFilterProductsUrl="account_block/get_products"
exports.getTappointsDataUrl = "account_block/filter_merchants_commission/"
exports.getTapCustomerDetailsApi = "account_block/get_policywise_customer_info/"
exports.getTagUrl ="bx_block_qr_codes/get_tags/"
exports.addTagUrl = "bx_block_qr_codes/add_qr_tag"
exports.privacyPolicyEndPoint = "bx_block_categories/privacy_policy";
// Customizable Area End