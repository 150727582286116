import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  tappmart_logo,
 
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  activeStep: number;

  innerStep: number; 
  policyType: string;
   
  modelNumber: string;
  registrationNumber: string;
  brand: string;
  model: string; 
  value: number; 
  openPolicyDetailDrawer: boolean;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  pinCode: string;
  addressOne: string;
  sumInsured: string;
  homeType: string;
  customerType: string;
  steps: any;
  houseHoldValue: string;
  gender: string;
  relationship: string;
  productName: string;
  productCompany: string;
  applicationDate: string;
  isFirstNameValid: boolean;
  isPhoneNumberValid: boolean;
  isEmailValid: boolean;
  isDateOfBirthValid: boolean;
  isGenderValid: boolean;
  isRelationshipValid: boolean;
  isPanValid: boolean;
  isPassportValid: boolean;
  isDLValid: boolean;
  isAadharValid: boolean;
  isProductNameValid: boolean;
  isBrandValid: boolean;
  isModelValid: boolean;
  isSerialNo1Valid: boolean;
  isSerialNo2Valid: boolean;

  isPurchaseDateValid: boolean;
  isPurchaseValueValid: boolean;
  isWarrantyValid: boolean;
  isAddressTypeValid: boolean;
  isAddress1Valid: boolean;
  isAddress2Valid: boolean;
  isPinCodeValid: boolean;
  isCityValid: boolean;
  isStateValid: boolean;
  isLandMarkValid: boolean;
  applicationNumber: string;
 
  imeiOne: string;
  isimeiOneValid: boolean;
  imeiTwo: string;
  productWarranty: string;
  addressType: string;
  isProductWarrantyValid: boolean;
  addressTwo: string;
  city: string;
  selectedState: string;
  landmark: string;
  productNames: any
  mobileBrands: any;
  applicationStartDate: any;
  purchaseDate: any;
  invoiceValue: string;
  cityCode: string;
  stateCode: string;
  productCode: string;
  productDescription: string;
  planCode: any;
  partnerCode: string;
  partnerBUCode: string;
  planName: string;
  serviceListData: any;
  serviceList: string;
  planMasterData: any;
  paymentDetails: any;
  planPrice: any;
  isApplicationStartDateValid: boolean;
  isServiceListValid: boolean;
  isPlanCodeValid: boolean;
  isInvoiceValueValid: boolean;
  policyDetails: any | null;
  orderDetails: any; 
  Account_ID: any;
  loading: boolean;
  toastMessage: string;
  openToast: boolean;
  toastType: string;
  maxYear: string;
  maxPurchaseDate: string;
  policyTabsData: any;
  openModal: boolean;
  id: any;
  policyNum: any;
  transaction_id: any;
  pay_description: string;
  policy_Number: string;
  allPlans: any;
  selectedPlans: any;
  stopPaymentApi: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
declare global {
  interface Window {
    loadBillDeskSdk?: any;
  }
}

export default class OneAssistController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getProductNameApiId: any;
  getMobileBrandsApiId: any;
  oneAssistCustomerApiId: any;
  getLocationApiId: any;
  getServiceListApiId: any;
  getPlanMasterApiId: any;
  issueOneAssistPolicyID: any;
  createOrderAPICalledID: any;
  verifyOrderAPICalledID: any;
  updateOrderAPICalledID: any;
  getOneAssistPolicyTabsID: any;
  generateCommissionId: any;
  sendMessagesApi: any
  getPolicySmsId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      innerStep: 0, 
      policyType: "Comprehensive", 
      modelNumber: "",
      registrationNumber: "",
      brand: "",
      model: "", 
      value: 0, 
      openPolicyDetailDrawer: false,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "",
      pinCode: "",
      addressOne: "",
      sumInsured: "Building + Household Items",
      homeType: "Flat",
      customerType: "Owner",
      steps: ["Personal Details", "Product Details", "Contact Details"],
      houseHoldValue: "",
      gender: "",
      relationship: "",
      productName: "",
      productCompany: "",
      applicationDate: "",
      isFirstNameValid: true,
      isPhoneNumberValid: true,
      isEmailValid: true,
      isDateOfBirthValid: true,
      isGenderValid: true,
      isRelationshipValid: true,
      isPanValid: true,
      isPassportValid: true,
      isDLValid: true,
      isAadharValid: true,
      isProductNameValid: true,
      isBrandValid: true,
      isModelValid: true,
      isSerialNo1Valid: true,
      isSerialNo2Valid: true,
      isPurchaseDateValid: true,
      isPurchaseValueValid: true,
      isWarrantyValid: true,
      isAddressTypeValid: true,
      isAddress1Valid: true,
      isAddress2Valid: true,
      isPinCodeValid: true,
      isCityValid: true,
      isStateValid: true,
      isLandMarkValid: true,
      applicationNumber: "", 
      imeiOne: "",
      isimeiOneValid: true,
      imeiTwo: "",
      productWarranty: "",
      addressType: "",
      isProductWarrantyValid: true,
      addressTwo: "",
      city: "",
      selectedState: "",
      landmark: "",
      productNames: [],
      mobileBrands: [],
      applicationStartDate: "",
      purchaseDate: "",
      invoiceValue: "",
      cityCode: "",
      stateCode: "",
      productCode: "",
      productDescription: "",
      planCode: "",
      partnerCode: "",
      partnerBUCode: "",
      planName: "",
      serviceListData: [],
      serviceList: "",
      planMasterData: [],
      paymentDetails: "",
      planPrice: [],
      isApplicationStartDateValid: true,
      isServiceListValid: true,
      isPlanCodeValid: true,
      isInvoiceValueValid: true,
      policyDetails: {},
      orderDetails: {}, 
      Account_ID: 0,
      loading: false,
      toastMessage: "",
      toastType: "success",
      openToast: false,
      maxYear: "",
      maxPurchaseDate: "",
      policyTabsData: {},
      openModal: false,
      id: null,
      policyNum: 0,
      transaction_id: 0,
      pay_description: '',
      policy_Number: "",
      allPlans: [],
      selectedPlans: "",
      stopPaymentApi: true

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
       
      this.setState({ token: token });
       
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.getLocationApiId:
            this.setLocationApi(responseJson)
            break;
          case apiRequestCallId === this.oneAssistCustomerApiId:
            this.setOneAssistCustomer(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.createOrderAPICalledID:
            this.setCreateOrderApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.verifyOrderAPICalledID:
            this.verifyOrderApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.issueOneAssistPolicyID:
            this.issueOneAssistApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.getOneAssistPolicyTabsID:
            this.getOneAsistPolicyTabsApi(responseJson, errorReponse)
            break;
          case apiRequestCallId === this.generateCommissionId:
            this.setCommisionApi(responseJson, errorReponse)
            break;
          default:
        }
      
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    //get currentDate
    let todaysDate = this.formatDate(new Date());

    let maxPurchase = todaysDate.split("/").reverse().join("-")
    this.setState({
      applicationDate: todaysDate,
      applicationStartDate: todaysDate,
      maxPurchaseDate: maxPurchase
    });

    const planDetails = localStorage.getItem("planDetails");
    const planCode = localStorage.getItem("planCode");

    if (planDetails !== null) {

      this.setState({
        selectedPlans: planDetails,
        planCode: planCode
      });
      console.log(this.state.selectedPlans)
    }
    this.validateDateOfBirth(todaysDate)

    this.getOneAssistPolicyDetails()

    const plans = [{ id: 1, duration: "1 PMS in a year", amount: 1499, planCode: 31538 }, { id: 2, duration: "2 PMS in a year", amount: 1699, planCode: 31521 }]


    this.setState({
      relationship: "self",
      planPrice: 1499,
      addressType: "INSPECTION",
      partnerCode: "29",
      partnerBUCode: "308",


      addressOne: "A-110, Sector 5, Noida, Uttar Pradesh. 201301",
      steps: ["Personal Details"],
      allPlans: plans

    })
    console.log(this.state.allPlans)
    if (window.location.href.includes("CustomerOneAssistPolicyTabs")) {
      let url = new URL(window.location.href)

      let merchantId: any = url.searchParams.get("merchantId");
      let product_id: any = url.searchParams.get("product_id");
      if (merchantId && product_id) {
        localStorage.setItem('merchantId', merchantId)
        localStorage.setItem('product_id', product_id)
      }

    }
    // Customizable Area End
  }

// Customizable Area Start
  setLocationApi = (responseJson: any) => {
    if (responseJson) {

      this.setState({
        selectedState: responseJson.state_name,
        city: responseJson.city_name,
        cityCode: responseJson.city_code,
        stateCode: responseJson.state_code,
        isCityValid: true,
        isStateValid: true
      })
    }
    else {
      this.setState({ isPinCodeValid: false })
    }
  }
  setOneAssistCustomer = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.error) {

      let id = responseJson?.account_id;
      localStorage.setItem("customerAccountID", responseJson?.account_id)

      this.setState({
        Account_ID: parseInt(id),
        loading: false,
        openPolicyDetailDrawer: true,
        id: responseJson?.id,paymentDetails:responseJson.on_board.customer_infos.product_id
      })
      this.getPolicySms()
    }
    else {

      this.setState({ loading: false, openToast: true, toastType: "error", toastMessage: "We're experiencing technical difficulties .Please try after some times." });
      setTimeout(() => this.redirectToHomeScreen(), 4000)
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  setCreateOrderApi = (responseJson: any, errorReponse: any) => {
   
    if (responseJson.data && responseJson.data[0].message !== "Internal server error") {
      console.log(responseJson.data[0].orderid);
      console.log(responseJson.data[0].bdorderid);
      if (responseJson.transaction_id) {
        this.setState({
          transaction_id: responseJson.transaction_id
        })
        setInterval(() => {
          if (this.state.stopPaymentApi === true) {
            this.verifyPayment(responseJson.data[0].orderid, responseJson.data[0].bdorderid, responseJson.transaction_id)
          }
        }, 3000)

      }

      const flow_config = {
        merchantId: responseJson.data[0].mercid,
        bdOrderId: responseJson.data[0].bdorderid,
        authToken: responseJson.data[0].links[1].headers.authorization,
        childWindow: true, 
        retryCount: 3,
        prefs: { "payment_categories": ["card", "emi", "nb", "upi", "wallets", "qr", "gpay"] }
      }

      const responseHandler = function (txn: any) {
        console.log("callback received status:: ", txn.status)
        console.log("callback received response:: ", txn.response)
      }
      const config = {
        responseHandler: responseHandler,
        merchantLogo: tappmart_logo,
        flowConfig: flow_config,
        flowType: "payments"
      }
      console.log("kumar", config.responseHandler)
      setTimeout(()=>{
        window.loadBillDeskSdk(config)
      })
     


      this.setState({ loading: false, openPolicyDetailDrawer: false });
      runEngine.debugLog("arrayHolder", this.state.orderDetails);
    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  verifyOrderApi = (responseJson: any, errorReponse: any) => {
    if (responseJson) {

      console.log(responseJson.payment_status);
      if (responseJson.payment_status === "success") {
        console.log("srccc")
        this.setState({
          stopPaymentApi: false
        })
        this.issueOneAssistPolicyCall()
      }

    } else {

      this.setState({ loading: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  issueOneAssistApi = (responseJson: any, errorReponse: any) => {

    if (responseJson && !responseJson.errors && responseJson.response) {

      let policyType = "One Assist";
      let policyAmount = "" + this.state.selectedPlans;
      let transactionDate = responseJson?.response?.membershipInfo?.startDate;
      let membershipNumber = responseJson?.response?.membershipInfo?.membershipNumber;
      let policyEndDate = responseJson?.response?.membershipInfo?.endDate;
      let ownerName = responseJson?.response?.primaryCustomerName;

      localStorage.setItem('policyType', policyType)
      localStorage.setItem('policyAmount', policyAmount)
      localStorage.setItem('transactionDate', transactionDate)
      localStorage.setItem('membershipNumber', membershipNumber)
      localStorage.setItem('policyEndDate', policyEndDate)
      localStorage.setItem('ownername', ownerName)

      let baseurl = responseJson?.url
      localStorage.setItem('base_url', baseurl)
      this.setState({
        policy_Number: responseJson?.response?.membershipInfo?.membershipNumber.toString()
      })

      this.setState({ loading: false, openToast: true, toastMessage: "Policy Issued Successfully.", toastType: "success" });
      let merchant_id = localStorage.getItem('merchantId')
      if (merchant_id) {
        this.generateCommission()
      }

      setTimeout(() => {

        this.RedirectoSuccessScreen()
      }, 4000)

    }
    else {
      this.setState({ loading: false, openModal: true });
      let merchant_id = localStorage.getItem('merchantId')
      if (merchant_id === "null" || merchant_id === null) {
        setTimeout(() =>
          this.props.navigation.navigate("CustomerHomeScreen")
          , 3000)
      } else {
        setTimeout(() =>
          this.props.navigation.navigate("MerchantPolicyDetails")
          , 3000)
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  getOneAsistPolicyTabsApi = (responseJson: any, errorReponse: any) => {
    if (responseJson && !responseJson.error && responseJson?.data) {
      this.setState({
        policyTabsData: responseJson?.data,
        loading: false
      })
    }
    else {
      this.setState({ loading: false })

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  setCommisionApi = (responseJson: any, errorReponse: any) => {

    if (responseJson && !responseJson.error && responseJson?.data) {
      console.log(responseJson)
    }
    else {
      this.setState({ loading: false })

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
  handleActiveStep = () => {
    let step = this.state.activeStep + 1;
    if (step == 1 && this.validateFirstStep()) {
      this.setState({
        activeStep: step
      })
    }

    if (step == 2 && this.validateSecondStep()) {
      this.setState({
        activeStep: step
      })
    }
    if (step == 3 && this.validateThirdStep()) {
      this.setState({
        activeStep: step
      })
    }

  };

  handleBack = () => {
    this.props.navigation.goBack();
  };
  RedirectToComponent = (component: string) => {
    this.props.navigation.navigate(component);

  };
  redirectToComponent = (component: string, data: any) => {
    localStorage.setItem("planDetails", JSON.stringify(data.amount));
    localStorage.setItem("planCode", JSON.stringify(data.planCode));
    this.props.navigation.navigate(component);


  };

  handleNext = () => {
    this.props.navigation.navigate("OneAssistPolicyPlans");
  };
  handleProceed = (component: string) => {
    if (component) {
      this.props.navigation.navigate("CustomerPolicyTabs", {
        itemId: 86,
        otherParam: 'anything you want here',
      });
    } else {
      let isFirstStepValid = this.validateFirstStep()
      let isThirdStepValid = this.validateThirdStep()
      if (isFirstStepValid && isThirdStepValid) {
        this.oneAssistOnboardCustomer()
        let step = this.state.activeStep;
        this.setState({

          loading: true
        });
      }


    }


  };
  handlePolicyTabsChange = (event: any, value: any) => {
    this.setState({
      value: value,
    });
    this.getPolicyBenefitsData()
  };
  handleFirstName = (e: any) => {
    this.setState({
      firstName: e.target.value,
      isFirstNameValid: true,
    });
  };

  handleDOB = (e: any) => {
    this.setState({
      dateOfBirth: e.target.value,
      isDateOfBirthValid: true
    });
  };
  
  handleEmail = (e: any) => {
    this.setState({
      email: e.target.value,
      isEmailValid: true,
    });
  };
  handlePhoneNumber = (e: any) => {
    this.setState({
      phoneNumber: e.target.value,
      isPhoneNumberValid: true
    });
  };
  handlePinCode = (e: any) => {
    
      this.setState({
        pinCode: e.target.value,
        isPinCodeValid: true
      })
      if (e.target.value.length === 6) {
        this.getAddressDetails(e.target.value)
      }
    

  };
  handleAddressOne = (e: any) => {
    this.setState({
      addressOne: e.target.value,
      isAddress1Valid: true
    });
  };
  
  handleSumInsured = (e: any) => {
    this.setState({
      sumInsured: e.target.value,
    });
  };
   
  handleGender = (e: any) => {
    this.setState({
      gender: e.target.value,
      isGenderValid: true
    });
  };

  handleRelationship = (e: any) => {
    this.setState({
      relationship: e.target.value,
      isRelationshipValid: true
    });
  };
  
  padTo2Digits = (num: any) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date: any) => {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };

  
  validateFirstStep = () => {
    const NameRegex = /^[a-zA-Z ]{4,30}$/;
    const mobileRegex = /^(\+\d{1,3}\s?)?(\()?[^0-5]\d{2}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isFirstStepValid = true;
    if (this.state.firstName == "" || !NameRegex.test(this.state.firstName)) {
      this.setState({
        isFirstNameValid: false,
      });
      isFirstStepValid = false;
    }
    if (
      this.state.phoneNumber == "" ||
      !mobileRegex.test(this.state.phoneNumber)
    ) {
      this.setState({
        isPhoneNumberValid: false,
      });
      isFirstStepValid = false;
    }
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        isEmailValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.dateOfBirth == "") {
      this.setState({
        isDateOfBirthValid: false,
      });
      isFirstStepValid = false;
    }
    if (this.state.gender == "") {
      this.setState({
        isGenderValid: false,
      });
      isFirstStepValid = false;
    }
    if (new Date(this.state.dateOfBirth) > new Date(this.state.maxYear)) {
      this.setState({
        isDateOfBirthValid: false,
      });
      isFirstStepValid = false;
    }

    return isFirstStepValid;
  };

  validateSecondStep = () => {
    let isSecondStepValid = true;
    if (this.state.productName == "") {
      this.setState({
        isProductNameValid: false

      })
      isSecondStepValid = false;
    }
    if (this.state.productCompany == "") {

      this.setState({
        isBrandValid: false,
      })
      isSecondStepValid = false;

    }
    if (this.state.modelNumber == "" || this.state.modelNumber.length > 50) {
      this.setState({
        isModelValid: false,
      })
      isSecondStepValid = false;

    }
    if (this.state.imeiOne.length < 7 || this.state.imeiOne.length > 25) {
      this.setState({
        isimeiOneValid: false,
      })
      isSecondStepValid = false;
    }
    if (this.state.invoiceValue == "") {
      this.setState({
        isInvoiceValueValid: false
      })
      isSecondStepValid = false;
    }
    if (this.state.purchaseDate == "") {
      this.setState({
        isPurchaseDateValid: false
      })
      isSecondStepValid = false;
    }



    return isSecondStepValid
  }
 
  handleAddressType = (e: any) => {
    this.setState({
      addressType: e.target.value,
      isAddressTypeValid: true
    })
  }

  validateThirdStep = () => {
    let isThirdStepValid = true;
    if (this.state.addressOne.length > 500 || this.state.addressOne == "") {
      this.setState({
        isAddress1Valid: false

      })
      isThirdStepValid = false;
    }
    if (this.state.pinCode.length != 6 || this.state.pinCode == "") {
      this.setState({
        isPinCodeValid: false

      })
      isThirdStepValid = false;
    }
    if (this.state.city == "") {
      this.setState({
        isCityValid: false
      })
      isThirdStepValid = false;
    }
    if (this.state.selectedState == "") {
      this.setState({
        isStateValid: false
      })
      isThirdStepValid = false;
    }

    return isThirdStepValid
  }
  handleAddressTwo = (e: any) => {
    this.setState({
      addressTwo: e.target.value,
      isAddress2Valid: true
    })
  }
  handlelandMark = (e: any) => {
    this.setState({
      landmark: e.target.value,
      isLandMarkValid: true
    })
  }
  
  formatMonth = (date: any) => {

    let monthNames = ["Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"];
    let m = date.split("-")
    let md = m[1]
    let month = monthNames[Number(md) - 1]
    let newMonth = `${m[2]}-${month}-${m[0]}`
    return newMonth;


  }
  formatDateTwo = (date: any) => {
    let monthNames = ["Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"];
    let m = date.split("/")

    let md = m[1]
    let month = monthNames[Number(md) - 1]
    let newMonth = `${m[2]}-${month}-${m[0]}`
    return newMonth;
  }
  
  getAddressDetails = (pin: any) => {
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLocationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pinCodeMaster_url + "?pin_code=" + pin
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  oneAssistOnboardCustomer = () => {

    let revDate = this.state.applicationDate.split("/").reverse().join("/");
    let appDate = this.formatDateTwo(revDate)

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let merchant_id = localStorage.getItem('merchantId')
    let splitName = []
    splitName = this.state.firstName.split(' ')

    const httpBody = {


      "initiatingSystem": 2,
      "customerInfo": [
        {
          "firstName": this.state.firstName,

          "emailId": this.state.email,
          "mobileNumber": this.state.phoneNumber,
          "full_phone_number": "91" + this.state.phoneNumber,
          "relationship": this.state.relationship,
          "assetInfo": []
        }
      ],
      "addressInfo": [
        {
          "addrType": this.state.addressType,
          "addrLine1": this.state.addressOne,
          "landmark": "testLandmark",
          "pinCode": this.state.pinCode,
          "cityCode": this.state.cityCode,
          "stateCode": this.state.stateCode
        }
      ],
      "orderInfo": {
        "partnerCode": "1687",
        "partnerBUCode": "95045",
        "planCode": this.state.planCode,
        "applicationNo": this.state.applicationNumber,
        "applicationDate": appDate
      },
      "paymentInfo": {
        "paymentMode": "COD"
      },
      "merchant_id": merchant_id,
      "insurance_type": "One Assist",

      id: this.state.id

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.oneAssistCustomerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneAssistUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
 

  handlePlanName = (e: any) => {
    let planCode = e.target.value
    let data = this.state.planMasterData.filter((item: any) => item.attributes.code == planCode)
    let planPrice = data[0]?.attributes?.price

    this.setState({
      planCode: planCode,
      planPrice: planPrice,
      isPlanCodeValid: true
    })
  }
 
  validateLastStep = () => {
    let isLastStepValid = true;
    if (this.state.applicationStartDate == "") {
      this.setState({
        isApplicationStartDateValid: false
      })
      isLastStepValid = false;
    }
    if (this.state.serviceList == "") {
      this.setState({
        isServiceListValid: false
      })
      isLastStepValid = false;
    }
    if (this.state.planCode == "") {
      this.setState({
        isPlanCodeValid: false
      })
      isLastStepValid = false;
    }

    return isLastStepValid;
  }
  createPayOrder = async () => {
    this.setState({ loading: true })

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let id = this.state.Account_ID
    const httpBody = {
      amount: this.state.selectedPlans,
      account_id: id,product_id:this.state.paymentDetails
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_order_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyPayment = (orderid: any, bdOrderId: any, transaction_id: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
      "account_id": this.state.Account_ID,
      "transaction_id": transaction_id,
      "order_id": orderid,
      "bdorder_id": bdOrderId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOrderAPICalledID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verify_signature_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  issueOneAssistPolicyCall = () => {

    this.setState({ loading: true })
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id = this.state.Account_ID
    const httpBody = {
      "account_id": account_id,
      "transaction_id": parseInt(this.state.transaction_id),
      "payment_status": true,total_premium:this.state.selectedPlans

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.issueOneAssistPolicyID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneAssistIssuePolicyUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  RedirectoSuccessScreen = () => {
    this.setState({ loading: false });
    this.props.navigation.navigate('CustomerOrderSuccess');
  }

  validateDateOfBirth = (tday: any) => {

    let tYear = tday.split("/")[2];
    let maxYear = parseInt(tYear) - 18;
    this.setState({ maxYear: maxYear + "-06-10" })
  }
  redirectToHomeScreen = () => {
    this.props.navigation.navigate('CustomerHomeScreen');
  }
  getOneAssistPolicyDetails = () => {
    console.log("cbcdjjddbcCAA")
    this.setState({ loading: true })
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOneAssistPolicyTabsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneAssistPolicyTabsEndPoint + "?name=One Assist"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPolicyBenefitsData() {

    if (this.state.policyTabsData?.attributes?.PolicyDetail?.policy_benefits) {
      let benefitsDiv: any = document.createElement('div')

      benefitsDiv.innerHTML = this.state.policyTabsData?.attributes?.PolicyDetail?.policy_benefits
      let pDiv = document.getElementById('policyBenefits')
      pDiv?.appendChild(benefitsDiv)
    }


  }
  closeModel = () => {
    this.setState({
      openModal: false
    })
    this.redirectToHomeScreen()
  }
  generateCommission = () => {
    let merchant_id = localStorage.getItem('merchantId')
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = this.state.Account_ID
    let transactionDate = localStorage.getItem('transactionDate');
    let policyAmount: any = localStorage.getItem('policyAmount');
    let product_id: any = localStorage.getItem('product_id');
    const httpBody = {
      "data": {
        "account_id": parseInt(account_id),
        "insurance_type": "One Assist",
        "merchant_id": merchant_id,
        "date_of_purchase": transactionDate,
        "premium_paid": parseInt(policyAmount),
        "product_id": parseInt(product_id),


      }


    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateCommissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateCommissionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getPolicySms = () => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = this.state.Account_ID
    const httpBody = {

      "data": {
        "account_id": parseInt(account_id),
        "policy_name": "One Assist",
        "platform": "Tappmart"
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPolicySmsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPolicySmsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  sendMessages = (status: any) => {

    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    let account_id: any = this.state.Account_ID

    const httpBody = {
      "data": {
        "account_id": parseInt(account_id),
        "platform": "Tappmart",
        "payment_status": status,
        "policyNumber": this.state.policy_Number
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessagesApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendMessagesUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Customizable Area End
}
