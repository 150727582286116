import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Paper,
  Button,
  Container,
  Typography,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import MerchantLoginIconHeader from "./MerchantLoginIconHeader.web";
import {
  sales,
  background,
  tappoints,
  customers,
  catalogue,
  next_page,
  order_history,
  tagPng

} from "./assets";
import DashboardControllerThree from "./DashboardControllerThree";
// Customizable Area End

// Customizable Area Start
class Dashboard extends DashboardControllerThree {

  render() {
    const { navigation } = this.props;
    return (
      <>
      {/*@ts-ignore */}
        <MerchantLoginIconHeader {...this.props} />
        {this.state.isCustomerLoading? <Backdrop
                    style={{ zIndex: 1000 + 1, color: "#fff" }}
                    open={this.state.isCustomerLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>:""}
        <div style={style.image}>
          <Container maxWidth="sm">
            {/*@ts-ignore */}
            <Box sx={style.dashboard}>
              <Typography variant="h6">Dashboard</Typography>
              <Button style={style.salesText} variant="outlined">
                <img src={sales} alt="" height="20" width="20" />
                <Typography
                  style={{ marginLeft: 5, color: "white" }}
                  variant="body1"
                >
                  SALESTEAM01
                </Typography>
              </Button>
            </Box>
            <Grid style={style.gridContainer} container spacing={2}>
              <Grid item xs={6}>
                <Paper>
                  {/*@ts-ignore */}
                  <Box sx={style.box}>
                    <img src={customers} alt="" height="60" width="60" />
                    {/*@ts-ignore */}
                    <Box sx={style.list}>
                      <Typography gutterBottom variant="h6">
                        <b>Customers</b>
                      </Typography>
                      <Typography variant="inherit">{this.state.customerCount>1?this.state.customerCount+" Customers":this.state.customerCount+" Customer"}</Typography>
                    </Box>
                    <img
                      style={{cursor: 'pointer'}}
                      src={next_page}
                      data-test-id="imgHandleCustomers"
                      alt=""
                      height="25"
                      width="25"
                      onClick={this.handleCustomers}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  {/*@ts-ignore */}
                  <Box sx={style.box}>
                    <img src={catalogue} alt="" height="60" width="60" />
                    {/*@ts-ignore */}
                    <Box sx={style.list}>
                      <Typography gutterBottom variant="h6">
                        <b>Catalogue</b>
                      </Typography>
                      <Typography variant="inherit">{this.state?.productsCount >0 ? this.state.productsCount +" Products": this.state.productsCount +" Product"}</Typography>
                    </Box>
                    <img
                      style={{cursor: 'pointer'}}
                      src={next_page}
                      alt=""
                      height="25"
                      width="25"
                      onClick={() => navigation.navigate("MerchantCatalogue")}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  {/*@ts-ignore */}
                  <Box sx={style.box}>
                    <img src={order_history} alt="" height="60" width="60" />
                    {/*@ts-ignore */}
                    <Box sx={style.list}>
                      <Typography gutterBottom variant="h6">
                        <b>Order History</b>
                      </Typography>
                      <Typography variant="inherit">{this.state.customerCount>1?this.state.customerCount+" Customers":this.state.customerCount+" Customer"}</Typography>
                    </Box>
                    <img
                      style={{cursor: 'pointer'}}
                      src={next_page}
                      alt=""
                      height="25"
                      width="25"
                      onClick={() => navigation.navigate("OrderHistory")}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  {/*@ts-ignore */}
                  <Box sx={style.box}>
                    <img src={tappoints} alt="" height="60" width="60" />
                    {/*@ts-ignore */}
                    <Box sx={style.list}>
                      <Typography gutterBottom variant="h6">
                        <b>Tappoints</b>
                      </Typography>
                      <Typography variant="inherit">
                        {this.state.tapPoints.toFixed(2)}
                      </Typography>
                    </Box>
                    <img
                      style={{cursor: 'pointer'}}
                      src={next_page}
                      alt=""
                      data-test-id="imgHandleTappoints"
                      height="25"
                      width="25"
                      onClick={this.handleTappoints}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  {/*@ts-ignore */}
                  <Box sx={style.box}>
                    <img src={tagPng} alt="" height="80" width="100" />
                    {/*@ts-ignore */}
                    <Box sx={style.list}>
                      <Typography gutterBottom variant="h6">
                        <b>Manage Tags</b>
                      </Typography>
                      <Typography variant="inherit">
                         
                      </Typography>
                    </Box>
                    <img
                      style={{cursor: 'pointer'}}
                      src={next_page}
                      alt=""
                      data-test-id="imgHandleManageTags"
                      height="25"
                      width="25"
                      onClick={this.handleManageTags}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const style = {
  image: {
    color: "white",
    height: "150%",
    paddingBottom: "25px",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  salesText: {
    borderRadius: 20,
  },
  dashboard: {
    p: 0.5,
    mt: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    mt: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  list: {
    mt: 6,
    mb: 1.5,
  },
  box: {
    p: 2,
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    marginTop: 3,
  },
};
// Customizable Area End
export default Dashboard;
