export const checkedIcon = require('../assets/checkedIcon.png')
export const homeIcon = require('../assets/home.png')
export const healthIcon = require('../assets/health.png')

export const travelIcon = require('../assets/travel.png')
export const twoWheelIcon = require('../assets/twoWheel.png')
export const fourWheelIcon = require('../assets/fourWheel.png')
export const rightArrowIcon = require('../assets/rightArrow.png')
export const tapsureLogo = require('../assets/tappmartLogo.png')
export const tappmart_logo = require("../../dashboard/assets/tappmartLogo.png");
 
export const starIns = require('../assets/starIns.png')
export const calendarIcon = require('../assets/calendarIcon.png')
export const premiumIcon = require('../assets/premiumIcon.png')

export const iciciIcon = require('../assets/iciciIcon.png')
export const nivaHealthInsIcon = require('../assets/nivaHealthInsIcon.png')

export const zeroHassleIcon = require('../assets/zeroHassleIcon.png')

export const healthBoosterIcon = require('../assets/healthBoosterIcon.png')
export const familyProtectIcon = require('../assets/familyProtectIcon.png')
export const nomedicalIcon = require('../assets/nomedicalIcon.png')

export const pinkDownArrowIcon = require('../assets/pinkDownArrowIcon.png')

export const digitIcon = require('../assets/digitIcon.png')
export const closeIcon = require('../assets/closeIcon.png')




export const gPay = require('../assets/gpay.png')
export const visaIcon = require('../assets/visa.png')
export const masterCard = require('../assets/masterCard.png')
export const cardsIcon = require('../assets/cardsIcon.png')
export const bankTransferIcon= require('../assets/bankTransferIcon.png')


export const healthInsIcon= require('../assets/healthInsIcon.png')

export const editIcon= require('../assets/editIcon.png')
export const iciciLomIcon= require('../assets/iciciLomIcon.png')

export const aboutHomeIcon= require('../assets/aboutHomeIcon.png')
export const uncheckedIcon= require('../assets/uncheckedIcon.png')

export const backIcon= require('../assets/backIcon.png')
export const travelIcon2= require('../assets/travelIcon.png')
export const backgroundImage= require('../assets/backgroundImage.png')