import React, { Component } from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Drawer from "@material-ui/core/Drawer";
import Loader from "../../../components/src/Loader";
import { closeIcon, tapsureLogo, backgroundImage, backIcon } from "./assets";

import CustomerICICICyberFraudController from "./CustomerICICICyberFraudController2";
import { icici_logo } from "../../dashboard/src/assets";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
tapsureLogo;
// Customizable Area End

// Customizable Area Start

export default class CustomerICICICyberFraud extends CustomerICICICyberFraudController {
  getStepContent(stepIndex: number) {
    const {
      firstName,
      lastName,
      gender,
      dateOfBirth,
    } = this.state.personalDetailsForm;
    const {
      email,
      phoneNumber,
      pinCode,
    } = this.state.contactDetailsForm;
    const {
      sumToBeInsured,
    } = this.state.policyDetailsForm;
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Personal Details</h3>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-select-gender"
                select
                fullWidth
                label="Select Gender"
                error={this.state.isErrorPersonal.gender ? true : false}
                name="gender"
                value={gender}
                onChange={this.handleChangePersonal}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                {/* <MenuItem value="Other">Others</MenuItem> */}
              </CustomInputFieldCustomerCyberFraud>
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.gender}
              </div>
            </Grid>
           
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                {" "}
                First Name
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                placeholder=" First Name"
                variant="outlined"
                error={this.state.isErrorPersonal.firstName ? true : false}
                name="firstName"
                value={firstName}
                onChange={this.handleChangePersonal}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.firstName}
              </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                Last Name
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                placeholder="Last Name"
                error={this.state.isErrorPersonal.lastName ? true : false}
                name="lastName"
                value={lastName}
                onChange={this.handleChangePersonal}
                variant="outlined"
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.lastName}
              </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                Date Of Birth
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-start-adornment"
                type="date"
                className={""}
                error={this.state.isErrorPersonal.dateOfBirth ? true : false}
                name="dateOfBirth"
                value={dateOfBirth}
                onChange={this.handleChangePersonal}
                variant="outlined"
                placeholder="Date of Birth"
                InputProps={{ inputProps: { max: moment().format('YYYY-MM-DD') } }}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.dateOfBirth}
              </div>
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <h3> Contact Details</h3>
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                Email ID
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                variant="outlined"
                type="email"
                placeholder="Email ID"
                name="email"
                value={email}
                onChange={this.handleChangeContact}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.email}
              </div>
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                Phone Number
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                variant="outlined"
                placeholder="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChangeContact}
                inputProps={{ maxLength: 10 }}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.phoneNumber}
              </div>
            </Grid>

            
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                Pincode
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                variant="outlined"
                placeholder="Pincode"
                name="pinCode"
                value={pinCode}
                onChange={this.handleChangeContact}
                inputProps={{ maxLength: 6 }}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.pinCode}
              </div>
              {
                (this.state.inValidpinCode && !this.state.isErrorContact.pinCode) && (
                  <div style={{ marginTop: 5, color: "red" }}>
                    This is not a valid pin code!
                  </div>
                )
              }
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
               {/*@ts-ignore */}
              <CustomInputLabelCustomerICICICyberFraud htmlFor="component-simple">
                City
              </CustomInputLabelCustomerICICICyberFraud>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-basic"
                placeholder="City"
                variant="outlined"
                name="city"
                value={this.state.city}
                // onChange={this.handleChangeContact}
                disabled
                defaultValue={"pune"}
              />
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorContact.city}
              </div>
            </Grid>
            <Grid item sm={12} md={12} xs={12} style={{ margin: '5px 0px' }}>
               {/*@ts-ignore */}
              <CustomInputFieldCustomerCyberFraud
                id="outlined-select-title"
                select
                fullWidth
                label="Sum to be Insured"
                name="sumToBeInsured"
                error={this.state.isErrorPersonal.title ? true : false}
                value={sumToBeInsured}
                onChange={this.handleChangePolicy}
              >
                {
                  this.policyList.map((data, index) => {
                    return (
                      <MenuItem value={data.amount} key={index}>{data.amount}</MenuItem>
                    )
                  })
                }
              </CustomInputFieldCustomerCyberFraud>
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPersonal.title}
              </div>
              <div style={{ marginTop: 5, color: "red" }}>
                {this.state.isErrorPolicy.sumToBeInsured}
              </div>
            </Grid>
          </>
        );
     
      default:
        return "Unknown stepIndex";
    }
  }
  render() {
    return (
      <>
        {/* Logo section */}
        <Snackbar open={this.state.openToast} autoHideDuration={1000} style={{ margin: "10px" }}>
          <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
            {this.state.toastMessage}
          </Alert>
        </Snackbar>
        <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
           {/*@ts-ignore */}
          <Modal
            open={this.state.openModal}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modalClass">
              <div style={webStyleCustomerICICICyberFraud.modalStyle}>
                <div>
                  <InfoIcon style={{ marginTop: '15px' }} />
                </div>
                <div style={{ padding: 5 }}>
                  <h4>We regret that your policy creation has failed at our end. In case payment is done, it will be  refunded within next 72 hours.</h4>
                </div>
                <div>
                  <Button
                    onClick={() => this.closeModel()} >
                    <img height="15px" src={closeIcon} alt="" />
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Backdrop>

        <Grid container spacing={2}>
          <Grid item>
            <img
              height="30px"
              width="100px"
              src={tapsureLogo}
              style={{ margin: "10px 20px" }}
              onClick={() => this.redirectToComponent("CustomerHomeScreen", '')}
            />
          </Grid>
        </Grid>{" "}
        {/* Steps-Container */}
         {/*@ts-ignore */}
        <FormContainerCustomerICICICyberFraud container className={this.state.activeStep === 2 ? "UIClass" : ""}>
          <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ margin: "10px" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={""}>
               {/*@ts-ignore */}
              <FormStepperCustomerICICICyberFraud activeStep={this.state.activeStep} alternativeLabel>
                {this.state.steps.map((label: string) => (
                  <Step key={label}>
                     {/*@ts-ignore */}
                    <CustomStepLabel>{label}</CustomStepLabel>
                  </Step>
                ))}
              </FormStepperCustomerICICICyberFraud>
              <Grid style={this.state.activeStep === 2 ? formDivStyle2 : formDivStyle}>
                {this.state.activeStep === this.state.steps.length ? (
                  <div>
                    <Typography className={""}>All steps completed</Typography>
                    <Button>Reset</Button>
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    {this.getStepContent(this.state.activeStep)}
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </FormContainerCustomerICICICyberFraud>
        {/* Premium section */}
         {/*@ts-ignore */}
        <BottomContainer container spacing={2}>
          {this.state.activeStep === 1 ? (
            <>
              <Grid item sm={7} lg={7} xs={7}>
                <Typography>Total Premium</Typography>
                <span>
                  {this.state.policyDetailsForm.premium}
                  <ExpandMoreIcon onClick={() => this.handleProceed()} />
                </span>
              </Grid>
              <Grid item sm={5} lg={5} xs={5}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => { this.handleProceed(); this.handleActiveStep() }}
                  >
                    Proceed
                  </Button>
                </div>
              </Grid>
            </>
          ) : (
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleActiveStep}
                style={{ width: "100%" }}
              >
                NEXT
              </Button>
            </Grid>
          )}
        </BottomContainer>
        <Loader loading={this.state.loading} />
        {/* Drawer Section */}
         {/*@ts-ignore */}
        <CustomDrawerCustomerICICICyberFraud
          open={this.state.openPolicyDetailDrawer}
          onClose={this.handleDrawerClose}
          anchor={"bottom"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyleCustomerICICICyberFraud.paddingLeftBottom}
          >
            <Grid item>
              <h3>Payment Details</h3>
            </Grid>
            <Grid item>
              <Button onClick={this.handleDrawerClose}>
                <img
                  style={{ marginRight: "-30px" }}
                  height="15"
                  width="15"
                  src={closeIcon}
                  alt=""
                />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyleCustomerICICICyberFraud.paddingLeftBottom}
          >
            <Grid item>
              <img style={webStyleCustomerICICICyberFraud.iciciImage} src={icici_logo} alt="" />
            </Grid>
            <Grid item>
              <div>Total Premium (Inc 18% GST)</div>
              <h3 style={{ float: "right" }}>
                <b>&#x20B9; {this.state.policyDetailsForm.premium}</b>
              </h3>
            </Grid>
          </Grid>

           {/*@ts-ignore */}
          <DrawerContainersCustomerICICICyberFraud
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyleCustomerICICICyberFraud.paddingLeftBottom}
          >
            
          </DrawerContainersCustomerICICICyberFraud>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={webStyleCustomerICICICyberFraud.paddingLeftBottom}
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  this.handleDrawerClose();
                  this.createPayOrder();
                }
                }
                style={{
                  width: "100%",
                  marginTop: "60px",
                  marginBottom: "45px",
                  textTransform: 'none'
                }}
              >
                Make Payment
              </Button>
            </Grid>
          </Grid>
        </CustomDrawerCustomerICICICyberFraud>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const FormStepperCustomerICICICyberFraud = withStyles({
  root: {
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    padding: "15px 5px",
    background: "inherit",
  },
})(Stepper);
const FormContainerCustomerICICICyberFraud = withStyles({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const formDivStyle = {
  backgroundColor: "white",
  padding: "10px 15px",
  borderRadius: "10px",
  margin: "10px",
};
const formDivStyle2 = {
  backgroundColor: "white",
  padding: "10px 15px",
  borderRadius: "10px",
  margin: "10px",
  height: '78%'
};
const BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const CustomStepLabel = withStyles({
  root: {
    color: "white",
    "& .MuiStepLabel-labelContainer": {
      color: "white",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "white",
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "White",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "white",
        border: 'none'
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "transparent",
      },
      "& .MuiStepIcon-root": {
        border: "1px solid white",
        borderRadius: "12px",
        color: "transparent",
      }
    },
    font:
      "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(StepLabel);

const webStyleCustomerICICICyberFraud: any = {
  greyText: {
    color: "grey",
    marginTop: "5px",
  },
  paddingLeftBottom: {
    padding: "0 15px",
  },
  marginBottom: "15px",
  drawerSectionStyle: {
    borderBottom: "1px solid #4a444430",
    padding: "0 15px",
  },
  marginZero: {
    margin: "0px",
  },
  iciciImage: {
    padding: "10px",
    borderRadius: "5px",
    height: "70px",
    width: "70px",
  },
  checkedRadio: {
    border: "1px solid #4054b5",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#4054b5",
    fontWeight: "bold",
  },
  unCheckedRadio: {
    border: "1px solid #e1e1e1",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#848484",
    fontWeight: "bold",
  },
  modalStyle: {
    color: "grey",
    display: "flex",
    alignItems: "baseline",
    backgroundColor: "white",
    padding: "5px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    borderRadius: "7px"
  }
};

const InputField = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

const CustomInputLabelCustomerICICICyberFraud = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);

const CustomDrawerCustomerICICICyberFraud = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      padding: "0 5px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Drawer);
const DrawerContainersCustomerICICICyberFraud = withStyles({
  root: {
    padding: "0 15px",
    height: "40px",
  },
})(Grid);

const CustomInputFieldCustomerCyberFraud = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField);
// Customizable Area End
