import React from "react";

// Customizable Area Start
import {
  Grid,
  Button,
  
} from "@material-ui/core";
import {
  
  withStyles,
} from "@material-ui/core/styles";

import {
  tappmart_logo,
  
  premiumIcon,
 
  backgroundImage,
  backIcon
} from "./assets";
 
import CustomerTravelInsController2  from "./CustomerTravelInsController2";
// Customizable Area End

// Customizable Area Start
export default class CustomerTravelPlans extends CustomerTravelInsController2 {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {" "}
        <Grid container spacing={2}>
          <Grid item>
            <img
            src={tappmart_logo} 

            className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen",'')}
            />
          </Grid>
        </Grid>{" "}
         {/*@ts-ignore */}
        <MainContainer container spacing={2}>
        <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ margin: "10px" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          {/* Arrow Container */}
          <Grid container>
            <Grid>
              <h3 style={{ color: "white", marginLeft: "15px" }}>
                {'ICICI Travel Plans'
                  
                }</h3>
            </Grid>
          </Grid>

          {/* Insurance Head */}
          {
            this.travelPolicyPlans.map((data, index) => {
              return (
                <Grid container style={childContainer} key={index}>
                  <Grid item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      
                    </div>
                  </Grid>

                  {/* Policy Cards */}

                  <Grid item sm={12} xs={12} lg={12}>
                    <Grid container direction="row" justifyContent="space-between"
                      style={{ flexWrap: "nowrap", marginTop: 5 }}>
                      <Grid
                        item
                        style={customerTravelStyle}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"


                        >
                          <Grid item>
                            <img src={premiumIcon} height="20px" style={duration1TravelStyle} />
                          </Grid>
                          <Grid item style={durationTravelStyle}>
                            <p style={{ margin: "2px" }}>Duration</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.duration}</h3>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{
                          border: "1px solid #3451af",
                          padding: "10px 15px",
                          margin: "15px 0px 10px 5px",
                          borderRadius: "6px",
                          width: "50%"
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <img src={premiumIcon} height="25px" style={{ marginLeft: '-25px' }} />
                          </Grid>
                          <Grid item style={{ margin: "5px" }}>
                            <p style={{ margin: "2px" }}>Total Premium</p>
                            <h3 style={{ margin: "2px", color: "5254b3" }}>{data.amount}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => this.RedirectToComponent('CustomerTravelDetailsSteps',data)}
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              )
            })
          }

        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start

const MainContainer = withStyles({
  root: {
    backgroundImage:
      `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "5px",
    marginTop: "10px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",

  },
})(Grid);

const childContainer = {
  padding: "10px",
  font: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",


  borderRadius: "5px",

  margin: "15px",
  backgroundColor: "white",
};

const customerTravelStyle={
    border: "1px solid #3451af",
    padding: "10px 15px",
    margin: "15px 0px 10px 5px",
    borderRadius: "6px",
    width: "50%"
}

const durationTravelStyle={
   margin: "5px" 
}

const duration1TravelStyle={
   marginLeft: '-25px' 
}
// Customizable Area End
