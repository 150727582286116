import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  activeStep: number;
  steps: any;
  innerStep: number; 
  value: number; 
  assureURL: string;
  isLoading: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerTwoWheelerController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getAssure_UrlId: any;
  getAssure_UrlTwoId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      activeStep: 0,
      steps: ["Personal Details", "Pre-existing Disease", "Contact Details"],
      innerStep: 0, 
      value: 0, 
      assureURL: "",
      isLoading: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAssure_UrlId != null &&
      this.getAssure_UrlId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.url) {
        this.setState({
          assureURL: responseJson.url,
          isLoading: false,
        });
      } else {
        console.log("NOT FOUND");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAssure_UrlTwoId != null &&
      this.getAssure_UrlTwoId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.url) {
        this.setState({
          assureURL: responseJson.url,
          isLoading: false,
        });
      } else {
        console.log("NOT FOUND");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    if (window.location.pathname == "/CustomerTwoWheelerSteps") {
      this.getAssureUrl();
    } else {
      this.getAssureUrlTwo();
    }
    
    
  }
 
  RedirectToComponent = (component: string) => {
    console.log("chjcds");
    this.props.navigation.navigate(component);
  };

  getAssureUrl = () => {
    // Customizable Area Start
    let url=new URL(window.location.href)

      let merchantId:any=url.searchParams.get("merchantId");
      let product_id:any=url.searchParams.get("product_id");
      if(merchantId && product_id){
        localStorage.setItem('merchantId',merchantId) 
        localStorage.setItem('product_id',product_id)
      }
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
        merchant_id: merchantId,
        product_id
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssure_UrlId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assure_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
 
  getAssureUrlTwo = () => {
    // Customizable Area Start
    let url=new URL(window.location.href)

      let merchantId:any=url.searchParams.get("merchantId");
      let product_id:any=url.searchParams.get("product_id");
      
      localStorage.setItem('merchantId',merchantId) 
      localStorage.setItem('product_id',product_id)
    // let merchantId=window.location.href.split("=")[1]
    const headers = {
      "Content-Type": configJSON.assureUrlContentType,
    };
    const httpBody = {
      merchant_id: merchantId,
      product_id
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssure_UrlTwoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assure_urlTwo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.assure_methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  // Customizable Area End
}
