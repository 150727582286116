import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Modal,
  Button,
  Container,
  Typography,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import MerchantLoginHeader from "../../mobile-account-login/src/MerchantLoginHeader.web";
import { background } from "../../mobile-account-registration/src/assets";
import { close, checked, unchecked } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

//@ts-ignore
import OtpInput from "react-otp-input";
import AdditionalDetailFormController from "./AdditionalDetailFormController";
import MerchantBasicDetails from "./MerchantBasicDetails.web";
import CongratulationsPage from "./CongratulationsPage.web";
import { Markup } from "interweave";
// Customizable Area End

export default class AdditionalDetailForm extends AdditionalDetailFormController {

  render() {
    // Customizable Area Start
    const { mobile, salesCode } = this.state.formData;
    // console.log("this.state.getSalesCodes", this.state.getSalesCodes);
    // console.log("this.state.formData", this.state.formData);
    return (
      <>
        {!this.state.congrats ? (
          <>
          {/*@ts-ignore */}
            <MerchantLoginHeader {...this.props} />
            <div style={style.image}>
              {!this.state.otp ? (
                <Container maxWidth="sm">
                  {!this.state.isLoading ?
                    <Paper elevation={2}>
                      {/*@ts-ignore */}
                      <Box sx={style.container}>
                        <form onSubmit={this.handleSubmit}>
                          <Typography
                            style={{ marginBottom: "15px" }}
                            variant="h6"
                          >
                            <b>Create an account</b>
                          </Typography>
                          {/*@ts-ignore */}
                          <Box>
                            <Typography gutterBottom variant="body1">
                              Phone Number
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Phone Number"
                              type="number"
                              name="mobile"
                              value={mobile}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +91
                                  </InputAdornment>
                                ),
                              }}
                              error={this.state.isError.mobile ? true : false}
                              onChange={this.handleChange}
                            />
                            <div style={{ marginTop: 5, color: "red" }}>
                              {this.state.isError.mobile}
                            </div>
                          </Box>
                          {/*@ts-ignore */}
                          <Box sx={{ mt: 2 }}>
                            <Typography gutterBottom variant="body1">
                              Sales Code
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Sales Code"
                              name="salesCode"
                              value={salesCode}
                              error={this.state.isError.salesCode ? true : false}
                              onChange={this.handleChange}
                            />
                            <div style={{ marginTop: 5, color: "red" }}>
                              {this.state.isError.salesCode}
                            </div>
                          </Box>
                          {/*@ts-ignore */}
                          <Box sx={style.checkbox}>
                            {
                              <img
                                style={style.checkboxImage}
                                src={!this.state.flag ? unchecked : checked}
                                alt=""
                                height="20"
                                width="20"
                                onClick={() => this.handleToggle()}
                              />
                            }
                            <Typography style={style.text} variant="caption">
                              I agree with{" "}
                              <span
                                style={style.termsPolicy}
                                onClick={this.handleTermsModal}
                              >
                                Terms &#38; Conditions
                              </span>{" "}
                              and{" "}
                              <span
                                style={style.termsPolicy}
                                onClick={this.handlePrivacyModal}
                              >
                                Privacy Policy
                              </span>
                            </Typography>
                          </Box>
                          <div style={{ marginTop: 5, color: "red" }}>
                            {this.state.isError.flag}
                          </div>
                          <Button
                            style={style.continueBtn}
                            size="large"
                            fullWidth
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Continue
                          </Button>
                        </form>
                      </Box>
                    </Paper>
                    :
                    <Backdrop
                      style={{ zIndex: 1000 + 1, color: "#fff" }}
                      open={this.state.isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  }
                </Container>
              ) : (
                <>
                  {!this.state.otpMatch ? (
                    <Container maxWidth="sm">
                      {!this.state.isLoadingOtp ?
                        <Paper elevation={2}>
                          {/*@ts-ignore */}
                          <Box sx={style.containerOtp}>
                            <form onSubmit={this.handleOtpSubmit}>
                              <Typography
                                style={{ marginBottom: "20px" }}
                                variant="h6"
                              >
                                <b>Enter OTP</b>
                              </Typography>
                              <Typography gutterBottom variant="body1">
                                A Verification code has been sent to your given
                                phone <b>+91 {mobile}</b>
                              </Typography>
                              {/*@ts-ignore */}
                              <Box sx={style.textfield}>
                                {/*@ts-ignore */}
                                <OtpInput
                                  inputStyle={{
                                    height: "50px",
                                    width: "50px",
                                    fontSize: "18px",
                                    border: "1px solid black",
                                    marginRight: 15,
                                    marginLeft: 15,
                                  }}
                                  value={this.state.otpInput}
                                  onChange={this.otpHandleChange}
                                  numInputs={4}
                                  isInputNum={true}
                                  shouldAutoFocus={true}
                                />
                              </Box>
                              <div
                                style={{
                                  marginTop: 5,
                                  color: "red",
                                  textAlign: "center",
                                }}
                              >
                                {this.state.isErrorOtp.otpInput}
                              </div>
                              <Button
                                style={style.verifyBtn}
                                size="large"
                                fullWidth
                                color="primary"
                                variant="contained"
                                type="submit"
                              >
                                Verify OTP
                              </Button>
                              {this.state.seconds > 0 ? (
                                <Typography
                                  gutterBottom
                                  align="center"
                                  variant="body1"
                                >
                                  Resend Code{" "}
                                  {`${0}${this.fmtMSS(this.state.seconds)}`}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{ textDecoration: "underline" }}
                                  gutterBottom
                                  align="center"
                                  variant="body1"
                                  onClick={() => { this.signUp(); this.handleClickResendOtp(); }}
                                >
                                  Resend Code
                                </Typography>
                              )}
                            </form>
                            <Dialog
                              open={this.state.openResendOtp}
                              onClose={this.handleCloseResendOtp}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              {/* <DialogTitle id="alert-dialog-title">
                                {"Login"}
                              </DialogTitle> */}
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  OTP sent successfully.
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={this.handleCloseResendOtp} autoFocus>
                                  Ok
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        </Paper>
                        :
                        <Backdrop
                          style={{ zIndex: 1000 + 1, color: "#fff" }}
                          open={this.state.isLoadingOtp}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      }
                    </Container>
                  ) : (
                    /*@ts-ignore */
                    <MerchantBasicDetails
                      {...this.props}
                      oldProps={this}
                      salesCodeProp={this.state.formData.salesCode}
                      mobile={this.state.formData.mobile}
                    />
                  )}
                </>
              )}

              {/* Terms and conditions */}
              <Container maxWidth="sm">
                {/*@ts-ignore */}
                <Modal
                  open={this.state.openTermsModal}
                  onClose={this.handleCloseTerms}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  {/*@ts-ignore */}
                  <Box sx={style.containerTerms}>
                    {/*@ts-ignore */}
                    <Box sx={style.terms}>
                      <Typography variant="h6">
                        <b>Terms & Conditions</b>
                      </Typography>
                      <img
                        src={close}
                        alt=""
                        height="18"
                        width="18"
                        style={{ cursor: 'hand' }}
                        onClick={this.handleCloseTerms}
                      />
                    </Box>
                    <Typography style={style.termsText}>
                      
                      <Markup content= {this.state.termsCondition.discription} />
                    </Typography>
                  </Box>
                </Modal>
              </Container>

              {/* Privay Policy */}
              <Container maxWidth="sm">
                {/*@ts-ignore */}
                <Modal
                  open={this.state.openPrivacyModal}
                  onClose={this.handleClosePrivacy}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  {/*@ts-ignore */}
                  <Box sx={style.containerTerms}>
                    {/*@ts-ignore */}
                    <Box sx={style.terms}>
                      <Typography variant="h6">
                        <b>Privacy Policy</b>
                      </Typography>
                      <img
                        src={close}
                        alt=""
                        height="18"
                        width="18"
                        onClick={this.handleClosePrivacy}
                        style={{ cursor: 'hand' }}
                      />
                    </Box>
                    <Typography style={style.termsText}>
                    <Markup content= {this.state.privacyPolicy.discription} />
                    </Typography>
                  </Box>
                </Modal>
              </Container>
            </div>
          </>
        ) : (
          /*@ts-ignore */
          <CongratulationsPage {...this.props} />
        )}
      </>
    );
    // Customizable Area End
  }
}

const style = {
  // Customizable Area Start
  image: {
    height: "100%",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  container: {
    p: 2,
    mt: 2,
    display: "flex",
    flexDirection: "column",
  },
  checkboxImage: {
    marginTop: 8,
    marginRight: 8,
    cursor: "pointer",
  },
  continueBtn: {
    marginTop: 12,
    padding: "15px",
    borderRadius: 8,
  },
  checkbox: {
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  text: {
    marginTop: 10,
  },
  termsPolicy: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  containerOtp: {
    p: 2,
    mt: 2,
    display: "flex",
    flexDirection: "column",
  },
  textfield: {
    p: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  verifyBtn: {
    marginTop: "15px",
    marginBottom: "20px",
    padding: "15px",
    borderRadius: 8,
  },

  // Terms Style
  containerTerms: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    padding: "15px 32px 70px",
    position: "absolute" as "absolute",
    top: "67%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px solid #000",
    width: "93%",
    overflowY: "scroll",
   height:"100%"
  },
  terms: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  termsText: {

  }
  // Customizable Area End
};
