import React, { Component } from "react";
// Customizable Area Start

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
 
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Loader from "../../../components/src/Loader";
 
import Drawer from "@material-ui/core/Drawer";
 
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from '@material-ui/core/Snackbar';
 
import Backdrop from "@material-ui/core/Backdrop";
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import { icici_logo } from "../../dashboard/src/assets";
import {
 
  tappmart_logo,
 
  closeIcon,
  backgroundImage,
  backIcon
} from "./assets";
 
// Customizable Area End

// Customizable Area Start
 
import CustomerTravelInsController2, { Props } from "./CustomerTravelInsController2";
export default class CustomerTravelDetailsSteps extends CustomerTravelInsController2 {

  constructor(props: Props) {
    super(props);
     
  }
  
 

  render() {
    
  
    return (
      <>

        {/* Logo section */}

        <Snackbar open={this.state.openToast} autoHideDuration={1000} style={{ margin: "10px" }}>
          <Alert severity={this.state.toastType == "success" ? "success" : "error"}>
            {this.state.toastMessage}
          </Alert>
        </Snackbar>
        <Backdrop
          style={{ zIndex: 1000 + 1, color: "#fff" }}
          open={this.state.loading}
        >
           {/*@ts-ignore */}
          <Modal
            open={this.state.openModal}
            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modalClass">
              <div style={webStyle_CustomerTravelDetailsStepsTwo.modalStyle}>
                <div>
                  <InfoIcon style={{ marginTop: '15px' }} />
                </div>
                <div style={{ padding: 5 }}>
                  <h4>We regret that your policy creation has failed at our end. In case payment is done, it will be  refunded within next 72 hours.</h4>
                </div>
                <div>
                  <Button
                    onClick={() => this.closeModel()} >
                    <img height="15px" src={closeIcon} alt="" />
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Backdrop>


        <Grid container spacing={2}>
          <Grid item>
            <img
                src={tappmart_logo} 

                className="tappMartLogoStyle"
              onClick={() => this.RedirectToComponent("CustomerHomeScreen",'')}
            />
          </Grid>
        </Grid>{" "}
        {/* Steps-Container */}
        <CustomerTravelDetailsStepsTwo_FormContainer container>
        <Grid item>
            <Button>
              {" "}
              <img
                height="18px"
                src={backIcon}
                style={{ margin: "10px" }}
                onClick={this.handleBack}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={""}>
              <CustomerTravelDetailsStepsTwo_FormStepper  alternativeLabel>
                
                  <Step >
                   
                    <CustomerTravelDetailsStepsTwo_CustomStepLabel>{this.state.steps}</CustomerTravelDetailsStepsTwo_CustomStepLabel>
                  </Step>
             
              </CustomerTravelDetailsStepsTwo_FormStepper>
               
                <Grid style={CustomerTravelDetailsStepsTwo_formDivStyle}>
                
                  <Grid container spacing={2}>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                {" "}
                Email
              </CustomInputLabel_CustomerTravelDetailsStepsTwo>
              <CustomInputField_CustomerTravelDetailsStepsTwo
                id="outlined-basic"
                placeholder="Email"
                variant="outlined"
                type="text"
                value={this.state.email}
                onChange={this.handleEmail}
                error={!this.state.isEmailValid}
                helperText={
                  this.state.isEmailValid
                    ? ""
                    : "Please enter a valid Email."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                Full Name
              </CustomInputLabel_CustomerTravelDetailsStepsTwo>
              <CustomInputField_CustomerTravelDetailsStepsTwo
                id="outlined-basic"
                placeholder="Full Name"
                type="text"
                aria-invalid="false"
                value={this.state.insurerName}
                variant="outlined"
                onChange={this.handleInsurerName}
                error={!this.state.insurerNameValid}
                helperText={
                  this.state.insurerNameValid
                    ? ""
                    : "Please enter a valid Insurer name."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                Pincode
              </CustomInputLabel_CustomerTravelDetailsStepsTwo>
              <CustomInputField_CustomerTravelDetailsStepsTwo
                id="outlined-basic"
                variant="outlined"
                type="number"
                placeholder="Pincode"
                value={this.state.pinCode}
                onChange={this.handlePinCode}
                 error={!this.state.isPinCodeValid}
                helperText={
                  this.state.isPinCodeValid
                    ? ""
                    : "Please enter a valid pincode."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                State
              </CustomInputLabel_CustomerTravelDetailsStepsTwo>
              <CustomInputField_CustomerTravelDetailsStepsTwo
                id="outlined-basic"
                variant="outlined"
                type="text"
                disabled
                placeholder="State"
                value={this.state.selectedState}
                onChange={this.handleState}
                error={!this.state.isSelectedStateValid}
                helperText={
                  this.state.isSelectedStateValid
                    ? ""
                    : "Please enter a valid state."
                }
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                City
              </CustomInputLabel_CustomerTravelDetailsStepsTwo>
              <CustomInputField_CustomerTravelDetailsStepsTwo
                id="outlined-basic"
                variant="outlined"
                type="text"
                disabled
                placeholder="City"
                value={this.state.city}
                onChange={this.handleCity}
                error={!this.state.isCityValid}
                helperText={
                  this.state.isCityValid
                    ? ""
                    : "Please enter a valid city."
                }
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
                    <CustomInputLabel_CustomerTravelDetailsStepsTwo htmlFor="component-simple">
                      Phone Number
                    </CustomInputLabel_CustomerTravelDetailsStepsTwo>
                    <CustomInputField_CustomerTravelDetailsStepsTwo
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Phone Number"
                      value={this.state.phoneNumber}
                      onChange={this.handlePhoneNumber}
                      error={!this.state.isPhoneNumberValid}
                      helperText={
                        this.state.isPhoneNumberValid
                          ? ""
                          : "Please enter a valid Number."
                      }
                    />
                  </Grid>


                  </Grid>
            
              </Grid> 
            
            </div>
          </Grid>
        </CustomerTravelDetailsStepsTwo_FormContainer>
        {/* Premium section */}
        <CustomerTravelDetailsStepsTwo_BottomContainer container spacing={2}>
          
            <>
              <Grid item sm={7} lg={7} xs={7}>
                <span>Total Premium</span>
                <br/>
                <span>
                  499.00 <ExpandMoreIcon />
                </span>
              </Grid>
              <Grid item sm={5} lg={5} xs={5}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => this.handleProceed()}
                  >
                    Proceed
                  </Button>
                </div>
              </Grid>
            </>
          
        
         
 
        </CustomerTravelDetailsStepsTwo_BottomContainer>
        <Loader loading={this.state.loading} />
        {/* Drawer Section */}
        <CustomDrawer_CustomerTravelDetailsStepsTwo
          open={this.state.openPolicyDetailDrawer}
          anchor={"bottom"}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.paddingLeftBottom}
          >
            <Grid item>
              <h3> Payment Details</h3>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                {
                  this.handleDrawerClose();
                }
                }
              >
                <img height="10px" src={closeIcon} alt="" />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.paddingLeftBottom}
          >
            <Grid item>
              <img style={webStyle_CustomerTravelDetailsStepsTwo.digitImage} src={icici_logo} alt="" />
            </Grid>
            <Grid item>
              <h4> Total Premium</h4>
              <h3>{this.state.policyDetailsForm.premium}</h3>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.drawerSectionStyle}
          >
            <Grid item>
              <p>Basic Premium</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle_CustomerTravelDetailsStepsTwo.marginZero}>{this.state.basicPremium}</h3>
            </Grid>
          </Grid>

          
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.drawerSectionStyle}
          >
            <Grid item>
              <p>Policy Period</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle_CustomerTravelDetailsStepsTwo.marginZero}>1 Year</h3>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.drawerSectionStyle}
          >
            <Grid item>
              <p>GST(18%)</p>
            </Grid>
            <Grid item>
              <h3 style={webStyle_CustomerTravelDetailsStepsTwo.marginZero}>{this.state.policyDetailsForm.gst}</h3>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={webStyle_CustomerTravelDetailsStepsTwo.paddingLeftBottom}
            spacing={2}
          >
            <Grid item sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                 {
                 
                  this.createPayOrder();
                 }
                }
                style={{ width: "100%", marginTop: "100px" }}
              >
                 
                Make Payment
              </Button>

            </Grid>
          </Grid>
        </CustomDrawer_CustomerTravelDetailsStepsTwo>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const CustomerTravelDetailsStepsTwo_FormStepper = withStyles({
  root: {
   font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    padding: "15px 5px",
    background:'inherit'
  },
})(Stepper);
const CustomerTravelDetailsStepsTwo_FormContainer = withStyles({
  root: {
    background: `url(${backgroundImage})`,

    backgroundSize: "cover",
    font:
      "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(Grid);

const CustomerTravelDetailsStepsTwo_formDivStyle = {
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "10px",
  margin: "10px",
};
const CustomerTravelDetailsStepsTwo_BottomContainer = withStyles({
  root: {
    padding: "10px 15px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
 

  },
})(Grid);

 

// Customizable Area Start
const CustomerTravelDetailsStepsTwo_CustomStepLabel = withStyles({
  root: {
    color: "white",
    "& .MuiStepLabel-labelContainer": {
      color: "white",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "white",
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "White",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "white",
        border: "none",
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "transparent",
        border: "1px solid white",
        borderRadius: "12px",
      },
      "& .MuiStepIcon-root": {
        border: "1px solid #ada7a7",
        borderRadius: "12px",
        color: "transparent",
      },
    },
    font:
      "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(StepLabel);
// Customizable Area End
const webStyle_CustomerTravelDetailsStepsTwo:any = {
  greyText: {
    color: "grey",
    marginTop: "5px",
  },
  paddingLeftBottom: {
    padding: "0 15px",
  },
  marginBottom: "15px",
  digitImage: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #8080804a",
    height: "50px",
  },
  drawerSectionStyle: {
    borderBottom: "1px solid #4a444430",
    padding: "0 15px",
  },
  marginZero: {
    margin: "0px",
  },
  checkedRadio: {
    border: "1px solid #4054b5",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#4054b5",
    fontWeight: "bold",
  },
  unCheckedRadio: {
    border: "1px solid #e1e1e1",
    paddingLeft: "10px",
    borderRadius: "5px",
    margin: "5px 0",
    color: "#848484",
    fontWeight: "bold",
  },
  modalStyle: {
    color: "grey",
    display: "flex",
    alignItems: "baseline",
    backgroundColor: "white",
    padding: "5px",
    font: "15px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
    borderRadius: "7px"
  }
};

const InputField_CustomerTravelDetailsStepsTwo = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

const CustomInputLabel_CustomerTravelDetailsStepsTwo = withStyles({
  root: {
    color: "#000",
    margin: "10px 0",
    fontSize: "14px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  },
})(InputLabel);

const CustomDrawer_CustomerTravelDetailsStepsTwo = withStyles({
  root: {
    "& .MuiDrawer-paper": {
      padding: "0 5px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
  
    },
  },
})(Drawer);

const CustomButtons = withStyles({
  root: {
    boxShadow: "rgb(128 128 128 / 53%) -2px 2px 8px 0px",
    margin: "10px 5px",
    color: "rgb(173,95,189)",
    minWidth: "50px",
  },
})(Button);

const CustomFormControl = withStyles({
  root: {
    width: "100%",
    marginTop: 5,
  },
})(FormControl);

const CustomRadioGroup = withStyles({
  root: {
    display: "block",
    "& .MuiFormControlLabel-root": {
      "& .MuiTypography-body1": {
        font:
          "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
      },
    },
  },
})(RadioGroup);

const CustomInputField_CustomerTravelDetailsStepsTwo = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(InputField_CustomerTravelDetailsStepsTwo);

const CustomSelect = withStyles({
  root: {
    padding: "15px",
  },
})(Select);

const IllnessRadioGroup = withStyles({
  root: {
    
    "& .MuiFormControlLabel-root": {
      "& .MuiTypography-body1": {
        font:
          "14px system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif",
      },
    },
  },
})(RadioGroup);
// Customizable Area End


